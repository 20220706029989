/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable import/no-unresolved */
// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import React, { useState, useRef, useEffect } from "react";
import {
  Input as InputComponent,
  Attachment,
  IconButton,
  useNotificationDispatch,
  Remove,
  Label,
} from "amazon-chime-sdk-component-library-react";

import debounce from "lodash/debounce";

import {
  Persistence,
  MessageType,
  sendChannelMessage,
  getChannelMessage,
  updateChannelReadMarker,
  describeChannelMembershipForAppInstanceUser,
  describeChannel,
} from "../../api/ChimeAPI";
import formatBytes from "../../utilities/formatBytes";
import AttachmentService from "../../services/AttachmentService";
import {
  useChatMessagingState,
  useChatChannelState,
} from "../../providers/ChatMessagesProvider";
import { useAuthContext } from "../../providers/AuthProvider";

import "../../../../assets/styles/pluginchat.scss";
import { appConfig } from "../../Config";
import { GROUP_CHANNEL_TYPE_TWO, INDIVIDUAL_CHANNEL_TYPE } from "../../../../app/utils/Constants";
import { systemChatPermission } from "../../operations/chatPermissionSlice";
import { useSelector } from "react-redux";
import { subSetUsersList } from "../../../LandingPage/subSetUserSlice";
import toast from "react-hot-toast";
import ToastContent from "../../../../components/layouts/ToastContent";

const uploadObjDefaults = {
  name: "",
  file: "",
  type: "",
  response: null,
  key: "",
};

const Input = ({ activeChannelArn, member, hasMembership }) => {
  const [text, setText] = useState("");
  const inputRef = useRef();
  const [uploadObj, setUploadObj] = useState([]);
  const uploadRef = useRef();
  const [sendDisable, setSendDisable] = useState(false);
  const [loader, setLoader] = useState(false);
  const systemChatPermissionResponse = useSelector(systemChatPermission);
  const subSetUsers = useSelector(subSetUsersList);
  const notificationDispatch = useNotificationDispatch();
  const { messages, setMessages } = useChatMessagingState();
  const { activeChannel, setReadMarker, setChannelList, channelList } =
    useChatChannelState();
  const { userId, username } = useAuthContext().member;
  const { isAnonymous } = useAuthContext();

  let permission =
    (window.localStorage.getItem("userDetail") &&
      JSON.parse(window.localStorage.getItem("userDetail")).permission &&
      JSON.parse(window.localStorage.getItem("userDetail")).permission) ||
    [];

  const chatPermission = permission.filter((perm) => perm.page === "chats");
  const access = chatPermission.length === 1 ? chatPermission[0].access : [];
  const canUpload =
    access && access.find((item) => item === "attachments") === "attachments"
      ? true
      : false;

  const resetState = () => {
    setText("");
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
    setText("");
  }, [activeChannelArn]);

  useEffect(() => {
    if (text) {
      eventHandlerWithDebounce();
    }
    resize()
  }, [text]);

  const resize = () => {
    var tx = document.getElementById('chat-search-box');
    if (tx) {
      tx.setAttribute('style', 'height:' + (text ? tx.scrollHeight : 20) + 'px; overflow-y:auto;');
      tx.addEventListener("input", OnInput, false);
    }
  }

  function OnInput(e) {
    this.style.height = '20';
    this.style.height = (text ? this.scrollHeight : 20) + 'px';
  }

  const onUpdateChannelReadMarker = async (channelArn) => {
    await updateChannelReadMarker(appConfig.appInstanceArn, channelArn, userId);
  };

  const eventHandler = async () => {
    const content = JSON.stringify({ Typing: "Indicator" });
    const response = await sendChannelMessage(
      activeChannel.ChannelArn,
      content,
      "NON_PERSISTENT",
      "CONTROL",
      member
    );
  };
  const eventHandlerWithDebounce = React.useCallback(
    debounce(eventHandler, 500),
    []
  );

  const onDescribeChannelMembershipForAppInstanceUser = async (channelArn) => {
    let channels = channelList;
    let channelIndex = channels.findIndex((ch) => ch.ChannelArn === channelArn);

    const a =
      (await describeChannelMembershipForAppInstanceUser(
        appConfig.appInstanceArn,
        channelArn,
        userId
      )) || {};
    const { AppInstanceUserMembershipSummary = {}, ChannelSummary = {} } = a;
    const { ReadMarkerTimestamp } = AppInstanceUserMembershipSummary;
    const { LastMessageTimestamp } = ChannelSummary;
    channels[channelIndex] = {
      ...channels[channelIndex],
      ReadMarkerTimestamp,
      LastMessageTimestamp,
    };
    setReadMarker(ReadMarkerTimestamp);
    setChannelList(channels);
    // let index = temp.findIndex((val) => val === a.ChannelSummary.ChannelArn);
    // if (index > 0) {
    //   temp.splice(index, 1);
    //   setNewMessages(temp);
    // }
  };
  const onChange = (e) => {
    setText(e.target.value);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (sendDisable) {
      return;
    }
    setSendDisable(true);
    setLoader(true);
    let sendMessageResponse;

    if (uploadObj.length > 0) {
      try {
        // We have files to upload
        const files = uploadObj;
        const uploadResponse = await Promise.all(files.map(async (item) => {
          const response = await AttachmentService.upload(item.file, userId);
          return response;

        }));
        let tempData = [];
        let attachments = [];
        uploadResponse.forEach((res, i) => {
          tempData.push({
            key: res.key,
            ...files[i].file
          })
          attachments.push({
            fileKey: res.key,
            name: files[i].file.name,
            size: files[i].file.size,
            type: files[i].file.type,
            userLevel: res.userLevel,
            userUploadDir: res.userUploadDir
          })
        })
        setUploadObj(tempData);
        const options = {};
        options.Metadata = JSON.stringify({
          attachments,
        });
        sendMessageResponse = await sendChannelMessage(
          activeChannelArn,
          text || " ",
          Persistence.PERSISTENT,
          MessageType.STANDARD,
          member,
          options
        );
        // Cleanup upload refs
        setUploadObj([]);
        uploadRef.current.value = "";
        setSendDisable(false);

      } catch (err) {
        setSendDisable(false);
        toast(
          (t) => <ToastContent type="error" message={`Upload failed, please try again later`} close={() => toast.remove()} />,
          {
            duration: 4000,
          }
        );
      }
    } else {
      try {
        sendMessageResponse = await sendChannelMessage(
          activeChannelArn,
          text,
          Persistence.PERSISTENT,
          MessageType.STANDARD,
          member
        );
        onUpdateChannelReadMarker(activeChannelArn);
        setSendDisable(false)
        // onDescribeChannelMembershipForAppInstanceUser(activeChannelArn);
      } catch (e) {
        setSendDisable(false)
      }
    }
    resetState();
    if (inputRef && inputRef.current) {
      inputRef.current.style.height = "30px";
    }
    //update currentChannel last read timestamp
    const channel = await describeChannel(activeChannelArn, userId);
    let channels = channelList;
    let channelIndex = channels.findIndex((ch) => ch.ChannelArn === activeChannelArn);
    channels[channelIndex] = {
      ...channels[channelIndex],
      ReadMarkerTimestamp: channel.LastMessageTimestamp,
      LastMessageTimestamp: channel.LastMessageTimestamp,
    };
    setChannelList(channels);
    if (sendMessageResponse && sendMessageResponse.response && sendMessageResponse.response.Status == "PENDING") {
      await getChannelMessage(
        activeChannelArn,
        member,
        sendMessageResponse.response.MessageId
      );

    }
    setLoader(false)
  };

  const onRemoveAttachmentHandler = (event, index) => {
    event.preventDefault();
    let temp = uploadObj;
    temp = temp.filter((t, idx) => idx !== index);
    setUploadObj(temp);
  };

  const uploadButton = (disableInput, canUpload) => {
    return (
      <div className="attach-icon-wrapper">
        <IconButton
          className="write-link attach"
          onClick={(_event) => {
            uploadRef.current.value = null;
            uploadRef.current.click();
          }}
          icon={<Attachment />}
          onKeyDown={e => {
            e.preventDefault();
            if (e.code === "Enter" && !((!text && (!uploadObj || uploadObj.length === 0)) || sendDisable || disableInput)) {
              onSubmit(e)
            }
          }}
          disabled={disableInput || !canUpload}
        />
      </div>
    )
  };

  if (hasMembership) {
    let disableInput = false;
    const { Name: channelName = "", ChannelArn } = activeChannel || {};
    const { content = {} } = systemChatPermissionResponse || {};
    const { send = [] } = content || {};
    if (channelName.includes(GROUP_CHANNEL_TYPE_TWO) && !send.includes(ChannelArn)) {
      disableInput = true;
    } else if (channelName.includes(INDIVIDUAL_CHANNEL_TYPE)) {
      let tempName = channelName.replace(INDIVIDUAL_CHANNEL_TYPE, '');
      let allUsers = tempName.split(", ");
      if (allUsers.includes(username)) {
        allUsers = allUsers.filter(user => user !== username);
        const { content: allSubSetUsers = {} } = subSetUsers || {};
        if (allUsers.length === 1 && Object.keys(allSubSetUsers).includes(allUsers[0])
          && (!allSubSetUsers[allUsers[0]].active || !allSubSetUsers[allUsers[0]].profile)) {
          disableInput = true;
        }
      }
    }
    return (
      <div className="message-input-container">
        <div className={`message-input-form ${disableInput ? "inactive-text" : ""}`}>
          <div className="w-100 ch-msg-wrapper">
            <label htmlFor="chat-search-box" className="visually-hidden">
              <span className="visually-hidden">Search Box</span>
            </label>
            <textarea
              height="30px"
              onChange={onChange}
              id="chat-search-box"
              value={text}
              type="text"
              placeholder="Type your message here"
              autoFocus
              className="text-input ch-input-wrapper"
              ref={inputRef}
              disabled={disableInput}
              onKeyDown={e => {
                if (e.code === "Enter" && !((!text && (!uploadObj || uploadObj.length === 0)) || sendDisable || disableInput)) {
                  onSubmit(e)
                }
              }}
            ></textarea>
          </div>
          {loader && <div
            className="ad-loading-wrapper d-block"><span className="loader ad-loader d-block">
              <span className="visually-hidden">loader icon</span>
            </span>
          </div>}
          {canUpload && uploadObj.length > 0 ? (
            <div className="attachment-preview file-attachment-wrapper px-1 pb-1">{
              uploadObj.map((file, index) => {
                return (
                  <div className="attach-container" key={index}>
                    <Attachment
                      style={{ margin: "auto 0" }}
                      width="1.5rem"
                      height="1.5rem"
                    />
                    <Label style={{ margin: "auto 0" }}>{file?.name}</Label>
                    <IconButton onClick={e => onRemoveAttachmentHandler(e, index)} icon={<Remove width="1.5rem" height="1.5rem" />} />
                  </div>
                )
              })
            }
            </div>
          ) : null}

          {isAnonymous ? "\u00a0\u00a0" : uploadButton(disableInput, canUpload)}
          <div className="helo">
            <label htmlFor="chat-box-attachment" className="visually-hidden">
              <span>Chat Attachment</span>
            </label>
            {canUpload && <input
              type="file"
              id="chat-box-attachment"
              className="ch-attachment-wrapper"
              accept="application/pdf, image/gif, image/jpeg, image/png, image/jpg"
              style={{ display: "none" }}
              ref={uploadRef}
              onChange={(event) => {
                let files = event.currentTarget.files;
                if (!files) return;
                files = Array.prototype.slice.call(files);
                let filesSize = 0;
                let tempFiles = [];
                files.forEach(f => {
                  tempFiles.push({
                    file: f,
                    name: f.name,
                  });
                  filesSize = filesSize + f.size;
                })
                if (tempFiles.every(f => {
                  const fileExt = f.name.slice((Math.max(0, f.name.lastIndexOf(".")) || Infinity) + 1);
                  return fileExt === "pdf" || fileExt === "gif" || fileExt === "jpeg" || fileExt === "png" || fileExt === "jpg"
                })) {
                  if (filesSize / 1024 / 1024 < 5) {
                    setUploadObj(tempFiles);
                  } else {
                    toast(
                      (t) => <ToastContent type="error" message={`File/Files size (${formatBytes(
                        filesSize
                      )}) Maximum supported file size is up to 5MB.`} close={() => toast.remove()} />,
                      {
                        duration: 4000,
                      }
                    );
                  }
                } else {

                  toast(
                    (t) => (
                      <ToastContent type="error" message={`File format not supported`} close={() => toast.remove()} />
                    ),
                    {
                      duration: 4000,
                    }
                  );
                }
              }}
              multiple
            />}
          </div>
        </div>
        <button className={"ch-send-button " + ((!text && (!uploadObj || uploadObj.length === 0)) || sendDisable || disableInput ? "" : "ch-active")} onClick={onSubmit} disabled={(!text && (!uploadObj || uploadObj.length === 0)) || sendDisable || disableInput}><span className="msgsend-icon"><span className="visually-hidden">Send Message Icon</span> </span></button>
      </div>
    );
  }
  return (
    <div className="message-input-container join-channel-message">
      Join this chat/ room to send messages.
    </div>
  );
};

export default Input;
