import React from "react";
import { HOME } from "../../../app/utils/Constants";
import Logo from "../../../components/layouts/Logo";

export default function DeviceLinkExpired(props) {
  document.documentElement.classList.add("link-expired-screen");

  return (
    <React.Fragment>
      <Logo />
      <div data-testid="device-link-expired-sand-complete-icon" className="mb-15 auth-success-wrapper position-relative">
        <span className="icon-sand-complete success-info">
          <span className="visually-hidden">Sand Complete Icon</span>
        </span>
      </div>
      <div>
        <h1 data-testid="device-link-expired-header" className="mb-15 roboto-regular login-title">
          {props &&
          props.location &&
          props.location.state &&
          props.location.state.path &&
          props.location.state.path === "reset"
            ? "Lost Device Link Expired"
            : props.location?.state.path === "invite" && ""}
        </h1>
        <p className="mb-20 roboto-regular login-info">
          This link was not used within 15 minutes. <br />
        </p>
        <button
          className="primary-btn"
          type="button"
          onClick={() => {
            props.history?.push(HOME);
          }}
        >
          <span>Go to Login</span>
          <span className="loader">
            <span className="visually-hidden">loader icon</span>
          </span>
        </button>
      </div>
    </React.Fragment>
  );
}
