import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ahaApi from "../../app/services/aha-api";
const initialState = {
  value: {},
  loading: false
};

export const orderFilterList = createAsyncThunk(
  "order/filter",
  async (data, { rejectWithValue }) => {
    const response = await ahaApi.Account.orderFilterList(data)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return rejectWithValue(err.response.data);
      });
    return response;
  }
);

export const orderFilterListSlice = createSlice({
  name: "orderFilterList",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(orderFilterList.pending, (state) => {
        state.loading = true;
      })
      .addCase(orderFilterList.fulfilled, (state, action) => {
        state.loading = false;
        state.value = action.payload;
      })
      .addCase(orderFilterList.rejected, (state, err) => {
        state.loading = false;
        state.value = err.payload;
      });
  },
});

export const orderFilterListResponse = (state) => state.orderFilterList.value;
export default orderFilterListSlice.reducer;
