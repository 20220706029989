import React from "react";

export default function Footer() {
  return (
    <footer>
      <p data-testid="footer" className="font-size-13 roboto-light white-color">
        &copy; 2024 mobileAHA. All Rights Reserved.
      </p>
    </footer>
  );
}
