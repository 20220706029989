import React from "react";
import { TRACKER, USERLIST } from "../../../app/utils/Constants";
import Logo from "../../../components/layouts/Logo";
import { useDispatch, useSelector } from "react-redux";
import { getProfile, profileList } from "../../LandingPage/Profile/profileSlice";

export default function AccessStatus() {
  const dispatch = useDispatch();
  const profile = useSelector(profileList);
  React.useEffect(() => {
    dispatch(getProfile());
  }, []);

  React.useEffect(() => {
    if (profile && profile.status === "SUCCESS") {
      if (profile && profile.content) {
        localStorage.setItem(
          "userDetail",
          JSON.stringify(profile && profile.content)
        );
      }
      setTimeout(() => {
        window.location.href = TRACKER;
      }, 1000);
    } else if (profile && profile.status === "ERROR") {
      setTimeout(() => {
        window.location.href = TRACKER;
      }, 1000);
    }
  }, [profile]);


  return (
    <React.Fragment>
      <Logo />
      <div data-testid="access-checkmark-circle" className="mb-15 auth-success-wrapper position-relative">
        <span className="checkmark-with-circle auth-success success-info">
          <span className="visually-hidden">Checkmark with circle</span>
        </span>
      </div>
      <h1 className="mb-15 roboto-regular login-title">
        Authorization Granted!
      </h1>
      <p
        style={{ color: "#0D6E2B" }}
        className="mb-15 roboto-regular login-info"
      >
        You are successfully logged in. Please wait while we redirect you.
      </p>
    </React.Fragment>
  );
}
