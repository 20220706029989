import _ from "lodash";
import uuid from "react-uuid";
import { BACKLOG_SECTION_TYPE, SCHEDULER_BOARD_SECTION_TYPE, SCHEDULE_SERVICE_TYPE } from "../../../app/utils/Constants";

export const handleDropEventOnCall = (
  result,
  initialBoardData,
  scheduleBoardData,
  backlogData = []
) => {
  if (!isDropPermitted(result.draggableId, result.destination.droppableId, scheduleBoardData)) {
    return {
      newOnCallScheduleBoardData: scheduleBoardData,
      resultBacklogData: backlogData,
    };
  }
  if (checkStartTimeEndTime(result.draggableId, result.destination.droppableId, scheduleBoardData)) {
    if (
      result.source.droppableId.startsWith(BACKLOG_SECTION_TYPE) &&
      result.destination.droppableId.startsWith("user")
    ) {
      return handleBacklogToUserDragEvent(result, initialBoardData, scheduleBoardData, backlogData);
    }

    if (
      result.source.droppableId.startsWith(BACKLOG_SECTION_TYPE) &&
      result.destination.droppableId.startsWith("emptyUser")
    ) {
      return handleBacklogToEmptyUserDragEvent(
        result,
        initialBoardData,
        scheduleBoardData,
        backlogData
      );
    }

    if (
      result.source.droppableId.startsWith("user") &&
      result.destination.droppableId.startsWith("user")
    ) {
      return handleUserToUserDragEvent(result, initialBoardData, scheduleBoardData, backlogData);
    }

    if (
      result.source.droppableId.startsWith("user") &&
      result.destination.droppableId.startsWith("emptyUser")
    ) {
      return handleUserToEmptyUserDragEvent(
        result,
        initialBoardData,
        scheduleBoardData,
        backlogData
      );
    }
  } else {
    return {
      errorMessage: "Please choose the shift slot",
      newOnCallScheduleBoardData: scheduleBoardData,
      resultBacklogData: backlogData,
    }
  }
  return {
    newOnCallScheduleBoardData: scheduleBoardData,
    resultBacklogData: backlogData,
  };
};

const handleUserToUserDragEvent = (
  result = {},
  initialBoardData,
  scheduleBoardData,
  backlogData = []
) => {
  const tempScheduleBoardData = _.cloneDeep(scheduleBoardData);
  let newOnCallScheduleBoardData = _.cloneDeep(scheduleBoardData);
  let parsedResult = parseSourceAndDestinationData(result);

  let payload = {};
  let resultBacklogData = backlogData;

  const { source = {}, destination = {} } = result;
  if (source.droppableId !== destination.droppableId) {
    let sourceUserObj = newOnCallScheduleBoardData[0].users[parsedResult.source.userIndex] || {};
    let isNoCoverageTypeSource = sourceUserObj.type_id === SCHEDULE_SERVICE_TYPE.NO_COVERAGE.ID;

    let destinationUserObj = newOnCallScheduleBoardData[0].users[parsedResult.destination.userIndex] || {};
    let isNoCoverageTypeDest = destinationUserObj.type_id === SCHEDULE_SERVICE_TYPE.NO_COVERAGE.ID;
    let sourceServices = newOnCallScheduleBoardData[0].users[parsedResult.source.userIndex].services || [];
    let sourceServiceIndex = -1;
    let sourceBlockId = null;
    sourceServices.forEach((ser, index) => {
      if (ser.uniqueServiceId === parsedResult.source.uniqueServiceId) {
        sourceServiceIndex = index;
        sourceBlockId = ser.block_id || null;
      }
    })
    if (sourceServiceIndex >= 0) {
      const zone =
        newOnCallScheduleBoardData[0].users[parsedResult.source.userIndex].services[
          sourceServiceIndex
        ].territories[parsedResult.source.territoryIndex].events[
          parsedResult.source.routineIndex
        ].zones[parsedResult.source.zoneIndex];

      if (!isNoCoverageTypeDest) {
        resultBacklogData = addUserToBacklog(
          newOnCallScheduleBoardData[0].users[parsedResult.source.userIndex],
          {
            ...parsedResult, source: {
              ...parsedResult.source, routineIndex: "oncall"
            }
          },
          backlogData,
          zone,
          newOnCallScheduleBoardData[0].users[parsedResult.destination.userIndex]
        );
      }

      let sourceZones =
        newOnCallScheduleBoardData[0].users[parsedResult.source.userIndex].services[
          sourceServiceIndex
        ].territories[parsedResult.source.territoryIndex].events[
          parsedResult.source.routineIndex
        ].zones;

      sourceZones.splice(parsedResult.source.zoneIndex, 1);

      if (sourceZones.length === 0) {
        //removing event
        let sourceSchedules =
          newOnCallScheduleBoardData[0].users[parsedResult.source.userIndex].services[
            sourceServiceIndex
          ].territories[parsedResult.source.territoryIndex].events;

        delete sourceSchedules[parsedResult.source.routineIndex];

        if (!sourceSchedules || Object.keys(sourceSchedules).length === 0) {
          //remove territory
          let sourceTerritories =
            newOnCallScheduleBoardData[0].users[parsedResult.source.userIndex].services[
              sourceServiceIndex
            ].territories;

          delete sourceTerritories[parsedResult.source.territoryIndex];
          // newOnCallScheduleBoardData[0].users[parsedResult.source.userIndex].services[
          //   sourceServiceIndex
          // ].territories = sourceTerritories;
          if (!sourceTerritories || _.isEmpty(sourceTerritories)) {
            sourceServices = sourceServices.filter(ser => ser.uniqueServiceId !== parsedResult.source.uniqueServiceId)
            newOnCallScheduleBoardData[0].users[parsedResult.source.userIndex].services = sourceServices;
          } else {
            newOnCallScheduleBoardData[0].users[parsedResult.source.userIndex].services[
              sourceServiceIndex
            ].territories = sourceTerritories;
          }
        } else {
          newOnCallScheduleBoardData[0].users[parsedResult.source.userIndex].services[
            sourceServiceIndex
          ].territories[parsedResult.source.territoryIndex].events = sourceSchedules;
        }
      } else {
        newOnCallScheduleBoardData[0].users[parsedResult.source.userIndex].services[
          sourceServiceIndex
        ].territories[parsedResult.source.territoryIndex].events[
          parsedResult.source.routineIndex
        ].zones = sourceZones;
      }

      //check if event type is different
      let originalSourceService =
        tempScheduleBoardData[0].users[parsedResult.source.userIndex].services[
        sourceServiceIndex
        ]
      let sourceTerritory =
        tempScheduleBoardData[0].users[parsedResult.source.userIndex].services[
          sourceServiceIndex
        ].territories[parsedResult.source.territoryIndex] || {};

      let destinationServices = newOnCallScheduleBoardData[0].users[parsedResult.destination.userIndex].services || [];
      // const destinationTerritory = {};
      let destServiceIndex = -1;
      destinationServices.forEach((ser, index) => {
        if (ser.uniqueServiceId === parsedResult.destination.uniqueServiceId) {
          destServiceIndex = index;
        }
      });

      if (destServiceIndex >= 0) {

        let destinationTerritory =
          newOnCallScheduleBoardData[0].users[parsedResult.destination.userIndex]
            .services[destServiceIndex].territories[
          parsedResult.destination.territoryIndex
          ];

        let sourceSchedule =
          tempScheduleBoardData[0].users[parsedResult.source.userIndex].services[
            sourceServiceIndex
          ].territories[parsedResult.source.territoryIndex].events[
          parsedResult.source.routineIndex
          ];

        let destinationSchedule =
          newOnCallScheduleBoardData[0].users[parsedResult.destination.userIndex]
            .services[destServiceIndex].territories[
            parsedResult.destination.territoryIndex
          ].events[parsedResult.destination.routineIndex];

        // if same zone already exists
        if (destinationSchedule.zones && destinationSchedule.zones.some(z => z.zone_id == zone.zone_id)) {
          return {
            newOnCallScheduleBoardData: scheduleBoardData,
            resultBacklogData: backlogData
          };
        }

        let destTerritories =
          newOnCallScheduleBoardData[0].users[parsedResult.destination.userIndex]
            .services[destServiceIndex].territories;

        // check if dragged zone's timezone matches with the service timezone
        if (originalSourceService?.timezone?.timezone_id == destinationServices[destServiceIndex]?.timezone?.timezone_id) {
          //check for different territories
          if (sourceTerritory.territory_id != destinationTerritory.territory_id) {
            if (
              Object.keys(destTerritories).some(
                (territoryId) => destTerritories[territoryId].territory_id == sourceTerritory.territory_id
              )
            ) {
              if (sourceSchedule.event_type_id != destinationSchedule.event_type_id) {
                let destSchedules =
                  newOnCallScheduleBoardData[0].users[parsedResult.destination.userIndex]
                    .services[destServiceIndex].territories[
                    parsedResult.source.territoryIndex
                  ].events || {};

                const newEventId = isNoCoverageTypeDest ? "nocoverage" : sourceSchedule.event_type_id;

                //check if dragged zone's event type is already present or not
                if (
                  Object.keys(destSchedules).some(
                    (sch) =>
                      destSchedules[sch].event_type_id ===
                      newEventId
                  )
                ) {
                  const destZones =
                    destSchedules[newEventId].zones || [];
                  destZones.push(zone);
                  destSchedules[newEventId].zones =
                    destZones;
                } else {
                  if (isNoCoverageTypeDest) {
                    sourceSchedule = {
                      ...sourceSchedule,
                      zones: [zone],
                      event_type: SCHEDULE_SERVICE_TYPE.NO_COVERAGE.NAME,
                      event_type_id: newEventId,
                      service_type: SCHEDULE_SERVICE_TYPE.NO_COVERAGE.ID
                    }
                    destSchedules[sourceSchedule.event_type_id] = sourceSchedule;
                  } else {
                    sourceSchedule = {
                      ...sourceSchedule,
                      zones: [zone],
                      event_type: SCHEDULE_SERVICE_TYPE.ON_CALL.NAME,
                      event_type_id: newEventId,
                      service_type: SCHEDULE_SERVICE_TYPE.ON_CALL.ID
                    }
                    destSchedules[sourceSchedule.event_type_id] = sourceSchedule;
                  }
                }
              } else {
                const destZones =
                  destTerritories[sourceTerritory.territory_id].events[sourceSchedule.event_type_id]
                    .zones || [];
                destZones.push(zone);
              }
            } else {
              if (isNoCoverageTypeDest) {
                sourceSchedule = {
                  ...sourceSchedule,
                  zones: [zone],
                  event_type: SCHEDULE_SERVICE_TYPE.NO_COVERAGE.NAME,
                  event_type_id: "nocoverage",
                  service_type: SCHEDULE_SERVICE_TYPE.NO_COVERAGE.ID
                }
                sourceTerritory.events = {
                  [sourceSchedule.event_type_id]: sourceSchedule,
                };
                destTerritories[sourceTerritory.territory_id] = sourceTerritory;
              } else {
                sourceSchedule = {
                  ...sourceSchedule,
                  zones: [zone],
                  event_type: SCHEDULE_SERVICE_TYPE.ON_CALL.NAME,
                  event_type_id: "oncall",
                  service_type: SCHEDULE_SERVICE_TYPE.ON_CALL.ID
                }
                sourceTerritory.events = {
                  [sourceSchedule.event_type_id]: sourceSchedule,
                };
                destTerritories[sourceTerritory.territory_id] = sourceTerritory;
              }
            }
          } else {
            //check if event type is different
            if (sourceSchedule.event_type_id !== destinationSchedule.event_type_id) {
              let destSchedules =
                newOnCallScheduleBoardData[0].users[parsedResult.destination.userIndex]
                  .services[destServiceIndex].territories[
                  parsedResult.destination.territoryIndex
                ].events || {};

              const newEventId = isNoCoverageTypeDest ? "nocoverage" : "oncall";

              //check if dragged zone's event type is already present or not
              if (
                Object.keys(destSchedules).some(
                  (sch) =>
                    destSchedules[sch].event_type_id === newEventId
                )
              ) {
                const destZones =
                  destSchedules[newEventId].zones || [];
                destZones.push(zone);
                destSchedules[newEventId].zones =
                  destZones;
              } else {
                if (isNoCoverageTypeDest) {
                  sourceSchedule = {
                    ...sourceSchedule,
                    zones: [zone],
                    event_type: SCHEDULE_SERVICE_TYPE.NO_COVERAGE.NAME,
                    event_type_id: "nocoverage",
                    service_type: SCHEDULE_SERVICE_TYPE.NO_COVERAGE.ID
                  }
                  destSchedules[sourceSchedule.event_type_id] = sourceSchedule;
                } else {
                  sourceSchedule = {
                    ...sourceSchedule,
                    zones: [zone],
                    event_type: SCHEDULE_SERVICE_TYPE.ON_CALL.NAME,
                    event_type_id: "oncall",
                    service_type: SCHEDULE_SERVICE_TYPE.ON_CALL.ID
                  }
                  destSchedules[sourceSchedule.event_type_id] = sourceSchedule;
                }
              }
            } else {
              const { destination = {} } = result;
              const destIndex = destination.index;

              let zones =
                newOnCallScheduleBoardData[0].users[parsedResult.destination.userIndex]
                  .services[destServiceIndex].territories[
                  parsedResult.destination.territoryIndex
                ].events[parsedResult.destination.routineIndex].zones || [];

              zones.splice(destIndex, 0, zone);
            }
          }
        } else {
          const destinationService = destinationServices[destServiceIndex] || {};
          //check if there is an existing timezone block with same shift
          if (destinationServices.some(ser => ser.timezone?.timezone_id == originalSourceService.timezone?.timezone_id && (destinationService.shift_timings && destinationService.shift_timings.shift_allocation_id ? (ser?.shift_timings?.shift_allocation_id == destinationService?.shift_timings?.shift_allocation_id) : (ser.start_time === destinationService.start_time && ser.end_time === destinationService.end_time)))) {
            // yes, move the newly added zone to that block
            for (let i = 0; i < destinationServices.length; i++) {
              let ser = destinationServices[i] || {};
              if (ser.timezone?.timezone_id == originalSourceService.timezone?.timezone_id && (destinationService.shift_timings && destinationService.shift_timings.shift_allocation_id ? (ser?.shift_timings?.shift_allocation_id == destinationService?.shift_timings?.shift_allocation_id) : (ser.start_time === destinationService.start_time && ser.end_time === destinationService.end_time))) {
                let newSourceSchedule = _.cloneDeep(sourceSchedule);
                let newSourceTerritory = _.cloneDeep(sourceTerritory);
                //check for different territories
                let { territories = {} } = ser;
                if (!Object.keys(territories).includes(newSourceTerritory.territory_id.toString())) {
                  if (isNoCoverageTypeDest) {

                    newSourceSchedule = {
                      ...newSourceSchedule,
                      zones: [zone],
                      event_type: SCHEDULE_SERVICE_TYPE.NO_COVERAGE.NAME,
                      event_type_id: "nocoverage",
                      service_type: SCHEDULE_SERVICE_TYPE.NO_COVERAGE.ID
                    }
                    newSourceTerritory.events = {
                      ["nocoverage"]: newSourceSchedule,
                    };
                    territories[newSourceTerritory.territory_id] = newSourceTerritory;
                    destinationServices[i].territories = territories;
                  } else {
                    newSourceSchedule = {
                      ...newSourceSchedule,
                      zones: [zone],
                      event_type: SCHEDULE_SERVICE_TYPE.ON_CALL.NAME,
                      event_type_id: "oncall",
                      service_type: SCHEDULE_SERVICE_TYPE.ON_CALL.ID
                    }
                    newSourceTerritory.events = {
                      ["oncall"]: newSourceSchedule,
                    };
                    territories[newSourceTerritory.territory_id] = newSourceTerritory;
                    destinationServices[i].territories = territories;
                  }
                } else {
                  //check if event type is different
                  let { events = {} } = territories[newSourceTerritory.territory_id] || {};
                  const newEventId = isNoCoverageTypeDest ? "nocoverage" : newSourceSchedule.event_type_id;
                  //check if dragged zone's event type is already present or not
                  if (!Object.keys(events).includes(newEventId)) {

                    if (isNoCoverageTypeDest) {
                      newSourceSchedule = {
                        ...newSourceSchedule,
                        zones: [zone],
                        event_type: SCHEDULE_SERVICE_TYPE.NO_COVERAGE.NAME,
                        event_type_id: newEventId,
                        service_type: SCHEDULE_SERVICE_TYPE.NO_COVERAGE.ID
                      }
                      events[newEventId] = newSourceSchedule;
                      destinationServices[i].territories[newSourceTerritory.territory_id].events = events;
                    } else {
                      newSourceSchedule = {
                        ...newSourceSchedule,
                        zones: [zone],
                        event_type: SCHEDULE_SERVICE_TYPE.ON_CALL.NAME,
                        event_type_id: newEventId,
                        service_type: SCHEDULE_SERVICE_TYPE.ON_CALL.ID
                      }
                      events[newEventId] = newSourceSchedule;
                      destinationServices[i].territories[newSourceTerritory.territory_id].events = events;
                    }
                  } else {
                    let { zones = [] } = events[newEventId] || {};

                    // if same zone already exists
                    if (zones.some(z => z.zone_id == zone.zone_id)) {
                      return {
                        newOnCallScheduleBoardData: scheduleBoardData,
                        resultBacklogData: backlogData
                      };
                    }
                    zones.push(zone);
                destinationServices[i].territories[newSourceTerritory.territory_id].events[newEventId].zones = zones;

                  }
                }
                parsedResult.destination = {
                  ...parsedResult.destination,
                  uniqueServiceId: ser.uniqueServiceId,
                  blockId: ser.block_id,
                  territoryIndex: newSourceTerritory.territory_id,
                  serviceIndex: i
                }
                destServiceIndex = i;
              }
            }
        newOnCallScheduleBoardData[0].users[parsedResult.destination.userIndex].services = destinationServices;
          } else {
            // no, create a new block
            let newOnCallBlock = _.cloneDeep(newOnCallScheduleBoardData[0].users[parsedResult.destination.userIndex].services[destServiceIndex]) || {};
            const newId = uuid();
            newOnCallBlock.uniqueServiceId = newId;
            newOnCallBlock.event_id = "";
            newOnCallBlock.timezone = originalSourceService.timezone;
            // newOnCallBlock.block_id = "";

            const newEventId = isNoCoverageTypeDest ? "nocoverage" : sourceSchedule.event_type_id;
            let newSourceSchedule = _.cloneDeep(sourceSchedule);
            let newSourceTerritory = _.cloneDeep(sourceTerritory);

            if (isNoCoverageTypeDest) {
              newSourceSchedule = {
                ...newSourceSchedule,
                zones: [zone],
                event_type: SCHEDULE_SERVICE_TYPE.NO_COVERAGE.NAME,
                event_type_id: newEventId,
                service_type: SCHEDULE_SERVICE_TYPE.NO_COVERAGE.ID
              }
            } else {
              newSourceSchedule = {
                ...newSourceSchedule,
                zones: [zone],
                event_type: SCHEDULE_SERVICE_TYPE.ON_CALL.NAME,
                event_type_id: newEventId,
                service_type: SCHEDULE_SERVICE_TYPE.ON_CALL.ID
              }
            }
            newSourceTerritory.events = {
              [newEventId]: newSourceSchedule,
            };
            newOnCallBlock.territories = {
              [newSourceTerritory.territory_id]: newSourceTerritory
            };

            destinationServices.push(newOnCallBlock);
            destinationServices.forEach((ser, idx) => {
              if (ser.uniqueServiceId === newId) {
                destServiceIndex = idx;
                parsedResult.destination = {
                  ...parsedResult.destination,
                  uniqueServiceId: newId,
                  territoryIndex: newSourceTerritory.territory_id,
                  serviceIndex: idx
                }
              }
            });
          }
        }
      }
      //construct payload
      payload =
        constructPayload(
          initialBoardData,
          newOnCallScheduleBoardData,
          zone,
          { ...parsedResult.source, serviceIndex: sourceServiceIndex, sourceBlockId },
          { ...parsedResult.destination, serviceIndex: destServiceIndex }
        ) || {};
    }

  } else {
    //if drop location is same, then just sort
    const sourceIndex = source.index;
    const destIndex = destination.index;

    let sourceZones =
      scheduleBoardData[0].users[parsedResult.source.userIndex].services[
        parsedResult.source.serviceIndex
      ].territories[parsedResult.source.territoryIndex].events[
        parsedResult.source.routineIndex
      ].zones;
    let tempZones = [...sourceZones];
    var temp = tempZones[sourceIndex];
    tempZones[sourceIndex] = tempZones[destIndex];
    tempZones[destIndex] = temp;

    newOnCallScheduleBoardData[0].users[parsedResult.source.userIndex].services[
      parsedResult.source.serviceIndex
    ].territories[parsedResult.source.territoryIndex].events[
      parsedResult.source.routineIndex
    ].zones = tempZones;
  }

  newOnCallScheduleBoardData = cleanBoardData(newOnCallScheduleBoardData);

  return { newOnCallScheduleBoardData, payload, resultBacklogData };
};

const handleBacklogToUserDragEvent = (
  result,
  initialBoardData,
  scheduleBoardData,
  backlogData = []
) => {
  let parsedResult = parseSourceAndDestinationData(result);
  const templBacklogData = _.cloneDeep(backlogData);

  let newOnCallScheduleBoardData = _.cloneDeep(scheduleBoardData);

  const zone =
    backlogData[parsedResult.source.serviceIndex].territories[
      parsedResult.source.territoryIndex
    ].events[parsedResult.source.routineIndex].zones[
    parsedResult.source.zoneIndex
    ];

  const sourceTerritory =
    templBacklogData[parsedResult.source.serviceIndex].territories[
    parsedResult.source.territoryIndex
    ];

  let destinationUserObj = newOnCallScheduleBoardData[0].users[parsedResult.destination.userIndex] || {};
  let isNoCoverageType = destinationUserObj.type_id === SCHEDULE_SERVICE_TYPE.NO_COVERAGE.ID;
  const destinationServices = newOnCallScheduleBoardData[0].users[parsedResult.destination.userIndex].services || [];

  // const destinationTerritory = {};
  let destServiceIndex = -1;
  destinationServices.forEach((ser, index) => {
    if (ser.uniqueServiceId === parsedResult.destination.uniqueServiceId) {
      destServiceIndex = index;
    }
  });

  let resultBacklogData = backlogData;
  let payload = {};

  if (destServiceIndex >= 0) {
    let destinationTerritory =
      newOnCallScheduleBoardData[0].users[parsedResult.destination.userIndex].services[
        destServiceIndex
      ].territories[parsedResult.destination.territoryIndex];

    let sourceSchedule =
      templBacklogData[parsedResult.source.serviceIndex].territories[
        parsedResult.source.territoryIndex
      ].events[parsedResult.source.routineIndex];


    let destinationSchedule =
      newOnCallScheduleBoardData[0].users[parsedResult.destination.userIndex].services[
        destServiceIndex
      ].territories[parsedResult.destination.territoryIndex].events[
      parsedResult.destination.routineIndex
      ] || [];

    // if same zone already exists
    if (destinationSchedule.zones && destinationSchedule.zones.some(z => z.zone_id == zone.zone_id)) {
      return {
        newOnCallScheduleBoardData: scheduleBoardData,
        resultBacklogData: backlogData
      };
    }

    let destTerritories =
      newOnCallScheduleBoardData[0].users[parsedResult.destination.userIndex].services[
        destServiceIndex
      ].territories;

    // check if dragged zone's timezone matches with the service timezone
    if (zone.timezone?.timezone_id == destinationServices[destServiceIndex]?.timezone?.timezone_id) {
      //check for different territories
      if (sourceTerritory.territory_id != destinationTerritory.territory_id) {
        if (
          Object.keys(destTerritories).some(
            (territoryId) => destTerritories[territoryId].territory_id == sourceTerritory.territory_id
          )
        ) {
          if (sourceSchedule.event_type_id != destinationSchedule.event_type_id) {
            let destSchedules =
              newOnCallScheduleBoardData[0].users[parsedResult.destination.userIndex]
                .services[destServiceIndex].territories[
                parsedResult.source.territoryIndex
              ].events || {};

            const newEventId = isNoCoverageType ? "nocoverage" : sourceSchedule.event_type_id;

            //check if dragged zone's event type is already present or not
            if (
              Object.keys(destSchedules).some(
                (sch) =>
                  destSchedules[sch].event_type_id ===
                  newEventId
              )
            ) {
              const destZones =
                destSchedules[newEventId].zones || [];
              destZones.push(zone);
              destSchedules[newEventId].zones =
                destZones;
            } else {
              if (isNoCoverageType) {
                sourceSchedule = {
                  ...sourceSchedule,
                  zones: [zone],
                  event_type: SCHEDULE_SERVICE_TYPE.NO_COVERAGE.NAME,
                  event_type_id: newEventId,
                  service_type: SCHEDULE_SERVICE_TYPE.NO_COVERAGE.ID
                }
                destSchedules[sourceSchedule.event_type_id] = sourceSchedule;
              } else {
                sourceSchedule.zones = [zone];
                destSchedules[sourceSchedule.event_type_id] = sourceSchedule;
              }
            }
          } else {
            const destZones = destTerritories[sourceTerritory.territory_id].events[sourceSchedule.event_type_id] ?
              destTerritories[sourceTerritory.territory_id].events[sourceSchedule.event_type_id]
                .zones || [] : [];
            destZones.push(zone);
          }
        } else {
          if (isNoCoverageType) {

            sourceSchedule = {
              ...sourceSchedule,
              zones: [zone],
              event_type: SCHEDULE_SERVICE_TYPE.NO_COVERAGE.NAME,
              event_type_id: "nocoverage",
              service_type: SCHEDULE_SERVICE_TYPE.NO_COVERAGE.ID
            }
            sourceTerritory.events = {
              [sourceSchedule.event_type_id]: sourceSchedule,
            };
            destTerritories[sourceTerritory.territory_id] = sourceTerritory;
          } else {
            sourceSchedule.zones = [zone];
            sourceTerritory.events = {
              [sourceSchedule.event_type_id]: sourceSchedule,
            };
            destTerritories[sourceTerritory.territory_id] = sourceTerritory;
          }
        }
      } else {
        //check if event type is different
        if (sourceSchedule.event_type_id != destinationSchedule.event_type_id) {
          let destSchedules =
            newOnCallScheduleBoardData[0].users[parsedResult.destination.userIndex]
              .services[destServiceIndex].territories[
              parsedResult.destination.territoryIndex
            ].events || {};

          const newEventId = isNoCoverageType ? "nocoverage" : sourceSchedule.event_type_id;

          //check if dragged zone's event type is already present or not
          if (
            Object.keys(destSchedules).some(
              (sch) =>
                destSchedules[sch].event_type_id === newEventId
            )
          ) {
            const destZones =
              destSchedules[newEventId].zones || [];
            destZones.push(zone);
            destSchedules[newEventId].zones =
              destZones;
          } else {
            if (isNoCoverageType) {
              sourceSchedule = {
                ...sourceSchedule,
                zones: [zone],
                event_type: SCHEDULE_SERVICE_TYPE.NO_COVERAGE.NAME,
                event_type_id: "nocoverage",
                service_type: SCHEDULE_SERVICE_TYPE.NO_COVERAGE.ID
              }
              destSchedules[sourceSchedule.event_type_id] = sourceSchedule;
            } else {
              sourceSchedule.zones = [zone];
              destSchedules[newEventId] = sourceSchedule;
            }
          }
        } else {
          const { destination = {} } = result;
          const destIndex = destination.index;

          let zones =
            newOnCallScheduleBoardData[0].users[parsedResult.destination.userIndex]
              .services[destServiceIndex].territories[
              parsedResult.destination.territoryIndex
            ].events[parsedResult.destination.routineIndex].zones || [];

          zones.splice(destIndex, 0, zone);
        }
      }
    } else {
      const destinationService = destinationServices[destServiceIndex] || {};
      //check if there is an existing timezone block with same shift
      if (destinationServices.some(ser => ser.timezone?.timezone_id == zone.timezone?.timezone_id && (destinationService.shift_timings && destinationService.shift_timings.shift_allocation_id ? (ser?.shift_timings?.shift_allocation_id == destinationService?.shift_timings?.shift_allocation_id) : (ser.start_time === destinationService.start_time && ser.end_time === destinationService.end_time)))) {
        // yes, move the newly added zone to that block
        for (let i = 0; i < destinationServices.length; i++) {
          let ser = destinationServices[i] || {};
          if (ser.timezone?.timezone_id == zone.timezone?.timezone_id && (destinationService.shift_timings && destinationService.shift_timings.shift_allocation_id ? (ser?.shift_timings?.shift_allocation_id == destinationService?.shift_timings?.shift_allocation_id) : (ser.start_time === destinationService.start_time && ser.end_time === destinationService.end_time))) {
            let newSourceSchedule = _.cloneDeep(sourceSchedule);
            let newSourceTerritory = _.cloneDeep(sourceTerritory);
            //check for different territories
            let { territories = {} } = ser;
            if (!Object.keys(territories).includes(newSourceTerritory.territory_id.toString())) {
              if (isNoCoverageType) {

                newSourceSchedule = {
                  ...newSourceSchedule,
                  zones: [zone],
                  event_type: SCHEDULE_SERVICE_TYPE.NO_COVERAGE.NAME,
                  event_type_id: "nocoverage",
                  service_type: SCHEDULE_SERVICE_TYPE.NO_COVERAGE.ID
                }
                newSourceTerritory.events = {
                  ["nocoverage"]: newSourceSchedule,
                };
                territories[newSourceTerritory.territory_id] = newSourceTerritory;
                destinationServices[i].territories = territories;
              } else {
                newSourceSchedule.zones = [zone];
                newSourceTerritory.events = {
                  [newSourceSchedule.event_type_id]: newSourceSchedule,
                };
                territories[newSourceTerritory.territory_id] = newSourceTerritory;
                destinationServices[i].territories = territories;
              }
            } else {
              //check if event type is different
              let { events = {} } = territories[newSourceTerritory.territory_id] || {};
              const newEventId = isNoCoverageType ? "nocoverage" : newSourceSchedule.event_type_id.toString();
              //check if dragged zone's event type is already present or not
              if (!Object.keys(events).includes(newEventId)) {

                if (isNoCoverageType) {
                  newSourceSchedule = {
                    ...newSourceSchedule,
                    zones: [zone],
                    event_type: SCHEDULE_SERVICE_TYPE.NO_COVERAGE.NAME,
                    event_type_id: "nocoverage",
                    service_type: SCHEDULE_SERVICE_TYPE.NO_COVERAGE.ID
                  }
                  events[newEventId] = newSourceSchedule;
                destinationServices[i].territories[newSourceTerritory.territory_id].events = events;
                } else {
                  newSourceSchedule.zones = [zone];
                  events[newEventId] = newSourceSchedule;
                destinationServices[i].territories[newSourceTerritory.territory_id].events = events;
                }
              } else {
                let { zones = [] } = events[newEventId] || {};

                // if same zone already exists
                if (zones.some(z => z.zone_id == zone.zone_id)) {
                  return {
                    newOnCallScheduleBoardData: scheduleBoardData,
                    resultBacklogData: backlogData
                  };
                }
                zones.push(zone);
                destinationServices[i].territories[newSourceTerritory.territory_id].events[newEventId].zones = zones;
              }
            }
            parsedResult.destination = {
              ...parsedResult.destination,
              uniqueServiceId: ser.uniqueServiceId,
              blockId: ser.block_id,
              territoryIndex: newSourceTerritory.territory_id,
              serviceIndex: i
            }
            destServiceIndex = i;
          }
        }
        newOnCallScheduleBoardData[0].users[parsedResult.destination.userIndex].services = destinationServices;
      } else {
        // no, create a new block
        let newOnCallBlock = _.cloneDeep(newOnCallScheduleBoardData[0].users[parsedResult.destination.userIndex].services[destServiceIndex]) || {};
        const newId = uuid();
        newOnCallBlock.uniqueServiceId = newId;
        newOnCallBlock.event_id = "";
        newOnCallBlock.timezone = zone.timezone;
        // newOnCallBlock.block_id = "";

        const newEventId = isNoCoverageType ? "nocoverage" : sourceSchedule.event_type_id;
        let newSourceSchedule = _.cloneDeep(sourceSchedule);
        let newSourceTerritory = _.cloneDeep(sourceTerritory);

        if (isNoCoverageType) {
          newSourceSchedule = {
            ...newSourceSchedule,
            zones: [zone],
            event_type: SCHEDULE_SERVICE_TYPE.NO_COVERAGE.NAME,
            event_type_id: newEventId,
            service_type: SCHEDULE_SERVICE_TYPE.NO_COVERAGE.ID
          }
        } else {
          newSourceSchedule.zones = [zone];
        }
        newSourceTerritory.events = {
          [newEventId]: newSourceSchedule,
        };
        newOnCallBlock.territories = {
          [newSourceTerritory.territory_id]: newSourceTerritory
        };

        destinationServices.push(newOnCallBlock);
        destinationServices.forEach((ser, idx) => {
          if (ser.uniqueServiceId === newId) {
            destServiceIndex = idx;
            parsedResult.destination = {
              ...parsedResult.destination,
              uniqueServiceId: newId,
              territoryIndex: newSourceTerritory.territory_id,
              serviceIndex: idx
            }
          }
        });
      }
    }

    newOnCallScheduleBoardData = cleanBoardData(newOnCallScheduleBoardData);
    if (!isNoCoverageType) {
      resultBacklogData = addUserToBacklog(
        newOnCallScheduleBoardData[0].users[parsedResult.destination.userIndex],
        parsedResult,
        backlogData,
        zone
      );
    }

    //construct payload
    payload =
      constructPayload(
        initialBoardData,
        newOnCallScheduleBoardData,
        zone,
        parsedResult.source,
        { ...parsedResult.destination, serviceIndex: destServiceIndex },
        BACKLOG_SECTION_TYPE
      ) || {};

  }

  return { newOnCallScheduleBoardData, payload, resultBacklogData };
};

const addUserToBacklog = (
  user,
  parsedResult,
  backlogData,
  zone,
  swapUser = undefined
) => {
  let resultBacklogData = _.cloneDeep(backlogData);

  let zones = resultBacklogData[0].territories[parsedResult.source.territoryIndex] ? resultBacklogData[0].territories[parsedResult.source.territoryIndex].events ?
    resultBacklogData[0].territories[parsedResult.source.territoryIndex].events[parsedResult.source.routineIndex] ? (resultBacklogData[0].territories[parsedResult.source.territoryIndex].events[parsedResult.source.routineIndex].zones || [])
      : [] : [] : [];

  const zoneIndex = _.findIndex(zones, function (f) {
    return f.zone_id == zone.zone_id;
  });

  if (zoneIndex >= 0) {
    let { count = 0 } = resultBacklogData[0].territories[parsedResult.source.territoryIndex].events[parsedResult.source.routineIndex].zones[zoneIndex] || {};
    count++;
    resultBacklogData[0].territories[parsedResult.source.territoryIndex].events[parsedResult.source.routineIndex].zones[zoneIndex].count = count;
  }
  return resultBacklogData;
};

const parseSourceAndDestinationData = (result) => {
  let parsedResult = {
    source: {},
    destination: {},
  };

  if (result.source.droppableId.startsWith(BACKLOG_SECTION_TYPE)) {
    const sourceSplits = result.draggableId.split("|");
    parsedResult.source.serviceIndex = sourceSplits[1];
    parsedResult.source.territoryIndex = sourceSplits[2];
    parsedResult.source.routineIndex = sourceSplits[3];
    parsedResult.source.zoneIndex = sourceSplits[4];
  }

  if (result.source.droppableId.startsWith("user")) {
    const sourceSplits = result.draggableId.split("|");
    let temp = sourceSplits[0];
    parsedResult.source.blockId = temp.split('/')[1] || null;
    parsedResult.source.uniqueServiceId = temp.split('/')[2] || null;
    parsedResult.source.userIndex = sourceSplits[1];
    parsedResult.source.serviceIndex = sourceSplits[2];
    parsedResult.source.territoryIndex = sourceSplits[3];
    parsedResult.source.routineIndex = sourceSplits[4];
    parsedResult.source.zoneIndex = sourceSplits[5];
  }

  if (result.destination.droppableId.startsWith("user")) {
    const destinationSplits = result.destination.droppableId.split("|");
    let temp = destinationSplits[0];
    parsedResult.destination.blockId = temp.split('/')[1] || null;
    parsedResult.destination.uniqueServiceId = temp.split('/')[2] || null;
    parsedResult.destination.userIndex = destinationSplits[1];
    parsedResult.destination.serviceIndex = destinationSplits[2];
    parsedResult.destination.territoryIndex = destinationSplits[3];
    parsedResult.destination.routineIndex = destinationSplits[4];
  }

  if (result.destination.droppableId.startsWith("emptyUser")) {
    const destinationSplits = result.destination.droppableId.split("|");
    let temp = destinationSplits[0];
    parsedResult.destination.blockId = temp.split('/')[1] || null;
    parsedResult.destination.uniqueServiceId = temp.split('/')[2] || null;
    parsedResult.destination.userIndex = destinationSplits[1];
    parsedResult.destination.serviceIndex = destinationSplits[2];
  }

  return parsedResult;
};

const handleBacklogToEmptyUserDragEvent = (
  result,
  initialBoardData,
  scheduleBoardData,
  backlogData = []
) => {
  let parsedResult = parseSourceAndDestinationData(result);
  let newOnCallScheduleBoardData = _.cloneDeep(scheduleBoardData);
  let newBacklogData = _.cloneDeep(backlogData);

  let sourceService = newBacklogData[parsedResult.source.serviceIndex];

  let territory =
    newBacklogData[parsedResult.source.serviceIndex].territories[
    parsedResult.source.territoryIndex
    ];

  let event = territory.events[parsedResult.source.routineIndex];

  let zone = event.zones[parsedResult.source.zoneIndex];

  let destinationUserObj = newOnCallScheduleBoardData[0].users[parsedResult.destination.userIndex] || {};
  let isNoCoverageType = destinationUserObj.type_id === SCHEDULE_SERVICE_TYPE.NO_COVERAGE.ID;
  const destinationServices = newOnCallScheduleBoardData[0].users[parsedResult.destination.userIndex].services || [];

  let destServiceIndex = -1;
  destinationServices.forEach((ser, index) => {
    if (ser.uniqueServiceId === parsedResult.destination.uniqueServiceId) {
      destServiceIndex = index;
    }
  });

  let tempZones = [zone];

  let destTerritories = {};

  if (destServiceIndex >= 0) {
    const destinationSchedule = newOnCallScheduleBoardData[0].users[parsedResult.destination.userIndex].services[destServiceIndex] ?
      newOnCallScheduleBoardData[0].users[parsedResult.destination.userIndex].services[destServiceIndex].territories ?
        newOnCallScheduleBoardData[0].users[parsedResult.destination.userIndex].services[destServiceIndex].territories[parsedResult.source.territoryIndex] ?
          newOnCallScheduleBoardData[0].users[parsedResult.destination.userIndex].services[destServiceIndex].territories[parsedResult.source.territoryIndex].events ?
            newOnCallScheduleBoardData[0].users[parsedResult.destination.userIndex].services[destServiceIndex].territories[parsedResult.source.territoryIndex].events[parsedResult.source.routineIndex] ?
              newOnCallScheduleBoardData[0].users[parsedResult.destination.userIndex].services[destServiceIndex].territories[parsedResult.source.territoryIndex].events[parsedResult.source.routineIndex] || {}
              : {} : {} : {} : {} : {};

    destTerritories = newOnCallScheduleBoardData[0].users[parsedResult.destination.userIndex].services[destServiceIndex] ?
      newOnCallScheduleBoardData[0].users[parsedResult.destination.userIndex].services[destServiceIndex].territories || {} : {};

    // if same zone already exists
    if (destinationSchedule.zones && destinationSchedule.zones.some(z => z.zone_id == zone.zone_id)) {
      return {
        newOnCallScheduleBoardData: scheduleBoardData,
        resultBacklogData: backlogData
      };
    }
    if (destinationSchedule.zones) {
      tempZones = [...destinationSchedule.zones, zone];
    }
  }
  if (isNoCoverageType) {
    event = {
      ...event,
      zones: tempZones,
      event_type: SCHEDULE_SERVICE_TYPE.NO_COVERAGE.NAME,
      event_type_id: "nocoverage",
      service_type: SCHEDULE_SERVICE_TYPE.NO_COVERAGE.ID
    }
    territory.events = {
      ["nocoverage"]: event,
    };
    sourceService.territories = { ...destTerritories, [territory.territory_id]: territory };
  } else {
    event.zones = tempZones;
    territory.events = {
      [event.event_type_id]: event,
    };
    sourceService.territories = { ...destTerritories, [territory.territory_id]: territory };
  }


  const currentServiceName = isNoCoverageType ? SCHEDULE_SERVICE_TYPE.NO_COVERAGE.NAME : SCHEDULE_SERVICE_TYPE.ON_CALL.NAME;
  const currentServiceTypeId = isNoCoverageType ? SCHEDULE_SERVICE_TYPE.NO_COVERAGE.ID : SCHEDULE_SERVICE_TYPE.ON_CALL.ID;
  const hasNoOnCallsOrNoCoverage = !destinationServices.some(service => service.service_name === currentServiceName);
  let onCallIndex = parsedResult.destination.serviceIndex;

  let onCallIndexBasedOnBlockId = parsedResult.destination.serviceIndex;
  let tempRoutineIndex = parsedResult.destination.serviceIndex;

  // check if routine draws has block id or not
  const hasBlockId = parsedResult.destination.blockId != "null";

  if (destinationServices === undefined || destinationServices.length == 0) {
    sourceService.uniqueServiceId = uuid();
    newOnCallScheduleBoardData[0].users[parsedResult.destination.userIndex].services =
      [sourceService];
    onCallIndex = 0;
  } else {
    // destination service is present
    if (hasNoOnCallsOrNoCoverage) {
      destinationServices.splice(parsedResult.destination.serviceIndex, 0, {
        uniqueServiceId: parsedResult.destination.uniqueServiceId,
        service_name: currentServiceName,
        service_type: currentServiceTypeId,
        territories: { [territory.territory_id]: territory }
      })
      onCallIndex = destinationServices.map(function (service) { return service.service_name; }).indexOf(currentServiceName)
      newOnCallScheduleBoardData[0].users[parsedResult.destination.userIndex].services = destinationServices;
    } else {
      let destinationTerritories = {};
      if (hasBlockId) {
        if (zone.timezone?.timezone_id == destinationServices[destServiceIndex]?.timezone?.timezone_id) {
          destinationServices.forEach((service, index) => {
            if (service.block_id === parsedResult.destination.blockId) {
              if (service.territories) {
                service.territories = sourceService.territories;
                destinationTerritories = sourceService.territories;
              } else {
                destinationTerritories = {}
              }
              onCallIndexBasedOnBlockId = index;
            }
          })
        } else {
          const destinationService = destinationServices[destServiceIndex] || {};
          //check if there is an existing timezone block with same shift
          if (destinationServices.some(ser => ser.timezone?.timezone_id == zone.timezone?.timezone_id && (destinationService.shift_timings && destinationService.shift_timings.shift_allocation_id ? (ser?.shift_timings?.shift_allocation_id == destinationService?.shift_timings?.shift_allocation_id) : (ser.start_time === destinationService.start_time && ser.end_time === destinationService.end_time)))) {
            // yes, move the newly added zone to that block
            for (let i = 0; i < destinationServices.length; i++) {
              let ser = destinationServices[i] || {};
              if (ser.timezone?.timezone_id == zone.timezone?.timezone_id && (destinationService.shift_timings && destinationService.shift_timings.shift_allocation_id ? (ser?.shift_timings?.shift_allocation_id == destinationService?.shift_timings?.shift_allocation_id) : (ser.start_time === destinationService.start_time && ser.end_time === destinationService.end_time))) {
                //check for different territories
                let { territories = {} } = ser;
                let newSourceSchedule = _.cloneDeep(event);
                let newSourceTerritory = _.cloneDeep(territory);
                if (!Object.keys(territories).includes(newSourceTerritory.territory_id.toString())) {
                  if (isNoCoverageType) {

                    newSourceSchedule = {
                      ...newSourceSchedule,
                      zones: [zone],
                      event_type: SCHEDULE_SERVICE_TYPE.NO_COVERAGE.NAME,
                      event_type_id: "nocoverage",
                      service_type: SCHEDULE_SERVICE_TYPE.NO_COVERAGE.ID
                    }
                    newSourceTerritory.events = {
                      ["nocoverage"]: newSourceSchedule,
                    };
                    territories[newSourceTerritory.territory_id] = newSourceTerritory;
                  } else {
                    newSourceSchedule = {
                      ...newSourceSchedule,
                      zones: [zone],
                      event_type: SCHEDULE_SERVICE_TYPE.ON_CALL.NAME,
                      event_type_id: "oncall",
                      service_type: SCHEDULE_SERVICE_TYPE.ON_CALL.ID
                    }
                    newSourceTerritory.events = {
                      ["oncall"]: newSourceSchedule,
                    };
                    territories[newSourceTerritory.territory_id] = newSourceTerritory;
                  }
                } else {
                  //check if event type is different
                  let { events = {} } = territories[newSourceTerritory.territory_id] || {};
                  //check if dragged zone's event type is already present or not
                  const newEventId = isNoCoverageType ? "nocoverage" : newSourceSchedule.event_type_id;
                  if (!Object.keys(events).includes(newEventId)) {

                    if (isNoCoverageType) {
                      newSourceSchedule = {
                        ...newSourceSchedule,
                        zones: [zone],
                        event_type: SCHEDULE_SERVICE_TYPE.NO_COVERAGE.NAME,
                        event_type_id: newEventId,
                        service_type: SCHEDULE_SERVICE_TYPE.NO_COVERAGE.ID
                      }
                      events[newEventId] = newSourceSchedule;
                    } else {
                      newSourceSchedule = {
                        ...newSourceSchedule,
                        zones: [zone],
                        event_type: SCHEDULE_SERVICE_TYPE.ON_CALL.NAME,
                        event_type_id: newEventId,
                        service_type: SCHEDULE_SERVICE_TYPE.ON_CALL.ID
                      }
                      events[newEventId] = newSourceSchedule;
                    }
                  } else {
                    let { zones = [] } = events[newEventId] || {};

                    // if same zone already exists
                    if (zones.some(z => z.zone_id == zone.zone_id)) {
                      return {
                        newOnCallScheduleBoardData: scheduleBoardData,
                        resultBacklogData: backlogData
                      };
                    }
                    zones.push(zone);
                  }
                }
                parsedResult.destination = {
                  ...parsedResult.destination,
                  uniqueServiceId: ser.uniqueServiceId,
                  blockId: ser.block_id,
                  territoryIndex: newSourceTerritory.territory_id,
                  serviceIndex: i
                }
                destServiceIndex = i;
                onCallIndexBasedOnBlockId = i;
                destinationTerritories=territories;
              }
            }
          } else {
          // create a new block
          let newOnCallBlock = _.cloneDeep(destinationServices[destServiceIndex]) || {};
          const newId = uuid();
          newOnCallBlock.uniqueServiceId = newId;
          newOnCallBlock.event_id = "";
          newOnCallBlock.timezone = zone.timezone;
          // newOnCallBlock.block_id = "";

          const newEventId = isNoCoverageType ? "nocoverage" : event.event_type_id;
          let newSourceSchedule = _.cloneDeep(event);
          let newSourceTerritory = _.cloneDeep(territory);

          if (isNoCoverageType) {
            newSourceSchedule = {
              ...newSourceSchedule,
              zones: [zone],
              event_type: SCHEDULE_SERVICE_TYPE.NO_COVERAGE.NAME,
              event_type_id: newEventId,
              service_type: SCHEDULE_SERVICE_TYPE.NO_COVERAGE.ID
            }
          } else {
            newSourceSchedule.zones = [zone];
          }
          newSourceTerritory.events = {
            [newEventId]: newSourceSchedule,
          };
          newOnCallBlock.territories = {
            [newSourceTerritory.territory_id]: newSourceTerritory
          };

          destinationServices.push(newOnCallBlock);
          destinationServices.forEach((ser, idx) => {
            if (ser.uniqueServiceId === newId) {
              onCallIndexBasedOnBlockId = idx;
              parsedResult.destination = {
                ...parsedResult.destination,
                uniqueServiceId: newId,
                territoryIndex: newSourceTerritory.territory_id,
                serviceIndex: idx
              }
            }
          });
          }
        }
      } else {
        destinationTerritories = { [territory.territory_id]: territory };
        tempRoutineIndex = destinationServices.length;
        destinationServices.push({
          uniqueServiceId: parsedResult.destination.uniqueServiceId,
          service_name: currentServiceName,
          service_type: currentServiceTypeId,
          territories: destinationTerritories
        })
      }

      if (destinationTerritories === undefined) {
        destinationTerritories = {};
      }

      if (Object.keys(destinationTerritories).length == 0) {
        destinationTerritories = { [territory.territory_id]: territory };
      }
      newOnCallScheduleBoardData[0].users[parsedResult.destination.userIndex].services[
        hasNoOnCallsOrNoCoverage ? onCallIndex : hasBlockId ? onCallIndexBasedOnBlockId : tempRoutineIndex
      ].territories = destinationTerritories;
    }
  }

  newOnCallScheduleBoardData = cleanBoardData(newOnCallScheduleBoardData);

  //construct payload
  const payload =
    constructPayload(
      initialBoardData,
      newOnCallScheduleBoardData,
      zone,
      parsedResult.source,
      {
        ...parsedResult.destination, territoryIndex: parsedResult.source.territoryIndex,
        serviceIndex: hasNoOnCallsOrNoCoverage ? onCallIndex : hasBlockId ? onCallIndexBasedOnBlockId : tempRoutineIndex
      },
      BACKLOG_SECTION_TYPE
    ) || {};

  let resultBacklogData = _.cloneDeep(backlogData);
  if (!isNoCoverageType) {
    resultBacklogData = addUserToBacklog(
      newOnCallScheduleBoardData[0].users[parsedResult.destination.userIndex],
      parsedResult,
      backlogData,
      zone
    );
  }

  return {
    newOnCallScheduleBoardData,
    payload,
    sectionType: BACKLOG_SECTION_TYPE,
    resultBacklogData,
  };
};

const handleUserToEmptyUserDragEvent = (
  result,
  initialBoardData,
  scheduleBoardData,
  backlogData = []
) => {
  let parsedResult = parseSourceAndDestinationData(result);

  let scheduleBoardDataForReference = _.cloneDeep(scheduleBoardData);
  let tempScheduleData = _.cloneDeep(scheduleBoardData);
  let newOnCallScheduleBoardData = _.cloneDeep(scheduleBoardData);

  let payload = {};
  let resultBacklogData = backlogData;

  //source
  let sourceUserObj = tempScheduleData[0].users[parsedResult.source.userIndex] || {};
  let isNoCoverageTypeSource = sourceUserObj.type_id === SCHEDULE_SERVICE_TYPE.NO_COVERAGE.ID;

  let destinationUserObj = newOnCallScheduleBoardData[0].users[parsedResult.destination.userIndex] || {};
  let isNoCoverageTypeDest = destinationUserObj.type_id === SCHEDULE_SERVICE_TYPE.NO_COVERAGE.ID;

  let sourceServices = tempScheduleData[0].users[parsedResult.source.userIndex].services || [];
  let sourceServiceIndex = -1;
  let sourceBlockId = null;
  sourceServices.forEach((ser, index) => {
    if (ser.uniqueServiceId === parsedResult.source.uniqueServiceId) {
      sourceServiceIndex = index;
      sourceBlockId = ser.block_id || null;
    }
  })
  if (sourceServiceIndex >= 0) {
    let sourceZones =
      tempScheduleData[0].users[parsedResult.source.userIndex].services[
        sourceServiceIndex
      ].territories[parsedResult.source.territoryIndex].events[
        parsedResult.source.routineIndex
      ].zones;

    sourceZones.splice(parsedResult.source.zoneIndex, 1);

    if (sourceZones.length === 0) {
      //removing event
      let sourceSchedules =
        newOnCallScheduleBoardData[0].users[parsedResult.source.userIndex].services[
          sourceServiceIndex
        ].territories[parsedResult.source.territoryIndex].events;

      delete sourceSchedules[parsedResult.source.routineIndex];

      if (!sourceSchedules || Object.keys(sourceSchedules).length === 0) {
        //remove territory
        let sourceTerritories =
          newOnCallScheduleBoardData[0].users[parsedResult.source.userIndex].services[
            sourceServiceIndex
          ].territories;

        delete sourceTerritories[parsedResult.source.territoryIndex];
        // newOnCallScheduleBoardData[0].users[parsedResult.source.userIndex].services[
        //   sourceServiceIndex
        // ].territories = sourceTerritories;
        if (!sourceTerritories || _.isEmpty(sourceTerritories)) {
          sourceServices = sourceServices.filter(ser => ser.uniqueServiceId !== parsedResult.source.uniqueServiceId)
          newOnCallScheduleBoardData[0].users[parsedResult.source.userIndex].services = sourceServices;
        } else {
          newOnCallScheduleBoardData[0].users[parsedResult.source.userIndex].services[
            sourceServiceIndex
          ].territories = sourceTerritories;
        }
      } else {
        newOnCallScheduleBoardData[0].users[parsedResult.source.userIndex].services[
          sourceServiceIndex
        ].territories[parsedResult.source.territoryIndex].events = sourceSchedules;
      }
    } else {
      newOnCallScheduleBoardData[0].users[parsedResult.source.userIndex].services[
        sourceServiceIndex
      ].territories[parsedResult.source.territoryIndex].events[
        parsedResult.source.routineIndex
      ].zones = sourceZones;
    }

    //destination
    let sourceService =
      scheduleBoardDataForReference[0].users[parsedResult.source.userIndex]
        .services[sourceServiceIndex] || {};

    let territory =
      scheduleBoardDataForReference[0].users[parsedResult.source.userIndex]
        .services[sourceServiceIndex].territories[
      parsedResult.source.territoryIndex
      ] || {};

    let event = territory.events[parsedResult.source.routineIndex];

    let zone = event.zones[parsedResult.source.zoneIndex];

    if (isNoCoverageTypeSource) {
      // no-coverage to oncall
      event = {
        ...event,
        zones: [zone],
        event_type: SCHEDULE_SERVICE_TYPE.ON_CALL.NAME,
        event_type_id: "oncall",
        service_type: SCHEDULE_SERVICE_TYPE.ON_CALL.ID
      }
      territory.events = {
        [event.event_type_id]: event,
      };
    } else {
      // either oncall to empty-oncall or oncall to empty-no-coverage
      if (isNoCoverageTypeDest) {
        // oncall to empty-no-coverage
        event = {
          ...event,
          zones: [zone],
          event_type: SCHEDULE_SERVICE_TYPE.NO_COVERAGE.NAME,
          event_type_id: "nocoverage",
          service_type: SCHEDULE_SERVICE_TYPE.NO_COVERAGE.ID
        }
        territory.events = {
          [event.event_type_id]: event,
        };
      } else {
        event.zones = [zone];
        territory.events = {
          [event.event_type_id]: event,
        };
      }
    }

    sourceService.territories = { [territory.territory_id]: territory };
    sourceService.start_time = null;
    sourceService.end_time = null;

    let destinationServices = newOnCallScheduleBoardData[0].users[parsedResult.destination.userIndex].services;
    const destServiceName = isNoCoverageTypeDest ? SCHEDULE_SERVICE_TYPE.NO_COVERAGE.NAME : SCHEDULE_SERVICE_TYPE.ON_CALL.NAME;
    const destServiceTypeId = isNoCoverageTypeDest ? SCHEDULE_SERVICE_TYPE.NO_COVERAGE.ID : SCHEDULE_SERVICE_TYPE.ON_CALL.ID;

    let destServiceIndex = -1;
    destinationServices.forEach((ser, index) => {
      if (ser.uniqueServiceId === parsedResult.destination.uniqueServiceId) {
        destServiceIndex = index;
      }
    });

    const hasNoOnCallsOrNoCoverage = !destinationServices.some(service => service.service_name === destServiceName);
    let onCallIndex = parsedResult.destination.serviceIndex;

    let onCallIndexBasedOnBlockId = parsedResult.destination.serviceIndex;
    let tempRoutineIndex = parsedResult.destination.serviceIndex;

    // check if routine draws has block id or not
    const hasBlockId = parsedResult.destination.blockId != "null";

    if (destinationServices === undefined || destinationServices.length == 0) {
      newOnCallScheduleBoardData[0].users[parsedResult.destination.userIndex].services =
        [sourceService];
      onCallIndex = 0;
    } else {
      // destination service is present
      if (hasNoOnCallsOrNoCoverage) {
        destinationServices.splice(parsedResult.destination.serviceIndex, 0, {
          uniqueServiceId: parsedResult.destination.uniqueServiceId,
          service_name: destServiceName,
          service_type: destServiceTypeId,
          territories: { [territory.territory_id]: territory }
        })
        onCallIndex = destinationServices.map(function (service) { return service.service_name; }).indexOf(destServiceName)
        newOnCallScheduleBoardData[0].users[parsedResult.destination.userIndex].services = destinationServices;
      } else {
        let destinationTerritories = {};
        if (hasBlockId) {
          if (sourceService.timezone?.timezone_id == destinationServices[destServiceIndex]?.timezone?.timezone_id) {
            destinationServices.forEach((service, index) => {
              if (service.block_id === parsedResult.destination.blockId) {
                destinationTerritories = service.territories || {};
                onCallIndexBasedOnBlockId = index;
              }
            })
          } else {
            const destinationService = destinationServices[destServiceIndex] || {};
          //check if there is an existing timezone block with same shift
          if (destinationServices.some(ser => ser.timezone?.timezone_id == sourceService.timezone?.timezone_id && (destinationService.shift_timings && destinationService.shift_timings.shift_allocation_id ? (ser?.shift_timings?.shift_allocation_id == destinationService?.shift_timings?.shift_allocation_id) : (ser.start_time === destinationService.start_time && ser.end_time === destinationService.end_time)))) {
            // yes, move the newly added zone to that block
            for (let i = 0; i < destinationServices.length; i++) {
              let ser = destinationServices[i] || {};
              if (ser.timezone?.timezone_id == sourceService.timezone?.timezone_id && (destinationService.shift_timings && destinationService.shift_timings.shift_allocation_id ? (ser?.shift_timings?.shift_allocation_id == destinationService?.shift_timings?.shift_allocation_id) : (ser.start_time === destinationService.start_time && ser.end_time === destinationService.end_time))) {
                //check for different territories
                let { territories = {} } = ser;
                let newSourceSchedule = _.cloneDeep(event);
                let newSourceTerritory = _.cloneDeep(territory);
                if (!Object.keys(territories).includes(newSourceTerritory.territory_id.toString())) {
                  if (isNoCoverageTypeSource) {

                    newSourceSchedule = {
                      ...newSourceSchedule,
                      zones: [zone],
                      event_type: SCHEDULE_SERVICE_TYPE.ON_CALL.NAME,
                      event_type_id: "oncall",
                      service_type: SCHEDULE_SERVICE_TYPE.ON_CALL.ID
                    }
                    newSourceTerritory.events = {
                      ["oncall"]: newSourceSchedule,
                    };
                  } else {
                                  // either oncall to empty-oncall or oncall to empty-no-coverage
              if (isNoCoverageTypeDest) {
                // oncall to empty-no-coverage
                newSourceSchedule = {
                  ...newSourceSchedule,
                  zones: [zone],
                  event_type: SCHEDULE_SERVICE_TYPE.NO_COVERAGE.NAME,
                  event_type_id: "nocoverage",
                  service_type: SCHEDULE_SERVICE_TYPE.NO_COVERAGE.ID
                }
                newSourceTerritory.events = {
                  ["nocoverage"]: newSourceSchedule,
                };
              } else {
                newSourceSchedule.zones = [zone];
                newSourceTerritory.events = {
                  [newSourceSchedule.event_type_id]: newSourceSchedule,
                };
              }
                  }
                  territories[newSourceTerritory.territory_id] = newSourceTerritory;
                } else {
                  //check if event type is different
                  let { events = {} } = territories[newSourceTerritory.territory_id] || {};
                  //check if dragged zone's event type is already present or not
                  let newEventId = isNoCoverageTypeSource ?  "oncall" : isNoCoverageTypeDest ? "nocoverage" :  newSourceSchedule.event_type_id;
                  if (!Object.keys(events).includes(newEventId)) {
                    if (isNoCoverageTypeSource) {
                      // no-coverage to oncall
                      newSourceSchedule = {
                        ...newSourceSchedule,
                        zones: [zone],
                        event_type: SCHEDULE_SERVICE_TYPE.ON_CALL.NAME,
                        event_type_id: newEventId,
                        service_type: SCHEDULE_SERVICE_TYPE.ON_CALL.ID
                      }
                      events[newEventId] = newSourceSchedule;
                    } else {
                      // either oncall to empty-oncall or oncall to empty-no-coverage
                      if (isNoCoverageTypeDest) {
                        // oncall to empty-no-coverage
                        newSourceSchedule = {
                          ...newSourceSchedule,
                          zones: [zone],
                          event_type: SCHEDULE_SERVICE_TYPE.NO_COVERAGE.NAME,
                          event_type_id: newEventId,
                          service_type: SCHEDULE_SERVICE_TYPE.NO_COVERAGE.ID
                        }
                        events[newEventId] = newSourceSchedule;
                      } else {
                        newSourceSchedule.zones = [zone];
                        events[newEventId] = newSourceSchedule;
                      }
                    }
                  } else {
                    let { zones = [] } = events[newEventId] || {};

                    // if same zone already exists
                    if (zones.some(z => z.zone_id == zone.zone_id)) {
                      return {
                        newOnCallScheduleBoardData: scheduleBoardData,
                        resultBacklogData: backlogData
                      };
                    }
                    zones.push(zone);
                  }
                }
                parsedResult.destination = {
                  ...parsedResult.destination,
                  uniqueServiceId: ser.uniqueServiceId,
                  blockId: ser.block_id,
                  territoryIndex: newSourceTerritory.territory_id,
                  serviceIndex: i
                }
                destServiceIndex = i;
                onCallIndexBasedOnBlockId = i;
                destinationTerritories=territories;
              }
            }
          } else {
            // create a new block
            let newOnCallBlock = _.cloneDeep(destinationServices[destServiceIndex]) || {};
            const newId = uuid();
            newOnCallBlock.uniqueServiceId = newId;
            newOnCallBlock.event_id = "";
            newOnCallBlock.timezone = sourceService.timezone;
            // newOnCallBlock.block_id = "";

            let newSourceSchedule = _.cloneDeep(event);
            let newSourceTerritory = _.cloneDeep(territory);

            if (isNoCoverageTypeSource) {
              // no-coverage to oncall
              newSourceSchedule = {
                ...newSourceSchedule,
                zones: [zone],
                event_type: SCHEDULE_SERVICE_TYPE.ON_CALL.NAME,
                event_type_id: "oncall",
                service_type: SCHEDULE_SERVICE_TYPE.ON_CALL.ID
              }
              newSourceTerritory.events = {
                [newSourceSchedule.event_type_id]: newSourceSchedule,
              };
            } else {
              // either oncall to empty-oncall or oncall to empty-no-coverage
              if (isNoCoverageTypeDest) {
                // oncall to empty-no-coverage
                newSourceSchedule = {
                  ...newSourceSchedule,
                  zones: [zone],
                  event_type: SCHEDULE_SERVICE_TYPE.NO_COVERAGE.NAME,
                  event_type_id: "nocoverage",
                  service_type: SCHEDULE_SERVICE_TYPE.NO_COVERAGE.ID
                }
                newSourceTerritory.events = {
                  [newSourceSchedule.event_type_id]: newSourceSchedule,
                };
              } else {
                newSourceSchedule.zones = [zone];
                newSourceTerritory.events = {
                  [newSourceSchedule.event_type_id]: newSourceSchedule,
                };
              }
            }

            destinationServices.push(newOnCallBlock);
            destinationServices.forEach((ser, idx) => {
              if (ser.uniqueServiceId === newId) {
                onCallIndexBasedOnBlockId = idx;
                parsedResult.destination = {
                  ...parsedResult.destination,
                  uniqueServiceId: newId,
                  territoryIndex: newSourceTerritory.territory_id,
                  serviceIndex: idx
                }
              }
            });
          }
          }

        } else {
          destinationTerritories = { [territory.territory_id]: territory };
          tempRoutineIndex = destinationServices.length;
          destinationServices.push({
            uniqueServiceId: parsedResult.destination.uniqueServiceId,
            service_name: destServiceName,
            service_type: destServiceTypeId,
            territories: destinationTerritories
          })
        }

        if (destinationTerritories === undefined) {
          destinationTerritories = {};
        }

        if (Object.keys(destinationTerritories).length == 0) {
          destinationTerritories = { [territory.territory_id]: territory };
        }
        newOnCallScheduleBoardData[0].users[parsedResult.destination.userIndex].services[
          hasNoOnCallsOrNoCoverage ? onCallIndex : hasBlockId ? onCallIndexBasedOnBlockId : tempRoutineIndex
        ].territories = destinationTerritories;
      }
    }

    newOnCallScheduleBoardData = cleanBoardData(newOnCallScheduleBoardData);

    //construct payload
    payload =
      constructPayload(
        initialBoardData,
        newOnCallScheduleBoardData,
        zone,
        { ...parsedResult.source, serviceIndex: sourceServiceIndex, sourceBlockId },
        {
          ...parsedResult.destination, territoryIndex: parsedResult.source.territoryIndex,
          serviceIndex: hasNoOnCallsOrNoCoverage ? onCallIndex : hasBlockId ? onCallIndexBasedOnBlockId : tempRoutineIndex
        }
      ) || {};

    if (!isNoCoverageTypeDest) {
      resultBacklogData = addUserToBacklog(
        newOnCallScheduleBoardData[0].users[parsedResult.destination.userIndex],
        {
          ...parsedResult, source: {
            ...parsedResult.source,
            routineIndex: "oncall"
          }
        },
        backlogData,
        zone
      );
    }
  }

  return { newOnCallScheduleBoardData, payload, resultBacklogData };
};

const isDropPermitted = (draggableId, droppableId, scheduleBoardData = []) => {
  if (
    !draggableId ||
    !droppableId ||
    droppableId.startsWith("emptyUser") ||
    droppableId.startsWith(BACKLOG_SECTION_TYPE)
  ) {
    return true;
  }

  let parsedResult = {
    source: {},
    destination: {},
  };

  if (draggableId.startsWith("user")) {
    const sourceSplits = draggableId.split("|");
    parsedResult.source.userIndex = sourceSplits[1];
    parsedResult.source.serviceIndex = sourceSplits[2];
    parsedResult.source.territoryIndex = sourceSplits[3];
    parsedResult.source.routineIndex = sourceSplits[4];
    parsedResult.source.zoneIndex = sourceSplits[5];
  }

  if (draggableId.startsWith(BACKLOG_SECTION_TYPE)) {
    const sourceSplits = draggableId.split("|");
    parsedResult.source.serviceIndex = sourceSplits[1];
    parsedResult.source.territoryIndex = sourceSplits[2];
    parsedResult.source.routineIndex = sourceSplits[3];
    parsedResult.source.zoneIndex = sourceSplits[4];
  }

  if (droppableId.startsWith("user")) {
    const destinationSplits = droppableId.split("|");
    parsedResult.destination.userIndex = destinationSplits[1];
    parsedResult.destination.serviceIndex = destinationSplits[2];
    parsedResult.destination.territoryIndex = destinationSplits[3];
    parsedResult.destination.routineIndex = destinationSplits[4];
  }

  if (parsedResult.source.userIndex != parsedResult.destination.userIndex) {
    return true;
  }

  return false;
};

const checkStartTimeEndTime = (draggableId, droppableId, scheduleBoardData = []) => {

  let parsedResult = {
    source: {},
    destination: {},
  };

  if (droppableId.startsWith("user") || droppableId.startsWith("emptyUser")) {
    const destinationSplits = droppableId.split("|");
    parsedResult.destination.userIndex = destinationSplits[1];
    parsedResult.destination.serviceIndex = destinationSplits[2];
  }
  if (parsedResult && parsedResult.destination) {
    const { start_time, end_time } = scheduleBoardData[0] ? scheduleBoardData[0].users ? scheduleBoardData[0].users[parsedResult.destination.userIndex] ?
      scheduleBoardData[0].users[parsedResult.destination.userIndex].services ? scheduleBoardData[0].users[parsedResult.destination.userIndex].services[parsedResult.destination.serviceIndex] || {}
        : {} : {} : {} : {};

    if (start_time && end_time) {
      return true;
    }
    return false;
  }
  return false
};

const cleanBoardData = (scheduleBoardData) => {
  let resultScheduleBoardData = _.cloneDeep(scheduleBoardData);

  scheduleBoardData[0].users.forEach((user, userIndex) => {
    user.services.forEach((service, serviceIndex) => {
      if (!service.territories) {
        return;
      }
      const { territories = {} } = service;
      Object.keys(territories).forEach((territoryId, territoryIndex) => {
        const { events = {} } = territories[territoryId];
        Object.keys(events).forEach((eventId, eventIndex) => {
          const { zones } = events[eventId];
          if (!zones) {
            delete resultScheduleBoardData[0].users[userIndex].services[
              serviceIndex
            ].territories[territoryIndex].events[eventId];
          }
        });

        if (!Object.keys(events).length) {
          delete resultScheduleBoardData[0].users[userIndex].services[
            serviceIndex
          ].territories[territoryId];
        }
      });
    });
  });

  return resultScheduleBoardData;
};

const constructPayload = (
  initialBoardData,
  newOnCallScheduleBoardData,
  newZone = {},
  sourceData = {},
  destinationData = {},
  sectionType = SCHEDULER_BOARD_SECTION_TYPE
) => {
  const { userIndex, serviceIndex, territoryIndex } = destinationData;
  const { userIndex: sourceUserIndex, serviceIndex: sourceServiceIndex, territoryIndex: sourceTerritoryIndex, sourceBlockId = null } = sourceData;
  let payload = {};
  const events =
    newOnCallScheduleBoardData[0].users[userIndex].services[serviceIndex].territories[
      sourceTerritoryIndex
    ].events;

  const destService =
    newOnCallScheduleBoardData[0].users[userIndex].services[serviceIndex];

  const initialDestService =
    initialBoardData[0].users[userIndex].services[serviceIndex] || {};

  // let sourceBlockId = null;
  // if (sectionType === SCHEDULER_BOARD_SECTION_TYPE) {
  //   const sourceService =
  //     newOnCallScheduleBoardData[0].users[sourceUserIndex].services[sourceServiceIndex];

  //   sourceBlockId = sourceService.block_id || null;
  // }

  const destUser = newOnCallScheduleBoardData[0].users[userIndex];

  const sourceUser = newOnCallScheduleBoardData[0].users[sourceUserIndex];

  const { start_time, end_time, uniqueServiceId, description, shift_timings = {}, timezone = {} } = destService;

  const { block_id = null } = initialDestService;

  Object.keys(events).forEach((eventId) => {
    const { zones = [], service_type } = events[eventId];
    zones.forEach((zone) => {
      if (zone.zone_id == newZone.zone_id) {
        payload = {
          service_type_id: sectionType === SCHEDULER_BOARD_SECTION_TYPE ? (destUser.type_id === SCHEDULE_SERVICE_TYPE.NO_COVERAGE.ID ? SCHEDULE_SERVICE_TYPE.NO_COVERAGE.ID : 3) : (service_type || 3),
          start_time,
          end_time,
          status: "ACTIVE",
          zone_ids: [Number(zone.zone_id)],
          territory_id: territoryIndex,
          facility_ids: [],
          block_id,
          current_block_id: sourceBlockId,
          person_id: destUser.user_id,
          event_id: eventId,
          territory_id: territoryIndex,
          user_index: userIndex,
          service_index: serviceIndex,
          uniqueServiceId,
          description: description || "",
          current_person_id:
            sectionType === SCHEDULER_BOARD_SECTION_TYPE
              ? sourceUser
                ? sourceUser.user_id
                : null
              : null,
        };
        if (sectionType === BACKLOG_SECTION_TYPE) {
          payload.current_service_type_id = null;
        } else {
          payload.current_service_type_id = sourceUser.type_id === SCHEDULE_SERVICE_TYPE.NO_COVERAGE.ID ? SCHEDULE_SERVICE_TYPE.NO_COVERAGE.ID : SCHEDULE_SERVICE_TYPE.ON_CALL.ID;
        }
        if (shift_timings.shift_allocation_id) {
          payload.shift_allocation_id = shift_timings.shift_allocation_id;
        }
        if (timezone.timezone_id) {
          payload.timezone_id = timezone.timezone_id;
        }
      }
    });
  });
  return payload;
};
