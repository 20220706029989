import _ from "lodash";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ahaApi from "./app/services/aha-api";
const initialState = {
  value: [],
  loading: false,
  loggedIn: false,
  isOpenReport: false
};

export const validSession = createAsyncThunk(
  "validSession",
  async (data, { rejectWithValue }) => {
    const response = await ahaApi.Account.checkValidSession()
      .then((res) => {
        // const timeStamp = moment();
        // sessionStorage.setItem('lastTimeStamp', timeStamp);
        return res;
      })
      .catch((err) => {
        return rejectWithValue(err.response.data);
      });
    return response;
  }
);

export const appSlice = createSlice({
  name: "validSession",
  initialState,
  reducers: {
    updateValidSession(state, action) {
      state.value = action.payload;
    },
    setLoggedIn(state, action) {
      state.loggedIn = action.payload;
    },
    setIsOpenReport(state, action) {
      state.isOpenReport = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(validSession.pending, (state) => {
        state.loading = true;
      })
      .addCase(validSession.fulfilled, (state, action) => {
        state.loading = false;
        state.value = action.payload;
      })
      .addCase(validSession.rejected, (state, err) => {
        state.loading = false;
        state.value = err.payload;
      });
  },
});

export const validSessionData = (state) => state.appData.value;
export const loggedIn = (state) => state.appData.loggedIn;
export const isOpenReportVal = (state) => state.appData ? state.appData.isOpenReport : false;
export const { updateValidSession, setLoggedIn, setIsOpenReport } = appSlice.actions;

export default appSlice.reducer;
