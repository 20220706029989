import _ from "lodash";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ahaApi from "../../../app/services/aha-api";

const initialState = {
	value: [],
	loading: false,
	surgeOrNonServiceModalData: {},
	onCallSurgeModalData: {}
};

export const surgeOrNonServiceFacilities = createAsyncThunk(
	"surgeOrNonServiceFacilities/list",
	async (data, { rejectWithValue }) => {
		const response = await ahaApi.Account.surgeOrNonServiceFacilities(data)
			.then((res) => {
				return res;
			})
			.catch((err) => {
				return rejectWithValue(err.response.data);
			});

		return response;
	}
);

export const surgeOrNonServiceFacilitiesSlice = createSlice({
	name: "surgeOrNonServiceFacilities",
	initialState,
	reducers: {
		clearSurgeOrNonServiceFacilities(state, action) {
			state.value = {}
		},
		setSurgeOrNonServiceModal(state, action) {
			state.surgeOrNonServiceModalData = action.payload;
		},
		setOnCallSurgeModalData(state, action) {
			state.onCallSurgeModalData = action.payload;
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(surgeOrNonServiceFacilities.pending, (state) => {
				state.loading = true;
			})
			.addCase(surgeOrNonServiceFacilities.fulfilled, (state, action) => {
				state.loading = false;
				state.value = action.payload;
			})
			.addCase(surgeOrNonServiceFacilities.rejected, (state, err) => {
				state.loading = false;
				state.value = err.payload;
			});
	},
});

export const surgeOrNonServiceFacilitiesList = (state) => state.surgeOrNonServiceFacilities.value;
export const surgeOrNonServiceModalData = (state) => state.surgeOrNonServiceFacilities.surgeOrNonServiceModalData;
export const onCallSurgeModalData = (state) => state.surgeOrNonServiceFacilities.onCallSurgeModalData;
export const { clearSurgeOrNonServiceFacilities, setSurgeOrNonServiceModal, setOnCallSurgeModalData } = surgeOrNonServiceFacilitiesSlice.actions;

export default surgeOrNonServiceFacilitiesSlice.reducer;
