import _ from "lodash";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ahaApi from "../../../app/services/aha-api";

const initialState = {
	value: [],
	loading: false,
};

export const labsList = createAsyncThunk(
	"labs/list",
	async (data, { rejectWithValue }) => {
		const response = await ahaApi.Account.labsList(data)
			.then((res) => {
				return res;
			})
			.catch((err) => {
				return rejectWithValue(err.response.data);
			});

		return response;
	}
);

export const getLabsSlice = createSlice({
	name: "labsList",
	initialState,
	reducers: {
		clearLabsList(state, action) {
			state.value = {}
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(labsList.pending, (state) => {
				state.loading = true;
			})
			.addCase(labsList.fulfilled, (state, action) => {
				state.loading = false;
				state.value = action.payload;
			})
			.addCase(labsList.rejected, (state, err) => {
				state.loading = false;
				state.value = err.payload;
			});
	},
});

export const labsListList = (state) => state.labsList.value;
export const { clearLabsList } = getLabsSlice.actions;

export default getLabsSlice.reducer;
