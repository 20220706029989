// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import React, { useState } from 'react';
import { withTheme } from 'styled-components';
import AsyncSelect from 'react-select/async';
import Avatar from "react-avatar";

export const MultiSelect = (props) => {
  const { type = "new", channelName = "" } = props;
  const [openMenu, setOpenMenu] = useState(false);

  const handleChange = (selections) => props.onChange(selections);

  const getCustomStyles = () => {
    const { theme } = props;
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        transition: 'background-color .05s ease-in',
        backgroundColor: state.isFocused ? `rgba(0,0,0, .08)` : ``,
        color: state.isFocused ? `#313131` : `#313131`,
        padding: 10,
        fontSize: 14,
        letterSpacing: 0.25,
        textTransform: 'capitalize',
        '&:first-of-type': {
          borderRadius: `${theme.radii.default} ${theme.radii.default} 0 0`
        },
        '&:last-of-type': {
          borderRadius: `0 0 ${theme.radii.default} ${theme.radii.default}`
        },
        '&:hover': {
          cursor: 'pointer',
          backgroundColor: `rgba(0,0,0, .08)`,
          color: `#313131`
        }
      }),

      menu: (provided) => ({
        ...provided,
        marginBottom: '2rem',
        boxShadow: 'none',
        position: 'relative',
        marginTop: '5px',
        padding: 0,
        borderRadius: '4px',
        background: '#fff',
        boxShadow: '0px 3px 6px rgba(0,0,0, .16)',
        border: 'none'
      }),

      control: (provided) => ({
        ...provided,
        marginTop: 0,
        width: '100%',
        minHeight: 40,
        padding: '0 10px',
        borderRadius: 5,
        border: '1px solid rgba(18, 18, 18, 0.24)',
        fontFamily: 'robotolight, sans-serif',
        fontSize: 16,
        color: '#313131',
        '&:focus-within': {
          boxShadow: `0 0 0 0.125rem ${theme.colors.primary.lightest}`
        },
        '&:hover': {
          borderColor: 'none',
        }
      }),
      menuList: () => ({
        padding: 0,
        boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.1)'

      }),
      multiValue: (provided) => ({
        ...provided,
        margin: '4px 12px 4px 2px!important',
        borderRadius: 16,
        border: '1px solid #ABADB0',
        backgroundColor: 'transparent',
      }),
      multiValueLabel: (provided) => ({
        ...provided,
        color: '#121212',
        fontSize: 13,
        letterSpacing: 0.23,
        fontFamily: 'robotoregular, sans-serif',
        textTransform: 'capitalize',
      }),
      Input: (provided) => ({
        ...provided,
        color: 'red',
      })
    }
    return customStyles;
  };

  const loadOptions = async (text, channelName) => {
    let options = await props.loadOptions(text) || [];
    const channelUsers = channelName.split(", ");
    channelUsers.forEach(user => {
      if (options.some(member => member.label === user)) {
        options = options.filter(m => m.label !== user);
      }
    });
    return options;
  }

  const formatOptionLabel = ({ value, label, fullName, fullNameForInitials, iconColorCode }) => (
    <div className="name-chip-wrapper">
      {fullNameForInitials && <Avatar
        color={iconColorCode ? `#${iconColorCode}` : "#333745"}
        name={fullNameForInitials}
        maxInitials={2}
        size={24}
        round="40px"
      />}
      <div className="name-chip-label">{fullName || label}</div>
    </div>
  );

  return (
    <AsyncSelect
      isMulti
      cacheOptions
      defaultOptions
      loadOptions={(text) =>
        type === "existing" ? loadOptions(text, channelName) : props.loadOptions(text)
      }
      isSearchable
      onChange={handleChange}
      hideSelectedOptions
      // menuIsOpen={openMenu}
      styles={getCustomStyles()}
      formatOptionLabel={formatOptionLabel}
      isLoading={false}
      isClearable
      className={props.className}
      placeholder={props.placeholder || 'Select'}
      classNamePrefix={props.classNamePrefix || 'modal-select-input ' }   />
  );
};

export default withTheme(MultiSelect);
