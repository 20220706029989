import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ahaApi from "../../../app/services/aha-api";
const initialState = {
  value: [],
  loading: true,
};

export const contactAdmin = createAsyncThunk(
  "contact/postData",
  async (data, { rejectWithValue }) => {
    const response = await ahaApi.Account.contact(data)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return rejectWithValue(err.response.data);
      });
    return response;
  }
);

export const contactSlice = createSlice({
  name: "contact",
  initialState,

  extraReducers: (builder) => {
    builder
      .addCase(contactAdmin.pending, (state) => {
        state.loading = true;
      })
      .addCase(contactAdmin.fulfilled, (state, action) => {
        state.loading = false;
        state.value = action.payload;
      })
      .addCase(contactAdmin.rejected, (state, err) => {
        state.loading = false;
        state.value = err.payload;
      });
  },
});

export const contactList = (state) =>
  state && state.contact && state.contact.value;

export default contactSlice.reducer;
