import Footer from "./Footer";
import Header from "./Header";
import LeftPanel from "./LeftPanel";
import RightPanel from "./RightPanel";

export default function Layout(props) {
  return (
    <>
      <div className="main-wrapper">
          <Header />
          <div className="flex-fill">
            <div className="signup-wrapper d-flex justify-content-between">
              <LeftPanel>{props.children}</LeftPanel>
              <RightPanel />
            </div>
          </div>
          <Footer />
        </div>
    </>
  );
}
