import React from "react";
import { Toast } from "react-bootstrap";
import { HOMEHEALTH, ROUTINEREDRAW, STATORDER, TIMEDDRAW } from "../../app/utils/Constants";
export default function ToastMessageNew(props) {
	return (
		<div className="overflow-hidden d-flex justify-content-center toast-container toast-container-new scheduler-toast-message">
			<Toast
				data-testid="toast"
				className={`${
					window.location.pathname === STATORDER || window.location.pathname === ROUTINEREDRAW || window.location.pathname === TIMEDDRAW || window.location.pathname === HOMEHEALTH
						? "toast-wrapper text-end st-toast-wrapper"
						: "toast-wrapper text-end"
				}`}
				style={{ display: props.toast ? "none" : "" }}
			>
				<Toast.Header closeButton={false}>
					<span
						data-testid="toast-header"
						className="close-icon"
						onClick={() => {
							props.setToast(true);
						}}
						aria-label="Close"
					>
						<span className="visually-hidden">Close Icon</span>
					</span>
				</Toast.Header>
				<Toast.Body className="toast-info-details d-flex align-items-center">
					<span
						data-testid="toast-body"
						className={
							props.type === "success"
								? "checkmark-with-circle pr-10"
								: props.type === "error" && "close-with-circle pr-10"
						}
					>
						{props.type === "error" ? (
							<span className="visually-hidden">error-close icon with circle</span>
						) : (
							props.type === "success" && <span className="visually-hidden">Checkmark icon with circle</span>
						)}
					</span>
					<p className="font-size-12">{props.message}</p>
				</Toast.Body>
			</Toast>
		</div>
	);
}
