// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import React from "react";
import {
  AddMemberModal,
  ManageMembersModal,
  DeleteChannelModal,
  NewChannelModal,
  LeaveChannelModal,
  ViewChannelDetailsModal,
  ViewMembersModal,
  EditChannelModal,
  BanModal,
  JoinMeetingModal,
  ManageChannelFlowModal,
  SetCustomStatusModal,
} from "../../components/ChannelModals";
import AddMembersToExistingSpace from "../../components/ChannelModals/AddMembersToExistingSpace";
import ChatDeleteChannelModal from "../../components/ChannelModals/ChatDeleteChannelModal";
import ChatLeaveChannelModal from "../../components/ChannelModals/ChatLeaveChannelModal";
import ChatManageMembersModal from "../../components/ChannelModals/ChatManageMembersModal";
import ChatNewMemberModal from "../../components/ChannelModals/ChatNewMemberModal";

const ModalManager = ({
  modal,
  setModal,
  activeChannel,
  meetingInfo,
  userId,
  onAddMember,
  onManageChannelFlow,
  handleChannelDeletion,
  handleJoinMeeting,
  handleMessageAll,
  handleDeleteMemberships,
  handleDeletePickerChange,
  handlePickerChange,
  handleMultiPickerChange,
  formatMemberships,
  onAddMembertoExisting,
  activeChannelMemberships,
  membersForDelete,
  onCreateChannel,
  activeChannelModerators,
  handleLeaveChannel,
  banList,
  banUser,
  unbanUser,
  activeChannelFlow,
  setCustomStatus,
  selectedMultiMembers,
  selectedMembers,
  loader
}) => {
  if (!modal) {
    return null;
  }

  switch (modal) {
    case "AddMembers":
      return (
        <AddMemberModal
          onClose={() => {
            handlePickerChange([]);
            setModal("");
          }}
          channel={activeChannel}
          onSubmit={onAddMember}
          handlePickerChange={handlePickerChange}
          members={activeChannelMemberships}
          selectedMembers={selectedMembers}
        />
      );
    case "ManageMembers":
      return (
        <ManageMembersModal
          onClose={() => {
            handleDeletePickerChange({});
            setModal("");
          }}
          channel={activeChannel}
          userId={userId}
          handleDeleteMemberships={handleDeleteMemberships}
          handlePickerChange={handleDeletePickerChange}
          members={formatMemberships(activeChannelMemberships)}
          selectedMembers={membersForDelete}
        />
      );
    case "ChatManageMembers":
      return (
        <ChatManageMembersModal
          onClose={() => {
            handleDeletePickerChange([]);
            setModal("");
          }}
          channel={activeChannel}
          userId={userId}
          handleDeleteMemberships={handleDeleteMemberships}
          handlePickerChange={handleDeletePickerChange}
          members={formatMemberships(activeChannelMemberships)}
          selectedMembers={membersForDelete}
        />
      );
    case "NewChannel":
      return (
        <NewChannelModal
          onClose={() => setModal("")}
          onCreateChannel={onCreateChannel}
          loader={loader}
        />
      );
    case "ChatNewMember":
      return (
        <ChatNewMemberModal
          onClose={() => {
            handleMultiPickerChange([]);
            setModal("");
          }}
          onCreateChannel={onCreateChannel}
          channel={activeChannel}
          onSubmit={onAddMember}
          handleMultiPickerChange={handleMultiPickerChange}
          selectedMultiMembers={selectedMultiMembers}
          members={[]}
          loader={loader}
        />
      );
    case "AddMemberToExisting":
      return (
        <AddMembersToExistingSpace
          onClose={() => {
            handleMultiPickerChange([]);
            setModal("");
          }}
          channel={activeChannel}
          onSubmit={onAddMembertoExisting}
          handleMultiPickerChange={handleMultiPickerChange}
          members={activeChannelMemberships}
          selectedMultiMembers={selectedMultiMembers}
        />
      );
    case "ViewDetails":
      return (
        <ViewChannelDetailsModal
          onClose={() => setModal("")}
          channel={activeChannel}
          moderators={activeChannelModerators}
          channelFlow={activeChannelFlow}
        />
      );
    case "LeaveChannel":
      return (
        <LeaveChannelModal
          onClose={() => setModal("")}
          handleLeaveChannel={handleLeaveChannel}
          channel={activeChannel}
        />
      );
    case "ChatLeaveChannel":
      return (
        <ChatLeaveChannelModal
          onClose={() => setModal("")}
          handleLeaveChannel={handleLeaveChannel}
          channel={activeChannel}
        />
      );
    case "ViewMembers":
      return (
        <ViewMembersModal
          onClose={() => setModal("")}
          channel={activeChannel}
          members={activeChannelMemberships}
          moderators={activeChannelModerators}
        />
      );
    case "ManageChannelFlow":
      return (
        <ManageChannelFlowModal
          onClose={() => setModal("")}
          channel={activeChannel}
          handlePickerChange={handleDeletePickerChange}
          onSubmit={onManageChannelFlow}
          channelFlow={activeChannelFlow}
        />
      );
    case "EditChannel":
      return (
        <EditChannelModal
          onClose={() => setModal("")}
          channel={activeChannel}
          userId={userId}
        />
      );
    case "Ban":
      return (
        <BanModal
          onClose={() => setModal("")}
          handlePickerChange={handlePickerChange}
          channel={activeChannel}
          members={activeChannelMemberships}
          moderators={activeChannelModerators}
          banList={banList}
          banUser={banUser}
          unbanUser={unbanUser}
        />
      );
    case "DeleteChannel":
      return (
        <DeleteChannelModal
          onClose={() => setModal("")}
          channel={activeChannel}
          handleChannelDeletion={handleChannelDeletion}
        />
      );
    case "ChatDeleteChannel":
      return (
        <ChatDeleteChannelModal
          onClose={() => setModal("")}
          channel={activeChannel}
          handleChannelDeletion={handleChannelDeletion}
        />
      );
    case "CustomStatus":
      return (
        <SetCustomStatusModal
          onClose={() => setModal("")}
          setCustomStatus={setCustomStatus}
        />
      );
    case "JoinMeeting":
      return (
        <JoinMeetingModal
          onClose={() => setModal("")}
          meetingInfo={meetingInfo}
          handleJoinMeeting={handleJoinMeeting}
          handleMessageAll={handleMessageAll}
        />
      );
    default:
      return null;
  }
};

export default ModalManager;
