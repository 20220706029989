import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import ahaApi from "../../app/services/aha-api";
import { phoneFormat } from "../../app/utils/testUtils";
import { ACKNOWLEDGED_STATUS, CANCELLED_STATUS, CANCEL_TEXT, NATERA_ID, NATERA_REQUESTED_TEXT, UNACKNOWLEDGED_STATUS, findLastIndex, replaceNullWithEmptyStringAndReturnUpdated } from "../../app/utils/Constants";
import _ from "lodash";
const initialState = {
  value: [],
  loading: false,
};

const constructListingData = (data) => {
  const { patient_first_name = "", patient_last_name = "", notes = [], order_creation_datetime = "", user = {}, coordinator = {},
    partner = {}, schedule_confirmation = {}, patient_dob, gender = {}, partner_order_id = "",
    patient_address1, patient_address2, patient_city, patient_state, patient_zip, region = {}, phleb_activity_informations = [], drop_off_points_location = [], drop_off_points = [], patient_phone, patient_notes = '',
    order_status = {}, ordering_physician = "", order_service = {}, patient_email = "", address_type = {}, sms_opt_in = "",
    order_accessions = [], partner_order_attribute = {}, result_finalized_date_time = "", notified_person = "", order_line_items = [], last_updated_datetime, order_creation_timezone = {},
    last_updated_timezone = {}, result_finalized_timezone = {}, is_validated_by_mapbox, phleb_activities = {} } = data;

  let tempNotes = notes ? notes.length ? notes.sort((a, b) => new Date(a.created_date_time) > new Date(b.created_date_time)
    ? -1
    : new Date(a.created_date_time) < new Date(b.created_date_time)
      ? 1
      : 0) : [] : []
  const cancellationNotes = tempNotes.filter(note => note.note_type && note.note_type.note_type_name == CANCEL_TEXT || note.note_type.note_type_name == NATERA_REQUESTED_TEXT);
  const notesWithoutCancellation = tempNotes.filter(note => note.note_type && note.note_type.note_type_name != CANCEL_TEXT && note.note_type.note_type_name != NATERA_REQUESTED_TEXT);

  let nateraCancellationNotes = "";
  if (order_status && order_status.id == CANCELLED_STATUS) {
    if (cancellationNotes && cancellationNotes.length === 1) {
      nateraCancellationNotes = cancellationNotes[0].note_type.note_type_label;
    }
  }
  let tempCheckIn = {};
  let tempCheckOut = {};
  let tempDropOffString = "";
  let tempDropOffs = [];

  let checkIns =
    phleb_activity_informations.filter((act) => act.action_type_id === 1) ||
    [];
  let checkOuts =
    phleb_activity_informations.filter((act) => act.action_type_id === 2) ||
    [];
  let dropOffs =
    phleb_activity_informations.filter((act) => act.action_type_id === 3) ||
    [];
  let checkInObj = {};
  let checkOutObj = {};

  if (dropOffs.length) {

    let checkinIndex = findLastIndex(
      phleb_activity_informations || [],
      (act) => act.action_type_id == 1
    );

    checkInObj = phleb_activity_informations ? phleb_activity_informations[checkinIndex] || {} : {};

    let checkoutIndex = findLastIndex(
      phleb_activity_informations,
      (act) => act.action_type_id == 2
    );

    checkOutObj = phleb_activity_informations[checkoutIndex] || {};

  } else {
    if (checkIns.length > checkOuts.length) {
      checkInObj = phleb_activity_informations.length
        ? phleb_activity_informations[phleb_activity_informations.length - 1]
          ? phleb_activity_informations[
            phleb_activity_informations.length - 1
          ].action_type_id === 1
            ? phleb_activity_informations[
            phleb_activity_informations.length - 1
            ]
            : phleb_activity_informations[
              phleb_activity_informations.length - 2
            ]
              ? phleb_activity_informations[
                phleb_activity_informations.length - 2
              ].action_type_id === 1
                ? phleb_activity_informations[
                phleb_activity_informations.length - 2
                ]
                : {}
              : {}
          : {}
        : {};
      checkOutObj = {};
    } else {
      let checkinIndex = findLastIndex(
        phleb_activity_informations || [],
        (act) => act.action_type_id == 1
      );

      checkInObj = phleb_activity_informations ? phleb_activity_informations[checkinIndex] || {} : {};

      let checkoutIndex = findLastIndex(
        phleb_activity_informations,
        (act) => act.action_type_id == 2
      );

      checkOutObj = phleb_activity_informations[checkoutIndex] || {};
    }
  }

  if (checkInObj && Object.keys(checkInObj).length) {
    tempCheckIn = {
      ...checkInObj,
      actionDateTime: checkInObj.action_datetime
        ? new Date(checkInObj.action_datetime)
        : ""
    };
  }

  if (checkOutObj && Object.keys(checkOutObj).length) {
    tempCheckOut = {
      ...checkOutObj,
      actionDateTime: checkOutObj.action_datetime
        ? new Date(checkOutObj.action_datetime)
        : ""
    };
  }

  const drop_offs = phleb_activities["Drop Off"] || [];

  drop_offs && drop_offs.forEach(dd => {
    const { drop_off_types = [], action_id } = dd
    if (action_id) {
      let selectedDropOffOption;
      drop_off_types.forEach(dt => {
        if (dt.selected) {
          selectedDropOffOption = dt;
        }
      });
      if (selectedDropOffOption) {
        tempDropOffs.push(dd);
      }
    }
  });
  if (tempDropOffs && tempDropOffs.length) {
    tempDropOffString = tempDropOffs.map(obj => {
      return obj.action_datetime ? `${moment(new Date(obj.action_datetime)).format("MM/DD/yyyy HH:mm:ss")} ${obj.timezone ? obj.timezone.abbreviation || "" : ""}` : ""
    }).join(", ")

  }

  let checkInDateTime =
    order_status &&
      (order_status.id == UNACKNOWLEDGED_STATUS || order_status.id == ACKNOWLEDGED_STATUS) &&
      phleb_activity_informations.length > 0
      ? {}
      : tempCheckIn;
  let checkOutDateTime =
    order_status &&
      (order_status.id == UNACKNOWLEDGED_STATUS || order_status.id == ACKNOWLEDGED_STATUS) &&
      phleb_activity_informations.length > 0
      ? {}
      : tempCheckOut;

  let dropOffDateTime =
    order_status &&
      (order_status.id == UNACKNOWLEDGED_STATUS || order_status.id == ACKNOWLEDGED_STATUS) &&
      phleb_activity_informations.length > 0
      ? ""
      : tempDropOffString;

  const checkoutStatus = tempCheckOut ? tempCheckOut.checkout_status ? tempCheckOut.checkout_status.label || "-" : "-" : "-"

  let accessionInfo = ""
  if (order_accessions && order_accessions.length) {
    accessionInfo = order_accessions.map(acc => { return acc.accession_datetime ? `(${acc.accession_id} - ${moment(new Date(acc.accession_datetime)).format("MM/DD/yyyy HH:mm:ss")})` : acc.accession_id }).join(", ")
  }
  let accName = "";
  let accNumber = "";
  if (partner_order_attribute) {
    accNumber = partner_order_attribute[`${partner.partner_name} Account Number`] ? partner_order_attribute[`${partner.partner_name} Account Number`].label : "";
    accName = partner_order_attribute[`${partner.partner_name} Account Name`] ? partner_order_attribute[`${partner.partner_name} Account Name`].label : ""
  }

  let visitTypes = order_line_items ? order_line_items.length ? order_line_items.filter(item => item.order_collection_method && item.order_collection_method.collection_type) : [] : [];
  visitTypes = visitTypes ? visitTypes.length ? visitTypes.map(item => item.order_collection_method && item.order_collection_method.collection_type).join("; ") : "-" : "-"

  return {
    ...data,
    scheduled_date_string: schedule_confirmation.schedule_date ? `${moment(new Date(`${schedule_confirmation.schedule_date} 00:00:00`)).format("MM/DD/yyyy")} ${schedule_confirmation.scheduled_timezone ? schedule_confirmation.scheduled_timezone.abbreviation || "" : ""}` : "-",
    scheduled_start_time_string: schedule_confirmation.start_date_time ? `${moment(new Date(schedule_confirmation.start_date_time)).format("HH:mm:ss")} ${schedule_confirmation.scheduled_timezone ? schedule_confirmation.scheduled_timezone.abbreviation || "" : ""}` : "-",
    scheduled_end_time_string: schedule_confirmation.end_date_time ? `${moment(new Date(schedule_confirmation.end_date_time)).format("HH:mm:ss")} ${schedule_confirmation.scheduled_timezone ? schedule_confirmation.scheduled_timezone.abbreviation || "" : ""}` : "-",
    patient_name_string: !_.isEmpty(patient_first_name) && !_.isEmpty(patient_last_name) ? `${patient_first_name} ${patient_last_name}` : '-',
    patient_phone_string: patient_phone ? !_.isEmpty(patient_phone.toString()) ? phoneFormat(patient_phone.toString()) : "-" : "-",
    patient_dob_string: !_.isEmpty(patient_dob) ? moment(new Date(`${patient_dob} 00:00:00`)).format("MM/DD/yyyy") : "-",
    gender_string: !_.isEmpty(gender) ? gender.type || "-" : "-",
    patient_address: !_.isEmpty(patient_address1) ? `${patient_address1} ${!_.isEmpty(patient_address2) ? `, ${patient_address2}` : ""}` : "-",
    city: !_.isEmpty(patient_city) ? patient_city : "-",
    state: !_.isEmpty(patient_state) ? patient_state.state_name || "-" : "-",
    zip: !_.isEmpty(patient_zip) ? patient_zip : "-",
    partner_name_string: !_.isEmpty(partner) ? partner.partner_name || "-" : "-",
    partner_id_string: !_.isEmpty(partner) ? partner.external_partner_id || "-" : "-",
    partner_order_id_string: !_.isEmpty(partner_order_id) ? partner_order_id : "-",
    notes_without_cancellation_string: notesWithoutCancellation.length ? notesWithoutCancellation.map(note => note.notes).join(", ") : "-",
    cancellation_notes_string: cancellationNotes.length ? cancellationNotes.map(note => note.notes).join(", ") : "-",
    order_creation_datetime_for_modal: order_creation_datetime,
    order_creation_datetime: `${moment(new Date(order_creation_datetime)).format("MM/DD/yyyy HH:mm:ss")} ${order_creation_timezone ? order_creation_timezone.abbreviation || "" : ""}`,
    phleb: !_.isEmpty(user) ? user.name ? user.name : "-" : "-",
    phleb_employee_id: !_.isEmpty(user) ? user.employee_id ? user.employee_id : "-" : "-",
    coordinator_string: !_.isEmpty(coordinator) ? coordinator.name ? coordinator.name : "-" : "-",
    coordinator_employee_id: !_.isEmpty(coordinator) ? coordinator.employee_id ? coordinator.employee_id : "-" : "-",
    region_name_string: !_.isEmpty(region) ? region.region_name || "-" : "-",
    checkout_status_string: checkoutStatus,
    drop_off_points_string: !_.isEmpty(drop_off_points) ? drop_off_points.length > 0 ? drop_off_points.join(", ") : "-" : "-",
    drop_off_points_location_string: !_.isEmpty(drop_off_points_location) ? drop_off_points_location.length > 0 ? drop_off_points_location.join(", ") : "-" : "-",
    patient_notes_string: !_.isEmpty(patient_notes) ? patient_notes : "-",
    "NateraCancellationInitiator": partner ? partner.id == NATERA_ID ? !_.isEmpty(nateraCancellationNotes) ? nateraCancellationNotes : "-" : "-" : "-",
    "OrderingPhysician": !_.isEmpty(ordering_physician) ? ordering_physician : "-",
    "ServiceType": !_.isEmpty(order_service) ? order_service.order_service_type_name || "-" : "-",
    "PatientEmailAddress": !_.isEmpty(patient_email) ? patient_email : "-",
    "PatientAddressType": !_.isEmpty(address_type) ? address_type.address_type_name || "-" : "-",
    "SMSOptin": !_.isEmpty(sms_opt_in) ? sms_opt_in : "-",
    "PatientConfirmation": schedule_confirmation ? schedule_confirmation.patient_confirmation ? "Yes" : "No" : "No",
    "Patient has Partner kit": schedule_confirmation ? !_.isEmpty(schedule_confirmation.patient_has_partner_kit) ? schedule_confirmation.patient_has_partner_kit : "-" : "-",
    "AccountNumber": accNumber,
    "AccountName": accName,
    "AccessionInformation": accessionInfo ? accessionInfo : "-",
    "NotifiedPerson": !_.isEmpty(notified_person) ? notified_person : "-",
    "ResultFinalizedDate": result_finalized_date_time ? `${moment(new Date(result_finalized_date_time)).format("MM/DD/yyyy")} ${result_finalized_timezone ? result_finalized_timezone.abbreviation || "" : ""}` : "-",
    "ResultFinalizedTime": result_finalized_date_time ? `${moment(new Date(result_finalized_date_time)).format("HH:mm:ss")} ${result_finalized_timezone ? result_finalized_timezone.abbreviation || "" : ""}` : "-",
    "ScheduledTimezone": schedule_confirmation ? !_.isEmpty(schedule_confirmation.scheduled_timezone) ? schedule_confirmation.scheduled_timezone.timezone_name || "-" : "-" : "-",
    "TestCode": order_line_items ? order_line_items.length ? order_line_items.map(item => item.test_code || item.test_code_name || (item.test_code_id ? item.test_code_id.test_code : "")).join("; ") : "-" : "-",
    "SupervisorName": !_.isEmpty(user) ? user.reporting_manager_name ? user.reporting_manager_name : "-" : "-",
    "CheckIn": checkInDateTime ? checkInDateTime.action_datetime ? `${moment(new Date(checkInDateTime.action_datetime)).format("MM/DD/yyyy HH:mm:ss")} ${checkInDateTime.timezone ? checkInDateTime.timezone.abbreviation || "" : ""}` : "-" : "-",
    "CheckOut": checkOutDateTime ? checkOutDateTime.action_datetime ? `${moment(new Date(checkOutDateTime.action_datetime)).format("MM/DD/yyyy HH:mm:ss")} ${checkOutDateTime.timezone ? checkOutDateTime.timezone.abbreviation || "" : ""}` : "-" : "-",
    "lastUpdated": last_updated_datetime ? `${moment(new Date(last_updated_datetime)).format("MM/DD/yyyy HH:mm:ss")} ${last_updated_timezone ? last_updated_timezone.abbreviation || "" : ""}` : "-",
    "VisitType": visitTypes,
    "PartnerKitRequired": order_line_items ? order_line_items.length ? order_line_items.map(item => item.partner_test_kit_required === undefined || item.partner_test_kit_required === null ? "" : item.partner_test_kit_required ? "Yes" : "No").join("; ") : "-" : "-",
    "TestDescription": order_line_items ? order_line_items.length ? order_line_items.map(item => item.test_code_name || (item.test_code_id ? item.test_code_id.test_code_name : "")).join("; ") : "-" : "-",
    "DropOff": dropOffDateTime || "-",
    "validatedByMapboxString": is_validated_by_mapbox ? "Yes" : "No"
  }
}



export const getHomeHealth = createAsyncThunk(
  "homeHealth/getHomeHealth",
  async (data, { rejectWithValue }) => {
    const response = await ahaApi.Account.getHomeHealthOrders(data)
      .then((res) => {
        let { content = {} } = res;
        let { orders = [], count } = content;

        // data to show in listing
        const listingOrders = orders.map(c => {
          const updatedObject = replaceNullWithEmptyStringAndReturnUpdated(c);
          const obj = constructListingData(updatedObject);
          return obj;
        })

        //  data to populate modal fields
        const modalDataList = orders.map(c => {
          //  data to populate modal fields
          const obj = constructListingData(c);
          return obj;
        })
        res.content = { listingOrders, modalDataList, count };
        return res;
      })
      .catch((err) => {
        return rejectWithValue(err.response.data);
      });

    return response;
  }
);

export const homeHealthSlice = createSlice({
  name: "homeHealth",
  initialState,
  reducers: {
    setHomeHealthList(state, action) {
      state.value = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getHomeHealth.pending, (state) => {
        state.loading = true;
      })
      .addCase(getHomeHealth.fulfilled, (state, action) => {
        state.loading = false;
        state.value = action.payload;
      })
      .addCase(getHomeHealth.rejected, (state, err) => {
        state.loading = false;
        state.value = err.payload;
      });
  },
});

export const homeHealth = (state) => state.homeHealth.value;
export const homeHealthLoader = (state) => state.homeHealth.loading;
export const { setHomeHealthList } = homeHealthSlice.actions;

export default homeHealthSlice.reducer;
