import _ from "lodash";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ahaApi from "../../../app/services/aha-api";
const initialState = {
  value: [],
  loading: false,
};

export const backlognonservice = createAsyncThunk(
  "schedule/backlognonservice",
  async (data, { rejectWithValue }) => {
    const response = await ahaApi.Account.backlogNonServiceList(data)
      .then((res) => {
        res.content = backlogData(res.content);
        return res;
      })
      .catch((err) => {
        return rejectWithValue(err.response.data);
      });
    return response;
  }
);

const backlogData = (backlogData) => {
  let resultBacklogData = _.cloneDeep(backlogData);

  resultBacklogData.services.forEach((service, serviceIndex) => {
    if (service.zones) {
      service.zones.forEach((zone, zoneIndex) => {
        if (zone.events) {
          resultBacklogData.services[serviceIndex].zones[zoneIndex].events =
            _.keyBy(zone.events, "event_type_id");
        }
      });

      let resultZones = resultBacklogData.services[serviceIndex].zones;

      resultBacklogData.services[serviceIndex].zones = _.keyBy(
        resultZones,
        "zone_id"
      );
    }
  });

  return resultBacklogData;
};

export const backlogNonServiceSlice = createSlice({
  name: "backlognonservice",
  initialState,
  reducers: {
    updateBacklogValue(state, action) {
      state.value = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(backlognonservice.pending, (state) => {
        state.loading = true;
      })
      .addCase(backlognonservice.fulfilled, (state, action) => {
        state.loading = false;
        state.value = action.payload;
      })
      .addCase(backlognonservice.rejected, (state, err) => {
        state.loading = false;
        state.value = err.payload;
      });
  },
});

export const backlogNonServiceList = (state) => state.backlognonservice.value;
export const { updateBacklogValue } = backlogNonServiceSlice.actions;

export default backlogNonServiceSlice.reducer;
