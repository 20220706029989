import React, { useRef } from "react";
import Select, { components, createFilter } from "react-select";
import 'react-virtualized/styles.css';
import { List, AutoSizer } from 'react-virtualized';
import ReactDOM from 'react-dom';


const height = 35; 
const multiValueContainer = ({ selectProps, data }) => {
	const label = data.label;
	const allSelected = selectProps.value;
	const index = allSelected.findIndex(selected => selected.label === label);
	const isLastSelected = index === allSelected.length - 1;
	const labelSuffix = isLastSelected ? `` : ", ";
	const val = `${label}${labelSuffix}`;
	return val;
};
const customStyles = {
	valueContainer: (provided, state) => ({
		...provided,
		display: "initial",
		textOverflow: "ellipsis",
		maxWidth: "90%",
		whiteSpace: "nowrap",
		overflow: "hidden",
		fontSize: 14,
		color: '#121212'
	})
};
const Option = (props) => {
	// eslint-disable-next-line no-unused-vars
	const { onMouseMove, onMouseOver, ...rest } = props.innerProps;
	const newProps = { ...props, innerProps: rest };
	return (
		<div>
			<components.Option {...newProps}>
				<input
					type="checkbox"
					checked={props.isSelected}
					onChange={() => null}
				/>{" "}
				<label>{props.label}</label>
			</components.Option>
		</div>
	);
};

const MenuList = (props) => {
	const { options, children, maxHeight, getValue } = props;
	const [value] = getValue();
	const initialOffset = options.indexOf(value) * height;

	const rows = children

	const getRowRender = (options = []) => ({ index, style, key, parent }) => {
		// const option = options[index];
		// console.log(option);
		// // We are rendering an extra item for the placeholder
		// // Do do this we increased our data set size to include one 'fake' item
		// if (!options) {
		//   return null;
		// }
		return <div key={key} style={style}>{rows[index]}</div>
	}

	if (!Array.isArray(rows)) {
		// For children like: "Loading" or "No Options" provided by 'react-select'
		return <>{children}</>
	  }

	return (
		<AutoSizer>
        {({ width }) => (
		<List
			// height={maxHeight}
			// itemCount={children.length}
			// itemSize={height}
			// initialScrollOffset={initialOffset}

			height={maxHeight}
			data={options}
			rowCount={children.length}
			rowHeight={height}
			width={width}
			overscanRowsCount={10}
			rowRenderer={getRowRender(options, children)}
			className="rv-facility-select-dropdown"
		/>
	)}
	</AutoSizer>
			// {({ index, style }) => <div style={style}>{children[index]}</div>}
		// </List>
	);
};


export const VirtualMultiSelect = (props) => {
	const { placeholder, name, id, className, classNamePrefix, isDisabled, options = [], onBlur, allowSelectAll = true, isSearchable = false, isOptionDisabled = false } = props;
	// isOptionSelected sees previous props.value after onChange
	const valueRef = useRef(props.value);
	valueRef.current = props.value;

	const selectAllOption = {
		value: "<SELECT_ALL>",
		label: "Select All"
	};

	const isSelectAllSelected = () =>
		valueRef.current?.length > 0 && valueRef.current.length === (options ? options.length : 0);

	const isOptionSelected = (option) =>
		valueRef.current.some(({ value }) => value === option.value) ||
		isSelectAllSelected();

	const getOptions = () => options.length > 0 && allowSelectAll ?  [selectAllOption, ...options] : options;

	const getValue = () =>
		isSelectAllSelected() ? [selectAllOption] : props.value;

	const onChange = (newValue, actionMeta) => {
		const { action, option, removedValue } = actionMeta;

		if (action === "select-option" && option.value === selectAllOption.value) {
			props.onChange(options, actionMeta);
		} else if (
			(action === "deselect-option" &&
				option.value === selectAllOption.value) ||
			(action === "remove-value" &&
				removedValue.value === selectAllOption.value)
		) {
			props.onChange([], actionMeta);
		} else if (
			actionMeta.action === "deselect-option" &&
			isSelectAllSelected()
		) {
			props.onChange(
				options.filter(({ value }) => value !== option.value),
				actionMeta
			);
		} else {
			props.onChange(newValue || [], actionMeta);
		}
	};

	const handleBlur = (textInput, { action }) => {
		if (action === "input-blur") {
			onBlur();
		}
	}

	return (
		<Select
			isOptionSelected={isOptionSelected}
			options={getOptions()}
			value={getValue()}
			onChange={onChange}
			hideSelectedOptions={false}
			closeMenuOnSelect={false}
			isMulti
			onMenuClose={onBlur}
			name={name}
			id={id}
			className={className}
			classNamePrefix={classNamePrefix}
			isDisabled={isDisabled}
			placeholder={placeholder}
			components={{
				Option,
				// ValueContainer,
				MultiValueContainer: multiValueContainer,
				MenuList
			}}
			allowSelectAll={allowSelectAll}
			styles={customStyles}
			isSearchable={isSearchable}
			filterOption={createFilter({ ignoreAccents: false })}
			// isOptionDisabled={() => isOptionDisabled ? getValue().length >= 10 : getValue().length >= 1000}
			// menuIsOpen
		/>
	);
};
