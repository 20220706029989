import * as React from "react";
import { UserCell } from "./UserCell";
import { UserData } from "./UserData";

export const SchedulerBoard = (props) => {
  let {
    scheduleBoardData = [],
    setBoardData,
    date,
    updateBoardData,
    handleDeleteService,
    constructPyloadForCheckinCheckout,
    constructPayloadToClearCheckinCheckoutSpin,
    getScheduleBoardData,
    deleteServiceData,
    setDeleteServiceData,
    tabValue,
    searchText,
    searchType,
    tempCourierAddress,
    setTempCourierAddress,
    setTempCourierAddressForEnRoute,
    tempCourierAddressForEnRoute,
    filterType,
    surgeOrNonServiceFacilitiesData,
    facilitiesForSurge,
    facilitiesForNonService,
    addFacilityToSurgeOrNonService,
    showDropArea,
    shiftTimingsList,
    timezone_ids,
    zonesForSurge,
    addZoneToSurge,
  } = props;
  // scheduleBoardData = [scheduleBoardData[0]];

  return (
    scheduleBoardData.length > 0 && (
      <div className="scheduler-grid" >
        <div className="scheduler-table-wrapper">
          <table className="scheduler-table">
            <thead>
              {scheduleBoardData.map((data, idx) => {
                return <UserCell date={date}
                  constructPyloadForCheckinCheckout={constructPyloadForCheckinCheckout}
                  tabValue={tabValue}
                  users={data.users} key={`${idx}-${tabValue}`} searchText={searchText} 
                  searchType={searchType} setTempCourierAddressForEnRoute={setTempCourierAddressForEnRoute}
                  tempCourierAddressForEnRoute={tempCourierAddressForEnRoute}
                  filterType={filterType}
                  timezone_ids={timezone_ids}
                  />;
              })}
            </thead>
            <tbody>
              {scheduleBoardData.map((data, idx) => {
                return (
                  <UserData
                    key={`${idx}-${tabValue}`}
                    users={data.users}
                    setBoardData={setBoardData}
                    date={date}
                    updateBoardData={updateBoardData}
                    handleDelete={props.handleDelete}
                    deleteIndex={props.deleteIndex}
                    handleDeleteService={handleDeleteService}
                    constructPyloadForCheckinCheckout={constructPyloadForCheckinCheckout}
                    constructPayloadToClearCheckinCheckoutSpin={constructPayloadToClearCheckinCheckoutSpin}
                    getScheduleBoardData={getScheduleBoardData}
                    deleteServiceData={deleteServiceData}
                    setDeleteServiceData={setDeleteServiceData}
                    tabValue={tabValue}
                    searchText={searchText}
                    searchType={searchType}
                    tempCourierAddress={tempCourierAddress}
                    setTempCourierAddress={setTempCourierAddress}
                    filterType={filterType}
                    surgeOrNonServiceFacilitiesData={surgeOrNonServiceFacilitiesData}
                    facilitiesForSurge={facilitiesForSurge}
                    facilitiesForNonService={facilitiesForNonService}
                    addFacilityToSurgeOrNonService={addFacilityToSurgeOrNonService}
                    showDropArea={showDropArea}
                    shiftTimingsList={shiftTimingsList}
                    timezone_ids={timezone_ids}
                    zonesForSurge={zonesForSurge}
                    addZoneToSurge={addZoneToSurge}
                  />
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    )
  );
};
