import React from "react";
import { Modal } from "react-bootstrap";
const TimedDrawHeader = (props) => {

	const { mode, error } = props;
	return (
		<React.Fragment>
			{mode === "view" ? (
				<Modal.Title data-testid="time-draw-modal-header-view" className="headline4">View Timed Draw</Modal.Title>
			) : mode === "create" ? (
				<React.Fragment>
					<Modal.Title data-testid="time-draw-modal-header-create" className="headline4"> Create Timed Draw</Modal.Title>
					{error && <p className="error-message-alert" data-testid="time-draw-modal-header-create-error">{error}</p>}
				</React.Fragment>
			) : (
				<React.Fragment>
					<Modal.Title data-testid="time-draw-modal-header-edit" className="headline4"> Edit Timed Draw</Modal.Title>
					{error && <p className="error-message-alert" data-testid="time-draw-modal-header-edit-error">{error}</p>}
				</React.Fragment>
			)}
		</React.Fragment>
	);
}

export default TimedDrawHeader;
