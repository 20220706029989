import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ahaApi from "../../app/services/aha-api";
const initialState = {
  value: [],
  loading: false,
};

export const getActiveZones = createAsyncThunk("users/getActiveZones", async (data) => {
  const response = await ahaApi.Account.getActiveZones(data).then((res) => {
    return res;
  });

  return response;
});

export const activeZonesSlice = createSlice({
  name: "zone",
  initialState,

  extraReducers: (builder) => {
    builder
      .addCase(getActiveZones.pending, (state) => {
        state.loading = true;
      })
      .addCase(getActiveZones.fulfilled, (state, action) => {
        state.loading = false;
        state.value = action.payload;
      })
      .addCase(getActiveZones.rejected, (state, err) => {
        state.loading = false;
        state.value = err.payload;
      });
  },
});

export const activeZonesList = (state) => state&&state.activeZonesList&&state.activeZonesList.value;

export default activeZonesSlice.reducer;
