import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ahaApi from "../../../app/services/aha-api";
const initialState = {
  value: [],
  status: false,
};

export const postVerifyCode = createAsyncThunk(
  "account/postVerifyCode",
  async (data, { rejectWithValue }) => {
    const response = await ahaApi.Account.verifyCode(data)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return rejectWithValue(err.response.data);
      });
    return response;
  }
);

export const verifyCodeSlice = createSlice({
  name: "verifyCode",
  initialState,

  extraReducers: (builder) => {
    builder
      .addCase(postVerifyCode.pending, (state) => {
        state.status = true;
      })
      .addCase(postVerifyCode.fulfilled, (state, action) => {
        state.status = false;
        state.value = action.payload;
      })
      .addCase(postVerifyCode.rejected, (state, err) => {
        state.status = false;
        state.value = err.payload;
      });
  },
});

export const verifyCodeList = (state) =>
  state && state.verifyCode && state.verifyCode.value;

export default verifyCodeSlice.reducer;
