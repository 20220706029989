import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import ahaApi from "../../app/services/aha-api";

const initialState = {
  value: [],
  loading: false,
};

export const doBulkCheckinCheckout = createAsyncThunk(
  "statOrders/bulkCheckinCheckout",
  async (data, { rejectWithValue }) => {
    const response = await ahaApi.Account.bulkCheckinCheckout(data)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return rejectWithValue(err.response.data);
      });

    return response;
  }
);

export const bulkCheckinCheckoutSlice = createSlice({
  name: "bulkCheckinCheckout",
  initialState,
  reducers: {
    clearBulkCheckinCheckoutResponse(state, action) {
      state.value = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(doBulkCheckinCheckout.pending, (state) => {
        state.loading = true;
      })
      .addCase(doBulkCheckinCheckout.fulfilled, (state, action) => {
        state.loading = false;
        state.value = action.payload;
      })
      .addCase(doBulkCheckinCheckout.rejected, (state, err) => {
        state.loading = false;
        state.value = err.payload;
      });
  },
});

export const bulkCheckinCheckoutResponse = (state) => state.bulkCheckinCheckoutState.value;
export const { clearBulkCheckinCheckoutResponse } = bulkCheckinCheckoutSlice.actions;
export default bulkCheckinCheckoutSlice.reducer;
