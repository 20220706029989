import React from "react";
export default function ToastContent(props) {
	const {message, close, type} = props;
	return (
		<React.Fragment>
		<div className="toaster-close">
		  <span
			className="close-icon"
			data-testid="toast-header"
			onClick={close}
			aria-label="Close"
		  >
			<span className="visually-hidden">Close Icon</span>
		  </span>
		</div>
		<div>
		  <div className="toast-info-details d-flex align-items-center">
			<span className={`${type === "success" ? "check-with-circle" : type === "error" ? "close-with-circle" : ""}  pr-10`}> <span className="visually-hidden">
			  Checkmark icon with circle
			</span></span>
			<p className="font-size-12">{message}</p>
		  </div>
		</div>
		{/* <button >Close</button> */}
	  </React.Fragment>
	);
}
