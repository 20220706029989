// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import React from 'react';

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalButtonGroup,
  ModalButton,
} from 'amazon-chime-sdk-component-library-react';
import { GROUP_CHANNEL_TYPE, GROUP_CHANNEL_TYPE_TWO, INDIVIDUAL_CHANNEL_TYPE } from '../../../../app/utils/Constants';
import { useAuthContext } from '../../providers/AuthProvider';

export const ChatDeleteChannelModal = ({
  onClose,
  channel,
  handleChannelDeletion,
}) => {
  const { username } = useAuthContext().member;
  let {Name=""} = channel || {};
  if (Name.includes(GROUP_CHANNEL_TYPE) || Name.includes(GROUP_CHANNEL_TYPE_TWO)) {
    Name = Name.replace(GROUP_CHANNEL_TYPE, '');
    Name = Name.replace(GROUP_CHANNEL_TYPE_TWO, '');
  }
  else if (Name.includes(INDIVIDUAL_CHANNEL_TYPE)) {
    Name = Name.replace(INDIVIDUAL_CHANNEL_TYPE, '');
    let allUsers = Name.split(", ");
    if (allUsers.includes(username)) {
      allUsers = allUsers.filter(user => user !== username);
      Name = allUsers.join(', ');
    }
  }
  return (
    <Modal onClose={onClose} className="ch-main-modal">
      <ModalHeader title={`Delete chat`} />
      <ModalBody className="modal-body">
        <form
          onSubmit={(e) => handleChannelDeletion(e, channel.ChannelArn, channel.Metadata, "chat")}
          id="deletion-form"
        />
        <p className="leave-chat">You cannot undo this action.</p>
      </ModalBody>
      <ModalButtonGroup
        primaryButtons={[
          <ModalButton
            label="Delete"
            form="deletion-form"
            type="submit"
            variant="primary"
            className="primary-btn ad-progress-btn primary-btn-outline"
          />,
          <ModalButton label="Cancel" closesModal variant="secondary" className="primary-btn primary-btn-outline outline" />,
        ]}
      />
    </Modal>
  );
};

export default ChatDeleteChannelModal;
