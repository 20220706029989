import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ahaApi from "../../../app/services/aha-api";

const initialState = {
  value: [],
  loading: false,
};

export const getZones = createAsyncThunk(
  "zones/getZones",
  async (data, { rejectWithValue }) => {
    const response = await ahaApi.Account.fetchZones(data)
      .then((res) => {
        let { content = [] } = res;
        content = content.map(c => {
          const { zone_name, zone_code, zone_description, territory = {}, latitude, longitude, timezone = {} } = c;

          return {
            ...c,
            zone_name_string: zone_name || "-",
            zone_description_string: zone_description || "-",
            zone_code_string: zone_code || "-",
            latitude_string: latitude ? latitude : "-",
            longitude_string: longitude ? longitude : "-",
            territory_code_string:  territory ? territory.territory_code || "-"  : "-",
            territory_name_string: territory ? territory.territory_name || "-"  : "-",
            timezone_string: timezone ? timezone.timezone_name || "-"  : "-",
          }
        })
        res.content = content;
        return res;
      })
      .catch((err) => {
        return rejectWithValue(err.response.data);
      });

    return response;
  }
);

export const zonesSlice = createSlice({
  name: "zones",
  initialState,

  extraReducers: (builder) => {
    builder
      .addCase(getZones.pending, (state) => {
        state.loading = true;
      })
      .addCase(getZones.fulfilled, (state, action) => {
        state.loading = false;
        state.value = action.payload;
      })
      .addCase(getZones.rejected, (state, err) => {
        state.loading = false;
        state.value = err.payload;
      });
  },
});

export const zonesList = (state) => state.zones.value;
export const zonesLoader = (state) => state.zones.loading;

export default zonesSlice.reducer;
