// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import React, { useState } from 'react';

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalButtonGroup,
  ModalButton,
  Input,
  Label,
  RadioGroup,
} from 'amazon-chime-sdk-component-library-react';

import '../../../../assets/styles/pluginchat.scss';
import { useAuthContext } from '../../providers/AuthProvider';

export const NewChannelModal = ({ onClose, onCreateChannel, loader }) => {
  const [name, setName] = useState('');
  const [privacy, setPrivacy] = useState('PRIVATE');
  const [mode, setMode] = useState('RESTRICTED');

  const { member } = useAuthContext();
  const onNameChange = (e) => {
    setName(e.target.value);
  };
  const onPrivacyChange = (e) => {
    setPrivacy(e.target.value);
  };
  const onModeChange = (e) => {
    setMode(e.target.value);
  };
  return (
    <Modal size="md" onClose={onClose} className="ch-main-modal">
      <ModalHeader title="Create Room" />
      <ModalBody className="modal-body">
        <form
          onSubmit={(e) => onCreateChannel(e, name, mode, privacy)}
          id="new-channel-form"
        >
          <div className="ch-form-field-input">
            {/* <Label className="lbl">Room name</Label> */}
            <Input
              className="value modal-input"
              showClear={false}
              type="text"
              value={name}
              onChange={(e) => onNameChange(e)}
              placeholder="Room name"
            />
          </div>
          {/* <div className="ch-form-field-input">
            <Label className="lbl">Moderator</Label>
            <Label className="value">{member.username}</Label>
          </div> */}
          {/* <div className="ch-form-field-input">
            <Label className="lbl">Type (cannot be changed)</Label>
            <div className="value ch-type-options">
              <RadioGroup
                options={[
                  { value: 'PRIVATE', label: 'Private' },
                  { value: 'PUBLIC', label: 'Public' },
                ]}
                value={privacy}
                onChange={(e) => onPrivacyChange(e)}
              />
            </div>
          </div> */}
          {/* {privacy !== 'PUBLIC' && (
            <div className="ch-form-field-input">
              <Label className="lbl">Mode</Label>
              <div className="value ch-mode-options">
                <RadioGroup
                  options={[
                    { value: 'RESTRICTED', label: 'Restricted' },
                    { value: 'UNRESTRICTED', label: 'Unrestricted' },
                  ]}
                  value={mode}
                  onChange={(e) => onModeChange(e)}
                />
              </div>
            </div>
          )} */}
        </form>
      </ModalBody>
      {loader && <div className="ad-loading-wrapper">
            <span className="loader ad-loader d-block">
              <span className="visually-hidden">loader icon</span>
            </span>
          </div>}
      <ModalButtonGroup
        primaryButtons={[
          <ModalButton
            label="Create Room"
            type="submit"
            form="new-channel-form"
            variant="primary"
            className="primary-btn ad-progress-btn primary-btn-outline ml-2"
            disabled={!name || loader}
          />,
          <ModalButton label="Cancel" closesModal variant="secondary" className="primary-btn primary-btn-outline outline" />,
        ]}
      />
    </Modal>
  );
};

export default NewChannelModal;
