import _ from "lodash";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ahaApi from "./app/services/aha-api";
import moment from "moment";
const initialState = {
  loading: false,
  validSessionDataForLogin: []
};

export const validSessionForLogin = createAsyncThunk(
	"validSessionForLogin",
	async (data, { rejectWithValue }) => {
	  const response = await ahaApi.Account.checkValidSession()
		.then((res) => {
			// const timeStamp = moment();
			// sessionStorage.setItem('lastTimeStamp', timeStamp);
		  return res;
		})
		.catch((err) => {
		  return rejectWithValue(err.response.data);
		});
	  return response;
	}
  );

export const appSliceForLogin = createSlice({
	name: "validSessionForLogin",
	initialState,
	reducers: {
	  updateValidSession(state, action) {
		state.value = action.payload;
	  },
	  setLoggedIn(state, action) {
		  state.loggedIn = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder
		.addCase(validSessionForLogin.pending, (state) => {
		  state.loading = true;
		})
		.addCase(validSessionForLogin.fulfilled, (state, action) => {
		  state.loading = false;
		  state.validSessionDataForLogin = action.payload;
		})
		.addCase(validSessionForLogin.rejected, (state, err) => {
		  state.loading = false;
		  state.validSessionDataForLogin = err.payload;
		});
	},
  });

export const validSessionDataForLogin = (state) => state.appDataForLogin.validSessionDataForLogin;

export default appSliceForLogin.reducer;
