import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ahaApi from "../../../app/services/aha-api";
const initialState = {
  value: [],
  status: false,
  loading: false,
};

export const postLostDevice = createAsyncThunk(
  "account/postLostDevice",
  async (data, { rejectWithValue }) => {
    const response = await ahaApi.Account.lostDevice(data)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return rejectWithValue(err.response.data);
      });
    return response;
  }
);

export const lostDeviceSlice = createSlice({
  name: "lostDevice",
  initialState,

  extraReducers: (builder) => {
    builder
      .addCase(postLostDevice.pending, (state) => {
        state.loading = true;
      })
      .addCase(postLostDevice.fulfilled, (state, action) => {
        state.loading = false;
        state.value = action.payload;
      })
      .addCase(postLostDevice.rejected, (state, err) => {
        state.loading = false;
        state.value = err.payload;
      });
  },
});

export const lostDeviceList = (state) =>
  state && state.lostDevice && state.lostDevice.value;
export const loading = (state) =>
  state && state.lostDevice && state.lostDevice.loading;

export default lostDeviceSlice.reducer;
