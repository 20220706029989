import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { scrollPos, setScrollPosition } from "../../features/Scheduler/Operations/checkinCheckoutSlice";

export const CustomDropdown = React.memo((props) => {
	const { id, options = [], value = {}, onChange = () => { }, isDisabled = false, placeholder = "", className = "",
		isOptionDisabled = () => { }, setMenuIsOpen, menuIsOpen = false, wrapperRef, showLabel = false } = props;

	let optionsRef = useRef({});

	const menuRef = useRef({});
	const dispatch = useDispatch();

	const scrollPosition = useSelector(scrollPos)

	useEffect(() => {
		if (menuRef.current) {
			menuRef.current.scrollTop = scrollPosition;
		}
		const onClickOutside = (e) => {
			const menuOpen = document.getElementById("menu-div")
			if (e.target.contains(menuOpen) && menuOpen
			) {
				setMenuIsOpen(false)
				dispatch(setScrollPosition(0))
			}
		}
		document.addEventListener("mousedown", onClickOutside);
		return () => {
			document.removeEventListener("mousedown", onClickOutside);
		}
	}, [])


	const onScroll = () => {
		const scrollY = window.scrollY //Don't get confused by what's scrolling - It's not the window
		const scrollTop = menuRef.current.scrollTop
		dispatch(setScrollPosition(scrollTop))
	}
	

	// useEffect(() => {
	// 	if (menuIsOpen && value.value && optionsRef && optionsRef.current && optionsRef.current[value.value]) {
	// 		optionsRef.current[value.value].scrollIntoView({ behavior: "smooth", block: "center" });
	// 	}
	// }, [menuIsOpen])


	const open = (e) => {
		e.preventDefault();
		setMenuIsOpen(!menuIsOpen);
	}
	const onSelectOption = (value) => {
		onChange(value);
		setMenuIsOpen(false);
		dispatch(setScrollPosition(0))
	}
	return (
		<div id={id} className={menuIsOpen ? "cus-dropdown-container cus-dropdown-open" : "cus-dropdown-container"}
			ref={wrapperRef}
		>
			<input data-testid="cutom-dropdown-input" type="text" value={showLabel ? (value.abbreviation || "") : (value.value || "")} readOnly onClick={e => open(e)}
				disabled={isDisabled}
				placeholder={placeholder}
				className={className}
			/>
			{menuIsOpen && <div className="cus-dropdown" id="menu-div" ref={menuRef} onScroll={onScroll} onBlur={e => {
				setMenuIsOpen(false)
				dispatch(setScrollPosition(0))
			}}>
				{menuIsOpen ? options.length > 0 ? options.map((option, index) => {
					return (
						<div ref={element => (optionsRef.current[option.value] = element)} key={index} disabled={isOptionDisabled(option)} onClick={() => !isOptionDisabled(option) && onSelectOption(option)}
							className={`cus-dropdown-options ${value.value === option.value ? "active" : ""} ${isOptionDisabled(option) ? " disabled" : ""}`}>
							{option.label}
						</div>
					)
				}) :
					<div data-testid="custom-dropdown-no-options">No Options</div>
					: null
				}
			</div>}
		</div>
	);
});
