/* eslint-disable import/no-unresolved */
// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import React, { useState, useEffect } from "react";

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalButtonGroup,
  ModalButton,
  Input,
  Label,
} from "amazon-chime-sdk-component-library-react";

import { createMemberArn } from "../../api/ChimeAPI";
import { useIdentityService } from "../../providers/IdentityProvider";
import { useAuthContext } from "../../providers/AuthProvider";
import MultiContactPicker from "../MultiContactPicker";
import { listAppInstanceUsers } from "../../api/ChimeAPI";

import "../../../../assets/styles/pluginchat.scss";
import { appConfig } from "../../Config";
import {
  GROUP_CHANNEL_TYPE,
  GROUP_CHANNEL_TYPE_TWO,
  INDIVIDUAL_CHANNEL_TYPE,
} from "../../../../app/utils/Constants";
import _ from "lodash";

export const AddMembersToExistingSpace = ({
  onClose,
  channel,
  handleMultiPickerChange,
  onSubmit,
  members,
  selectedMultiMembers,
}) => {
  const [usersList, setUsersList] = useState([]);
  const identityClient = useIdentityService();
  const { useCognitoIdp } = useAuthContext();
  const { userId } = useAuthContext().member;

  const getUserAttributeByName = (user, attribute) => {
    try {
      return user.Attributes.filter((attr) => attr.Name === attribute)[0].Value;
    } catch (err) {
      return "none";
      // throw new Error(`Failed at getUserAttributeByName() with error: ${err}`);
    }
  };

  const getAllUsersFromCognitoIdp = async (searchText) => {
    let users = await identityClient.getUsers(60, searchText);

    let list = users.map((user) => {
      if (getUserAttributeByName(user, "profile") !== "none") {
        const { Attributes = [], Username = "" } = user;
        const firstName = Attributes.filter((att) => att.Name === "given_name")
          ? Attributes.filter((att) => att.Name === "given_name")[0]
            ? Attributes.filter((att) => att.Name === "given_name")[0].Value
            : ""
          : "";
        const middleName = Attributes.filter(
          (att) => att.Name === "middle_name"
        )
          ? Attributes.filter((att) => att.Name === "middle_name")[0]
            ? Attributes.filter((att) => att.Name === "middle_name")[0].Value
            : ""
          : "";
        const lastName = Attributes.filter((att) => att.Name === "family_name")
          ? Attributes.filter((att) => att.Name === "family_name")[0]
            ? Attributes.filter((att) => att.Name === "family_name")[0].Value
            : ""
          : "";
        const iconColorCode = Attributes.filter(
          (att) => att.Name === "nickname"
        )
          ? Attributes.filter((att) => att.Name === "nickname")[0]
            ? Attributes.filter((att) => att.Name === "nickname")[0].Value
            : ""
          : "";
        // const fullName = firstName + `${_.isEmpty(middleName) ? " " : ` ${middleName} `}` + lastName;
        const fullName = firstName + " " + lastName;
        const fullNameForInitials = firstName + " " + lastName;
        return {
          label: user.Username,
          value: user.Attributes.filter((attr) => attr.Name === "profile")[0]
            .Value,
          fullName,
          fullNameForInitials,
          iconColorCode,
        };
      }
      return false;
    });
    list = list.filter((l) => l);
    list = list.filter((user) => {
      return memberArns.indexOf(createMemberArn(user.value)) === -1;
    });
    // setUsersList(list);
    return list;
  };

  const loadOptions = async (searchText) => {
    let text = "given_name ^= " + '"' + searchText + '"';
    return await getAllUsers(text);
  };

  const getAllUsersFromListAppInstanceUsers = async () => {
    let list = [];
      let users = await listAppInstanceUsers(appConfig.appInstanceArn, userId);
      list = users.map((user) => {
        return {
          label: user.Name,
          value: user.AppInstanceUserArn.split("/user/")[1],
        };
      });
      list = list.filter((user) => {
        return memberArns.indexOf(createMemberArn(user.value)) === -1;
      });
    // setUsersList(list)
    return list;
  };

  const getAllUsers = (searchText) => {
    // either approach works, but if you have an IDP it is likely other apps will use IDP to find users so why not reuse here
    if (useCognitoIdp) {
      return getAllUsersFromCognitoIdp(searchText);
    } else {
      return getAllUsersFromListAppInstanceUsers();
    }
  };

  useEffect(() => {
    if (!identityClient) return;
    if (useCognitoIdp) {
      identityClient.setupClient();
    }
    // getAllUsers();
  }, [identityClient]);

  const memberArns = members.map((mem) => mem.Member.Arn);

  // let nonmembers = usersList.filter((user) => {
  // 	if (!user.value) {
  // 		return false;
  // 	}
  // 	return memberArns.indexOf(createMemberArn(user.value)) === -1;
  // });

  let { Name = "" } = channel || {};
  if (
    Name.includes(GROUP_CHANNEL_TYPE) ||
    Name.includes(INDIVIDUAL_CHANNEL_TYPE) ||
    Name.includes(GROUP_CHANNEL_TYPE_TWO)
  ) {
    Name = Name.replace(GROUP_CHANNEL_TYPE, "");
    Name = Name.replace(INDIVIDUAL_CHANNEL_TYPE, "");
    Name = Name.replace(GROUP_CHANNEL_TYPE_TWO, "");
  }

  return (
    <Modal onClose={onClose} className="add-members ch-main-modal">
      <ModalHeader title={`Add member`} />
      <ModalBody className="modal-body">
        <form
          onSubmit={(e) => onSubmit(e, "chat")}
          id="new-chat-channel-form"
          className="ch-form-wrapper"
        >
          <div className="ch-add-member stat-order-wrapper ac-top-wrapper">
          <MultiContactPicker
            onChange={handleMultiPickerChange}
            type="existing"
            channelName={Name}
            loadOptions={loadOptions}
            className="sch-statlisting-dropdown ac-select-contact"
            classNamePrefix="sch-statlisting-select"
          />
          </div>
        </form>
      </ModalBody>
      <ModalButtonGroup
        primaryButtons={[
          <ModalButton
            label="Add"
            type="submit"
            disabled={selectedMultiMembers.length === 0}
            variant="primary"
            form="new-chat-channel-form"
            className="primary-btn ad-progress-btn primary-btn-outline"
          />,
          <ModalButton
            label="Cancel"
            variant="secondary"
            closesModal
            className="primary-btn primary-btn-outline outline"
          />,
        ]}
      />
    </Modal>
  );
};

export default AddMembersToExistingSpace;
