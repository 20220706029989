import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ahaApi from "../../../app/services/aha-api";
const initialState = {
  value: [],
  loading: false,
};

export const deleteEvent = createAsyncThunk(
  "schedule/deleteEvent",
  async (data, { rejectWithValue }) => {
    const response = await ahaApi.Account.eventDelete(data.data, data.type, data.tab)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return rejectWithValue(err.response.data);
      });
    return response;
  }
);

export const deleteEventSlice = createSlice({
  name: "deleteEvent",
  initialState,
  reducers: {
    clearDeleteEventResponse(state, action) {
      state.value = {}
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(deleteEvent.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteEvent.fulfilled, (state, action) => {
        state.loading = false;
        state.value = action.payload;
      })
      .addCase(deleteEvent.rejected, (state, err) => {
        state.loading = false;
        state.value = err.payload;
      });
  },
});

export const deleteEventList = (state) => state.deleteEvent.value;
export const deleteEventLoading = (state) => state.deleteEvent.loading;

export const {clearDeleteEventResponse} = deleteEventSlice.actions;

export default deleteEventSlice.reducer;
