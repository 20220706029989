import { sortComparer } from "../../components/commons/CustomGrid";

window.customStatus = function (args) {
    const { status_color_code = {}, order_status = {} } = args;
    const color = status_color_code
        ? status_color_code.color_code
            ? status_color_code.color_code
            : ""
        : "";
    return `<div class="stat-order-status"
        style="background: ${color};"
      >
        <p class="title">
          <span
            class="${`icon ${order_status
            ? order_status.order_status_name
                ? order_status.order_status_name.toLowerCase()
                : ""
            : ""
        }`}"
          ></span>
          ${order_status.order_status_name}
        </p>
      </div>`;
};

export const HOME_HEALTH_COLUMNS = [
    {
        field: "order_status.order_status_name",
        headerText: "Status",
        width: "210",
        template: "<div>${customStatus(data)}</div>",
        disableHtmlEncode: false,
    },
    {
        field: "order_number",
        headerText: "Order #",
        width: "210",
        disableHtmlEncode: false,
    },
    {
        field: "partner_order_id_string",
        headerText: "Partner Order ID",
        width: "210",
        disableHtmlEncode: false,
    },
    {
        field: "order_creation_datetime",
        headerText: "Order Created",
        width: "210",
        disableHtmlEncode: false,
        type: "Date"
    },
    {
        field: "order_creation_method.method_name",
        headerText: "Order Creation Method",
        width: "210",
        disableHtmlEncode: false,
        visible: true,
    },
    {
        field: "created_by.name",
        headerText: "Order Creation User",
        width: "210",
        disableHtmlEncode: false,
        visible: false,
    },
    {
        field: "checkout_status_string",
        headerText: "Checkout Status",
        width: "210",
        disableHtmlEncode: false,
        visible: true,
    },
    {
        field: "scheduled_date_string",
        headerText: "Scheduled Date",
        width: "210",
        disableHtmlEncode: false,
        type: "Date"
    },
    {
        field: "scheduled_start_time_string",
        headerText: "Scheduled Start Time",
        width: "210",
        disableHtmlEncode: false,
    },
    {
        field: "scheduled_end_time_string",
        headerText: "Scheduled End Time",
        width: "210",
        disableHtmlEncode: false,
    },
    {
        field: "region_name_string",
        headerText: "Region",
        width: "220",
        disableHtmlEncode: false,
    },
    {
        field: "coordinator_string",
        headerText: "Coordinator",
        width: "220",
        disableHtmlEncode: false,
    },
    {
        field: "coordinator_employee_id",
        headerText: "Coordinator Emp ID",
        width: "230",
        disableHtmlEncode: false
    },
    {
        field: "phleb",
        headerText: "Phlebotomist",
        width: "220",
        disableHtmlEncode: false,
    },
    {
        field: "phleb_employee_id",
        headerText: "Phleb Emp ID",
        width: "230",
        disableHtmlEncode: false
    },
    {
        field: "patient_name_string",
        headerText: "Patient Name",
        width: "220",
        disableHtmlEncode: false,
    },
    {
        field: "patient_phone_string",
        headerText: "Patient Phone Number",
        width: "220",
        disableHtmlEncode: false,
    },
    {
        field: "patient_notes_string",
        headerText: "Patient Requested Date/Time",
        width: "220",
        disableHtmlEncode: false,
    },
    {
        field: "patient_dob_string",
        headerText: "Patient DOB",
        width: "210",
        visible: false,
        disableHtmlEncode: false,
    },
    {
        field: "gender_string",
        headerText: "Patient Biological Sex",
        width: "210",
        visible: false,
        disableHtmlEncode: false,
    },
    {
        field: "patient_address",
        headerText: "Patient Street Address",
        width: "220",
        disableHtmlEncode: false,
    },
    {
        field: "city",
        headerText: "City",
        width: "220",
        disableHtmlEncode: false,
    },
    {
        field: "state",
        headerText: "State",
        width: "220",
        disableHtmlEncode: false,
    },
    {
        field: "zip",
        headerText: "Zip",
        width: "220",
        disableHtmlEncode: false,
    },
    {
        field: "drop_off_points_string",
        headerText: "Drop Off Type",
        width: "210",
        visible: false,
        disableHtmlEncode: false,
    },
    {
        field: "drop_off_points_location_string",
        headerText: "Drop Location Name",
        width: "210",
        visible: false,
        disableHtmlEncode: false,
    },
    {
        field: "partner_name_string",
        headerText: "Partner Name",
        width: "210",
        visible: false,
        disableHtmlEncode: false,
    },
    {
        field: "partner_id_string",
        headerText: "Partner ID",
        width: "210",
        visible: false,
        disableHtmlEncode: false,
    },
    {
        field: "cancellation_notes_string",
        headerText: "Cancellation Reason",
        width: "255",
        disableHtmlEncode: false,
    },
    {
        field: "notes_without_cancellation_string",
        headerText: "Notes",
        width: "255",
        disableHtmlEncode: false,
    }
];

export const HOME_HEALTH_NEW_COLUMNS = [
    {
        field: "lastUpdated",
        headerText: "Last Updated DateTime",
        width: "255",
        visible: true,
        filter: { operators: "contains" },
        type: "Date",
        sortComparer: sortComparer
    },
    {
        field: "NateraCancellationInitiator",
        headerText: "Natera Cancellation Initiator",
        width: "255",
        visible: true,
        filter: { operators: "contains" }
    },
    {
        field: "OrderingPhysician",
        headerText: "Ordering Physician",
        width: "255",
        visible: false,
        filter: { operators: "contains" }
    },
    {
        field: "AccountNumber",
        headerText: "Account Number",
        width: "255",
        visible: false,
        filter: { operators: "contains" }
    }, {
        field: "AccountName",
        headerText: "Account Name",
        width: "255",
        visible: false,
        filter: { operators: "contains" }
    },
    {
        field: "ServiceType",
        headerText: "Service Type",
        width: "255",
        visible: false,
        filter: { operators: "contains" }
    },
    {
        field: "PatientEmailAddress",
        headerText: "Patient Email Address",
        width: "255",
        visible: false,
        filter: { operators: "contains" }
    }, {
        field: "PatientAddressType",
        headerText: "Patient Address Type",
        width: "255",
        visible: false,
        filter: { operators: "contains" }
    }, {
        field: "PatientConfirmation",
        headerText: "Patient Confirmation",
        width: "255",
        visible: false,
        filter: { operators: "contains" }
    }, {
        field: "PatienthasPartnerkit",
        headerText: "Patient has Partner kit",
        width: "255",
        visible: false,
        filter: { operators: "contains" }
    }, {
        field: "SMSOptin",
        headerText: "SMS Opt in",
        width: "255",
        visible: false,
        filter: { operators: "contains" }
    }, {
        field: "AccessionInformation",
        headerText: "Accession Information",
        width: "255",
        visible: false,
        filter: { operators: "contains" }
    }, {
        field: "NotifiedPerson",
        headerText: "Notified Person",
        width: "255",
        visible: false,
        filter: { operators: "contains" }
    },
    // {
    //     field: "ResultFinalizedDateTime",
    //     headerText: "Result Finalized DateTime",
    //     width: "255",
    //     visible: false,
    //     filter: { operators: "contains" }
    // },
    {
        field: "ScheduledTimezone",
        headerText: "Scheduled Timezone",
        width: "255",
        visible: false,
        filter: { operators: "contains" }
    },
    {
        field: "TestCode",
        headerText: "Test Code(s)",
        width: "255",
        visible: false,
        filter: { operators: "contains" }
    },
    {
        field: "SupervisorName",
        headerText: "Supervisor Name",
        width: "255",
        visible: false,
        filter: { operators: "contains" }
    },
    {
        field: "CheckIn",
        headerText: "Check-In Date/Time",
        width: "255",
        visible: false,
        filter: { operators: "contains" },
        type: "Date"
    },
    {
        field: "CheckOut",
        headerText: "Check-Out Date/Time",
        width: "255",
        visible: false,
        filter: { operators: "contains" },
        type: "Date"
    },
    {
        field: "TestDescription",
        headerText: "Test Description(s)",
        width: "255",
        visible: false,
        filter: { operators: "contains" }
    },
    {
        field: "VisitType",
        headerText: "Visit Type",
        width: "255",
        visible: false,
        filter: { operators: "contains" }
    },
    {
        field: "PartnerKitRequired",
        headerText: "Partner Kit Required",
        width: "255",
        visible: false,
        filter: { operators: "contains" }
    },
    {
        field: "ResultFinalizedDate",
        headerText: "Result Finalized Date",
        width: "255",
        visible: false,
        filter: { operators: "contains" },
        type: "Date"
    },
    {
        field: "ResultFinalizedTime",
        headerText: "Result Finalized Time",
        width: "255",
        visible: false,
        filter: { operators: "contains" }
    },
    {
        field: "DropOff",
        headerText: "Drop-Off Date/Time",
        width: "255",
        visible: false,
        filter: { operators: "contains" }
    },
    {
        field: "validatedByMapboxString",
        headerText: "Validated by MapBox",
        width: "255",
        visible: false,
        filter: { operators: "contains" }
    },
];

export const STAT_COLUMNS = [
    {
        field: "order_status.order_status_name",
        headerText: "Status",
        width: "210",
        template: '<div>${customStatus(data)}</div>',
        disableHtmlEncode: false,
    },
    {
        field: "order_number",
        headerText: "Order #",
        width: "210",
        disableHtmlEncode: false,
    },
    {
        field: "order_creation_datetime",
        headerText: "Order Created",
        width: "210",
        disableHtmlEncode: false,
        type: "Date"
    },
    {
        field: "order_creation_method.method_name",
        headerText: "Order Creation Method",
        width: "210",
        visible: false,
        disableHtmlEncode: false,
    },
    {
        field: "order_type.order_type_name",
        headerText: "Order Type",
        width: "210",
        visible: false,
        disableHtmlEncode: false,
    },
    {
        field: "order_frequency.frequency",
        headerText: "Order Frequency",
        width: "210",
        visible: false,
        disableHtmlEncode: false,
    },
    {
        field: "parent_account_name_string",
        headerText: "Parent Account Name",
        width: "210",
        disableHtmlEncode: false,
    },
    {
        field: "parent_account_number_string",
        headerText: "Parent Account Number",
        width: "210",
        visible: false,
        disableHtmlEncode: false,
    },
    {
        field: "child_account_number_string",
        headerText: "Child Account Number",
        width: "210",
        visible: false,
        disableHtmlEncode: false,
    },
    {
        field: "child_account_name_string",
        headerText: "Child Account Name",
        width: "210",
        visible: false,
        disableHtmlEncode: false,
    },
    {
        field: "childAccountAddressString",
        headerText: "Child Account Address",
        width: "210",
        visible: false,
        disableHtmlEncode: false,
    },
    {
        field: "ordering_physician_string",
        headerText: "Ordering Physician",
        width: "210",
        visible: false,
        disableHtmlEncode: false,
    },
    {
        field: "patientNameString",
        headerText: "Patient Name",
        width: "220",
        disableHtmlEncode: false,
    },
    {
        field: "phleb",
        headerText: "Phlebotomist",
        width: "230",
        disableHtmlEncode: false
    },
    {
        field: "phlebEmployeeId",
        headerText: "Phleb Emp ID",
        width: "230",
        disableHtmlEncode: false
    },
    {
        field: "region_name_string",
        headerText: "Region",
        width: "220",
        disableHtmlEncode: false,
    },
    {
        field: "territory_name_string",
        headerText: "Territory",
        width: "190",
        disableHtmlEncode: false,
    },
    {
        field: "zone_name_string",
        headerText: "Zone",
        width: "230",
        disableHtmlEncode: false,
    },
    {
        field: "patient_dob_string",
        headerText: "Patient DOB",
        width: "210",
        visible: false,
        disableHtmlEncode: false,
        type: "Date"
    },
    {
        field: "gender_string",
        headerText: "Patient Gender",
        width: "210",
        visible: false,
        disableHtmlEncode: false,
    },
    {
        field: "patient_location_string",
        headerText: "Patient Location",
        width: "210",
        visible: false,
        disableHtmlEncode: false,
    },
    {
        field: "drop_point",
        headerText: "Drop Off Type",
        width: "210",
        visible: false,
        disableHtmlEncode: false,
    },
    {
        field: "drop_point_location",
        headerText: "Drop Location Name",
        width: "210",
        visible: false,
        disableHtmlEncode: false,
    },
    {
        field: "test_codes_string",
        headerText: "Test Code(s)",
        width: "210",
        visible: false,
        disableHtmlEncode: false,
    },
    {
        field: "cancellation_notes_string",
        headerText: "Cancellation Reason",
        width: "255",
        disableHtmlEncode: false,
    },
    {
        field: "notes_without_cancellation_string",
        headerText: "Notes",
        width: "255",
        disableHtmlEncode: false,
    }
];

export const STAT_NEW_COLUMNS = [
    {
        field: "lastUpdated",
        headerText: "Last Updated DateTime",
        width: "255",
        visible: true,
        filter: { operators: "contains" },
        type: "Date",
        sortComparer: sortComparer
    },
    {
        field: "OrderCreationUser",
        headerText: "Order Creation User",
        width: "255",
        visible: false
    },
    {
        field: "PatientLocation",
        headerText: "Patient Location",
        width: "255",
        visible: false
    },
    {
        field: "NotifiedPerson",
        headerText: "Notified Person",
        width: "255",
        visible: false
    },
    {
        field: "SupervisorName",
        headerText: "Supervisor Name",
        width: "255",
        visible: false,
        filter: { operators: "contains" }
    },
    {
        field: "CheckIn",
        headerText: "Check-In Date/Time",
        width: "255",
        visible: false,
        filter: { operators: "contains" },
        type: "Date"
    },
    {
        field: "CheckOut",
        headerText: "Check-Out Date/Time",
        width: "255",
        visible: false,
        filter: { operators: "contains" },
        type: "Date"
    },
    {
        field: "TestName",
        headerText: "Test Name(s)",
        width: "255",
        visible: false,
        filter: { operators: "contains" }
    },
    {
        field: "OrderCollectionMethod",
        headerText: "Order Collection Method",
        width: "255",
        visible: false,
        filter: { operators: "contains" }
    },
    {
        field: "CollectionDate",
        headerText: "Collection Date",
        width: "255",
        visible: false,
        filter: { operators: "contains" }
    },
    {
        field: "DropOff",
        headerText: "Drop-Off Date/Time",
        width: "255",
        visible: false,
        filter: { operators: "contains" },
        type: "Date"
    },
];

export const ROUTINE_REDRAW_COLUMNS = [
    {
        field: "order_status.order_status_name",
        headerText: "Status",
        width: "210",
        template: '<div>${customStatus(data)}</div>',
        disableHtmlEncode: false,
    },
    {
        field: "order_number",
        headerText: "Order #",
        width: "210",
        disableHtmlEncode: false,
    },
    {
        field: "order_type.order_type_name",
        headerText: "Order Type",
        width: "210",
        visible: false,
        disableHtmlEncode: false,
    },
    {
        field: "order_frequency.frequency",
        headerText: "Order Frequency",
        width: "210",
        visible: false,
        disableHtmlEncode: false,
    },
    {
        field: "order_creation_method.method_name",
        headerText: "Order Creation Method",
        width: "210",
        visible: false,
        disableHtmlEncode: false,
    },
    {
        field: "order_creation_datetime",
        headerText: "Order Created",
        width: "210",
        disableHtmlEncode: false,
        type: "Date"
    },
    {
        field: "parent_account_name_string",
        headerText: "Parent Account Name",
        width: "210",
        disableHtmlEncode: false,
    },
    {
        field: "parent_account_number_string",
        headerText: "Parent Account Number",
        width: "210",
        visible: false,
        disableHtmlEncode: false,
    },
    {
        field: "child_account_number_string",
        headerText: "Child Account Number",
        width: "210",
        visible: false,
        disableHtmlEncode: false,
    },
    {
        field: "child_account_name_string",
        headerText: "Child Account Name",
        width: "210",
        visible: false,
        disableHtmlEncode: false,
    },
    {
        field: "childAccountAddressString",
        headerText: "Child Account Address",
        width: "210",
        visible: false,
        disableHtmlEncode: false,
    },
    {
        field: "ordering_physician_string",
        headerText: "Ordering Physician",
        width: "210",
        visible: false,
        disableHtmlEncode: false,
    },
    {
        field: "region_name_string",
        headerText: "Region",
        width: "220",
        disableHtmlEncode: false,
    },
    {
        field: "territory_name_string",
        headerText: "Territory",
        width: "190",
        disableHtmlEncode: false,
    },
    {
        field: "zone_name_string",
        headerText: "Zone",
        width: "230",
        disableHtmlEncode: false,
    },
    {
        field: "phleb",
        headerText: "Phlebotomist",
        width: "230",
        disableHtmlEncode: false,
    },
    {
        field: "phlebEmployeeId",
        headerText: "Phleb Emp ID",
        width: "230",
        disableHtmlEncode: false
    },
    {
        field: "patientNameString",
        headerText: "Patient Name",
        width: "220",
        disableHtmlEncode: false,
    },
    {
        field: "patient_dob_string",
        headerText: "Patient DOB",
        width: "210",
        visible: false,
        disableHtmlEncode: false,
        type: "Date"
    },
    {
        field: "gender_string",
        headerText: "Patient Gender",
        width: "210",
        visible: false,
        disableHtmlEncode: false,
    },
    {
        field: "patient_location_string",
        headerText: "Patient Location",
        width: "210",
        visible: false,
        disableHtmlEncode: false,
    },
    {
        field: "drop_point",
        headerText: "Drop Off Type",
        width: "210",
        visible: false,
        disableHtmlEncode: false,
    },
    {
        field: "drop_point_location",
        headerText: "Drop Location Name",
        width: "210",
        visible: false,
        disableHtmlEncode: false,
    },
    {
        field: "test_codes_string",
        headerText: "Test Code(s)",
        width: "210",
        visible: false,
        disableHtmlEncode: false,
    },
    {
        field: "cancellation_notes_string",
        headerText: "Cancellation Reason",
        width: "255",
        disableHtmlEncode: false,
    },
    {
        field: "notes_without_cancellation_string",
        headerText: "Notes",
        width: "255",
        disableHtmlEncode: false,
    }
];

export const ROUTINE_REDRAW_NEW_COLUMNS = [
    {
        field: "lastUpdated",
        headerText: "Last Updated DateTime",
        width: "255",
        visible: true,
        filter: { operators: "contains" },
        type: "Date",
        sortComparer: sortComparer
    },
    {
        field: "OrderCreationUser",
        headerText: "Order Creation User",
        width: "255",
        visible: false
    },
    {
        field: "PatientLocation",
        headerText: "Patient Location",
        width: "255",
        visible: false
    },
    {
        field: "SupervisorName",
        headerText: "Supervisor Name",
        width: "255",
        visible: false,
        filter: { operators: "contains" }
    },
    {
        field: "CheckIn",
        headerText: "Check-In Date/Time",
        width: "255",
        visible: false,
        filter: { operators: "contains" },
        type: "Date"
    },
    {
        field: "CheckOut",
        headerText: "Check-Out Date/Time",
        width: "255",
        visible: false,
        filter: { operators: "contains" },
        type: "Date"
    },
    {
        field: "TestName",
        headerText: "Test Name(s)",
        width: "255",
        visible: false,
        filter: { operators: "contains" }
    },
    {
        field: "OrderCollectionMethod",
        headerText: "Order Collection Method",
        width: "255",
        visible: false,
        filter: { operators: "contains" }
    },
    {
        field: "CollectionDate",
        headerText: "Collection Date",
        width: "255",
        visible: false,
        filter: { operators: "contains" }
    },
    {
        field: "DropOff",
        headerText: "Drop-Off Date/Time",
        width: "255",
        visible: false,
        filter: { operators: "contains" },
        type: "Date"
    },
];

export const TIMED_DRAW_COLUMNS = [
    {
        field: "order_status.order_status_name",
        headerText: "Status",
        width: "210",
        template: '<div>${customStatus(data)}</div>',
        disableHtmlEncode: false,
    },
    {
        field: "order_number",
        headerText: "Order #",
        width: "210",
        disableHtmlEncode: false,
    },
    {
        field: "order_type.order_type_name",
        headerText: "Order Type",
        width: "210",
        visible: false,
        disableHtmlEncode: false,
    },
    {
        field: "order_frequency.frequency",
        headerText: "Order Frequency",
        width: "210",
        visible: false,
        disableHtmlEncode: false,
    },
    {
        field: "order_creation_method.method_name",
        headerText: "Order Creation Method",
        width: "210",
        visible: false,
        disableHtmlEncode: false,
    },
    {
        field: "order_creation_datetime",
        headerText: "Order Created",
        width: "210",
        disableHtmlEncode: false,
        type: "Date"
    },
    {
        field: "collection_time_string",
        headerText: "Collection Date & Time",
        width: "210",
        visible: true,
        disableHtmlEncode: false,
        type: "Date"
    },
    {
        field: "parent_account_name_string",
        headerText: "Parent Account Name",
        width: "210",
        disableHtmlEncode: false,
    },
    {
        field: "parent_account_number_string",
        headerText: "Parent Account Number",
        width: "210",
        visible: false,
        disableHtmlEncode: false,
    },
    {
        field: "child_account_number_string",
        headerText: "Child Account Number",
        width: "210",
        visible: false,
        disableHtmlEncode: false,
    },
    {
        field: "child_account_name_string",
        headerText: "Child Account Name",
        width: "210",
        visible: false,
        disableHtmlEncode: false,
    },
    {
        field: "childAccountAddressString",
        headerText: "Child Account Address",
        width: "210",
        visible: false,
        disableHtmlEncode: false,
    },
    {
        field: "ordering_physician_string",
        headerText: "Ordering Physician",
        width: "210",
        visible: false,
        disableHtmlEncode: false,
    },
    {
        field: "region_name_string",
        headerText: "Region",
        width: "220",
        disableHtmlEncode: false,
    },
    {
        field: "territory_name_string",
        headerText: "Territory",
        width: "190",
        disableHtmlEncode: false,
    },
    {
        field: "zone_name_string",
        headerText: "Zone",
        width: "230",
        disableHtmlEncode: false,
    },
    {
        field: "phleb",
        headerText: "Phlebotomist",
        width: "230",
        disableHtmlEncode: false,
    },
    {
        field: "phlebEmployeeId",
        headerText: "Phleb Emp ID",
        width: "230",
        disableHtmlEncode: false
    },
    {
        field: "patientNameString",
        headerText: "Patient Name",
        width: "220",
        disableHtmlEncode: false,
    },
    {
        field: "patient_dob_string",
        headerText: "Patient DOB",
        width: "210",
        visible: false,
        disableHtmlEncode: false,
        type: "Date"
    },
    {
        field: "gender_string",
        headerText: "Patient Gender",
        width: "210",
        visible: false,
        disableHtmlEncode: false,
    },
    {
        field: "patient_location_string",
        headerText: "Patient Location",
        width: "210",
        visible: false,
        disableHtmlEncode: false,
    },
    {
        field: "drop_point",
        headerText: "Drop Off Type",
        width: "210",
        visible: false,
        disableHtmlEncode: false,
    },
    {
        field: "drop_point_location",
        headerText: "Drop Location Name",
        width: "210",
        visible: false,
        disableHtmlEncode: false,
    },
    {
        field: "test_codes_string",
        headerText: "Test Code(s)",
        width: "210",
        visible: false,
        disableHtmlEncode: false,
    },
    {
        field: "cancellation_notes_string",
        headerText: "Cancellation Reason",
        width: "255",
        disableHtmlEncode: false,
    },
    {
        field: "notes_without_cancellation_string",
        headerText: "Notes",
        width: "255",
        disableHtmlEncode: false,
    }
];

export const TIMED_DRAW_NEW_COLUMNS = [
    {
        field: "lastUpdated",
        headerText: "Last Updated DateTime",
        width: "255",
        visible: true,
        filter: { operators: "contains" },
        type: "Date",
        sortComparer: sortComparer
    },
    {
        field: "OrderCreationUser",
        headerText: "Order Creation User",
        width: "255",
        visible: false
    },
    {
        field: "PatientLocation",
        headerText: "Patient Location",
        width: "255",
        visible: false
    },
    {
        field: "NotifiedPerson",
        headerText: "Notified Person",
        width: "255",
        visible: false
    },
    {
        field: "SupervisorName",
        headerText: "Supervisor Name",
        width: "255",
        visible: false,
        filter: { operators: "contains" }
    },
    {
        field: "CheckIn",
        headerText: "Check-In Date/Time",
        width: "255",
        visible: false,
        filter: { operators: "contains" },
        type: "Date"
    },
    {
        field: "CheckOut",
        headerText: "Check-Out Date/Time",
        width: "255",
        visible: false,
        filter: { operators: "contains" },
        type: "Date"
    },
    {
        field: "TestName",
        headerText: "Test Name(s)",
        width: "255",
        visible: false,
        filter: { operators: "contains" }
    },
    {
        field: "OrderCollectionMethod",
        headerText: "Order Collection Method",
        width: "255",
        visible: false,
        filter: { operators: "contains" }
    },
    {
        field: "CollectionDate",
        headerText: "Collection Date",
        width: "255",
        visible: false,
        filter: { operators: "contains" }
    },
    {
        field: "DropOff",
        headerText: "Drop-Off Date/Time",
        width: "255",
        visible: false,
        filter: { operators: "contains" },
        type: "Date"
    },
];