import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import ahaApi from "../../app/services/aha-api";
import { phoneFormat } from "../../app/utils/testUtils";
const initialState = {
  value: [],
  loading: true,
};

export const getUsers = createAsyncThunk(
  "users/getUsers",
  async (data, { rejectWithValue }) => {
    const response = await ahaApi.Account.users(data)
      .then((res) => {
        let users = res.content || [];
        let tempUsers = [...users];
        tempUsers = tempUsers.map(user => {
          return {
            ...user,
            currently_logged_in: user.currently_logged_in ? "Yes" : "No",
            last_login_datetime: user.last_login_datetime ? moment(user.last_login_datetime).format("MM/DD/yyyy HH:mm:ss") : "-",
            middle_initial_string: user.middle_initial || "-",
            home_region_name_string: user.home_region ? (user.home_region.region_name || "-") : "-",
            reporting_manager_name_string: user.reporting_manager_name || "-",
            phone_number_string: user.phone_number ? phoneFormat(user.phone_number.toString()) : "-",
            personal_phone_number_string: user.personal_phone_number || "-",
            email_string: user.email || "-",
            has_company_vehicle_string: user.has_company_vehicle ? "Yes" : "No"
          }
        })
        res.content = tempUsers
        return res;
      })
      .catch((err) => {
        return rejectWithValue(err.response.data);
      });

    return response;
  }
);

export const userSlice = createSlice({
  name: "users",
  initialState,

  extraReducers: (builder) => {
    builder
      .addCase(getUsers.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUsers.fulfilled, (state, action) => {
        state.loading = false;
        state.value = action.payload;
      })
      .addCase(getUsers.rejected, (state, err) => {
        state.loading = false;
        state.value = err.payload;
      });
  },
});

export const usersList = (state) => state.users.value;
export const userLoader = (state) => state.users.loading;

export default userSlice.reducer;
