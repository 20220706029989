import React from "react";
import Logo from "../../../components/layouts/Logo";
import {
  FORGOT_PASSWORD,
  FORGOT_USER,
  HOME,
} from "../../../app/utils/Constants";
// import { useNavigate } from "react-router-dom";
export default function TroubleLogin(props) {
  //   const navigate = useNavigate();
  document.documentElement.classList.add("login-flow");
  return (
    <React.Fragment>
      <Logo />
      <div>
        <h1 className="mb-10 roboto-regular login-title">
          Trouble Logging In?
        </h1>
        <p className="mb-15 roboto-regular login-info">
          If email is on file, then this will send a recovery code which will
          unlock account.
        </p>
        <form className="aha-login-wrapper">
          <button
            className="primary-btn mb-15"
            type="submit"
            onClick={() => {
              props.history &&props.history.push && props.history.push(FORGOT_USER);
            }}
          >
            <span>Forgot Username</span>
            <span className="loader">
              <span className="visually-hidden">loader icon</span>
            </span>
          </button>
          <div className="separator-wrapper mb-15">
            <span className="position-relative d-block roboto-bold font-size-12">
              or
            </span>
          </div>
          <button
            className="primary-btn mb-10"
            type="submit"
            onClick={() => {
              props.history && props.history.push && props.history.push(FORGOT_PASSWORD);
              document.documentElement.classList.remove("login-flow");
            }}
          >
            <span>Forgot Password</span>
            <span className="loader">
              <span className="visually-hidden">Loader Icon</span>
            </span>
          </button>
        </form>
        <div className="aha-link-wrapper">
          <a href={HOME}>Back to Sign In</a>
        </div>
      </div>
    </React.Fragment>
  );
}
