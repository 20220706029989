import * as React from "react";
import { Dropdown } from "react-bootstrap";
import _ from "lodash";

export const MapFilter = (props) => {
  let {
    selectedFilter = "",
    selectFilterType,
    searchText = "",
    setSearchText,
    selectOption,
    selectedOption = {},
    toggleDropdown,
    dropdown,
    filterItems,
    formattedFilterValue,
    formattedSearchResult
  } = props;
  return (
    <React.Fragment>
      <Dropdown
        className="filter-type"
        onToggle={(val) => toggleDropdown(val)}
        show={dropdown}
        data-testid="map-filter-dropdown"
      >
        <Dropdown.Toggle
          id="dropdown-autoclose-inside"
          className="filter-title"
          data-testid="map-filter-toggle"
        >
          {_.isEmpty(selectedFilter)
            ? "Select Search Type"
            : formattedFilterValue}
        </Dropdown.Toggle>
        <Dropdown.Menu className="poo" data-testid="map-filter-dropdownmenu">
          <ul data-testid="map-filter-menu">
            {filterItems.map((item, index) => {
              return (
                <li
                  className={selectedFilter === item.value ? "active" : ""}
                  key={item.value}
                  onClick={() => selectFilterType(item.value)}
                  data-testid={`map-filter-menuitem-${index}`}
                >
                  {item.label}
                </li>
              );
            })}
          </ul>
        </Dropdown.Menu>
      </Dropdown>
      <div className="tracker-search">
        <div className="search-input">
          <span className="service-icon">
            <span className="visually-hidden">Search</span>
          </span>
          <label htmlFor="tracker-search" className="visually-hidden">
            Search
          </label>
          <input
            id="tracker-search"
            type="text"
            placeholder="Search"
            onChange={(e) => setSearchText(e.target.value)}
            value={searchText}
            autoComplete="off"
          />
        </div>
      </div>
      <div className="tracker-result">
        <ul>
          {formattedSearchResult.map((result, index) => {
            return (
              <li
                key={index}
                className={selectedOption.name === result.name ? "active" : ""}
                onClick={() => selectOption(result)}
                data-testid={`map-filter-result-${index}`}
              >
                {result.name}
              </li>
            );
          })}
        </ul>
      </div>
    </React.Fragment>
  );
};
