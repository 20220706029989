import React from "react";
import Logo from "../../../components/layouts/Logo";
import QRCode from "qrcode.react";
import { VERIFY_DEVICE } from "../../../app/utils/Constants";
import { Tooltip, Button, Overlay } from "react-bootstrap";
import { CopyToClipboard } from "react-copy-to-clipboard";
export default function GoogleAuthentication(props) {
  document.documentElement.classList.add("login-flow");
  const [error, setError] = React.useState(false);

  const secret_code =
    props &&
    props.location &&
    props.location.state &&
    props.location.state.secret_code;
  const session_id =
    props &&
    props.location &&
    props.location.state &&
    props.location.state.session_id;
  const challenge_name =
    props &&
    props.location &&
    props.location.state &&
    props.location.state.challenge_name;
  const reset =
    props &&
    props.location &&
    props.location.state &&
    props.location.state.reset;
  const pass =
    props &&
    props.location &&
    props.location.state &&
    props.location.state.password;
  React.useState(() => {
    if (
      (props &&
        props.location &&
        props.location.state &&
        !props.location.state.session_id) ||
      (props && props.location && props.location.state === undefined)
    ) {
      setError(true);
    }
  }, []);
  const [show, setShow] = React.useState(false);
  const target = React.useRef(null);
  // console.log(secret_code.length);
  const [toggle, setToggle] = React.useState(false);
  const [copy, setCopy] = React.useState(false);
  return (
    <React.Fragment>
      <Logo />
      {!error ? (
        <div>
          <h1 className="mb-10 roboto-regular login-title">
            Connect to Google Authenticator
          </h1>
          <p className="mb-20 roboto-regular login-info">
            Two-factor authentication is required to access this application.
          </p>
          <p className="mb-12 roboto-regular login-info">
            To continue, please install Google Authenticator on your mobile
            phone from Google Play. To get set up, use the authenticator app to
            scan the barcode below. Or, you may manually enter the secret key.
          </p>
          <div className="mb-10 qr-code-wrapper">
            <QRCode value={`otpauth://totp/mobileAha?secret=${secret_code}`} />
          </div>
          <form className="aha-login-wrapper">
            <div className="separator-wrapper mab-5">
              <span className="position-relative d-block roboto-bold font-size-12">
                or
              </span>
            </div>
            <div className="mb-20 login-info manually-enter-wrapper">
              {!toggle ? (
                <p className="roboto-regular">
                  Manually enter{" "}
                  <a className="roboto-medium" onClick={() => setToggle(true)}>
                    {" "}
                    this secret key{" "}
                  </a>
                </p>
              ) : (
                <div className="d-flex align-items-center">
                  <CopyToClipboard
                    text={secret_code}
                    onCopy={() => setCopy(true)}
                  >
                    <span className="d-flex">
                      <Button
                        className={"secret-key-copy" + (copy ? " copied" : "")}
                        ref={target}
                        onClick={() => setShow(true)}
                      >
                        <span className="copy-icon">
                          <span className="visually-hidden">Copy Icon</span>
                        </span>
                      </Button>
                      <p
                        className={
                          "aha-secret-key roboto-light" +
                          (copy ? " copied" : "")
                        }
                        onClick={() => setShow(!show)}
                        title="Click to copy"
                      >
                        {secret_code}
                      </p>
                    </span>
                  </CopyToClipboard>

                  <Overlay target={target.current} show={show} placement="top">
                    {(props) => <Tooltip {...props}>Copied!</Tooltip>}
                  </Overlay>

                  <a
                    className="roboto-medium font-size-12"
                    onClick={() => setToggle(!toggle)}
                  >
                    Hide Secret Key
                  </a>
                </div>
              )}
            </div>
            <button
              className="primary-btn mb-10"
              type="button"
              onClick={() => {
                props.history.push({
                  pathname: VERIFY_DEVICE,
                  state: {
                    challenge_name: challenge_name,
                    secret_code: secret_code,
                    session_id: session_id,
                    password: pass ? pass : false,
                    reset: reset ? reset : false,
                  },
                });
              }}
            >
              <span>continue</span>
              <span className="loader">
                <span className="visually-hidden">loader icon</span>
              </span>
            </button>
          </form>
        </div>
      ) : (
        <div>Cannot access this page</div>
      )}
    </React.Fragment>
  );
}
