import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import {
  getProfile,
  profileList,
} from "../LandingPage/Profile/profileSlice";
import Avatar from "react-avatar";
import { getFilter } from "../LandingPage/AddUser/filterSlice";
import { MapFilter } from "./mapFilter";
import "mapbox-gl/dist/mapbox-gl.css";
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from "!mapbox-gl";
import { MapBox } from "./mapBox";
import {
  clearFilterOptionsResponse,
  filterOptions,
  filterOptionsList,
  filterOptionsLoader,
  setTimezoneDataSet,
  timezoneDataSet,
} from "./filterOptionsSlice";
import { clearMarkers, markers, markersData, markersLoader } from "./markersSlice";
import { filterItems, logout, mapFilters, trackerClass, tzFilters } from "../../app/utils/Constants";
import FacilityDefault from "../../assets/images/facility-default.png";
import FacilityToggle from "../../assets/images/facility-toggle.png";

import LabDefault from "../../assets/images/lab-default.png";
import LabToggle from "../../assets/images/lab-toggle.png";

import PhlebDefault from "../../assets/images/phleb-default.png";
import PhlebToggle from "../../assets/images/phleb-toggle.png";

import HospitalDefault from "../../assets/images/hospital-default.png";
import HospitalToggle from "../../assets/images/hospital-toggle.png";
import TimezoneIcon from "../../assets/images/timezone-icon.png";
import { CommonHeaderRightSection } from "../../components/commons/CommonHeaderRightSection";
import * as turf from '@turf/turf';

mapboxgl.accessToken = "pk.eyJ1IjoibWFkaGFucmFqIiwiYSI6ImNrdncwNnE5ODNrZTkycWtsaWZmNG00cG0ifQ.nd-4mXLZFx02rKavSTsKsw";

const LAT = 40.6934;
const LNG = -98.5115;
const ZOOM = 3.94;

export const Tracker = () => {
  const dispatch = useDispatch();
  const profile = useSelector(profileList);
  const filterOptionsResult = useSelector(filterOptionsList);
  const markersResult = useSelector(markersData);
  const markerLoader = useSelector(markersLoader);
  const filterOptionsLoading = useSelector(filterOptionsLoader);
  const timezoneDataSetValue = useSelector(timezoneDataSet);
  const [name, setName] = React.useState("");
  const [color, setColor] = React.useState("");
  let [searchText, setSearchText] = React.useState("");
  const [selectedFilter, setSelectedFilter] = React.useState("");
  const [searchResults, setSearchResults] = React.useState([]);
  const [selectedOption, setSelectedOption] = React.useState("");
  const [dropdown, toggleDropdown] = React.useState(false);
  const [selectedLocation, setSelectedLocation] = React.useState({});
  const [currentOption, setCurrentOption] = React.useState({});
  const [initialCurrentOption, setInitialCurrentOption] = React.useState({});
  const mapContainer = React.useRef(null);
  const map = React.useRef(null);
  const [lng, setLng] = React.useState(LNG);
  const [lat, setLat] = React.useState(LAT);
  const [zoom, setZoom] = React.useState(ZOOM);
  const [mapFilterType, setMapFilterType] = React.useState(mapFilters);
  const [tzFilter, setTzFilter] = React.useState(tzFilters.map(item => item.value));
  const [loading, setLoading] = React.useState(false);
  const [isRefresh, setIsRefresh] = React.useState(false);
  React.useEffect(() => {
    document.documentElement.classList.add(trackerClass);
    loadTzDataset();
    dispatch(getProfile());
    dispatch(getFilter());
    dispatch(markers());
  }, []);

  React.useEffect(() => {
    if (profile && profile.status === "SUCCESS") {
      if (profile && profile.content) {
        localStorage.setItem(
          "userDetail",
          JSON.stringify(profile && profile.content)
        );
        setName(profile && profile.content && profile.content.name);
        setColor(profile && profile.content && profile.content.icon_color_code);
      }
    }
  }, [profile]);
  React.useEffect(() => {
    if (filterOptionsResult) {
      const { status, message_code, content = [] } = filterOptionsResult || {};
      if (status === "SUCCESS") {
        setSearchResults(content || []);
      }
    }
  }, [filterOptionsResult]);

  React.useEffect(() => {
    if (markersResult) {
      const { status, content = {} } = markersResult || {};
      if (status === "SUCCESS") {
        constructJeoJson(content);
      }
    }
  }, [markersResult]);

  React.useEffect(() => {
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/streets-v11?optimize=true",
      center: [lng, lat],
      zoom: zoom,
    });
    map.current.addControl(
      new mapboxgl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true,
        },
        // When active the map will receive updates to the device's location as it changes.
        trackUserLocation: true,
        // Draw an arrow next to the location dot to indicate which direction the device is heading.
        showUserHeading: true,
      })
    );

    // add navigation control
    map.current.addControl(new mapboxgl.NavigationControl(), "top-right");

    map.current.on("load", () => {
      map.current.loadImage(
        TimezoneIcon,
        (error, image) => {
          if (error) throw error;
          map.current.addImage('timer-icon', image);
        });
      map.current.loadImage(
        FacilityDefault,
        (error, image) => {
          if (error) throw error;
          map.current.addImage('facility-default', image);
        });
      map.current.loadImage(
        FacilityToggle,
        (error, image) => {
          if (error) throw error;
          map.current.addImage('facility-toggle', image);
        });

      map.current.loadImage(
        HospitalDefault,
        (error, image) => {
          if (error) throw error;
          map.current.addImage('hospital-default', image);
        });
      map.current.loadImage(
        HospitalToggle,
        (error, image) => {
          if (error) throw error;
          map.current.addImage('hospital-toggle', image);
        });

      map.current.loadImage(
        LabDefault,
        (error, image) => {
          if (error) throw error;
          map.current.addImage('lab-default', image);
        });
      map.current.loadImage(
        LabToggle,
        (error, image) => {
          if (error) throw error;
          map.current.addImage('lab-toggle', image);
        });

      map.current.loadImage(
        PhlebDefault,
        (error, image) => {
          if (error) throw error;
          map.current.addImage('phleb-default', image);
        });
      map.current.loadImage(
        PhlebToggle,
        (error, image) => {
          if (error) throw error;
          map.current.addImage('phleb-toggle', image);
        });

        if (timezoneDataSetValue && timezoneDataSetValue.features) {
          addTzLayer(timezoneDataSetValue);
        }

      map.current.addSource('facility', {
        'type': 'geojson',
        'data': {
          'type': 'FeatureCollection',
          'features': []
        }
      });
      map.current.addSource('hospital', {
        'type': 'geojson',
        'data': {
          'type': 'FeatureCollection',
          'features': []
        }
      });
      map.current.addSource('lab', {
        'type': 'geojson',
        'data': {
          'type': 'FeatureCollection',
          'features': []
        }
      });
      map.current.addSource('phleb', {
        'type': 'geojson',
        'data': {
          'type': 'FeatureCollection',
          'features': []
        }
      });

      map.current.addLayer({
        'id': 'facility',
        'type': 'symbol',
        'source': "facility",
        'layout': {
          'icon-image': ['get', 'icon'],
          'icon-ignore-placement': true,
        }
      });
      map.current.addLayer({
        'id': 'hospital',
        'type': 'symbol',
        'source': "hospital",
        'layout': {
          'icon-image': ['get', 'icon'],
          'icon-ignore-placement': true,
        }
      });
      map.current.addLayer({
        'id': 'phleb',
        'type': 'symbol',
        'source': "phleb",
        'layout': {
          'icon-image': ['get', 'icon'],
          'icon-ignore-placement': true,
        }
      });
      map.current.addLayer({
        'id': 'lab',
        'type': 'symbol',
        'source': "lab",
        'layout': {
          'icon-image': ['get', 'icon'],
          'icon-ignore-placement': true,
        }
      });
    });

    //facility
    map.current.on('click', 'facility', (e) => {
      // Copy coordinates array.
      const coordinates = e.features[0].geometry.coordinates.slice();
      const properties = e.features[0].properties || {};

      // Ensure that if the map is zoomed out such that multiple
      // copies of the feature are visible, the popup appears
      // over the copy being pointed to.
      while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
      }
      setCurrentOption(prevState => {
        let { hospital = {}, lab = {}, phleb = {}, facility = {} } = prevState || {};

        let { features: labsFeatures = [] } = lab;
        let { features: phlebFeatures = [] } = phleb;
        let { features: hosFeatures = [] } = hospital;

        labsFeatures = clearMarkerActiveStates(labsFeatures, "lab");

        hosFeatures = clearMarkerActiveStates(hosFeatures, "hospital");

        phlebFeatures = clearMarkerActiveStates(phlebFeatures, "phleb");

        let points = map.current.queryRenderedFeatures(e.point, {
          layers: ['facility']
        })
        if (points.length) {
          let { features = [] } = facility;
          features = features.map((item, index) => {
            const { properties: itemProp = {} } = item
            if (itemProp.id === points[0].properties.id) {
              return { ...item, properties: { ...item.properties, icon: 'facility-toggle' } }
            } else {
              return { ...item, properties: { ...item.properties, icon: 'facility-default' } }
            }
          });
          facility.features = features;

          phleb.features = phlebFeatures;
          lab.features = labsFeatures;
          hospital.features = hosFeatures;

          map.current.getSource('facility').setData(facility)
          map.current.getSource('phleb').setData(phleb)
          map.current.getSource('lab').setData(lab)
          map.current.getSource('hospital').setData(hospital)

          prevState = { ...prevState, facility, phleb, lab, hospital }
        }
        return prevState;
      })
      setSelectedLocation(properties);
    });
    map.current.on('mouseenter', 'facility', () => {
      map.current.getCanvas().style.cursor = 'pointer';
    });

    map.current.on('mouseleave', 'facility', () => {
      map.current.getCanvas().style.cursor = '';
    });

    //hospital
    map.current.on('click', 'hospital', (e) => {
      // Copy coordinates array.
      const coordinates = e.features[0].geometry.coordinates.slice();
      const properties = e.features[0].properties || {};

      // Ensure that if the map is zoomed out such that multiple
      // copies of the feature are visible, the popup appears
      // over the copy being pointed to.
      while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
      }
      setCurrentOption(prevState => {
        let { hospital = {}, lab = {}, phleb = {}, facility = {} } = prevState || {};

        let { features: labsFeatures = [] } = lab;
        let { features: phlebFeatures = [] } = phleb;
        let { features: facilityFeatures = [] } = facility;

        labsFeatures = clearMarkerActiveStates(labsFeatures, "lab");

        facilityFeatures = clearMarkerActiveStates(facilityFeatures, "facility")

        phlebFeatures = clearMarkerActiveStates(phlebFeatures, "phleb");

        let points = map.current.queryRenderedFeatures(e.point, {
          layers: ['hospital']
        })
        if (points.length) {
          let { features = [] } = hospital;
          features = features.map((item, index) => {
            const { properties: itemProp = {} } = item
            if (itemProp.id === points[0].properties.id) {
              return { ...item, properties: { ...item.properties, icon: 'hospital-toggle' } }
            } else {
              return { ...item, properties: { ...item.properties, icon: 'hospital-default' } }
            }
          });
          hospital.features = features;

          phleb.features = phlebFeatures;
          lab.features = labsFeatures;
          facility.features = facilityFeatures;

          map.current.getSource('facility').setData(facility)
          map.current.getSource('phleb').setData(phleb)
          map.current.getSource('lab').setData(lab)
          map.current.getSource('hospital').setData(hospital)

          prevState = { ...prevState, facility, phleb, lab, hospital }
        }
        return prevState;
      })
      setSelectedLocation(properties);
    });
    map.current.on('mouseenter', 'hospital', () => {
      map.current.getCanvas().style.cursor = 'pointer';
    });

    map.current.on('mouseleave', 'hospital', () => {
      map.current.getCanvas().style.cursor = '';
    });

    //lab
    map.current.on('click', 'lab', (e) => {
      // Copy coordinates array.
      const coordinates = e.features[0].geometry.coordinates.slice();
      const properties = e.features[0].properties || {};

      // Ensure that if the map is zoomed out such that multiple
      // copies of the feature are visible, the popup appears
      // over the copy being pointed to.
      while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
      }
      setCurrentOption(prevState => {
        let { lab = {}, phleb = {}, hospital = {}, facility = {} } = prevState || {};

        let { features: hosFeatures = [] } = hospital;
        let { features: phlebFeatures = [] } = phleb;
        let { features: facilityFeatures = [] } = facility;


        hosFeatures = clearMarkerActiveStates(hosFeatures, "hospital");

        facilityFeatures = clearMarkerActiveStates(facilityFeatures, "facility")

        phlebFeatures = clearMarkerActiveStates(phlebFeatures, "phleb");

        let points = map.current.queryRenderedFeatures(e.point, {
          layers: ['lab']
        })
        if (points.length) {
          let { features = [] } = lab;
          features = features.map((item, index) => {
            const { properties: itemProp = {} } = item
            if (itemProp.id === points[0].properties.id) {
              return { ...item, properties: { ...item.properties, icon: 'lab-toggle' } }
            } else {
              return { ...item, properties: { ...item.properties, icon: 'lab-default' } }
            }
          });
          lab.features = features;

          phleb.features = phlebFeatures;
          facility.features = facilityFeatures;
          hospital.features = hosFeatures;

          map.current.getSource('facility').setData(facility)
          map.current.getSource('phleb').setData(phleb)
          map.current.getSource('lab').setData(lab)
          map.current.getSource('hospital').setData(hospital)

          prevState = { ...prevState, facility, phleb, lab, hospital }
        }
        return prevState;
      })
      setSelectedLocation(properties);
    });
    map.current.on('mouseenter', 'lab', () => {
      map.current.getCanvas().style.cursor = 'pointer';
    });

    map.current.on('mouseleave', 'lab', () => {
      map.current.getCanvas().style.cursor = '';
    });

    //phleb
    map.current.on('click', 'phleb', (e) => {
      // Copy coordinates array.
      const coordinates = e.features[0].geometry.coordinates.slice();
      const properties = e.features[0].properties || {};

      // Ensure that if the map is zoomed out such that multiple
      // copies of the feature are visible, the popup appears
      // over the copy being pointed to.
      while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
      }
      setCurrentOption(prevState => {

        let { lab = {}, phleb = {}, hospital = {}, facility = {} } = prevState || {};

        let { features: hosFeatures = [] } = hospital;
        let { features: labsFeatures = [] } = lab;
        let { features: facilityFeatures = [] } = facility;

        hosFeatures = clearMarkerActiveStates(hosFeatures, "hospital");

        labsFeatures = clearMarkerActiveStates(labsFeatures, "lab");

        facilityFeatures = clearMarkerActiveStates(facilityFeatures, "facility");

        let points = map.current.queryRenderedFeatures(e.point, {
          layers: ['phleb']
        })
        if (points.length) {
          let { features = [] } = phleb;
          features = features.map((item, index) => {
            const { properties: itemProp = {} } = item
            if (itemProp.id === points[0].properties.id) {
              return { ...item, properties: { ...item.properties, icon: 'phleb-toggle' } }
            } else {
              return { ...item, properties: { ...item.properties, icon: 'phleb-default' } }
            }
          });
          phleb.features = features;

          facility.features = facilityFeatures;
          lab.features = labsFeatures;
          hospital.features = hosFeatures;

          map.current.getSource('facility').setData(facility)
          map.current.getSource('phleb').setData(phleb)
          map.current.getSource('lab').setData(lab)
          map.current.getSource('hospital').setData(hospital)

          prevState = { ...prevState, facility, phleb, lab, hospital }
        }
        return prevState;
      })
      setSelectedLocation(properties);
    });
    map.current.on('mouseenter', 'phleb', () => {
      map.current.getCanvas().style.cursor = 'pointer';
    });

    map.current.on('mouseleave', 'phleb', () => {
      map.current.getCanvas().style.cursor = '';
    });
  }, [lat, lng]);
  React.useEffect(() => {
    if (!map.current) return; // wait for map to initialize
    // Render custom marker components

    map.current.on("move", () => {
      setLng(map.current.getCenter().lng.toFixed(4));
      setLat(map.current.getCenter().lat.toFixed(4));
      setZoom(map.current.getZoom().toFixed(2));
    });
    // map.current.on("zoomend", () => {
    //   shouldCallApi && setShouldCallApi(false);
    // });
  });
  React.useEffect(
    () => () => {
      //clear redux state
      dispatch(clearFilterOptionsResponse());
      dispatch(clearMarkers());
      document.documentElement.classList.remove(trackerClass);
    },
    []
  );

  const loadTzDataset = async () => {
    if (!timezoneDataSetValue || !timezoneDataSetValue.features) {
      setLoading(true);
      try {
        const response = await fetch('/timezone_boundary_dataset.min.json');
        if (!response.ok) {
          console.log("something went wrong");
          setLoading(false);
          return;
        }
        const jsonData = await response.json();
        dispatch(setTimezoneDataSet(jsonData));
        addTzLayer(jsonData);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    }
  };

  const addTzLayer = (timezoneDataSetValue) => {
    if (!map.current) return;
    timezoneDataSetValue && timezoneDataSetValue.features && timezoneDataSetValue.features.forEach(tzData => {
      map.current.addSource(`timezone-source-${tzData.id}`, {
        'type': 'geojson',
        'data': tzData
      });

      // Add fill layer
      map.current.addLayer(tzData.properties.color_code ? {
        'id': `timezone-layer-${tzData.id}`,
        'type': 'fill',
        'source': `timezone-source-${tzData.id}`,
        'paint': {
          'fill-color': tzData.properties.color_code,
          'fill-opacity': 0.3
        }
      } : {
        'id': `timezone-layer-${tzData.id}`,
        'type': 'fill',
        'source': `timezone-source-${tzData.id}`,
        'paint': {
          'fill-color': 'rgba(0, 0, 0, 0)',
          'fill-opacity': 0
        }
      });
      // Add a black outline around the polygon.
      map.current.addLayer({
        'id': `timezone-outline-${tzData.id}`,
        'type': 'line',
        'source': `timezone-source-${tzData.id}`,
        'layout': {},
        'paint': {
          'line-color': '#000',
          'line-width': 1.5
        }
      });

      const center = turf.center(tzData.geometry);
      // Create a GeoJSON feature for the label
      const labelFeature = {
        "type": "Feature",
        "geometry": {
          "type": "Point",
          "coordinates": center.geometry.coordinates
        },
        "properties": {
          "label": tzData.properties.zone,
          "icon": "timer-icon"
        }
      };
      console.log(labelFeature, 'labelFeature');
      // Add a GeoJSON source for the label
      map.current.addSource(`timezone-label-source-${tzData.id}`, {
        'type': 'geojson',
        'data': labelFeature
      });

      // Add a symbol layer for the label
      map.current.addLayer({
        'id': `timezone-label-layer-${tzData.id}`,
        'type': 'symbol',
        'source': `timezone-label-source-${tzData.id}`,
        'layout': {
          'icon-image': 'timer-icon',
          'icon-size': 0.2,
          'icon-anchor': 'bottom',
          'text-field': ['get', 'label'],
          'text-size': 18,
          'text-anchor': 'top',
          'text-offset': [0, 0.2],
          'icon-allow-overlap': true,
          'text-allow-overlap': true
        },
        'paint': {
          'text-color': '#000',
          'text-halo-color': '#fff',
          'text-halo-width': 1
        }
      });
    });
  }
  const constructJeoJson = (content = {}) => {
    const { coordinates = {} } = content;
    const { features = {} } = coordinates;
    const {
      labs = [],
      hospitals = [],
      phlebs = [],
      facilities = [],
    } = features || {};

    let labsFeatures = [];
    let hosFeatures = [];
    let phlebFeatures = [];
    let facFeatures = [];

    labs.forEach(async (lab, index) => {
      const { coordinate = {}, id: labId } = lab;
      const { latitude: lat = null, longitude: lng = null } = coordinate;
      if (!_.isNull(lat) && !_.isNull(lng)) {
        labsFeatures.push({
          'type': 'Feature',
          'geometry': {
            'type': 'Point',
            'coordinates': [lng, lat]
          },
          'properties': { ...lab, type: "lab", icon: `lab-default` }
        })
      }
    });

    facilities.forEach(async (fac) => {
      const { coordinate = {}, id: facId } = fac;
      const { latitude: lat = null, longitude: lng = null } = coordinate;
      if (!_.isNull(lat) && !_.isNull(lng)) {
        facFeatures.push({
          'type': 'Feature',
          'geometry': {
            'type': 'Point',
            'coordinates': [lng, lat]
          },
          'properties': { ...fac, type: "facility", icon: `facility-default` }
        })
      }
    });
    hospitals.forEach(async (hp) => {
      const { coordinate = {}, id: hospitalId } = hp;
      const { latitude: lat = null, longitude: lng = null } = coordinate;
      if (!_.isNull(lat) && !_.isNull(lng)) {
        hosFeatures.push({
          'type': 'Feature',
          'geometry': {
            'type': 'Point',
            'coordinates': [lng, lat]
          },
          'properties': { ...hp, type: "hospital", icon: `hospital-default` }
        })
      }
    });

    phlebs.forEach(async (ph) => {
      const { coordinate = {}, id: phlebId } = ph;
      const { latitude: lat = null, longitude: lng = null } = coordinate;
      if (!_.isNull(lat) && !_.isNull(lng)) {
        phlebFeatures.push({
          'type': 'Feature',
          'geometry': {
            'type': 'Point',
            'coordinates': [lng, lat]
          },
          'properties': { ...ph, type: "phleb", icon: `phleb-default` }
        })
      }
    });

    const finalData = {
      lab: {
        "type": "FeatureCollection",
        features: [...labsFeatures]
      },
      facility: {
        "type": "FeatureCollection",
        features: [...facFeatures]
      },
      hospital: {
        "type": "FeatureCollection",
        features: [...hosFeatures]
      },
      phleb: {
        "type": "FeatureCollection",
        features: [...phlebFeatures]
      }
    };

    setCurrentOption(finalData);
    setInitialCurrentOption(finalData);
    if (isRefresh && !_.isEmpty(selectedOption)) {
      setIsRefresh(false);
      handleChangeMapFilterType("all");
      setLatLngs(finalData, selectedOption, true);
    }
  }
  const closeMarker = () => {
    setSelectedLocation({});
    if (map.current) {
      const { facility = {}, lab = {}, hospital = {}, phleb = {} } = initialCurrentOption;
      setCurrentOption(initialCurrentOption);
      map.current.getSource('facility').setData(facility)
      map.current.getSource('lab').setData(lab)
      map.current.getSource('hospital').setData(hospital)
      map.current.getSource('phleb').setData(phleb)
    }
  };

  const clearMarkerActiveStates = (data = [], type) => {
    return data.map(item => {
      return {
        ...item,
        properties: {
          ...item.properties,
          icon: `${type}-default`
        }
      }
    });
  }

  const selectFilterType = (value) => {
    dispatch(filterOptions(value));
    setSearchText("");
    setSearchResults([]);
    setSelectedFilter(value);
    toggleDropdown(false);
  };

  const selectOption = (value) => {
    setLatLngs(currentOption, value);
    setSelectedOption(value);
  };

  const setLatLngs = _.memoize(async (content = {}, selectedOption = {}, refresh = false) => {
    if (!map.current) return;
    setLoading(true);
    const { lab = {}, facility = {}, hospital = {}, phleb = {} } = content;
    let { features: labs = [] } = lab;
    let { features: hospitals = [] } = hospital;
    let { features: facilities = [] } = facility;
    let { features: phlebs = [] } = phleb;

    const { coordinate = {}, id: existingId, name } = selectedOption || {};
    const { latitude = null, longitude = null } = coordinate;

    if (refresh) {
      labs = clearMarkerActiveStates(labs, 'lab');
      phlebs = clearMarkerActiveStates(phlebs, 'phleb');
      facilities = clearMarkerActiveStates(facilities, 'facility');
      hospitals = clearMarkerActiveStates(hospitals, 'hospital');
    }

    if (
      longitude &&
      latitude &&
      selectedFilter !== "territory" &&
      selectedFilter !== "zone" &&
      mapFilterType.includes(selectedFilter === "phleb" ? "phlebotomist" : selectedFilter)
    ) {

      let centerContent = content[selectedFilter] || {};
      let { features: contFeatures = [] } = centerContent;
      if (centerContent) {
        if (selectedFilter === "hospital") {
          if (hospitals.some(fea => fea.properties.id == existingId)) {
            hospitals = hospitals.map(fea => {
              if (fea.properties.id == existingId) {
                return {
                  ...fea, properties: {
                    ...fea.properties, icon: `${selectedFilter}-toggle`
                  }
                }
              } else {
                return {
                  ...fea
                }
              }
            });
          }
        } else if (selectedFilter === "phleb") {
          if (phlebs.some(fea => fea.properties.id == existingId)) {
            phlebs = phlebs.map(fea => {
              if (fea.properties.id == existingId) {
                return {
                  ...fea, properties: {
                    ...fea.properties, icon: `${selectedFilter}-toggle`
                  }
                }
              } else {
                return {
                  ...fea
                }
              }
            });
          }
        } else if (selectedFilter === "lab") {
          if (labs.some(fea => fea.properties.id == existingId)) {
            labs = labs.map(fea => {
              if (fea.properties.id == existingId) {
                return {
                  ...fea, properties: {
                    ...fea.properties, icon: `${selectedFilter}-toggle`
                  }
                }
              } else {
                return {
                  ...fea
                }
              }
            });
          }
        } else if (selectedFilter === "facility") {
          if (facilities.some(fea => fea.properties.id == existingId)) {
            facilities = facilities.map(fea => {
              if (fea.properties.id == existingId) {
                return {
                  ...fea, properties: {
                    ...fea.properties, icon: `${selectedFilter}-toggle`
                  }
                }
              } else {
                return {
                  ...fea
                }
              }
            });
          }
        }
      }
      setSelectedLocation({ ...selectedOption, type: selectedFilter });
    }

    if (map.current.getSource("lab")) {
      map.current.getSource("lab").setData({
        type: "FeatureCollection",
        features: labs
      });
    } else {
      map.current.addSource('lab', {
        'type': 'geojson',
        'data': {
          'type': 'FeatureCollection',
          'features': labs
        }
      });
    }
    if (map.current.getSource("facility")) {
      map.current.getSource("facility").setData({
        type: "FeatureCollection",
        features: facilities
      });
    } else {
      map.current.addSource('facility', {
        'type': 'geojson',
        'data': {
          'type': 'FeatureCollection',
          'features': facilities
        }
      });
    }
    if (map.current.getSource("hospital")) {
      map.current.getSource("hospital").setData({
        type: "FeatureCollection",
        features: hospitals
      });
    } else {
      map.current.addSource('hospital', {
        'type': 'geojson',
        'data': {
          'type': 'FeatureCollection',
          'features': hospitals
        }
      });
    }
    if (map.current.getSource("phleb")) {
      map.current.getSource("phleb").setData({
        type: "FeatureCollection",
        features: phlebs
      });
    } else {
      map.current.addSource('phleb', {
        'type': 'geojson',
        'data': {
          'type': 'FeatureCollection',
          'features': phlebs
        }
      });
    }

    longitude &&
      latitude &&
      map.current.flyTo({
        center: [longitude, latitude],
        zoom: [10.15],
        essential: true,
      });
    setLoading(false);
  });

  const refreshMap = () => {
    if (!_.isEmpty(selectedFilter)) {
      dispatch(filterOptions(selectedFilter));
    }
    if (!_.isEmpty(selectedOption)) {
      setMapFilterType(['facility', 'lab', 'hospital', 'phlebotomist']);
      setIsRefresh(true);
      dispatch(
        markers()
      );
    }
  };

  const handleChangeMapFilterType = (value = "all") => {
    setLoading(true);
    let tempMapFilterType = [...mapFilterType];
    const tempValue = value === "phlebotomist" ? "phleb" : value;
    if (tempValue === "all") {
      map.current.setLayoutProperty(
        "facility",
        'visibility',
        'visible'
      );
      map.current.setLayoutProperty(
        "hospital",
        'visibility',
        'visible'
      );
      map.current.setLayoutProperty(
        "phleb",
        'visibility',
        'visible'
      );
      map.current.setLayoutProperty(
        "lab",
        'visibility',
        'visible'
      );
      setMapFilterType(tempMapFilterType);
    } else {
      if (mapFilterType.includes(value)) {
        map.current.setLayoutProperty(
          tempValue,
          'visibility',
          'none'
        );
        tempMapFilterType = tempMapFilterType.filter((type) => type !== value)
      } else {
        map.current.setLayoutProperty(
          tempValue,
          'visibility',
          'visible'
        );
        tempMapFilterType = [value, ...mapFilterType];
      }
      setMapFilterType(tempMapFilterType);
    }
    setLoading(false);
  };

  const toggleTimezoneMode = (value, id) => {
    let tempTzFilter = [...tzFilter];

    if (value === "All") {
      const isAllSelected = tzFilters.every(item => tzFilter.includes(item.value));
      if (isAllSelected) {
        tempTzFilter = [];
      } else {
        tempTzFilter = tzFilters.map(item => item.value);
      }
      timezoneDataSetValue.features.forEach(tzData => {
        map.current.setLayoutProperty(
          `timezone-layer-${tzData.id}`,
          'visibility',
          isAllSelected ? 'none' : 'visible'
        );
        map.current.setLayoutProperty(
          `timezone-outline-${tzData.id}`,
          'visibility',
          isAllSelected ? 'none' : 'visible'
        );
        map.current.setLayoutProperty(
          `timezone-label-layer-${tzData.id}`,
          'visibility',
          isAllSelected ? 'none' : 'visible'
        );
      });
    } else {
      const isSelected = tzFilter.includes(value);
      if (isSelected) {
        tempTzFilter = tempTzFilter.filter((type) => type !== value)
      } else {
        tempTzFilter = [value, ...tzFilter];
      }
      map.current.setLayoutProperty(
        `timezone-layer-${id}`,
        'visibility',
        isSelected ? 'none' : 'visible'
      );
      map.current.setLayoutProperty(
        `timezone-outline-${id}`,
        'visibility',
        isSelected ? 'none' : 'visible'
      );
      map.current.setLayoutProperty(
        `timezone-label-layer-${id}`,
        'visibility',
        isSelected ? 'none' : 'visible'
      );
    }
    setTzFilter(tempTzFilter);
  }

  let formattedSearchResult = [];
  if (!_.isEmpty(searchText)) {
    searchText = searchText.replace(/[^\w\s]/gi, "");
    if (Array.isArray(searchResults)) {
      searchResults.forEach((data) => {
        let row = {};
        row = { ...data };
        if (
          !searchText ||
          (row.name &&
            row.name
              .toString()
              .toLowerCase()
              .search(searchText.toLowerCase()) !== -1)
        ) {
          formattedSearchResult.push(row);
        }
      });
    }
  }

  let formattedFilterValue =
    selectedFilter.charAt(0).toUpperCase() + selectedFilter.slice(1);
  formattedFilterValue =
    formattedFilterValue === "Phleb" ? "Phlebotomist" : formattedFilterValue;

  return (
    <React.Fragment>
      <div
        className={"ad-loading-wrapper " + (loading || markerLoader || filterOptionsLoading ? "d-block" : "d-none")}
      >
        <span
          className={"loader ad-loader " + (loading || markerLoader || filterOptionsLoading ? "d-block" : "d-none")}
        >
          <span className="visually-hidden">loader icon</span>
        </span>
      </div>
      <CommonHeaderRightSection
        logout={logout}
        color={color}
        name={name}
        title="Map View"
        className="d-flex"
      />
      <div className="tracker-wrapper">
        <div className="tracker-filter">
          <MapFilter
            searchText={searchText}
            selectedFilter={selectedFilter}
            setSearchText={setSearchText}
            setSelectedFilter={setSelectedFilter}
            selectFilterType={selectFilterType}
            searchResults={searchResults}
            selectOption={selectOption}
            selectedOption={selectedOption}
            toggleDropdown={toggleDropdown}
            dropdown={dropdown}
            filterItems={filterItems}
            formattedSearchResult={formattedSearchResult}
            formattedFilterValue={formattedFilterValue}
          />
        </div>
        <div className="tracker-map">
          <MapBox
            mapContainer={mapContainer}
            map={map}
            lng={lng}
            lat={lat}
            zoom={zoom}
            selectedLocation={selectedLocation}
            refreshMap={refreshMap}
            selectedFilter={selectedFilter}
            closeMarker={closeMarker}
            filterOptionsLoading={filterOptionsLoading}
            mapFilterType={mapFilterType}
            handleChangeMapFilterType={handleChangeMapFilterType}
            selectedOption={selectedOption}
            loader={loading || markerLoader || filterOptionsLoading}
            toggleTimezoneMode={toggleTimezoneMode}
            tzFilter={tzFilter}
          />
        </div>
      </div>
    </React.Fragment>
  );
};
