import { ProgressButtonComponent } from "@syncfusion/ej2-react-splitbuttons";
import React from "react";
import { Button } from "react-bootstrap";
import { CANCELLED_STATUS, DROPPED_OFF, DROPPED_OFF_STATUS } from "../../app/utils/Constants";
const StatOrderFooter = (props) => {

	const { mode, updateStatOrder, status, editOrder, saveChanges, originalStatus, originalStatusText, notify, handleSubmit, cancelModal, isFormChanged} = props;
	return (
		<React.Fragment>
			<Button
				data-testid="button-cancel"
				className="primary-btn primary-btn-outline outline"
				onClick={cancelModal}
			>
				Cancel
			</Button>
			{mode === "view" ? (
				updateStatOrder &&
				status != CANCELLED_STATUS && (
					<Button
						data-testid="stat-order-button-view"
						className="primary-btn primary-btn-outline outline"
						onClick={editOrder}
					>
						Edit Stat Order
					</Button>
				)
			) : (
				<ProgressButtonComponent
					data-testid="stat-order-button-submit"
					content={mode === "edit" ? "Save Changes" : "Create Order"}
					enableProgress={true}
					spinSettings={{ position: "Center" }}
					animationSettings={{ effect: "SlideLeft" }}
					cssClass="primary-btn primary-btn-outline ad-progress-btn"
					onClick={() => saveChanges()}
					disabled={mode === "edit" ? !isFormChanged : false}
				></ProgressButtonComponent>
			)}
			{mode === "view" && originalStatus == DROPPED_OFF_STATUS && originalStatusText === DROPPED_OFF && notify && (
				<ProgressButtonComponent
					data-testid="stat-order-button-submit-with-notify"
					content={"Save Changes"}
					enableProgress={true}
					spinSettings={{ position: "Center" }}
					animationSettings={{ effect: "SlideLeft" }}
					cssClass="primary-btn primary-btn-outline ad-progress-btn"
					onClick={() => handleSubmit()}
				></ProgressButtonComponent>
			)}
		</React.Fragment>
	);
}

export default StatOrderFooter;
