import { ChipDirective, ChipListComponent, ChipsDirective } from "@syncfusion/ej2-react-buttons";
import CustomGrid from "../../components/commons/CustomGrid";

export default function ZoneList(props) {
	const { data = [], loading = false } = props;
	return (
		<CustomGrid
			type="zone"
			data={data}
			loading={loading}
			editModal={props.editModal}
			columns={[
				{
					field: "zone_code_string",
					headerText: "Zone Code",
					width: "210",
					visible: true
				},
				{
					field: "zone_name_string",
					headerText: "Zone Name",
					width: "210",
					visible: true
				},
				{
					field: "zone_description_string",
					headerText: "Zone Description",
					width: "380",
					visible: true
				},
				{
					field: "territory_code_string",
					headerText: "Territory Code",
					width: "190",
					visible: true
				},
				{
					field: "territory_name_string",
					headerText: "Territory Name",
					width: "190",
					visible: true
				},
				{
					field: "timezone_string",
					headerText: "Timezone",
					width: "190",
					visible: true
				},
				{
					field: "latitude_string",
					headerText: "Latitude",
					width: "210",
					visible: false
				},
				{
					field: "longitude_string",
					headerText: "Longitude",
					width: "210",
					visible: false
				},
				{
					field: "status.status_name",
					headerText: "Zone Status",
					width: "195",
					template: (props) => {
					  const { status = {} } = props;
					  return status && status.status_name === "Active" ? (
						<div className="user-status-wrapper">
						  <ChipListComponent id="chip-default">
							<ChipsDirective>
							  <ChipDirective
								text={status.status_name}
								cssClass="e-user-active"
							  ></ChipDirective>
							</ChipsDirective>
						  </ChipListComponent>
						</div>
					  ) : (
						status &&
						status.status_name === "Inactive" ? (
						  <div className="user-status-wrapper">
							<ChipListComponent id="chip-default">
							  <ChipsDirective>
								<ChipDirective
								  text={status.status_name}
								  cssClass="e-user-deactivated"
								></ChipDirective>
							  </ChipsDirective>
							</ChipListComponent>
						  </div>
						) : status &&
						status.status_name === "Pending" && (
							<div className="user-status-wrapper">
							  <ChipListComponent id="chip-default">
								<ChipsDirective>
								  <ChipDirective
									text={status.status_name}
									cssClass="e-user-pending"
								  ></ChipDirective>
								</ChipsDirective>
							  </ChipListComponent>
							</div>
						  )
					  );
					},
				  },
			]}
		/>
	);
}
