/* eslint-disable no-console */
/* eslint-disable import/no-unresolved */
// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import React, { createContext, useContext, useState, useEffect, useRef } from "react";
import { Auth } from "@aws-amplify/auth";
import { useNotificationDispatch } from "amazon-chime-sdk-component-library-react";
import { appConfig } from "../Config";
import AWS from "aws-sdk";
import toast, { Toaster } from "react-hot-toast";
import ToastContent from "../../../components/layouts/ToastContent";


const AuthContext = createContext();
const AuthProvider = ({ children }) => {
  const notificationDispatch = useNotificationDispatch();
  // Member
  const [member, setMember] = useState({
    username: "",
    userId: "",
  });
  const [chatDateRange, setChatDateRange] = useState({
    startDate: "",
    endDate: "",
  });
  const [chatFilter, setChatFilter] = useState({
    keyword: "",
  });
  const dateRangeRef = useRef();
  // Auth state
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  // isAnonymous (anon does not have access to write to S3 for attachments) default to cognito flow
  const [isAnonymous, setAnonymous] = useState(false);
  // Using CognitoIdp (if true use Cognito IDP to search for users when adding members to a room,
  // else lookup using ListAppInstanceUsers API), default to Cognito flow
  const [useCognitoIdp, setUseCognitoIdp] = useState(true);
  const [pauseTimer, setPauseTimer] = useState(false);


  const userSignOut = async () => {
    try {
      await Auth.signOut();
      setIsAuthenticated(false);
    } catch (error) {
      console.log(`error signing out ${error}`);
    }
  };

  const userSignUp = async (username, password) => {
    try {
      await Auth.signUp({
        username,
        password,
        attributes: {
          // TODO: Utilize input field for email that way we can then have users self confirm after reg.
          email: "email@me.com",
          profile: "none",
        },
      });
      toast(
        (t) => (
          <ToastContent type="error" message="Your registration information has been set to your administrator. Contact them for additional instructions." close={() => toast.remove()} />
        ),
        {
          duration: 4000,
        }
      );
    } catch (error) {
      toast(
        (t) => (
          <ToastContent type="error" message="Registration failed." close={() => toast.remove()} />
        ),
        {
          duration: 4000,
        }
      );
    }
  };

  const updateUserAttributes = async (userId) => {
    try {
      const user = await Auth.currentAuthenticatedUser();

      await Auth.updateUserAttributes(user, {
        profile: userId,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const getAwsCredentialsFromCognito = async () => {
    const creds = await Auth.currentCredentials();
    const essentialCreds = await Auth.essentialCredentials(creds);
    AWS.config.region = appConfig.region;
    AWS.config.credentials = essentialCreds;
    return essentialCreds;
  };

  const setAuthenticatedUserFromCognito = async () => {
    try {
      setUseCognitoIdp(true);
      const curUser = await Auth.currentUserInfo();
      console.log(curUser, 'curUser');
      if (curUser) {
        const userData = { username: curUser.username, userId: curUser.attributes.profile };
        setMember(userData);
        // localStorage.setItem("memberInfo", JSON.stringify(userData))
        if (curUser.attributes?.profile === "none") {
          updateUserAttributes(curUser.attributes.profile);
          // Once we set attribute let's have user relogin to refresh SigninHookFn trigger.
          setIsAuthenticated(false);
          toast(
            (t) => (
              <ToastContent type="error" message="Your account is activated! Please sign in again to confirm." close={() => toast.remove()} />
            ),
            {
              duration: 4000,
            }
          );
        } else {
          setAnonymous(false);
          setIsAuthenticated(true);
        }
      }
    } catch (err) {
      console.log(`Failed to set authenticated user! ${err}`);
    }
  };

  const userSignIn = (username, password) => {
    Auth.signIn({ username, password })
      .then(setAuthenticatedUserFromCognito)
      .catch((err) => {
        toast(
          (t) => (
            <ToastContent type="error" message="Your username and/or password is invalid!" close={() => toast.remove()} />
          ),
          {
            duration: 4000,
          }
        );
      });
  };

  const setAuthenticatedUserFromCredentialExchangeService = (response) => {
    setUseCognitoIdp(false);
    setAnonymous(true);
    const userData = {
      username: response.ChimeDisplayName,
      userId: response.ChimeUserId,
    }
    setMember(userData);
    // localStorage.setItem("memberInfo", JSON.stringify(userData))
    const stsCredentials = response.ChimeCredentials;
    updateUserAttributes(response.ChimeUserId);
    AWS.config.region = appConfig.region;
    AWS.config.credentials = {
      accessKeyId: stsCredentials.AccessKeyId,
      secretAccessKey: stsCredentials.SecretAccessKey,
      sessionToken: stsCredentials.SessionToken,
    };

    setIsAuthenticated(true);
  };

  // Credential Exchange Service Code.  Set Access Token on Authorization header using Bearer type.
  const userExchangeTokenForAwsCreds = (accessToken) => {
    fetch(`${appConfig.apiGatewayInvokeUrl}creds`, {
      method: "POST",
      credentials: "include",
      headers: new Headers({
        Authorization: `Bearer ${btoa(accessToken)}`,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        setAuthenticatedUserFromCredentialExchangeService(data);
      })
      .catch((err) => {
        console.log(err);
        toast(
          (t) => (
            <ToastContent type="error" message="Your username and/or password is invalid!" close={() => toast.remove()} />
          ),
          {
            duration: 4000,
          }
        );
      });
  };

  const initialize = async () => {
    try {
      await getAwsCredentialsFromCognito();
      await Auth.currentAuthenticatedUser({ bypassCache: false });
      await setAuthenticatedUserFromCognito();
    } catch (err) {
      console.log(err, err.message, 'err9');
      setIsAuthenticated(false);
    }
  }

  useEffect(() => {
    initialize();
  }, [Auth]);

  // useEffect(() => {
  //   if (!member.userId) {
  //     let memberInfo = localStorage.getItem("memberInfo") || "";
  //     if (memberInfo) {
  //       memberInfo = JSON.parse(memberInfo) || {};
  //       memberInfo && memberInfo.userId && setMember(memberInfo);
  //     }
  //   }
  // }, [])

  const authFulfiller = {
    member,
    isAuthenticated,
    isAnonymous,
    useCognitoIdp,
    userSignOut,
    userSignUp,
    userSignIn,
    userExchangeTokenForAwsCreds: userExchangeTokenForAwsCreds,
    pauseTimer,
    setPauseTimer,
    setChatDateRange,
    chatDateRange,
    dateRangeRef,
    setChatFilter,
    chatFilter
  };

  return (
    <AuthContext.Provider value={authFulfiller}>
      {children}
      <Toaster containerClassName="toaster-container" toastOptions={{
        className: "toaster-wrapper"
      }}
      />
    </AuthContext.Provider>
  );
};

const useAuthContext = () => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error("useAuthContext must be used within AuthProvider");
  }

  return context;
};

export { AuthProvider, useAuthContext };
