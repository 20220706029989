
import moment from 'moment';
import * as React from 'react';
import Avatar from "react-avatar";
import { Accordion, Button, Form, FormGroup, InputGroup } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { ArrowContainer, Popover } from 'react-tiny-popover';
import { SCHEDULE_SERVICE_TYPE, TYPE_HOMEHEALTH, areEqualDates, convertToMMDDYYYY } from '../../app/utils/Constants';
import { CustomDropdown } from '../../components/commons/CustomDropdown';
import { MapBoxInputSearch } from '../../components/commons/mapBoxInputSearch';
import { disableSaveForStartRoute, menuIsOpenRouteValue, menuIsOpenTimezoneValue, routeAuditKeyVal, setDisableSaveForStartRoute, setMenuIsOpenRoute, setMenuIsOpenTimezoneRoute, setRouteAuditKey, setShowPopover, showPopoverVal } from './Operations/scheduleListSlice';

const constructTimeOptionsTwo = (isPastDate) => {
	let options = [];
	for (let i = 22; i < 24; i++) {
		options.push({
			label: `-${i < 10 ? "0" : ""}${i}:00`,
			value: `-${i < 10 ? "0" : ""}${i}:00`,
		});
		options.push({
			label: `-${i < 10 ? "0" : ""}${i}:15`,
			value: `-${i < 10 ? "0" : ""}${i}:15`,
		});
		options.push({
			label: `-${i < 10 ? "0" : ""}${i}:30`,
			value: `-${i < 10 ? "0" : ""}${i}:30`,
		});
		options.push({
			label: `-${i < 10 ? "0" : ""}${i}:45`,
			value: `-${i < 10 ? "0" : ""}${i}:45`,
		});
	}
	for (let i = 0; i < 22; i++) {
		options.push({
			label: `${i < 10 ? "0" : ""}${i}:00`,
			value: `${i < 10 ? "0" : ""}${i}:00`,
		});
		options.push({
			label: `${i < 10 ? "0" : ""}${i}:15`,
			value: `${i < 10 ? "0" : ""}${i}:15`,
		});
		options.push({
			label: `${i < 10 ? "0" : ""}${i}:30`,
			value: `${i < 10 ? "0" : ""}${i}:30`,
		});
		options.push({
			label: `${i < 10 ? "0" : ""}${i}:45`,
			value: `${i < 10 ? "0" : ""}${i}:45`,
		});
	}
	options = options.map((opt, index) => {
		return { ...opt, id: index };
	});
	return options;
};

function isAfter2200(timestring) {
	const [hours, minutes] = timestring.split(':').map(Number);
  
	const targetHours = 22;
	const targetMinutes = 0;
  
	if (hours === 0 && minutes === 0) {
		return false;
	}
  
	if (hours > targetHours || (hours === targetHours && minutes > targetMinutes)) {
		return true;
	} else {
		return false;
	}
  }

export const UserCell = (props) => {
		let permission =
		(window.localStorage.getItem("userDetail") &&
		  JSON.parse(window.localStorage.getItem("userDetail")).permission &&
		  JSON.parse(window.localStorage.getItem("userDetail")).permission) ||
		[];
		permission = permission.filter((perm) => perm.page === "events");
		const eventAccess = permission.length === 1 ? permission[0].access : [];
		const isExecutive = eventAccess && eventAccess.find((item) => item === "upsert-past-date") === "upsert-past-date" ? true : false;

	const { users = [], searchText = "", searchType, date, constructPyloadForCheckinCheckout, tabValue, setTempCourierAddressForEnRoute,
		tempCourierAddressForEnRoute, filterType, timezone_ids } = props;

	const dispatch = useDispatch();
	const menuIsOpen = useSelector(menuIsOpenRouteValue);
	const menuIsOpenTimezone = useSelector(menuIsOpenTimezoneValue);
	const auditKey = useSelector(routeAuditKeyVal);
	const showPopover = useSelector(showPopoverVal);
	const disableSaveButtonAfterApiCall = useSelector(disableSaveForStartRoute)

	const timeRef = React.useRef(null);
	const timezoneRef = React.useRef(null);

	const [reRender, setReRender] = React.useState(false);

	const [clearStartRoutineAddress, setClearStartRoutineAddress] = React.useState(false);
	const [newEnRoute, setNewEnRoute] = React.useState({});
	const { time = "", dropOffLat = "", dropOffLng = "", note = "", timezone = "" } = newEnRoute;
	const toggleModal = (e, userIndex, timezone) => {
			e.stopPropagation();
			let temp = {...newEnRoute};
			if (timezone && timezone.timezone_id) {
				temp.timezone = {
					...timezone,
					value: timezone.timezone_id,
					label: timezone.timezone_name
				}
			}
			setNewEnRoute(temp)
			dispatch(setShowPopover(`route-${userIndex}`));
	}

	const handleChangeEnRoute = (property, value) => {
		setNewEnRoute({ ...newEnRoute, [property]: value });
	}

	const addUpdateRoute = (userId, routeId, uniqueServiceId, userIndex, lat, lng, existingTime) => {
		let currentDate = new Date(date);
		currentDate.setDate(currentDate.getDate() - 1);

		let newTime = time.value || existingTime;
		let isPastDate = time.value ? time.label.includes("-") : existingTime.includes("-");
		let actionData = [];
		actionData.push({
			id: routeId,
			action_type_id: 0,
			action_datetime: `${`${moment(isPastDate ? currentDate : date).format("YYYY-MM-DD")} ${newTime.replace("-", "")}`}`,
			hospital_id: null,
			lab_id: null,
			is_courier_drop_off: false,
			facility_id: 0,
			latitude: dropOffLat || lat,
			longitude: dropOffLng || lng,
			order_id: 0,
			user_id: userId,
			event_id: 0,
			notes: note,
			timezone_id: timezone.value || ""
		});
		const formData = new FormData();
		formData.append("actions", JSON.stringify(actionData));
		dispatch(setDisableSaveForStartRoute(true));
		constructPyloadForCheckinCheckout(
			formData,
			userIndex, 0, uniqueServiceId
		);
	};

	const clearAll = () => {
		setClearStartRoutineAddress(true);
		setNewEnRoute({});
	}

	const closeModal = () => {
		setNewEnRoute({});
		dispatch(setShowPopover(""));
		dispatch(setMenuIsOpenRoute(false));
		dispatch(setRouteAuditKey(""));
		dispatch(setMenuIsOpenTimezoneRoute(false));
		setTempCourierAddressForEnRoute({});
	}

	const showByFilter = (services = [], userType) => {
		return (userType === SCHEDULE_SERVICE_TYPE.NO_COVERAGE.ID ? filterType.nocoverage : filterType.scheduled && filterType.unscheduled ?
			true : filterType.scheduled ?
				tabValue === "onCall" ?
					services.length > 0 : tabValue === "routine" ?
						services.some(ser => ser.service_name === "Routine Draws" || ser.service_name === "Drop Off") : services.some(ser => ser.service_name === "Home Health") : filterType.unscheduled ?
					tabValue === "onCall" ? services.length === 0 : tabValue === "routine" ?
						(!services.length || !services.some(ser => ser.service_name === "Routine Draws" || ser.service_name === "Drop Off")) : (!services.length || !services.some(ser => ser.service_name === "Home Health")) : false)
	}

	let showNoResultsZone = false;
	if (searchType === "zone" && searchText) {
		if (tabValue === "onCall") {
			let zoneCount = 0;
			let showbyFilterFlag = filterType.nocoverage ? false : (filterType.scheduled && filterType.unscheduled ?
				false : filterType.scheduled ? false : filterType.unscheduled ? true : false);
			users && users.forEach(user => {
				const { services = [] } = user;
				services && services.forEach(ser => {
					const { territories = {} } = ser || {};
					if (territories) {
						Object.keys(territories).forEach(terr => {
							const { events = {} } = territories[terr];
							if (events.oncall) {
								const { zones = [] } = events.oncall;
								if (zones && zones.some(z => z.zone_name.toLowerCase().search(searchText.toLowerCase()) !== -1)) {
									zoneCount++;
								}
							}
						})
					}
				});
			});
			showNoResultsZone = filterType.nocoverage ? false : zoneCount === 0 || showbyFilterFlag;
		} else if (tabValue === TYPE_HOMEHEALTH) {

		} else {
			let zoneCount = 0;
			let showbyFilterFlag = (filterType.scheduled && filterType.unscheduled ?
				false : filterType.scheduled ? false : filterType.unscheduled ? true : false);
			users && users.forEach(user => {
				const { services = [] } = user || {};
				let onlyRoutineServices = services ? services.filter(ser => ser.service_name === "Routine Draws") : [];
				onlyRoutineServices && onlyRoutineServices.forEach(ser => {
					const { zones = {} } = ser || {};
					if (zones && Object.keys(zones).some(z => zones[z].zone.toLowerCase().search(searchText.toLowerCase()) !== -1)) {
						zoneCount++;
					}
				});
			});
			showNoResultsZone = zoneCount === 0 || showbyFilterFlag;
		}
	}

	const showNoResults = searchType === "phlebotomist" ? (users.some(user => user.type_id === SCHEDULE_SERVICE_TYPE.NO_COVERAGE.ID) && filterType.nocoverage) ? false : !users.some(user => (user.type_id!== SCHEDULE_SERVICE_TYPE.NO_COVERAGE.ID && (!searchText || user.name.toLowerCase().search(searchText.toLowerCase()) !== -1)) && showByFilter(user.services || [], user.type_id)) : showNoResultsZone;


	const toastModal = document.getElementsByClassName("toast-wrapper") || [];
	let isModalOpen = false;
	if (toastModal && toastModal.length) {
		const displayText = window.getComputedStyle(toastModal[0]) && window.getComputedStyle(toastModal[0]).display;
		if (displayText === "block") {
			isModalOpen = true;
		} else if (displayText === "none") {
			isModalOpen = false
		}
	}
	const selectedDate = new Date(date);
	selectedDate.setHours(0, 0, 0, 0);

	var now = new Date();
	now.setHours(0, 0, 0, 0);

	const isPastDate = selectedDate < now;

	return (
		<React.Fragment>
			{users.map((user, userIndex) => {
				const { name = "", job_title, icon_color_code, services = [], reporting_manager_name, employee_id, type_id, phleb_type: userType } = user;

				const hasRouteStarted = services && services.some(ser => ser.service_name === "Start Route");

				let enRouteData = services ? services.filter(ser => ser.service_name === "Start Route") ?
					services.filter(ser => ser.service_name === "Start Route")[0] || {} : {} : {};

				const { uniqueServiceId, audit_history = [] } = enRouteData;

				let { id: routeId, time: startTime, latitude, longitude, date: routeDate, timezone: originalTimezone = {}, preferred_time, preferred_timezone = {} } = enRouteData.start_route ? enRouteData.start_route[0] || {} : {};

				const selectedDate = new Date(date);
				startTime = `${areEqualDates(routeDate, selectedDate) ? "" : "-"}${startTime}`;

				let updatedPreferredTime = preferred_time ? areEqualDates(routeDate, selectedDate) ? preferred_time : `-${preferred_time}` : "";
				

				let zoneCount = 0;
				if (searchType === "zone" && searchText) {
					if (tabValue === "onCall") {
						services && services.forEach(ser => {
							const { territories = {} } = ser || {};
							if (territories) {
								Object.keys(territories).forEach(terr => {
									const { events = {} } = territories[terr];
									if (events.oncall) {
										const { zones = [] } = events.oncall;
										if (zones && zones.some(z => z.zone_name.toLowerCase().search(searchText.toLowerCase()) !== -1)) {
											zoneCount++;
										}
									}
								})
							}
						})
					} else if (tabValue === TYPE_HOMEHEALTH) {

					} else {
						let onlyRoutineServices = services ? services.filter(ser => ser.service_name === "Routine Draws") : [];
						onlyRoutineServices && onlyRoutineServices.forEach(ser => {
							const { zones = {} } = ser || {};
							if (zones && Object.keys(zones).some(z => zones[z].zone.toLowerCase().search(searchText.toLowerCase()) !== -1)) {
								zoneCount++;
							}
						})
					}
				}

				let isSaveDisabled = !routeId ? (!dropOffLat || !dropOffLng || !time || !note || !timezone || !timezone.value) : !note || !timezone || !timezone.value;
				return ((user.type_id === SCHEDULE_SERVICE_TYPE.NO_COVERAGE.ID ? filterType.nocoverage : searchType === "phlebotomist" ? (!searchText
					|| name.toLowerCase().search(searchText.toLowerCase()) !== -1) : (!searchText || zoneCount > 0)) && (!isPastDate ? user.status != "INACTIVE" : true) &&
					(showByFilter(services, user.type_id)) &&
					// <div key={userIndex}>
					<Popover
						className=""
						key={userIndex}
						id="dropoff-popover"
						containerClassName="sch-popover-container"
						isOpen={showPopover === `route-${userIndex}`}
						reposition={true}
						onClickOutside={(e) => {
							e.preventDefault();
							setReRender(!reRender);
							if (!isModalOpen) {
								closeModal();
							}
						}}
						content={
							({ position, childRect, popoverRect }) =>
								<ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
									position={position}
									childRect={childRect}
									popoverRect={popoverRect}
									arrowColor={'white'}
									arrowSize={10}
									arrowStyle={{ opacity: 1 }}
									className='popover-arrow-container'
									arrowClassName='popover-arrow'
								>
									<div
										title={(routeId ? "Update" : "Start") + ' Route'}
										className="sch-popover sch-dropoff-popover"
										onClick={e => e.stopPropagation()}
									>
										<span className="service-icon2 popoverclose" onClick={() => document.body.click()}><span className="visually-hidden">Close Icon</span></span>
										<div className="scheduler-wrapper popover-wrapper en-route-popover sch-notes-wrapper">
											<div>
												<h3 className="title roboto-regular en-popover-title">{routeId ? "Update" : "Start"} Route</h3>
												<p className='en-popover-uname'>{name} {userType ? `(${userType})` : ""}</p>
											</div>
											<div className="schedule-accordian-wrapper accordion dropoff-schedule-wrapper enroute-wrapper">
												<div className="choose-time-wrapper flex-wrap dropoff-time-wrapper ">
													<div className="schedule-time">
														<FormGroup className="mb-3">
															<Form.Label htmlFor="lat">
																Starting Address<span className="d-block roboto-bold">
																	{routeId && latitude && longitude && `Lat: ${latitude} | Lng: ${longitude}`}
																</span></Form.Label>
															<MapBoxInputSearch tempCourierAddress={tempCourierAddressForEnRoute} setTempCourierAddress={setTempCourierAddressForEnRoute}
																id={`startRoute-${userIndex}`} handleChangeLatLng={(data) => setNewEnRoute({ ...newEnRoute, ...data })}
																newDropOff={newEnRoute} latitude={dropOffLat}
																longitude={dropOffLng}
																setClearStartRoutineAddress={setClearStartRoutineAddress}
																clearStartRoutineAddress={clearStartRoutineAddress}
																disabled={!isExecutive}
															/>
														</FormGroup>
														<div className='d-flex'>
															<FormGroup className="mb-3">
																<Form.Label htmlFor="dropoff-time">Start Time</Form.Label>
																<CustomDropdown
																	name=""
																	id="route-start-time"
																	className="sch-schedule-dropdown sch-schedule-select__control"
																	menuIsOpen={menuIsOpen || false}
																	setMenuIsOpen={(val) => dispatch(setMenuIsOpenRoute(val))}
																	wrapperRef={timeRef}
																	options={constructTimeOptionsTwo(isPastDate)}
																	onChange={e => handleChangeEnRoute("time", e)}
																	value={time}
																	placeholder="Choose"
																	isDisabled={!isExecutive}
																/>
																<span className="d-block roboto-bold form-timezone-text">{routeId && startTime && convertToMMDDYYYY(startTime, originalTimezone, "Actual", true, true)}</span>
																<span className="d-block roboto-bold form-timezone-text">{updatedPreferredTime && convertToMMDDYYYY(updatedPreferredTime, preferred_timezone, "Preferred", true, true)}</span>
															</FormGroup>
															<FormGroup className="mb-4 aha-timezone-select-wrapper">
																<Form.Label htmlFor="route-start-timezone">Timezone</Form.Label>
																<CustomDropdown
																	name=""
																	id="route-start-timezone"
																	className="sch-schedule-dropdown sch-schedule-select__control"
																	menuIsOpen={menuIsOpenTimezone || false}
																	setMenuIsOpen={(val) => dispatch(setMenuIsOpenTimezoneRoute(val))}
																	wrapperRef={timezoneRef}
																	options={timezone_ids}
																	onChange={e => handleChangeEnRoute("timezone", e)}
																	value={timezone}
																	placeholder="Tz"
																	isDisabled={!isExecutive}
																	showLabel={true}
																/>
																{preferred_time && 
																<>
																<span className="invisible d-inline-block" style={{width:'100%'}}>timezone</span>
																</>
																}
															</FormGroup>
														</div>
														<InputGroup className="mb-1">
															<Form.Label htmlFor="check-out-time">
																Notes
															</Form.Label>
															<div className="position-relative w-100 aha-modalform-group">
																<textarea
																	value={note}
																	onChange={(e) => handleChangeEnRoute("note", e.target.value)}
																	disabled={!isExecutive}
																/>
															</div>
														</InputGroup>
													</div>
												</div>
												<Accordion activeKey={auditKey} onSelect={(val) => {
													dispatch(setRouteAuditKey(val))
												}}>
													<Accordion.Item className='w-100' eventKey="0">
														<Accordion.Header className="audit-log-header">Audit Log
															<span className="al-arrow-down"></span> </Accordion.Header>
														<Accordion.Body>
															{/* For checkin */}
															<ul className="audit-list-wrapper justify-content-between flex-wrap">
																{audit_history.length > 0 ? audit_history.map((act, idx) => {
																	const { user = {}, activity, datetime = "", notes = "", type, abbreviation } = act;
																	return (
																		<li key={idx}>
																			<span className="al-activity roboto-bold">{moment(datetime).format("MM/DD/YYYY HH:mm:ss")} {abbreviation || ""}</span>
																			<span className="d-flex"> <span className="al-activity pe-1">{activity} {user ? ` By: @${user.username}` : ""}<i>{type ? ` (${type})` : ""}</i></span>
																				<span className="al-activity"></span> </span>
																			{notes && <span className="al-activity">Notes: {notes}</span>}
																		</li>
																	)
																})
																	: <p className="font-size-12 grey-color">No logs to show</p>
																}
															</ul>
														</Accordion.Body>
													</Accordion.Item>
												</Accordion>
											</div>
											<div className="d-flex checkin-btn-wrapper justify-content-end">
												<Button className="primary-btn primary-btn-outline outline" disabled={!isExecutive} onClick={() => clearAll()}>clear</Button>
												<Button className="primary-btn primary-btn-outline"
													disabled={(!isExecutive) || isSaveDisabled || disableSaveButtonAfterApiCall} onClick={() => addUpdateRoute(user.user_id, routeId || 0, uniqueServiceId, userIndex, latitude, longitude, startTime)}>Save</Button>
											</div>
										</div>
									</div>
								</ArrowContainer>
						}
					>
						{/* <div onClick={(e) => {
							e.stopPropagation();
							setShow({ [userIndex]: true });
						}}> */}
						<th className={type_id === SCHEDULE_SERVICE_TYPE.NO_COVERAGE.ID ? "on-call-header" : ""}>
							<div className="grid-head justify-content-between">
								{/* <span className="service-icon"></span> */}
								<div className='d-flex align-items-center sch-th-left-wrapper'>
									{type_id === SCHEDULE_SERVICE_TYPE.NO_COVERAGE.ID ? <span class="scheduled-already-icon pe-2"><span class="visually-hidden">No coverage</span></span> :  <Avatar
										color={icon_color_code ? `#${icon_color_code}` : "#333745"}
										name={name}
										maxInitials={2}
										size={40}
										round="50px"
										className='sch-avatar-wrapper'
										title={`Emp ID: ${employee_id}\n${reporting_manager_name ? reporting_manager_name.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase()) : ""}`}
									/>}
									
									{type_id === SCHEDULE_SERVICE_TYPE.NO_COVERAGE.ID ?
									<div className='no-coverage-wrapper'><h2 className="sch-user-name font-size-16">NO COVERAGE</h2></div>
									 : <div className='sch-user-wrapper' title={`Emp ID: ${employee_id}\n${reporting_manager_name ? reporting_manager_name.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase()) : ""}`}>
										<h2 className="sch-user-name font-size-16">{name} {userType ? `(${userType})` : ""}</h2>
										<p className="sch-user-position">{job_title}</p>
									</div>}
								</div>
								{tabValue === "routine" && <div onClick={(e) => toggleModal(e, userIndex, originalTimezone)} style={!hasRouteStarted ? isExecutive ? {} : { pointerEvents: "none", cursor: "not-allowed" } : {}}>
									<span className={`${hasRouteStarted ? `enroute-icon enroute-start` : `enroute-home ${isExecutive ? "enroute-start" : ""}`}`}>
										<span className="visually-hidden">
											En route Icon
										</span>
									</span>
								</div>}
							</div>
						</th>
						{/* </div> */}
					</Popover>
					// </div>
				)
			})}
			{showNoResults &&
				<div className="search-no-records no-records-board d-flex justify-content-center align-items-center">
					<p className="no-result-text">	No results found</p>
				</div>
			}
		</React.Fragment>
	);
}
