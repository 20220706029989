import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ahaApi from "../../../app/services/aha-api";
const initialState = {
  value: [],
  loading: false,
};

export const getManagerHH = createAsyncThunk(
  "schedule/getManagerHH",
  async (data, { rejectWithValue }) => {
    const response = await ahaApi.Account.homeHealthReportingManagers(data)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return rejectWithValue(err.response.data);
      });
    return response;
  }
);

export const getManagerSlice = createSlice({
  name: "getManagerHH",
  initialState,
  reducers: {
    updateReportingDataVal(state, action) {
      state.value = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getManagerHH.pending, (state) => {
        state.loading = true;
      })
      .addCase(getManagerHH.fulfilled, (state, action) => {
        state.loading = false;
        state.value = action.payload;
      })
      .addCase(getManagerHH.rejected, (state, err) => {
        state.loading = false;
        state.value = err.payload;
      });
  },
});

export const getManagerList = (state) => state.getManagerHH.value;
export const managerListLoading = (state) => state.getManagerHH.loading;
export const { updateReportingDataVal } = getManagerSlice.actions;

export default getManagerSlice.reducer;
