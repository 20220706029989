import CustomGrid from "../../components/commons/CustomGrid";

export default function TerritoryList(props) {
	const { data = [], loading = false } = props;
	return (
		<CustomGrid
			type="territory"
			data={data}
			loading={loading}
			editModal={props.editModal}
			columns={[
				{
					field: "territory_code_string",
					headerText: "Territory Code",
					width: "210",
					visible: true
				},
				{
					field: "territory_name_string",
					headerText: "Territory Name",
					width: "210",
					visible: true
				},
				{
					field: "territory_description_string",
					headerText: "Territory Description",
					width: "380",
					visible: true
				},
				{
					field: "region_code_string",
					headerText: "Region Code",
					width: "190",
					visible: true
				},
				{
					field: "latitude_string",
					headerText: "Latitude",
					width: "210",
					visible: false
				},
				{
					field: "longitude_string",
					headerText: "Longitude",
					width: "210",
					visible: false
				}
			]}
		/>
	);
}
