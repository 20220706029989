import { ProgressButtonComponent } from "@syncfusion/ej2-react-splitbuttons";
import { Modal } from "react-bootstrap";

export default function UpdateFacilitiesWarningModal(props = {}) {
	const { handleAssignZoneToFacilities, facilityZonesState = {}, setFacilityZonesState } = props;

	const { searchText = "", value = false, response = {}, selectedZone = {}, selectedFacilities = {} } = facilityZonesState;
	const { error_content = {} } = response;
	let { facilities = [], zones = [] } = error_content;

	const showNoResults = !zones.some(result => (!searchText || result.zoneName.toLowerCase().search(searchText.toLowerCase()) !== -1))

	return (
		<Modal
			show={value}
			onHide={() => setFacilityZonesState({ value: false })}
			className="ad-adduser-modal sc-copycal-modal ad-deactivation-confirmation"
			backdrop="static"
			enforceFocus={false}
			data-testid="modal-status"
			backdropClassName="network-error-backdrop"
		>
			<Modal.Header closeButton></Modal.Header>
			<Modal.Body className="text-center">
				<div className="sch-confirmation-wrapper">
					<span className="assignment-confirm"></span>
				</div>
				<p
					data-testid="status-confirmation"
					className="confirmation-title"
				>
					<p>Some facilities are still linked to this zone. Before you can set it to <span className='roboto-bold'>Inactive</span> please move these facilities to another zone.</p>
				</p>
				<p
					data-testid="status-subtitle"
					className="font-size-14 roboto-medium confirmation-subtitle mb-20 edit-inactive-user"
				>

					<div className="tracker-search scheduler-tabs-wrapper surge-non-service-search-div d-flex align-items-start w-100 ad-reporting-wrapper">
						<div className="flex-50">
							<div className="position-relative text-start  m-0">
								<label htmlFor="tracker-search" className="roboto-regular">
									Zone
								</label>
								<input
									id="tracker-search"
									type="text"
									placeholder="Search"
									className="form-control search-box w-100 min-w-auto"
									autoComplete="off"
									value={searchText || ""}
									onChange={e => {
										setFacilityZonesState({ ...facilityZonesState, searchText: e.target.value })
									}}
								/>
								<span className="search-icon"><span className="visually-hidden">Search Icon</span></span>
							</div>

							{searchText && !showNoResults &&
								<div className="tracker-wrapper">
									<div className="tracker-result sr-dropdown-container">
										 <ul id="facilities-list-wrapper" className=" w-100">
											{zones.map((result, index) => {
												return ((!searchText || result.zoneName.toLowerCase().search(searchText.toLowerCase()) !== -1) &&
													<li
														key={index}
														onClick={() => setFacilityZonesState({ ...facilityZonesState, selectedZone: result, searchText: "" })}
														className="me-0 text-start text-capitalize"
													>
														{result.zoneName}
													</li>
												);
											})}
										</ul>  
										
									</div>
								</div>}
								{showNoResults && <p className="d-flex no-manager-found"><span className="light-black-color font-size-11">
								No zone found</span>	</p> }

							{selectedZone && selectedZone.zoneName && <div className="mt-2">
								<p className="roboto-regular font-size-12 w-100 text-start">Assign to:</p>
								<div className="assigned-manager d-flex align-items-center">
									<span>{selectedZone.zoneName}</span>
								</div>
							</div>}

						</div>

						<div className="d-flex flex-column align-items-center text-start ms-4 sc-checkbox-wrapper w-100">
							<p className="roboto-regular font-size-12 w-100">{facilities ? facilities.length : ""} Facilities(s) remaining</p>
							<div className="ad-reporting-manager-wrapper">
								<ul className="ad-reporting-manager-list">
									{facilities.map(con => {
										return (
											<li>
												<div className="form-check text-start">
													<input
														className="form-check-input"
														type="checkbox"
														id={`scheduled-${con.id}`}
														checked={selectedFacilities[con.id] || false}
														value={selectedFacilities[con.id] || false}
														onChange={e => {
															setFacilityZonesState({
																...facilityZonesState,
																selectedFacilities: {
																	...selectedFacilities,
																	[con.id]: e.target.checked
																}
															})
														}}
													/>
													<label
														className="form-check-label pb-0 mt-1"
														htmlFor={`scheduled-${con.id}`}
													>
														{con.accountName}
													</label>
												</div>
											</li>
										)
									})}
								</ul>
							</div>

						</div>
					</div>
				</p>
				<div className="d-flex justify-content-center mb-2 mt-2">
					<ProgressButtonComponent
						data-testid="button-save"
						content={"Assign"}
						spinSettings={{ position: "Center" }}
						animationSettings={{ effect: "SlideLeft" }}
						cssClass="primary-btn primary-btn-outline ad-progress-btn"
						onClick={handleAssignZoneToFacilities}
						disabled={!selectedZone || !selectedZone.zoneId || !selectedFacilities || Object.keys(selectedFacilities).every(id => !selectedFacilities[id])}
					></ProgressButtonComponent>
				</div>
			</Modal.Body>
		</Modal>
	);
}
