/* eslint-disable react/no-children-prop */
/* eslint-disable no-console */
// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import React, { useState, useEffect } from "react";
import {
  InfiniteList,
  PopOverItem,
  Modal,
  ModalHeader,
  ModalBody,
  ModalButtonGroup,
  ModalButton,
  ChatBubble,
  ChatBubbleContainer,
  EditableChatBubble,
  formatDate,
  formatTime,
} from "amazon-chime-sdk-component-library-react";
import { AttachmentProcessor } from "./AttachmentProcessor";
import _ from "lodash";

import {
  listChannelMessages,
  createMemberArn,
  updateChannelMessage,
  redactChannelMessage,
} from "../../api/ChimeAPI";
import insertDateHeaders from "../../utilities/insertDateHeaders";

import "../../../../assets/styles/pluginchat.scss";
import { useChatChannelState } from "../../providers/ChatMessagesProvider";
import moment from "moment";
import { REGEX_URL } from "../../../../app/utils/Constants";
import { useSelector } from "react-redux";
import { usersList } from "../../../LandingPage/userSlice";
import Avatar from "react-avatar";
import { useRef } from "react";
import LoadingGif from "../../../../assets/images/loading.gif";

const Messages = ({
  messages,
  messagesRef,
  setMessages,
  channelArn,
  channelName,
  userId,
  setChannelMessageToken,
  activeChannelRef,
  cognitoUsers,
  setChatLoader
}) => {
  const myRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const { channelMessageTokenRef, activeReadMarker } = useChatChannelState();

  const handleScrollTop = async () => {
    if (!channelMessageTokenRef.current || isLoading) {
      setIsLoading(false);
      return;
    }
    const list = document.getElementById('ch-msg-list-id');
    if (list && list.scrollTop === 0) {
      setIsLoading(true);
      // const previousScrollHeight = list.scrollHeight;

      let oldMessages = {};
      oldMessages = await listChannelMessages(
        activeChannelRef.current.ChannelArn,
        userId,
        channelMessageTokenRef.current
      );
      const newMessages = [...oldMessages.Messages, ...messagesRef.current];

      setMessages(newMessages);
      setChannelMessageToken(oldMessages.NextToken);
      // const differenceInScrollHeight = list.scrollHeight - previousScrollHeight;
      // list.scrollTop = list.scrollTop + differenceInScrollHeight - 10;
      setIsLoading(false);
    }
  };

  const [showDiscardModal, setShowDiscardModal] = useState(false);
  const [showRedactModal, setShowRedactModal] = useState(false);
  const [editingMessageId, setEditingMessageId] = useState("");
  const [redactingMessageId, setRedactingMessageId] = useState("");
  const [unreadMarker, setUnreadMarker] = useState(true);

  useEffect(() => {
    const list = document.getElementById('ch-msg-list-id');
    list && list.addEventListener('scroll', handleScrollTop);
    return () => {
      list && list.removeEventListener('scroll', handleScrollTop);
    };
  }, [isLoading, channelMessageTokenRef.current]);

  const handleDiscardEdit = () => {
    setShowDiscardModal(false);
    setEditingMessageId("");
  };

  const discardModal = (
    <Modal onClose={() => setShowDiscardModal(false)}>
      <ModalHeader title="Discard Changes?" />
      <ModalBody>
        <div className="leave-chat">You cannot undo this action.</div>
        <ModalButtonGroup
          primaryButtons={[
            <ModalButton
              label="Discard"
              type="submit"
              variant="primary"
              onClick={handleDiscardEdit}
              key="1"
            />,
            <ModalButton
              label="Cancel"
              variant="secondary"
              closesModal
              key="2"
            />,
          ]}
        />
      </ModalBody>
    </Modal>
  );

  const handleShowRedactModal = (messageId) => {
    setRedactingMessageId(messageId);
    setShowRedactModal(true);
  };

  const handleCloseRedactModal = () => {
    setRedactingMessageId("");
    setShowRedactModal(false);
  };

  const redact = async () => {
    await redactChannelMessage(channelArn, redactingMessageId, userId);
    setShowRedactModal(false);
  };

  const redactModal = (
    <Modal onClose={handleCloseRedactModal}>
      <ModalHeader title="Delete Message?" />
      <ModalBody>
        <div className="leave-chat">You cannot undo this action.</div>
        <ModalButtonGroup
          primaryButtons={[
            <ModalButton
              label="Delete"
              type="submit"
              variant="primary"
              onClick={redact}
              key="1"
            />,
            <ModalButton
              label="Cancel"
              variant="secondary"
              closesModal
              key="2"
            />,
          ]}
        />
      </ModalBody>
    </Modal>
  );

  const cancelEdit = (e) => {
    e.preventDefault();
    setShowDiscardModal(true);
  };

  const saveEdit = async (e, newText, metadata) => {
    e.preventDefault();
    const response = await updateChannelMessage(
      channelArn,
      editingMessageId,
      newText,
      metadata,
      userId
    );
    setEditingMessageId("");
  };

  const flattenedMessages = messages.map((m, i) => {
    const content = !m.Content || m.Redacted ? "(Deleted)" : m.Content;
    let editedNote;
    if (m.LastEditedTimestamp && !m.Redacted) {
      const time = formatTime(m.LastEditedTimestamp);
      const date = formatDate(
        m.LastEditedTimestamp,
        undefined,
        undefined,
        "today",
        "yesterday"
      );
      editedNote = (
        <i style={{ fontStyle: "italic" }}>{` (edited ${date} at ${time})`}</i>
      );
    }

    const messageStatus = m.Status.Value == null ? "SENT" : m.Status.Value;
    let statusNote;
    if (messageStatus !== "SENT") {
      statusNote = (
        <i style={{ fontStyle: "italic" }}>{`     (${messageStatus})`}</i>
      );
    }

    let senderName = m.Sender.Name ? m.Sender.Name.includes("DEACTIVATED-") ? m.Sender.Name.replace("DEACTIVATED-", "") : m.Sender.Name : "";
    const currentSender = cognitoUsers[senderName] || {};

    const { name = "", icon_color_code } = currentSender;
    return {
      content: content,
      editedNote: editedNote,
      messageId: m.MessageId,
      createdTimestamp: m.CreatedTimestamp,
      redacted: m.Redacted,
      senderName: (
        <div className="user-name-wrapper">
          <Avatar
            color={icon_color_code ? `#${icon_color_code}` : "#333745"}
            name={name}
            maxInitials={2}
            size={36}
            round="40px"
          />
          <div className="username-label">
            {name ? name.includes("DEACTIVATED-") ? name.replace("DEACTIVATED-", "") : name : senderName}
          </div>
        </div>
      ),
      senderId: m.Sender.Arn,
      metadata: m.Metadata,
      status: m.Status.Value,
      statusNote: statusNote,
      ReadMarkerTimestamp: m.ReadMarkerTimestamp,
    };
  });

  const listItems = insertDateHeaders(flattenedMessages);
  const messageList = listItems.map((m, i, self) => {
    if (!m.content) {
      return m;
    }

    if (m.Metadata) {
      let metadata = JSON.parse(m.Metadata);
      if (metadata.isMeetingInfo) {
        return m;
      }
    }

    const variant =
      createMemberArn(userId) === m.senderId ? "outgoing" : "incoming";
    let actions = null;
    const messageStatus = m.status == null ? "SENT" : m.status;
    if (variant === "outgoing" && messageStatus === "SENT") {
      actions = [
        <PopOverItem
          key="1"
          children={<span>Edit</span>}
          onClick={() => setEditingMessageId(m.messageId)}
        />,
        <PopOverItem
          key="2"
          children={<span>Delete</span>}
          onClick={() => handleShowRedactModal(m.messageId)}
        />,
      ];
    }

    const prevMessageSender = self[i - 1]?.senderId;
    const currMessageSender = m.senderId;
    const nextMessageSender = self[i + 1]?.senderId;

    let showTail = true;
    if (
      currMessageSender && // it is a message
      nextMessageSender && // the item after is a message
      currMessageSender === nextMessageSender // the item after is from the same sender
    ) {
      showTail = false;
    }
    let showName = true;
    if (
      currMessageSender && // it is a message
      prevMessageSender && // the item before is a message
      currMessageSender === prevMessageSender // the message before is from the same sender
    ) {
      showName = false;
    }

    const attachment = (metadata) => {
      try {
        const metadataJSON = JSON.parse(metadata);
        return metadataJSON?.attachments || [];
      } catch (err) {
        // not an json object! ignoring
      }
      return false;
    };
    const timestamp = moment(m.createdTimestamp).format("ddd HH:mm");

    const child = document.getElementById("chat_detail_unread");

    child &&
      child.parentElement &&
      child.parentElement.classList &&
      child.parentElement.classList.add("chat_unread_parent");

    const attachments = attachment(m.metadata) || "";
    return (
      <>
        {activeReadMarker < m.createdTimestamp && (
          <React.Fragment>
            <div className={"chat_detail_unread"} id="chat_detail_unread">
              unread
            </div>
          </React.Fragment>
        )}

        <ChatBubbleContainer
          //timestamp={formatTime(m.createdTimestamp)}
          //actions={actions}
          key={`message${i.toString()}`}
          className="ch-bubble-container"
        >
          {editingMessageId === m.messageId && !m.redacted ? (
            <EditableChatBubble
              variant={variant}
              senderName={m.senderName}
              content={m.content}
              save={(event, value) => saveEdit(event, value, m.metadata)}
              cancel={cancelEdit}
              showName={showName}
            //showTail={showTail}
            />
          ) : (
            <ChatBubble
              timestamp={timestamp}
              variant={variant}
              senderName={m.senderName}
              redacted={m.redacted}
              showName={showName}
              // showTail={showTail}
              className="ch-bubble-inner"
            >
              <div
                className="ch-content-container"
                dangerouslySetInnerHTML={{
                  __html:
                    m.content.toUpperCase() != "NULL" &&
                    m.content.replace(REGEX_URL, () => {
                      return (
                        '<a href="' +
                        m.content +
                        '"target="_blank">' +
                        m.content +
                        "</a>"
                      );
                    }),
                }}
              ></div>
              {m.editedNote}
              {m.statusNote}
              {m.metadata && attachments && attachments.length > 0 &&
                attachments.map((file, i) => {
                  return (
                    <div style={{ marginTop: "10px" }} key={i}>
                      <AttachmentProcessor
                        senderId={m.senderId}
                        messageId={m.messageId}
                        channelArn={channelArn}
                        userId={userId}
                        setMessages={setMessages}
                        messages={messages}
                        setChatLoader={setChatLoader}
                        {...file}
                      />
                    </div>
                  )
                })}
            </ChatBubble>
          )}
        </ChatBubbleContainer>
      </>
    );
  });
  let allUsers = channelName.split(", ");
  let tempUsers = [];
  allUsers.forEach((user) => {
    const currentUser = cognitoUsers[user] || {};
    if (_.isEmpty(currentUser)) {
      tempUsers.push(user);
    } else {
      const { name = "" } = currentUser;
      tempUsers.push(name);
    }
  });
  const formattedChannelName = tempUsers.join(", ");
  return (
    <>
      {showDiscardModal && discardModal}
      {showRedactModal && redactModal}
      <div className="ch-msg-list-header">
        <h2 className="font-size-16 roboto-medium channel-name">
          {formattedChannelName}
        </h2>
      </div>
      {isLoading && <div className="text-center"><img loading="lazy" src={LoadingGif} width={30} height={30} alt="loading" title="loading" /></div>}
      <InfiniteList
        items={messageList}
        onLoad={() => { }}
        isLoading={false}
        className="ch-msg-list-wrapper"
        id="ch-msg-list-id"
      />
    </>
  );
};
export default Messages;