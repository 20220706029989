import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ahaApi from "../../../app/services/aha-api";
const initialState = {
  value: [],
  loading: false
};

export const getShiftTimings = createAsyncThunk(
  "schedule/shiftTimings",
  async (data, { rejectWithValue }) => {
    const response = await ahaApi.Account.getShiftTimings(data)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return rejectWithValue(err.response.data);
      });
    return response;
  }
);

export const shiftTimingsSlice = createSlice({
  name: "shiftTimings",
  initialState,
  reducers: {
    setFilter(state, action) {
      const { parent, property, value } = action.payload;
      state[parent] = {
        ...state[parent],
        [property]: value,
      };
    },
    setDate(state, action) {
      state.date = action.payload;
    },
    setFilterObject(state, action) {
      const { property, value } = action.payload;
      state[property] = value;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getShiftTimings.pending, (state) => {
        state.loading = true;
      })
      .addCase(getShiftTimings.fulfilled, (state, action) => {
        state.loading = false;
        state.value = action.payload;
      })
      .addCase(getShiftTimings.rejected, (state, err) => {
        state.loading = false;
        state.value = err.payload;
      });
  },
});

export const shiftTimingsResponse = (state) => state.shiftTimings.value;

export default shiftTimingsSlice.reducer;
