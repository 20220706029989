// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import React from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalButtonGroup,
  ModalButton,
} from 'amazon-chime-sdk-component-library-react';

import ContactPicker from '../ContactPicker';
import { GROUP_CHANNEL_TYPE, INDIVIDUAL_CHANNEL_TYPE, GROUP_CHANNEL_TYPE_TWO } from '../../../../app/utils/Constants';
import { useAuthContext } from '../../providers/AuthProvider';
import { useIdentityService } from '../../providers/IdentityProvider';
import _ from "lodash";

export const ChatManageMembersModal = ({
  onClose,
  channel,
  members,
  handlePickerChange,
  handleDeleteMemberships,
  selectedMembers
}) => {
  const identityClient = useIdentityService();
  const { useCognitoIdp } = useAuthContext();
  const { username } = useAuthContext().member;
  const [formattedMembers, setFormattedMembers] = React.useState([]);
  let {Name=""} = channel || {};
  if (Name.includes(GROUP_CHANNEL_TYPE) || Name.includes(GROUP_CHANNEL_TYPE_TWO)) {
    Name = Name.replace(GROUP_CHANNEL_TYPE, '');
    Name = Name.replace(GROUP_CHANNEL_TYPE_TWO, '');
  }
  else if (Name.includes(INDIVIDUAL_CHANNEL_TYPE)) {
    Name = Name.replace(INDIVIDUAL_CHANNEL_TYPE, '');
    let allUsers = Name.split(", ");
    if (allUsers.includes(username)) {
      allUsers = allUsers.filter(user => user !== username);
      Name = allUsers.join(', ');
    }
  }

  React.useEffect(() => {
    if (!identityClient) return;
    if (useCognitoIdp) {
      identityClient.setupClient();
    }
    // getAllUsers();
  }, [identityClient]);

  React.useEffect(async() => {
    const addedUsersPromises = members.map(async user => {
      const tempUser = {...user};
      const searchText = "username = " + "\"" + user.label + "\"";
      let usersInfo = await identityClient.getUsers(60, searchText) || [];
      if (usersInfo.length) {
        const currentUser = usersInfo[0] || {};
        const {Attributes=[], Username = ""} = currentUser;
        const firstName = Attributes.filter(att => att.Name === "given_name") ? Attributes.filter(att => att.Name === "given_name")[0]
            ? Attributes.filter(att => att.Name === "given_name")[0].Value : "" : "";
        const middleName = Attributes.filter(att => att.Name === "middle_name") ? Attributes.filter(att => att.Name === "middle_name")[0]
        ? Attributes.filter(att => att.Name === "middle_name")[0].Value : "" : "";
        const lastName = Attributes.filter(att => att.Name === "family_name") ? Attributes.filter(att => att.Name === "family_name")[0]
        ? Attributes.filter(att => att.Name === "family_name")[0].Value : "" : "";
        const iconColorCode = Attributes.filter(att => att.Name === "nickname") ? Attributes.filter(att => att.Name === "nickname")[0]
        ? Attributes.filter(att => att.Name === "nickname")[0].Value : "" : "";
        // const fullName = firstName + `${_.isEmpty(middleName) ? " " : ` ${middleName} `}` + lastName;
        const fullName = firstName + " " + lastName;
        const fullNameForInitials = firstName + " " + lastName;

        tempUser.fullName = fullName;
        tempUser.fullNameForInitials = fullNameForInitials;
        tempUser.iconColorCode = iconColorCode;
      }
      return tempUser;
    });
    const addedUsers = await Promise.all(addedUsersPromises);
    setFormattedMembers(addedUsers);
  }, []);
  return (
    <Modal onClose={onClose} className="ch-main-modal">
      <ModalHeader title={`Edit Chat`} />
      <ModalBody className="modal-body">
        <form className="ch-form-wrapper" >
          <ContactPicker onChange={handlePickerChange} options={formattedMembers} />
        </form>
      </ModalBody>
      <ModalButtonGroup
        primaryButtons={[
          <ModalButton
            label="Remove"
            onClick={() => handleDeleteMemberships("chat")}
            variant="primary"
            closesModal
            className="primary-btn ad-progress-btn primary-btn-outline"
            disabled={_.isEmpty(selectedMembers)}
          />,
          <ModalButton label="Cancel" closesModal variant="secondary" className="primary-btn primary-btn-outline outline" />,
        ]}
      />
    </Modal>
  );
};

export default ChatManageMembersModal;
