import * as React from "react";
import { SchedulerBacklog } from "../SchedulerBacklog";
import { DragDropContext } from "react-beautiful-dnd";
import _ from "lodash";
import { SchedulerBoard } from "../SchedulerBoard";

export const HomeHealthScheduler = (props) => {
	const { profileLoading, selectedDate, tabValue, date, updateBoardData,
		handleDelete, deleteUserIndex, handleDeleteService, constructPyloadForCheckinCheckout, getScheduleBoardData,
		deleteServiceData, setDeleteServiceData, homeHealthScheduleBoardData, setBoardData, setHomeHealthBoardData,
		homeHealthBacklogData, onDragEnd, searchText = "", searchType, onDragUpdate, filterType, onBeforeCapture, showDropArea} = props;

		const roles =
		(window.localStorage.getItem("userDetail") &&
		JSON.parse(window.localStorage.getItem("userDetail")).roles &&
		JSON.parse(window.localStorage.getItem("userDetail")).roles.length === 1 &&
		JSON.parse(window.localStorage.getItem("userDetail")).roles) || [];
	
		const admin = roles && roles.includes(
		  "Admin"
		);
	
		const isPhleb = roles && roles.includes(
		  "Phlebotomist"
		);

	return (
		<React.Fragment>
			<div className="scheduler-wrapper">
				{homeHealthScheduleBoardData.length === 0 ? (
					<div
						className="toast toast-wrapper text-center show sch-toast-wrapper sch-right-toast-wrapper"
						role="alert"
						aria-live="assertive"
						aria-atomic="true"
					>
						<div className="toast-info-details d-flex align-items-center toast-body">
							<p className="sch-info-text">
							{!isPhleb
									? "Please select Staff to view the Schedule Board"
									: "User does not have Permission to view the Schedule"}
							</p>
						</div>
					</div>
				) : null}
				<DragDropContext onDragEnd={onDragEnd} onDragUpdate={onDragUpdate} onBeforeCapture={onBeforeCapture}>
					{!profileLoading && (
						<SchedulerBacklog
							selectedDate={selectedDate}
							backlogData={homeHealthBacklogData}
							tabValue={tabValue}
							searchType={searchType}
							searchText={searchText}
							showDropArea={showDropArea}
						/>
					)}
					<SchedulerBoard
						selectedDate={selectedDate}
						scheduleBoardData={homeHealthScheduleBoardData}
						setBoardData={setHomeHealthBoardData}
						date={date}
						updateBoardData={updateBoardData}
						handleDelete={handleDelete}
						deleteIndex={deleteUserIndex}
						handleDeleteService={handleDeleteService}
						deleteServiceData={deleteServiceData}
						setDeleteServiceData={setDeleteServiceData}
						tabValue={tabValue}
						searchText={searchText}
						searchType={searchType}
						filterType={filterType}
						showDropArea={showDropArea}
					/>
				</DragDropContext>
			</div>
		</React.Fragment>
	);
};
