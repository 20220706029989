import * as React from "react";
import { Button, Dropdown, Form, InputGroup, Tab, Tabs } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import ReactSelect, { components } from "react-select";
import Select from "react-select";
import {
  getProfile,
  profileList,
  profileLoader,
} from "../LandingPage/Profile/profileSlice";
import Avatar from "react-avatar";
import { SchedulerBoard } from "./SchedulerBoard";
import { SchedulerBacklog } from "./SchedulerBacklog";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import {
  boardData,
  constructDictionary,
  draggableInProgress,
  handleDropEvent,
  setDraggableInProgress,
  sortEvents,
} from "./scheduleHelper";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import {
  scheduleList,
  scheduleListList,
  scheduleListLoading,
  setDisableSaveForDropOff,
  setDisableSaveForStartRoute,
  setShowPopover,
  showPopoverVal,
  updateScheduleListValue,
} from "./Operations/scheduleListSlice";
import { filterList, getFilter } from "../LandingPage/AddUser/filterSlice";
import {
  backlog,
  backlogList,
  updateBacklogValue,
} from "./Operations/backlogSlice";
import {
  getManager,
  getManagerList,
  updateReportingDataVal,
} from "./Operations/reportingSlice";
import {
  updateEvent,
  eventResponse,
  clearUpdateEventResponse,
} from "./Operations/eventSlice";
import _ from "lodash";
import {
  clearDeleteEventResponse,
  deleteEvent,
  deleteEventList,
  deleteEventLoading,
} from "./Operations/deleteSlice";
import {
  clearDeleteServiceResponse,
  deleteService,
  deleteServiceList,
  deleteServiceLoading,
} from "./Operations/deleteServiceSlice";
import ToastMessageNew from "../../components/layouts/ToastMessageNew";
import {
  filterState,
  setFilter,
  setDate,
  setFilterObject,
} from "./Operations/filterSlice";
import {
  BACKLOG_SECTION_TYPE,
  NON_SERVICE_TYPE,
  ON_CALL_DELETE_SURGE_WARN,
  SCHEDULE_SERVICE_TYPE,
  SURGE_TYPE,
  TYPE_HOMEHEALTH,
  isOnCallScheduleDateAfterDeployment,
  scheduleClass,
} from "../../app/utils/Constants";
import CopyScheduleModal from "./CopyScheduleModal";
import {
  clearCopySchedule,
  copyScheduleResponse,
  copyScheduleApiResponse,
} from "./Operations/copyScheduleSlice";
import LottieAnimation from "../../components/layouts/LottieAnimation";
import { postToken, tokenList } from "../accounts/Login/tokenSlice";
import { appConfig } from "../Chat/Config";
import {
  checkinCheckoutApiResponse,
  checkinCheckoutResponse,
  clearCheckinCheckout,
  setActiveFacility,
  setDisableSaveButton,
} from "./Operations/checkinCheckoutSlice";
import {
  downloadUnassigned,
  downloadUnassignedList,
} from "./Operations/downloadSlice";
import StatusModal from "../../components/commons/StatusModal";
import { OnCallScheduler } from "./OnCall/onCallScheduler";
import {
  getOnCallBacklog,
  onCallBacklogList,
  updateOnCallBacklogValue,
} from "./Operations/onCallBacklogSlice";
import {
  getOnCallScheduleList,
  onCallScheduleListLoading,
  onCallScheduleListResponse,
  setOpenPopover,
  updateOnCallScheduleListValue,
} from "./Operations/onCallScheduleListSlice";
import { handleDropEventOnCall } from "./OnCall/onCallScheduleHelper";
import {
  clearOnCallDeleteServiceResponse,
  onCallDeleteService,
  onCallDeleteServiceList,
  onCallDeleteServiceLoading,
} from "./Operations/onCallDeleteServiceSlice";
import { HomeHealthScheduler } from "./HomeHealth/HomeHealthScheduler";
import DropdownButton from "react-bootstrap/DropdownButton";
import {
  bulkCheckinCheckoutResponse,
  clearBulkCheckinCheckoutResponse,
  doBulkCheckinCheckout,
} from "../StatOrder/bulkCheckinCheckout";
import {
  onCallSurgeModalData,
  setOnCallSurgeModalData,
  setSurgeOrNonServiceModal,
  surgeOrNonServiceFacilitiesList,
  surgeOrNonServiceModalData,
} from "./Operations/getSurgeOrNonServiceFacilitiesSlice";
import {
  backlognonservice,
  backlogNonServiceList,
} from "./Operations/backlogNonServiceSlice";
import ServiceStopDayModal from "./ServiceStopDayModal";
import { clearDeleteRoutineAction, deleteRoutineActionApiResponse, deleteRoutineActionResponse } from "./Operations/deleteRoutineAction";
import { getZones, zonesList } from "../LandingPage/zonesSlice";
import FacilityCoverageDropdown from "./FacilityCoverageDropdown";
import { CommonHeaderRightSection } from "../../components/commons/CommonHeaderRightSection";
import { getShiftTimings, shiftTimingsResponse } from "./Operations/shiftTimingsSlice";
import { getRoutineManagerList } from "./Operations/routineReportingSlice";
import uuid from "react-uuid";

export const multiValueContainer = ({ selectProps, data }) => {
  const label = data.label;
  const allSelected = selectProps.value;
  const index = allSelected.findIndex((selected) => selected.label === label);
  const isLastSelected = index === allSelected.length - 1;
  const labelSuffix = isLastSelected ? `` : ", ";
  const val = `${label} ${labelSuffix}`;
  return val;
};
export const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};
export const customStyles = {
  valueContainer: (provided, state) => ({
    ...provided,
    display: "initial",
    maxWidth: "90%",
    height: "28px",
    lineHeight: "26px",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    fontSize: 14,
    color: "#121212",
  }),
};

export const Scheduler = () => {
  let interval;
  const [paused, setPaused] = React.useState(false);
  const username = localStorage.getItem("username");

  let currentTab = window.localStorage.getItem("scheduleTab") || "routine";

  const dispatch = useDispatch();
  const tokenData = useSelector(tokenList);
  const profile = useSelector(profileList);
  const profileLoading = useSelector(profileLoader);
  const schedulerList = useSelector(scheduleListList);
  const onCallScheduleList = useSelector(onCallScheduleListResponse);
  const downloadList = useSelector(downloadUnassignedList);
  const reportingData = useSelector(getManagerList);
  const routineReportingData = useSelector(getRoutineManagerList);
  const backlogListData = useSelector(backlogList);
  const onCallBacklogListData = useSelector(onCallBacklogList);
  const surgeOrNonServiceFacilities = useSelector(
    surgeOrNonServiceFacilitiesList
  );

  const deleteServiceLoader = useSelector(deleteServiceLoading);
  const deleteEventLoader = useSelector(deleteEventLoading);
  const onCallDeleteServiceLoader = useSelector(onCallDeleteServiceLoading);

  const backlogNonServiceListData = useSelector(backlogNonServiceList);

  const [showDropArea, setShowDropArea] = React.useState(false);

  const [scheduleBoardData, setBoardData] = React.useState([]);
  const [initialBoardData, setInitialBoardData] = React.useState([]);

  const [backlogData, setBacklogData] = React.useState([]);
  const [initialBacklogData, setInitialBacklogData] = React.useState([]);

  const [onCallScheduleBoardData, setOnCallBoardData] = React.useState([]);
  const [initialOnCallScheduleBoardData, setInitialOnCallScheduleBoardData] =
    React.useState([]);

  const [onCallBacklogData, setOnCallBacklogData] = React.useState([]);
  const [initialOnCallBacklogData, setInitialOnCallBacklogData] =
    React.useState([]);

  const [homeHealthScheduleBoardData, setHomeHealthBoardData] = React.useState(
    []
  );
  const [
    initialHomeHealthScheduleBoardData,
    setInitialHomeHealthScheduleBoardData,
  ] = React.useState([]);

  const [homeHealthBacklogData, setHomeHealthBacklogData] = React.useState([]);
  const [initialHomeHealthBacklogData, setInitialHomeHealthBacklogData] =
    React.useState([]);

  const [tempCourierAddress, setTempCourierAddress] = React.useState({});
  const [tempCourierAddressForEnRoute, setTempCourierAddressForEnRoute] =
    React.useState({});

  const filter = useSelector(filterList);
  const { content = {} } = filter || {};
  let timezone_ids = content.timezone_ids ? content.timezone_ids.map(item => { return { ...item, label: item.name, value: item.id } }) : [];
  timezone_ids = timezone_ids.filter(tz => tz.isVisible);
  const shiftTimingsVal = useSelector(shiftTimingsResponse);
  const zonesListData = useSelector(zonesList);
  const updateEventResponse = useSelector(eventResponse);
  const copyScheduleResult = useSelector(copyScheduleResponse);
  const checkinCheckoutResult = useSelector(checkinCheckoutResponse);
  const deleteRoutineActionResult = useSelector(deleteRoutineActionResponse);
  const bulkCheckinCheckoutData = useSelector(bulkCheckinCheckoutResponse);
  const surgeOrNonServiceModal = useSelector(surgeOrNonServiceModalData);
  const onCallSurgeModal = useSelector(onCallSurgeModalData);
  const [name, setName] = React.useState("");
  const [color, setColor] = React.useState("");
  const [selectedDate, setSelectedDate] = React.useState("1");
  const [refreshLoader, setRefreshLoader] = React.useState(false);

  const reduxState = useSelector(filterState);
  const [componentFilter, setComponentFilter] = React.useState({});
  const deleteData = useSelector(deleteEventList);
  const [toast, setToast] = React.useState(true);
  const [toastMessage, setToastMessage] = React.useState("");
  const [deleteUserIndex, setDeleteUserIndex] = React.useState("");
  const [openCalender, setOpenCalender] = React.useState(false);
  const [statusModalType, setStatusModalType] = React.useState({
    value: false,
  });
  let [destWeeks, setDestWeeks] = React.useState([]);
  const [modalError, setModalError] = React.useState("");
  const [checkinCheckoutDropOffData, setCheckinCheckoutDropOffData] =
    React.useState({});
  const [upsertData, setUpsertData] = React.useState({});
  const [deleteServiceData, setDeleteServiceData] = React.useState(null);

  const [searchText, setSearchText] = React.useState("");
  const [searchType, setSearchType] = React.useState("phlebotomist");
  const [dropdown, toggleDropdown] = React.useState(false);

  const [reportingOptions, setReportingOptions] = React.useState([]);
  const [phlebOptions, setPhlebOptions] = React.useState([]);
  const [zoneOptions, setZoneOptions] = React.useState([]);

  const [openStopDayModal, setOpenStopDayModal] = React.useState({
    value: false,
    error: "",
    facility: {},
    stopDay: "",
  });

  const [filterType, setFilterType] = React.useState({
    scheduled: true,
    unscheduled: true,
    nocoverage: true
  });

  const [showInactiveFac, setShowInactiveFac] = React.useState(false);

  const [facilitiesForSurge, setFacilitiesForSurge] = React.useState([]);
  const [facilitiesForNonService, setFacilitiesForNonService] = React.useState(
    []
  );

  const [zonesForSurge, setZonesForSurge] = React.useState([]);

  const [onCallDeleteTempData, setOnCallDeleteTempData] =
    React.useState({});


  const [dateSelected, setDateSelected] = React.useState(
    window.localStorage.getItem(`${username}.scheduleFilter`)
      ? JSON.parse(window.localStorage.getItem(`${username}.scheduleFilter`))
        .selectedDate
      : ""
  );
  const [selectedZones, setZones] = React.useState(
    window.localStorage.getItem(`${username}.scheduleFilter`)
      ? JSON.parse(window.localStorage.getItem(`${username}.scheduleFilter`))
        .facilityCoverage &&
      JSON.parse(window.localStorage.getItem(`${username}.scheduleFilter`))
        .facilityCoverage.zone
      : []
  );
  const [selectedRegion, setSelectedRegion] = React.useState(
    window.localStorage.getItem(`${username}.scheduleFilter`)
      ? JSON.parse(window.localStorage.getItem(`${username}.scheduleFilter`))
        .facilityCoverage &&
      JSON.parse(window.localStorage.getItem(`${username}.scheduleFilter`))
        .facilityCoverage.region
      : ""
  );
  const [selectedTerritory, setSelectedTerritory] = React.useState(
    window.localStorage.getItem(`${username}.scheduleFilter`)
      ? JSON.parse(window.localStorage.getItem(`${username}.scheduleFilter`))
        .facilityCoverage &&
      JSON.parse(window.localStorage.getItem(`${username}.scheduleFilter`))
        .facilityCoverage.territory
      : ""
  );
  const deleteServiceResponse = useSelector(deleteServiceList);
  const onCallDeleteServiceResponse = useSelector(onCallDeleteServiceList);

  let [tabValue, setTabValue] = React.useState(currentTab);
  const showPopover = useSelector(showPopoverVal);

  let tabRef = React.useRef({});
  tabRef.current = tabValue;

  const { facilityCoverage = {}, date = new Date() } = componentFilter || {};
  const {
    region: facilityRegion = "",
    territory: facilityTerritory = [],
    zone = [],
    manager = [],
    phlebs = [],
  } = facilityCoverage;

  document.documentElement.classList.add(scheduleClass);

  // let zones = zonesListData ? zonesListData.content || [] : [];
  // zones = zones.filter(z => z.status === "ACTIVE");
  // let zone_modified = zones
  //   ? zones.map((val) => {
  //     return { value: val.id, label: val.name };
  //   })
  //   : [];
  // zone_modified = zone_modified
  //   ? zone_modified.sort((a, b) => a.label.localeCompare(b.label))
  //   : [];
  const user_region = profile && profile.content && profile.content.region;
  const user_territory_facilities =
    profile &&
    profile.content &&
    profile.content.territory &&
    profile.content.territory.filter(
      (val) => val.region_id === facilityRegion.value
    );
  let reg_modified_facilities = user_region
    ? user_region.map((val) => {
      return { value: val.id, label: val.name };
    })
    : [];
  reg_modified_facilities = reg_modified_facilities
    ? reg_modified_facilities.sort((a, b) => a.label.localeCompare(b.label))
    : [];
  let terr_modified_facilities = user_territory_facilities
    ? user_territory_facilities.map((val) => {
      return { value: val.id, label: val.name };
    })
    : [];
  terr_modified_facilities = terr_modified_facilities
    ? terr_modified_facilities.sort((a, b) => a.label.localeCompare(b.label))
    : [];
  const roles =
    (window.localStorage.getItem("userDetail") &&
      JSON.parse(window.localStorage.getItem("userDetail")).roles &&
      JSON.parse(window.localStorage.getItem("userDetail")).roles.length ===
      1 &&
      JSON.parse(window.localStorage.getItem("userDetail")).roles) ||
    [];

  const admin = roles && roles.includes("Admin");

  const isPhleb = roles && roles.includes("Phlebotomist");

  let shiftTimingsList = shiftTimingsVal ? shiftTimingsVal.content || [] : [];
  if (date) {
    shiftTimingsList = shiftTimingsList.filter(item => item.day.toLowerCase() === moment(date).format('ddd').toLowerCase());
  } else {
    shiftTimingsList = [];
  }

  React.useEffect(() => {
    dispatch(getProfile());
    dispatch(getFilter());
    dispatch(getShiftTimings());
    const { facilityCoverage: lsCoverage = {}, selectedDate = "" } =
      window.localStorage.getItem(`${username}.scheduleFilter`)
        ? JSON.parse(window.localStorage.getItem(`${username}.scheduleFilter`))
        : {};
    if (Object.keys(lsCoverage).length > 0) {
      const {
        region: fRegion = "",
        territory: fTerr = [],
        zone: fZone = [],
        manager: sManager = [],
        phlebs = [],
      } = lsCoverage;
      dispatch(
        setFilterObject({ property: "facilityCoverage", value: lsCoverage })
      );
      if (tabRef.current === "onCall") {
        // get on call backlog list
        if (
          !_.isEmpty(fRegion) &&
          !_.isEmpty(fTerr) &&
          !_.isEmpty(selectedDate)
        ) {
          let terrs = fTerr
            ? Array.isArray(fTerr)
              ? fTerr.map((val) => parseInt(val.value))
              : localStorage.setItem(
                `${username}.scheduleFilter`,
                JSON.stringify({
                  facilityCoverage: { ...facilityCoverage, territory: [] },
                  selectedDate: date,
                })
              )
            : [];
          const payloadDate = moment(new Date(selectedDate)).format(
            "YYYY-MM-DD"
          );
          getOnCallBacklogListData(fRegion, terrs, payloadDate);
        }

        // get on call schedule list
        if (
          !_.isEmpty(fRegion) &&
          !_.isEmpty(fTerr) &&
          !_.isEmpty(sManager) &&
          !_.isEmpty(selectedDate)
        ) {
          let managers = sManager
            ? Array.isArray(sManager)
              ? sManager.map((val) => parseInt(val.value))
              : localStorage.setItem(
                `${username}.scheduleFilter`,
                JSON.stringify({
                  facilityCoverage: { ...facilityCoverage, manager: [] },
                  selectedDate: date,
                })
              )
            : [];
          let tempPhlebs = phlebs
            ? Array.isArray(phlebs)
              ? phlebs.map((val) => parseInt(val.value))
              : localStorage.setItem(
                `${username}.scheduleFilter`,
                JSON.stringify({
                  facilityCoverage: { ...facilityCoverage, phlebs: [] },
                  selectedDate: date,
                })
              )
            : [];
          let terrs = fTerr
            ? Array.isArray(fTerr)
              ? fTerr.map((val) => parseInt(val.value))
              : []
            : [];
          const payloadDate = moment(new Date(selectedDate)).format(
            "YYYY-MM-DD"
          );
          getOnCallScheduleListData(
            fRegion,
            terrs,
            managers,
            payloadDate,
            tempPhlebs
          );
        }
      } else {
        if (
          !_.isEmpty(fRegion) &&
          !_.isEmpty(fTerr) &&
          !_.isEmpty(fZone) &&
          !_.isEmpty(selectedDate)
        ) {
          let zoneIds = fZone
            ? Array.isArray(fZone)
              ? fZone.map((val) => parseInt(val.value))
              : localStorage.setItem(
                `${username}.scheduleFilter`,
                JSON.stringify({
                  facilityCoverage: { ...facilityCoverage, zone: [] },
                  selectedDate: date,
                })
              )
            : [];
          let terrs = fTerr
            ? Array.isArray(fTerr)
              ? fTerr.map((val) => parseInt(val.value))
              : localStorage.setItem(
                `${username}.scheduleFilter`,
                JSON.stringify({
                  facilityCoverage: { ...facilityCoverage, territory: [] },
                  selectedDate: date,
                })
              )
            : [];
          const payloadDate = moment(new Date(selectedDate)).format(
            "YYYY-MM-DD"
          );
          const data = {
            event_date: payloadDate,
            region_id: fRegion.value,
            territory_id: terrs,
            zone_id: zoneIds,
          };
          dispatch(backlog(data));
          dispatch(backlognonservice(data));
        }
        if (
          !_.isEmpty(fRegion) &&
          !_.isEmpty(fTerr) &&
          !_.isEmpty(sManager) &&
          !_.isEmpty(selectedDate)
        ) {
          let managers = sManager
            ? Array.isArray(sManager)
              ? sManager.map((val) => parseInt(val.value))
              : localStorage.setItem(
                `${username}.scheduleFilter`,
                JSON.stringify({
                  facilityCoverage: { ...facilityCoverage, manager: [] },
                  selectedDate: date,
                })
              )
            : [];
          let tempPhlebs = phlebs
            ? Array.isArray(phlebs)
              ? phlebs.map((val) => parseInt(val.value))
              : localStorage.setItem(
                `${username}.scheduleFilter`,
                JSON.stringify({
                  facilityCoverage: { ...facilityCoverage, phlebs: [] },
                  selectedDate: date,
                })
              )
            : [];
          let terrs = fTerr
            ? Array.isArray(fTerr)
              ? fTerr.map((val) => parseInt(val.value))
              : []
            : [];
          const payloadDate = moment(new Date(selectedDate)).format(
            "YYYY-MM-DD"
          );
          const data = {
            event_date: payloadDate,
            region_id: fRegion.value,
            territory_id: terrs,
            reporting_manager_id: managers,
            user_id: tempPhlebs.length ? tempPhlebs : [],
          };
          dispatch(scheduleList(data));
        }
      }

      if (!_.isEmpty(fRegion) && !_.isEmpty(fTerr)) {
        const managerPayload = {
          region_id: fRegion.value,
          territory_id: fTerr
            ? Array.isArray(fTerr)
              ? fTerr.map((val) => parseInt(val.value))
              : []
            : [],
        };
        dispatch(getManager(managerPayload));
      }

      if (!_.isEmpty(fRegion) && !_.isEmpty(fTerr) && !_.isEmpty(sManager)) {
        const managerPayload = {
          region_id: fRegion.value,
          territory_id: fTerr
            ? Array.isArray(fTerr)
              ? fTerr.map((val) => parseInt(val.value))
              : []
            : [],
          reporting_manager_id: sManager
            ? Array.isArray(sManager)
              ? sManager.map((val) => parseInt(val.value))
              : []
            : [],
        };
        dispatch(getManager(managerPayload));
      }
      if (!_.isEmpty(selectedDate) && !_.isEmpty(fTerr)) {
        let terrs = fTerr
          ? Array.isArray(fTerr)
            ? fTerr.map((val) => parseInt(val.value))
            : []
          : [];
        const payloadDate = moment(new Date(selectedDate)).format(
          "YYYY-MM-DD"
        );
        handleGetZones(payloadDate, terrs)
      }
    }
    if (!_.isEmpty(selectedDate)) {
      dispatch(setDate(new Date(selectedDate)));
    }
  }, []);

  React.useEffect(() => {
    if (user_region) {
      const { facilityCoverage: lsCoverage = {}, selectedDate = "" } =
        window.localStorage.getItem(`${username}.scheduleFilter`)
          ? JSON.parse(
            window.localStorage.getItem(`${username}.scheduleFilter`)
          )
          : {};
      if (Object.keys(lsCoverage).length > 0) {
        const { region: fRegion = "" } = lsCoverage;
        if (!user_region.some((reg) => reg.id == fRegion.value)) {
          localStorage.setItem(
            `${username}.scheduleFilter`,
            JSON.stringify({
              facilityCoverage: {
                ...facilityCoverage,
                region: "",
                territory: [],
                zone: [],
                manager: [],
                phlebs: [],
              },
              selectedDate,
            })
          );
          dispatch(
            setFilterObject({
              property: "facilityCoverage",
              value: {
                region: "",
                territory: [],
                zone: [],
                manager: [],
                phlebs: [],
              },
            })
          );
          dispatch(updateBacklogValue({}));
          dispatch(updateOnCallBacklogValue({}));
          dispatch(updateScheduleListValue([]));
          dispatch(updateOnCallScheduleListValue([]));
        }
      }
    }
  }, [user_region]);

  React.useEffect(() => {
    if (reportingData) {
      if (reportingData.status === "SUCCESS") {
        const { content = {} } = reportingData;
        let { type, users = [] } = content || {};

        const selectedDate = new Date(date);
        selectedDate.setHours(0, 0, 0, 0);

        var now = new Date();
        now.setHours(0, 0, 0, 0);
        if (selectedDate < now) {
          //past
        } else {
          users = users.filter((user) => user.status !== "INACTIVE");
        }
        users = users
          .map((val) => {
            return { ...val, value: val.user_id, label: type === "PHLEB" ? `${val.full_name} ${val.type ? `(${val.type})` : ""}` : val.full_name };
          })
          .sort((a, b) => a.label.localeCompare(b.label));

        if (type === "PHLEB") {
          const isSelectAllSelected = phlebs?.length > 0 && phlebs.length === (phlebOptions ? phlebOptions.length : 0);
          setPhlebOptions(users);
          let finalUsers = [...users];
          if (isSelectAllSelected) {
          } else {
            if (phlebs && phlebs.length) {
              finalUsers = phlebs;
              phlebs.forEach((mg) => {
                if (users.some((user) => user.value === mg.value)) {
                } else {
                  finalUsers = finalUsers.filter((t) => t.value !== mg.value);
                }
              });
            }
          }
          dispatch(
            setFilter({
              parent: "facilityCoverage",
              property: "phlebs",
              value: finalUsers,
            })
          );
          localStorage.setItem(
            `${username}.scheduleFilter`,
            JSON.stringify({
              facilityCoverage: { ...facilityCoverage, phlebs: finalUsers },
              selectedDate: date,
            })
          );
        } else if (type === "REPORTING_MANAGER") {
          const isSelectAllSelected = manager?.length > 0 && manager.length === (reportingOptions ? reportingOptions.length : 0);
          setReportingOptions(users);
          if (isSelectAllSelected) {
            dispatch(
              setFilter({
                parent: "facilityCoverage",
                property: "manager",
                value: users,
              })
            );
            localStorage.setItem(
              `${username}.scheduleFilter`,
              JSON.stringify({
                facilityCoverage: { ...facilityCoverage, manager: users },
                selectedDate: date,
              })
            );
            if (facilityTerritory && !_.isEmpty(facilityTerritory)) {
              const managers = users.map((val) => parseInt(val.value)) || [];
              handleGetManager(facilityTerritory, managers);
            }
          } else {
            if (manager && manager.length) {
              let temp = manager;
              manager.forEach((mg) => {
                if (users.some((user) => user.value === mg.value)) {
                } else {
                  temp = temp.filter((t) => t.value !== mg.value);
                }
              });
              dispatch(
                setFilter({
                  parent: "facilityCoverage",
                  property: "manager",
                  value: temp,
                })
              );
              localStorage.setItem(
                `${username}.scheduleFilter`,
                JSON.stringify({
                  facilityCoverage: { ...facilityCoverage, manager: temp },
                  selectedDate: date,
                })
              );
            }
          }
        }
      } else if (reportingData.status === "ERROR" && reportingData.message) {
        setToast(false);
        setToastMessage(reportingData.message);
      }
    }
  }, [reportingData]);

  React.useEffect(() => {
    if (zonesListData) {
      if (zonesListData.status === "SUCCESS") {
        const { content = [] } = zonesListData;
        let zones = content || [];
        zones = zones.filter(z => z.status === "ACTIVE");
        let zone_modified = zones
          ? zones.map((val) => {
            return { value: val.id, label: val.name };
          })
          : [];
        zone_modified = zone_modified
          ? zone_modified.sort((a, b) => a.label.localeCompare(b.label))
          : [];
        setZoneOptions(zone_modified);
        const isSelectAllSelected = zone?.length > 0 && zone.length === (zoneOptions ? zoneOptions.length : 0);

        const selectedDate = new Date(date);
        selectedDate.setHours(0, 0, 0, 0);

        if (isSelectAllSelected) {
          dispatch(
            setFilter({
              parent: "facilityCoverage",
              property: "zone",
              value: zone_modified,
            })
          );
          localStorage.setItem(
            `${username}.scheduleFilter`,
            JSON.stringify({
              facilityCoverage: { ...facilityCoverage, zone: zone_modified },
              selectedDate: date,
            })
          );
        }
      }
    }
  }, [zonesListData]);

  React.useEffect(() => {
    if (date) {
      localStorage.setItem(
        `${username}.scheduleFilter`,
        JSON.stringify({
          selectedDate: date,
          facilityCoverage,
        })
      );
      if (facilityTerritory && !_.isEmpty(facilityTerritory)) {
        const isSelectAllSelectedForManagers = manager?.length > 0 && manager.length === (reportingOptions ? reportingOptions.length : 0);
        // get reporting-managers
        handleGetManager(facilityTerritory);
        if (!isSelectAllSelectedForManagers && manager) {
          const managers = manager.map((val) => parseInt(val.value)) || [];
          // get phlebs
          handleGetManager(facilityTerritory, managers);
        }
        let terrs = facilityTerritory
          ? Array.isArray(facilityTerritory)
            ? facilityTerritory.map((val) => parseInt(val.value))
            : []
          : [];
        const payloadDate = moment(new Date(date)).format(
          "YYYY-MM-DD"
        );
        handleGetZones(payloadDate, terrs);
      }
      if (tabRef.current === "onCall") {
        if (!_.isEmpty(facilityRegion) && !_.isEmpty(facilityTerritory)) {
          const data = {
            event_date: moment(date).format("YYYY-MM-DD"),
            region_id: facilityRegion.value,
            territory_id: facilityTerritory.map((val) => parseInt(val.value)),
          };
          dispatch(getOnCallBacklog(data));
        } else {
          dispatch(updateOnCallBacklogValue({}));
        }
        // fetching on call list
        if (
          !_.isEmpty(facilityRegion) &&
          !_.isEmpty(facilityTerritory) &&
          !_.isEmpty(manager)
        ) {
          let managers = manager.map((val) => parseInt(val.value));
          const data = {
            event_date: moment(date).format("YYYY-MM-DD"),
            region_id: facilityRegion.value,
            territory_id: facilityTerritory.map((val) => parseInt(val.value)),
            reporting_manager_id: managers,
            user_id: phlebs
              ? phlebs.length
                ? phlebs.map((val) => parseInt(val.value))
                : []
              : [],
          };
          dispatch(getOnCallScheduleList(data));
        }
      } else {
        if (
          !_.isEmpty(facilityRegion) &&
          !_.isEmpty(facilityTerritory) &&
          !_.isEmpty(zone)
        ) {
          handleFacilityCoverage(zone, date);
        }
        if (
          !_.isEmpty(facilityRegion) &&
          !_.isEmpty(facilityTerritory) &&
          !_.isEmpty(manager)
        ) {
          let allPhlebs = [];
          if (!_.isEmpty(phlebs)) {
            allPhlebs = phlebs.map((ph) => ph.value);
          }
          handleEventForManager(manager, date, allPhlebs);
        }
      }
    }
  }, [date]);

  React.useEffect(() => {
    if (!paused) {
      interval = setInterval(() => {
        handleAuto();
      }, 15000);
    }
    return () => {
      clearInterval(interval);
    };
  }, [
    manager,
    phlebs,
    // facilityRegion,
    facilityTerritory,
    zone,
    date,
    paused,
  ]);

  const handleClickOutside = (event) => {
    const listId = document.getElementById("facilities-list-wrapper");
    const clickedInsideDiv = event.target.closest('#surge-unique-div');
    if (listId && !clickedInsideDiv) {
      dispatch(setSurgeOrNonServiceModal({}));
      dispatch(setOnCallSurgeModalData({}));
    }
  };

  React.useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  React.useEffect(() => {
    if (profile && profile.status === "SUCCESS") {
      if (profile && profile.content) {
        localStorage.setItem(
          "userDetail",
          JSON.stringify(profile && profile.content)
        );
        setName(profile && profile.content && profile.content.name);
        setColor(profile && profile.content && profile.content.icon_color_code);
      }
    }
  }, [profile]);

  React.useEffect(() => {
    if (schedulerList && schedulerList.status) {
      if (schedulerList.status === "SUCCESS") {
        let { content = [] } = schedulerList;
        // const homeHealthContent = content[0] ? filterHomeHealthService(content[0]) : [];
        setBoardData(content || []);
        setInitialBoardData(content || []);
        setRefreshLoader(false);
        // setHomeHealthBoardData(homeHealthContent);
        // setInitialHomeHealthScheduleBoardData(homeHealthContent);
      } else if (schedulerList.status === "ERROR" && schedulerList.message) {
        setToast(false);
        setToastMessage(schedulerList.message);
      }
    } else {
      setBoardData([]);
    }
  }, [schedulerList]);

  React.useEffect(() => {
    if (checkinCheckoutResult && checkinCheckoutResult.status === "SUCCESS") {
      const { content: response = [] } = checkinCheckoutResult;

      const {
        userIndex,
        serviceIndex,
        zoneIndex,
        routineIndex,
        facilityIndex,
        uniqueServiceId,
        prevId,
      } = checkinCheckoutDropOffData;
      let newScheduleBoardData = _.cloneDeep(scheduleBoardData);

      let req = [];
      if (response.some((res) => res.action_type_id === 1)) {
        let currentAction = {};
        response.forEach((res) => {
          if (res.action_type_id === 1) {
            currentAction = {
              ...res,
              order_requisitions: res.orderRequisitions || [],
            };
          }
        });

        const data = {
          ...currentAction,
          action_time: currentAction.action_time,
          action_date: currentAction.action_date,
          action_id: currentAction.id,
          action_type: "Check In",
          audit_history: currentAction.audit_history || [],
        };
        newScheduleBoardData = updateCheckinCheckoutState(
          newScheduleBoardData,
          "Check In",
          userIndex,
          serviceIndex,
          uniqueServiceId,
          zoneIndex,
          routineIndex,
          facilityIndex,
          data
        );
      }
      if (response.some((res) => res.action_type_id === 2)) {
        let currentAction = {};
        response.forEach((res) => {
          if (res.action_type_id === 2) {
            currentAction = {
              ...res,
              order_requisitions: res.orderRequisitions || [],
            };
          }
        });

        const data = {
          ...currentAction,
          action_time: currentAction.action_time,
          action_date: currentAction.action_date,
          action_id: currentAction.id,
          action_type: "Check Out",
          audit_history: currentAction.audit_history || [],
        };
        newScheduleBoardData = updateCheckinCheckoutState(
          newScheduleBoardData,
          "Check Out",
          userIndex,
          serviceIndex,
          uniqueServiceId,
          zoneIndex,
          routineIndex,
          facilityIndex,
          data
        );
      }
      if (response.some((res) => res.action_type_id === 6)) {
        let currentAction = {};
        response.forEach((res) => {
          if (res.action_type_id === 6) {
            currentAction = {
              ...res,
              order_requisitions: res.orderRequisitions || [],
            };
          }
        });

        const data = {
          ...currentAction,
          action_time: currentAction.action_time,
          action_date: currentAction.action_date,
          action_id: currentAction.id,
          action_type: "Spin",
          audit_history: currentAction.audit_history || [],
        };
        newScheduleBoardData = updateCheckinCheckoutState(
          newScheduleBoardData,
          "Spin",
          userIndex,
          serviceIndex,
          uniqueServiceId,
          zoneIndex,
          routineIndex,
          facilityIndex,
          data
        );
      }
      if (response.some((res) => res.action_type_id === 3)) {
        let currentAction = {};
        response.forEach((res) => {
          if (res.action_type_id === 3) {
            currentAction = {
              ...res,
              order_requisitions: res.orderRequisitions || [],
            };
            // req = [...order_requisitions, ...res.orderRequisitions];
          }
        });
        const data = {
          ...currentAction,
          time: currentAction.action_time,
          audit_history: currentAction.audit_history || [],
        };
        newScheduleBoardData = updateCheckinCheckoutState(
          newScheduleBoardData,
          "Drop Off",
          userIndex,
          serviceIndex,
          uniqueServiceId,
          zoneIndex,
          routineIndex,
          facilityIndex,
          data,
          prevId
        );
        setTempCourierAddress({});
      }
      if (response.some((res) => res.action_type_id === 0)) {
        let currentAction = {};
        response.forEach((res) => {
          if (res.action_type_id === 0) {
            currentAction = {
              ...res,
              order_requisitions: res.orderRequisitions || [],
            };
          }
        });
        const data = {
          ...currentAction,
          time: currentAction.action_time,
          audit_history: currentAction.audit_history,
        };
        newScheduleBoardData = updateCheckinCheckoutState(
          newScheduleBoardData,
          "Start Route",
          userIndex,
          serviceIndex,
          uniqueServiceId,
          zoneIndex,
          routineIndex,
          facilityIndex,
          data
        );
        setTempCourierAddressForEnRoute({});
      }
      if (response.some((res) => res.event_has_facility_id)) {
        let currentAction = {};
        response.forEach((res) => {
          if (res.event_has_facility_id) {
            currentAction = { ...res };
          }
        });
        const data = {
          ...currentAction,
        };
        newScheduleBoardData = updateCheckinCheckoutState(
          newScheduleBoardData,
          "Notes",
          userIndex,
          serviceIndex,
          uniqueServiceId,
          zoneIndex,
          routineIndex,
          facilityIndex,
          data
        );
      }
      setBoardData(newScheduleBoardData);
      setCheckinCheckoutDropOffData({});
      dispatch(setDisableSaveButton(false));
      dispatch(setDisableSaveForStartRoute(false));
      dispatch(setDisableSaveForDropOff(false));
      document.body.click();
    } else if (
      checkinCheckoutResult &&
      checkinCheckoutResult.status === "ERROR"
    ) {
      dispatch(setDisableSaveButton(false));
      dispatch(setDisableSaveForStartRoute(false));
      dispatch(setDisableSaveForDropOff(false));
      setCheckinCheckoutDropOffData({});
      if (
        checkinCheckoutResult.message_code &&
        checkinCheckoutResult.message_code === "ACCESS_TOKEN_EXPIRED"
      ) {
      } else if (
        checkinCheckoutResult.message_code === "SESSION_EXPIRED_LOGIN_AGAIN"
      ) {
      } else {
        setToast(false);
        setToastMessage(checkinCheckoutResult && checkinCheckoutResult.message);
      }
    }
  }, [checkinCheckoutResult]);

  React.useEffect(() => {
    if (deleteRoutineActionResult && deleteRoutineActionResult.status === "SUCCESS") {
      const { content = {} } = deleteRoutineActionResult;

      const {
        userIndex,
        serviceIndex,
        zoneIndex,
        routineIndex,
        facilityIndex,
        uniqueServiceId,
        prevId,
      } = checkinCheckoutDropOffData;
      let newScheduleBoardData = _.cloneDeep(scheduleBoardData);

      newScheduleBoardData = clearCheckinCheckoutSpinState(
        newScheduleBoardData,
        userIndex,
        serviceIndex,
        uniqueServiceId,
        zoneIndex,
        routineIndex,
        facilityIndex,
        content.audit_history || []
      );
      setBoardData(newScheduleBoardData);
      setCheckinCheckoutDropOffData({});
      dispatch(setDisableSaveButton(false));
      dispatch(setDisableSaveForStartRoute(false));
      dispatch(setDisableSaveForDropOff(false));
      document.body.click();
    } else if (
      deleteRoutineActionResult &&
      deleteRoutineActionResult.status === "ERROR"
    ) {
      dispatch(setDisableSaveButton(false));
      dispatch(setDisableSaveForStartRoute(false));
      dispatch(setDisableSaveForDropOff(false));
      setCheckinCheckoutDropOffData({});
      if (
        deleteRoutineActionResult.message_code &&
        deleteRoutineActionResult.message_code === "ACCESS_TOKEN_EXPIRED"
      ) {
      } else if (
        deleteRoutineActionResult.message_code === "SESSION_EXPIRED_LOGIN_AGAIN"
      ) {
      } else {
        setToast(false);
        setToastMessage(deleteRoutineActionResult && deleteRoutineActionResult.message);
      }
    }
  }, [deleteRoutineActionResult]);

  const getOnCallBacklogListData = (reg, terr, payloadDate) => {
    if (!_.isEmpty(reg) && !_.isEmpty(terr)) {
      const data = {
        event_date: payloadDate,
        region_id: reg.value,
        territory_id: terr,
      };
      dispatch(getOnCallBacklog(data));
    } else {
      dispatch(updateOnCallBacklogValue({}));
    }
  };

  const getOnCallScheduleListData = (
    reg,
    terr,
    managers,
    payloadDate,
    phlebs = []
  ) => {
    if (!_.isEmpty(reg) && !_.isEmpty(terr) && !_.isEmpty(managers)) {
      const data = {
        event_date: payloadDate,
        region_id: reg.value,
        territory_id: terr,
        reporting_manager_id: managers,
        user_id: phlebs,
      };
      dispatch(getOnCallScheduleList(data));
    }
  };

  React.useEffect(() => {
    if (onCallScheduleList && onCallScheduleList.status) {
      if (onCallScheduleList.status === "SUCCESS") {
        let { content = [] } = onCallScheduleList;
        setOnCallBoardData(content || []);
        setInitialOnCallScheduleBoardData(content || []);
        setRefreshLoader(false);
      } else if (
        onCallScheduleList.status === "ERROR" &&
        onCallScheduleList.message
      ) {
        setToast(false);
        setToastMessage(onCallScheduleList.message);
      }
    } else {
      setOnCallBoardData([]);
    }
  }, [onCallScheduleList]);

  React.useEffect(() => {
    setComponentFilter(reduxState);
  }, [reduxState]);

  React.useEffect(() => {
    if (updateEventResponse) {
      const {
        status,
        message_code,
        content = {},
        message = "",
      } = updateEventResponse || {};
      if (status === "SUCCESS" && Object.keys(content).length > 0) {
        const {
          service_index,
          user_index,
          zone_id,
          event_id,
          facility_ids = [],
          zone_ids = [],
          uniqueServiceId,
          service_type_id,
          isTimingChanged = false,
          tabType = "routine",
          territory_id,
          person_id,
          serviceTypeForSurgeAndNonService,
          serviceTypeForSurgeAndNonServiceHasValue,
          block_id,
          is2X,
          notes,
          facilityIndex = -1,
        } = upsertData;
        if (tabType === "onCall") {
          if (serviceTypeForSurgeAndNonServiceHasValue) {
            dispatch(
              setOnCallSurgeModalData({
                [`${person_id}|${serviceTypeForSurgeAndNonService}|${block_id}`]:
                {
                  ...onCallSurgeModal[
                  `${person_id}|${serviceTypeForSurgeAndNonService}|${block_id}`
                  ],
                  modalType: "",
                  searchVal: "",
                },
              })
            );
          }
          let newScheduleBoardData = _.cloneDeep(onCallScheduleBoardData);
          newScheduleBoardData = isTimingChanged
            ? onCallUpdateBlockIdInBoard(
              newScheduleBoardData,
              user_index,
              service_type_id,
              uniqueServiceId,
              content
            )
            : onCallUpdateEventIdInBoard(
              newScheduleBoardData,
              user_index,
              service_index,
              uniqueServiceId,
              territory_id,
              event_id,
              zone_ids[0],
              content
            );
          setInitialOnCallScheduleBoardData(newScheduleBoardData);
          setOnCallBoardData(newScheduleBoardData);
          setInitialBacklogData(onCallBacklogData);
        } else if (tabType === "routine") {
          if (serviceTypeForSurgeAndNonServiceHasValue) {
            dispatch(
              setSurgeOrNonServiceModal({
                [`${person_id}|${serviceTypeForSurgeAndNonService}|${block_id}`]:
                {
                  ...surgeOrNonServiceModal[
                  `${person_id}|${serviceTypeForSurgeAndNonService}|${block_id}`
                  ],
                  modalType: "",
                  searchVal: "",
                },
              })
            );
          }
          let newScheduleBoardData = _.cloneDeep(scheduleBoardData);
          newScheduleBoardData = isTimingChanged
            ? updateBlockIdInBoard(
              newScheduleBoardData,
              user_index,
              service_type_id,
              uniqueServiceId,
              content
            )
            : updateEventIdInBoard(
              newScheduleBoardData,
              user_index,
              service_index,
              uniqueServiceId,
              zone_id,
              event_id,
              facility_ids[0],
              content,
              is2X,
              notes,
              facilityIndex
            );
          setInitialBoardData(newScheduleBoardData);
          setBoardData(newScheduleBoardData);
          setInitialBacklogData(backlogData);
          toggleStopDayModal({ value: false });
          is2X && getScheduleBoardData();
        }
        setUpsertData({});
        if (tabType === "onCall") {
          if (!_.isEmpty(facilityRegion) && !_.isEmpty(facilityTerritory)) {
            const payloadDate = moment(date).format("YYYY-MM-DD");
            getOnCallBacklogListData(
              facilityRegion,
              facilityTerritory.map((val) => parseInt(val.value)),
              payloadDate
            );
          }
        } else {
          !_.isEmpty(facilityRegion) &&
            !_.isEmpty(facilityTerritory) &&
            !_.isEmpty(zone) &&
            handleFacilityCoverage(zone, date);
        }

        dispatch(clearUpdateEventResponse());
        // setBoardData(updatedBoardData);
      } else if (status === "ERROR") {
        if (message_code === "ACCESS_TOKEN_EXPIRED") {
        } else if (
          message_code === "SESSION_EXPIRED_LOGIN_AGAIN" ||
          message_code === "ACCESS_TOKEN_INVALID"
        ) {
        } else {
          if (tabRef.current === "onCall") {
            setOnCallBoardData(initialOnCallScheduleBoardData);
            setOnCallBacklogData(initialOnCallBacklogData);
          } else {
            setBoardData(initialBoardData);
            setBacklogData(initialBacklogData);
          }
          setToast(false);
          setToastMessage(message);
          toggleStopDayModal({ value: false });
          //   window.alert(message);
        }
      }
    }
  }, [updateEventResponse]);

  React.useEffect(() => {
    if (backlogListData && backlogListData.status) {
      if (backlogListData.status === "SUCCESS") {
        let { content = {} } = backlogListData;
        const { services = [] } = content;
        let facilitiesForSurge = constructFacilitiesForSurgeOrNonService(
          content,
          "surge"
        );
        facilitiesForSurge = facilitiesForSurge.map((fac) => {
          return { ...fac, event_type_id: "surge", event_type: SURGE_TYPE };
        });
        setFacilitiesForSurge(facilitiesForSurge);
        setBacklogData(services);
        setInitialBacklogData(services);
        setRefreshLoader(false);
      } else if (
        backlogListData.status === "ERROR" &&
        backlogListData.message
      ) {
        setToast(false);
        setToastMessage(backlogListData.message);
      }
    } else {
      setBacklogData([]);
    }
  }, [backlogListData]);

  React.useEffect(() => {
    if (backlogNonServiceListData && backlogNonServiceListData.status) {
      if (backlogNonServiceListData.status === "SUCCESS") {
        let { content = {} } = backlogNonServiceListData;
        const facilitiesForNonService =
          constructFacilitiesForSurgeOrNonService(content);
        setFacilitiesForNonService(facilitiesForNonService);
      } else if (backlogNonServiceListData.status === "ERROR") {
      }
    } else {
      setBacklogData([]);
    }
  }, [backlogNonServiceListData]);

  React.useEffect(() => {
    if (onCallBacklogListData && onCallBacklogListData.status) {
      if (onCallBacklogListData.status === "SUCCESS") {
        let { content = {} } = onCallBacklogListData;
        const { services = [] } = content;

        let zonesForSurge = constructZonesForSurge(
          content,
          "surge"
        );
        zonesForSurge = zonesForSurge.map((fac) => {
          return { ...fac, event_type_id: "surge", event_type: SURGE_TYPE };
        });
        setZonesForSurge(zonesForSurge);
        setOnCallBacklogData(services);
        setInitialOnCallBacklogData(services);
      } else if (
        onCallBacklogListData.status === "ERROR" &&
        onCallBacklogListData.message
      ) {
        setToast(false);
        setToastMessage(onCallBacklogListData.message);
      }
    } else {
      setOnCallBacklogData([]);
    }
  }, [onCallBacklogListData]);

  React.useEffect(() => {
    if (deleteData) {
      const {
        status,
        message_code,
        content = {},
        message = "",
        error_content = []
      } = deleteData || {};
      if (status === "SUCCESS") {
        if (tabValue === "onCall") {
          //   if (!_.isEmpty(facilityRegion) &&
          //   !_.isEmpty(facilityTerritory)) {
          //   const payloadDate = moment(date).format("YYYY-MM-DD");
          //   getOnCallBacklogListData(facilityRegion, facilityTerritory.map((val) => parseInt(val.value)), payloadDate)
          // }
          dispatch(setOpenPopover(""));
          handleChangeTab("onCall")
          setOnCallDeleteTempData({});
          setStatusModalType({ value: false });
          setInitialOnCallScheduleBoardData(onCallScheduleBoardData);
          setInitialOnCallBacklogData(onCallBacklogData);
        } else {
          // !_.isEmpty(facilityRegion) &&
          // !_.isEmpty(facilityTerritory) &&
          // !_.isEmpty(zone) &&
          // handleFacilityCoverage(zone, date);
          setInitialBoardData(scheduleBoardData);
          setInitialBacklogData(backlogData);
        }
        // setBoardData(updatedBoardData);
      } else if (status === "ERROR") {
        if (message_code === "ACCESS_TOKEN_EXPIRED") {
        } else if (
          message_code === "SESSION_EXPIRED_LOGIN_AGAIN" ||
          message_code === "ACCESS_TOKEN_INVALID"
        ) {
        } else if (message_code === ON_CALL_DELETE_SURGE_WARN) {
          setStatusModalType({
            value: true, status: "confirmation", type: message_code,
            title: error_content
          })
          // setOnCallBoardData(initialOnCallScheduleBoardData);
          // setOnCallBacklogData(initialBacklogData);
        } else {
          if (tabValue === "onCall") {
            setOnCallBoardData(initialOnCallScheduleBoardData);
            setOnCallBacklogData(initialOnCallBacklogData);
          } else {
            setBoardData(initialBoardData);
            setBacklogData(initialBacklogData);
          }
          setToast(false);
          setToastMessage(message);
        }
      }
    }
  }, [deleteData]);

  React.useEffect(() => {
    if (deleteServiceResponse) {
      const {
        status,
        message_code,
        message = "",
      } = deleteServiceResponse || {};
      if (status === "SUCCESS") {
        setInitialBoardData(scheduleBoardData);
        setInitialBacklogData(backlogData);
      } else if (status === "ERROR") {
        if (message_code === "ACCESS_TOKEN_EXPIRED") {
        } else if (
          message_code === "SESSION_EXPIRED_LOGIN_AGAIN" ||
          message_code === "ACCESS_TOKEN_INVALID"
        ) {
        } else {
          setBoardData(initialBoardData);
          setBacklogData(initialBacklogData);
          setToast(false);
          setToastMessage(message);
        }
      }
    }
  }, [deleteServiceResponse]);

  React.useEffect(() => {
    if (onCallDeleteServiceResponse) {
      const {
        status,
        message_code,
        message = "",
      } = onCallDeleteServiceResponse || {};
      if (status === "SUCCESS") {
        setInitialOnCallScheduleBoardData(onCallScheduleBoardData);
        setInitialOnCallBacklogData(onCallBacklogData);
      } else if (status === "ERROR") {
        if (message_code === "ACCESS_TOKEN_EXPIRED") {
        } else if (
          message_code === "SESSION_EXPIRED_LOGIN_AGAIN" ||
          message_code === "ACCESS_TOKEN_INVALID"
        ) {
        } else {
          setOnCallBoardData(initialOnCallScheduleBoardData);
          setOnCallBacklogData(initialOnCallBacklogData);
          setToast(false);
          setToastMessage(message);
        }
      }
    }
  }, [onCallDeleteServiceResponse]);

  React.useEffect(() => {
    if (copyScheduleResult) {
      const {
        status,
        message_code,
        content = {},
        message = "",
      } = copyScheduleResult || {};
      if (status === "SUCCESS") {
        toggleCalender(false);
        setStatusModalType({
          ...statusModalType,
          value: false,
          status: "success",
        });
        setModalError("");
        setTimeout(() => setStatusModalType({ value: false }), 2000);
      } else if (status === "ERROR") {
        if (
          message_code === "ACCESS_TOKEN_EXPIRED" ||
          message_code === "ACCESS_TOKEN_INVALID"
        ) {
        } else if (
          message_code === "SESSION_EXPIRED_LOGIN_AGAIN" ||
          message_code === "ACCESS_TOKEN_INVALID"
        ) {
        } else {
          setModalError(message);
          setStatusModalType({ value: false });
        }
      }
    }
  }, [copyScheduleResult]);

  React.useEffect(() => {
    var isNoRecordEnabled =
      document.getElementsByClassName("search-no-records no-records-board");
    if (isNoRecordEnabled && isNoRecordEnabled.length) {
      isNoRecordEnabled[0].parentNode &&
        isNoRecordEnabled[0].parentNode.parentNode && isNoRecordEnabled[0].parentNode.parentNode.classList &&
        isNoRecordEnabled[0].parentNode.parentNode.classList.add("w-100");
    } else {
      var tableDiv = document.getElementsByClassName("scheduler-table");
      if (tableDiv && tableDiv.length) {
        tableDiv[0] && tableDiv[0].classList && tableDiv[0].classList.remove("w-100");
      }
    }
  });

  // React.useEffect(() => {
  // 	['click'].forEach((event) => {
  // 		window.addEventListener(event, () => {
  //       console.log('click');
  //       setPaused(true);
  //       setTimeout(() => {
  //         setPaused(false);
  //       }, 1000)
  // 		});
  // 	});
  // }, [])

  React.useEffect(
    () => () => {
      //clear redux state
      dispatch(clearDeleteServiceResponse());
      dispatch(clearUpdateEventResponse());
      dispatch(clearDeleteEventResponse());
      dispatch(clearOnCallDeleteServiceResponse());
      dispatch(clearCopySchedule());
      dispatch(clearCheckinCheckout());
      dispatch(updateReportingDataVal([]));
      dispatch(updateBacklogValue({}));
      dispatch(updateScheduleListValue([]));
      dispatch(updateOnCallBacklogValue({}));
      dispatch(updateOnCallScheduleListValue([]));
      dispatch(clearDeleteRoutineAction());
      // dispatch(clearBulkCheckinCheckoutResponse());
      document.documentElement.classList.remove(scheduleClass);
    },
    []
  );

  const constructFacilitiesForSurgeOrNonService = (
    backlogData,
    type = "nonserviceday"
  ) => {
    let resultBacklogData = _.cloneDeep(backlogData);
    let result = [];
    resultBacklogData.services.forEach((service, serviceIndex) => {
      if (service.zones) {
        Object.keys(service.zones).forEach((zoneId, zoneIndex) => {
          if (service.zones[zoneId].events) {
            const { events = {} } = service.zones[zoneId];
            Object.keys(events).forEach((eventId) => {
              if (
                (type === "surge" ? eventId !== "willcall" : true) &&
                events[eventId].facilities
              ) {
                const { facilities = [] } = events[eventId];
                facilities.forEach((fac) => {
                  result.push({
                    ...fac,
                    ...service.zones[zoneId],
                    event_type: events[eventId].event_type,
                    event_type_id: events[eventId].event_type_id,
                    service_type: events[eventId].service_type,
                    service_name: service.service_name,
                  });
                });
              }
            });
          }
        });

        let resultZones = resultBacklogData.services[serviceIndex].zones;

        resultBacklogData.services[serviceIndex].zones = _.keyBy(
          resultZones,
          "zone_id"
        );
      }
    });

    return result;
  };

  const constructZonesForSurge = (
    backlogData,
    type = "surge"
  ) => {
    let resultBacklogData = _.cloneDeep(backlogData);
    let result = [];
    resultBacklogData.services.forEach((service, serviceIndex) => {
      if (service.territories) {
        Object.keys(service.territories).forEach((tId, tIndex) => {
          if (service.territories[tId].events) {
            const { events = {} } = service.territories[tId];
            Object.keys(events).forEach((eventId) => {
              if (
                (type === "surge" ? eventId !== "willcall" : true) &&
                events[eventId].zones
              ) {
                const { zones = [] } = events[eventId];
                zones.forEach((z) => {
                  result.push({
                    ...z,
                    ...service.territories[tId],
                    event_type: events[eventId].event_type,
                    event_type_id: events[eventId].event_type_id,
                    service_type: events[eventId].service_type,
                    service_name: service.service_name,
                  });
                });
              }
            });
          }
        });

        let resultZones = resultBacklogData.services[serviceIndex].zones;

        resultBacklogData.services[serviceIndex].zones = _.keyBy(
          resultZones,
          "zone_id"
        );
      }
    });

    return result;
  };

  const handleDeleteService = (
    userId,
    serviceType = 1,
    userIndex,
    service = {},
    allFacilities = []
  ) => {
    if (allFacilities.length) {
      setToast(false);
      setToastMessage(
        "A facility has been assigned to the user, hence cannot be deleted. Please unassign and try again."
      );
    } else {
      let newScheduleBoardData = _.cloneDeep(scheduleBoardData);
      let services = newScheduleBoardData[0].users[userIndex].services;
      const facilitiesToUpdateInBacklog = [];
      const { zones = {}, block_id = null } = service || {};
      Object.keys(zones).forEach((zoneId) => {
        const { events = {} } = zones[zoneId];
        Object.keys(events).forEach((eventId) => {
          const { facilities = [] } = events[eventId];
          facilities.forEach((fac) => {
            facilitiesToUpdateInBacklog.push({
              zoneId,
              eventId,
              facilityId: fac.facility_id,
            });
          });
        });
      });
      services = services.filter(
        (ser, index) => ser.block_id !== service.block_id
      );
      newScheduleBoardData[0].users[userIndex].services = services;

      setBoardData(newScheduleBoardData);

      //updating backlog status
      const templBacklogData = _.cloneDeep(backlogData);
      const backlogService = templBacklogData[0] || {};
      const backlogZones = backlogService.zones || {};
      facilitiesToUpdateInBacklog.forEach((data) => {
        if (backlogZones[data.zoneId]) {
          const backlogEvents = backlogZones[data.zoneId].events || {};
          if (backlogEvents[data.eventId]) {
            const backlogFacilities =
              backlogEvents[data.eventId].facilities || [];
            backlogFacilities.forEach((fac) => {
              if (fac.facility_id == data.facilityId) {
                let { users = [] } = fac;
                users = users.filter((user) => user.user_id != userId);
                fac.users = users;
              }
            });
          }
        }
      });

      setBacklogData(templBacklogData);

      let payload = {
        person_id: userId,
        service_type_id: serviceType || 1,
        event_date: moment(date).format("YYYY-MM-DD"),
        block_id,
      };
      setDeleteServiceData(service.uniqueServiceId);
      dispatch(deleteService(payload));
    }
  };

  const handleDeleteOnCallService = (
    userId,
    serviceType = 1,
    userIndex,
    service = {},
    allZones = []
  ) => {
    if (allZones.length) {
      setToast(false);
      setToastMessage(
        "A zone has been assigned to the user, hence cannot be deleted. Please unassign and try again."
      );
    } else {
      let newOnCallScheduleBoardData = _.cloneDeep(onCallScheduleBoardData);
      let services = newOnCallScheduleBoardData[0].users[userIndex].services;
      const zonesToUpdateInBacklog = [];
      const { territories = {}, block_id = null } = service || {};
      Object.keys(territories).forEach((terrId) => {
        const { events = {} } = territories[terrId];
        Object.keys(events).forEach((eventId) => {
          const { zones = [] } = events[eventId];
          zones.forEach((zone) => {
            zonesToUpdateInBacklog.push({
              terrId,
              eventId,
              zoneId: zone.zone_id,
            });
          });
        });
      });
      services = services.filter(
        (ser, index) => ser.block_id !== service.block_id
      );
      newOnCallScheduleBoardData[0].users[userIndex].services = services;

      setOnCallBoardData(newOnCallScheduleBoardData);

      //updating backlog status
      const templBacklogData = _.cloneDeep(onCallBacklogData);
      const backlogService = templBacklogData[0] || {};
      const backlogTerritories = backlogService.territories || {};
      zonesToUpdateInBacklog.forEach((data) => {
        if (backlogTerritories[data.terrId]) {
          const backlogEvents = backlogTerritories[data.terrId].events || {};
          if (backlogEvents[data.eventId]) {
            const backlogTerritories = backlogEvents[data.eventId].zones || [];
            backlogTerritories.forEach((zone) => {
              if (zone.zone_id == data.zoneId) {
                let { count = 0 } = zone;
                if (count > 0) {
                  count--;
                }
                zone.count = count;
              }
            });
          }
        }
      });

      setOnCallBacklogData(templBacklogData);

      let payload = {
        person_id: userId,
        service_type_id: serviceType || 1,
        event_date: moment(date).format("YYYY-MM-DD"),
        block_id,
      };
      setDeleteServiceData(service.uniqueServiceId);
      dispatch(onCallDeleteService(payload));
    }
  };

  const updateCheckinCheckoutState = (
    scheduleBoardData,
    type,
    userIndex,
    serviceIndex,
    uniqueServiceId,
    zoneIndex,
    routineIndex,
    facilityIndex,
    data = {},
    prevId = -1
  ) => {
    let newScheduleBoardData = _.cloneDeep(scheduleBoardData);
    if (type === "Drop Off") {
      let services = newScheduleBoardData
        ? newScheduleBoardData[0]
          ? newScheduleBoardData[0].users[userIndex]
            ? newScheduleBoardData[0].users[userIndex].services || []
            : []
          : []
        : [];
      let currentServiceIndex = -1;
      services.forEach((ser, index) => {
        if (ser.uniqueServiceId === uniqueServiceId) {
          currentServiceIndex = index;
        }
      });
      if (currentServiceIndex >= 0) {
        const { drop_off = [] } = services[currentServiceIndex] || {};
        let tempDropOff = Array.isArray(drop_off) ? [...drop_off] : [];
        if (drop_off.some((dr) => dr.id == prevId)) {
          tempDropOff = tempDropOff.map((dropOff) => {
            if (dropOff.id === prevId) {
              return {
                ...dropOff,
                ...data,
              };
            }
            return dropOff;
          });
        } else {
          tempDropOff.push(data);
        }
        services[currentServiceIndex].drop_off = tempDropOff;
        if (
          newScheduleBoardData[0] &&
          newScheduleBoardData[0].users &&
          newScheduleBoardData[0].users[userIndex]
        ) {
          newScheduleBoardData[0].users[userIndex].services = services;
        }
        // setBoardData(newScheduleBoardData);
      } else {
        //no drop-off present
        const drop_off = [{ ...data }];
        services.push({
          drop_off,
          service_name: type,
          uniqueServiceId,
        });
        if (
          newScheduleBoardData[0] &&
          newScheduleBoardData[0].users &&
          newScheduleBoardData[0].users[userIndex]
        ) {
          newScheduleBoardData[0].users[userIndex].services = services;
        }
      }
    } else if (type === "Start Route") {
      let services = newScheduleBoardData
        ? newScheduleBoardData[0]
          ? newScheduleBoardData[0].users[userIndex]
            ? newScheduleBoardData[0].users[userIndex].services || []
            : []
          : []
        : [];
      let currentServiceIndex = -1;
      services.forEach((ser, index) => {
        if (ser.uniqueServiceId === uniqueServiceId) {
          currentServiceIndex = index;
        }
      });
      if (currentServiceIndex >= 0) {
        const { start_route = [] } = services[currentServiceIndex] || {};
        let tempStartRoute = Array.isArray(start_route) ? [...start_route] : [];
        if (start_route.length) {
          tempStartRoute[0] = {
            ...tempStartRoute[0],
            time: data.time,
            latitude: data.latitude,
            longitude: data.longitude,
            date: data.action_date,
            id: data.id,
            preferred_time: data.preferred_time,
            timezone: data.timezone,
            preferred_timezone: data.preferred_timezone
          };
        } else {
          tempStartRoute.push({
            ...data, date: data.action_date, id: data.id,
            preferred_time: data.preferred_time,
            timezone: data.timezone,
            preferred_timezone: data.preferred_timezone
          });
        }
        services[currentServiceIndex].start_route = tempStartRoute;
        services[currentServiceIndex].audit_history = data.audit_history;
        if (
          newScheduleBoardData[0] &&
          newScheduleBoardData[0].users &&
          newScheduleBoardData[0].users[userIndex]
        ) {
          newScheduleBoardData[0].users[userIndex].services = services;
        }
        // setBoardData(newScheduleBoardData);
      } else {
        //no route present
        const start_route = [{ ...data, date: data.action_date, id: data.id }];
        services.push({
          start_route,
          service_name: type,
          uniqueServiceId,
          audit_history: data.audit_history,
          preferred_time: data.preferred_time,
          timezone: data.timezone,
          preferred_timezone: data.preferred_timezone
        });
        if (
          newScheduleBoardData[0] &&
          newScheduleBoardData[0].users &&
          newScheduleBoardData[0].users[userIndex]
        ) {
          newScheduleBoardData[0].users[userIndex].services = services;
        }
      }
    } else if (type === "Notes") {
      const services = newScheduleBoardData
        ? newScheduleBoardData[0]
          ? newScheduleBoardData[0].users[userIndex]
            ? newScheduleBoardData[0].users[userIndex].services || []
            : []
          : []
        : [];
      let currentServiceIndex = -1;
      services.forEach((ser, index) => {
        if (ser.uniqueServiceId === uniqueServiceId) {
          currentServiceIndex = index;
        }
      });
      if (currentServiceIndex >= 0) {
        let facilities =
          newScheduleBoardData[0].users[userIndex].services[currentServiceIndex]
            .zones[zoneIndex].events[routineIndex].facilities;
        let currentFacility =
          newScheduleBoardData[0].users[userIndex].services[currentServiceIndex]
            .zones[zoneIndex].events[routineIndex].facilities[facilityIndex];

        currentFacility.event_has_facility_notes = data.notes;
        facilities[facilityIndex] = currentFacility;

        newScheduleBoardData[0].users[userIndex].services[
          currentServiceIndex
        ].zones[zoneIndex].events[routineIndex].facilities = facilities;
      }
    } else {
      const services = newScheduleBoardData
        ? newScheduleBoardData[0]
          ? newScheduleBoardData[0].users[userIndex]
            ? newScheduleBoardData[0].users[userIndex].services || []
            : []
          : []
        : [];
      let currentServiceIndex = -1;
      services.forEach((ser, index) => {
        if (ser.uniqueServiceId === uniqueServiceId) {
          currentServiceIndex = index;
        }
      });
      if (currentServiceIndex >= 0) {
        let facilities =
          newScheduleBoardData[0].users[userIndex].services[currentServiceIndex]
            .zones[zoneIndex].events[routineIndex].facilities;
        let currentFacility =
          newScheduleBoardData[0].users[userIndex].services[currentServiceIndex]
            .zones[zoneIndex].events[routineIndex].facilities[facilityIndex];

        let action = currentFacility.action || [];
        if (action.some((act) => act.action_type == type)) {
          action.forEach((act) => {
            if (act.action_type === type) {
              act.action_datetime = `${data.action_date} ${data.action_time}`;
              act.action_id = data.action_id;
              act.audit_history = data.audit_history;
              act.preferred_datetime = data.preferred_datetime;
              act.timezone = data.timezone;
              act.preferred_timezone = data.preferred_timezone;
            }
          });
        } else {
          action.push({
            ...data,
            action_datetime: `${data.action_date} ${data.action_time}`,
            preferred_datetime: data.preferred_datetime,
            timezone: data.timezone,
            preferred_timezone: data.preferred_timezone
          });
        }
        if (action.some((act) => act.action_type == "Spin")) {
          currentFacility.status = "Spin";
        } else if (!action.some((act) => act.action_type == "Spin") &&
          action.some((act) => act.action_type == "Check Out")) {
          currentFacility.status = "Check Out";
        } else if (
          !action.some((act) => act.action_type == "Check Out") &&
          action.some((act) => act.action_type == "Check In")
        ) {
          currentFacility.status = "Check In";
        }
        currentFacility.action = action;
        currentFacility.audit_history = data.audit_history;
        facilities[facilityIndex] = currentFacility;

        newScheduleBoardData[0].users[userIndex].services[
          currentServiceIndex
        ].zones[zoneIndex].events[routineIndex].facilities = facilities;
        // setBoardData(newScheduleBoardData);
      }
    }
    return newScheduleBoardData;
  };

  const clearCheckinCheckoutSpinState = (
    scheduleBoardData,
    userIndex,
    serviceIndex,
    uniqueServiceId,
    zoneIndex,
    routineIndex,
    facilityIndex,
    data = [],
    prevId = -1
  ) => {
    let newScheduleBoardData = _.cloneDeep(scheduleBoardData);
    const services = newScheduleBoardData
      ? newScheduleBoardData[0]
        ? newScheduleBoardData[0].users[userIndex]
          ? newScheduleBoardData[0].users[userIndex].services || []
          : []
        : []
      : [];
    let currentServiceIndex = -1;
    services.forEach((ser, index) => {
      if (ser.uniqueServiceId === uniqueServiceId) {
        currentServiceIndex = index;
      }
    });
    if (currentServiceIndex >= 0) {
      let facilities =
        newScheduleBoardData[0].users[userIndex].services[currentServiceIndex]
          .zones[zoneIndex].events[routineIndex].facilities;
      let currentFacility =
        newScheduleBoardData[0].users[userIndex].services[currentServiceIndex]
          .zones[zoneIndex].events[routineIndex].facilities[facilityIndex];


      let action = currentFacility.action || [];
      action = action.filter((act) => act.action_type != "Spin" && act.action_type != "Check In" && act.action_type != "Check Out")
      currentFacility.action = action;
      currentFacility.status = "";
      currentFacility.audit_history = data;
      facilities[facilityIndex] = currentFacility;

      newScheduleBoardData[0].users[userIndex].services[
        currentServiceIndex
      ].zones[zoneIndex].events[routineIndex].facilities = facilities;
      // setBoardData(newScheduleBoardData);
    }
    return newScheduleBoardData;
  };

  const updateEventIdInBoard = (
    scheduleBoardData,
    userIndex,
    serviceIndex,
    uniqueServiceId,
    zoneIndex,
    routineIndex,
    facilityId,
    data = {},
    is2X,
    notes,
    facilityIndex
  ) => {
    let newScheduleBoardData = _.cloneDeep(scheduleBoardData);
    if (
      !_.isEmpty(upsertData) &&
      userIndex !== undefined &&
      serviceIndex !== undefined &&
      zoneIndex !== undefined &&
      routineIndex !== undefined &&
      facilityId
    ) {
      let services = newScheduleBoardData[0].users[userIndex].services || [];

      if (uniqueServiceId) {
        let currentService = {};
        let currentServiceIndex = -1;
        services.forEach((ser, index) => {
          if (ser.uniqueServiceId === uniqueServiceId) {
            currentService = ser;
            currentServiceIndex = index;
          }
        });
        currentService.block_id = data.block_id;

        services[currentServiceIndex] = currentService;
        if (currentServiceIndex >= 0) {
          let events = services[currentServiceIndex].zones[zoneIndex].events;

          const currentEvent = events[routineIndex] || {};
          let { facilities = [] } = currentEvent;
          currentEvent.event_id = data.id;
          if (is2X) {
            if (facilities[facilityIndex]) {
              facilities[facilityIndex].event_has_facility_id =
                data.event_has_facility_id || "";
              facilities[facilityIndex].event_has_facility_notes = notes || "";
            }
          } else {
            if (
              facilities.some((facility) => facility.facility_id === facilityId)
            ) {
              let currentFacilityIndex = -1;
              facilities.forEach((fac, idx) => {
                if (fac.facility_id === facilityId) {
                  currentFacilityIndex = idx;
                }
              });
              if (currentFacilityIndex > -1) {
                facilities[currentFacilityIndex].event_has_facility_id =
                  data.event_has_facility_id || "";
              }
            }
          }
          currentEvent.facilities = facilities;
          events[routineIndex] = currentEvent;
          newScheduleBoardData[0].users[userIndex].services[
            currentServiceIndex
          ].zones[zoneIndex].events = events;
        }
      } else {
        let currentService = services[serviceIndex] || {};
        currentService.block_id = data.block_id;

        services[serviceIndex] = currentService;

        let events = services[serviceIndex].zones[zoneIndex].events;

        const currentEvent = events[routineIndex] || {};
        const { facilities = [] } = currentEvent;
        currentEvent.event_id = data.id;
        if (is2X) {
          if (facilities[facilityIndex]) {
            facilities[facilityIndex].event_has_facility_id =
              data.event_has_facility_id || "";
            facilities[facilityIndex].event_has_facility_notes = notes || "";
          }
        } else {
          if (
            facilities.some((facility) => facility.facility_id === facilityId)
          ) {
            let currentFacilityIndex = -1;
            facilities.forEach((fac, idx) => {
              if (fac.facility_id === facilityId) {
                currentFacilityIndex = idx;
              }
            });
            if (currentFacilityIndex > -1) {
              facilities[currentFacilityIndex].event_has_facility_id =
                data.event_has_facility_id || "";
            }
          }
        }
        currentEvent.facilities = facilities;
        events[routineIndex] = currentEvent;
        newScheduleBoardData[0].users[userIndex].services[serviceIndex].zones[
          zoneIndex
        ].events = events;
      }
    }
    return newScheduleBoardData;
  };

  const updateBlockIdInBoard = (
    scheduleBoardData,
    userIndex,
    serviceType,
    serviceUniqueId,
    data = {}
  ) => {
    let newScheduleBoardData = _.cloneDeep(scheduleBoardData);
    if (
      !_.isEmpty(upsertData) &&
      userIndex !== undefined &&
      serviceType !== undefined &&
      serviceUniqueId
    ) {
      let services = newScheduleBoardData[0].users[userIndex].services || [];
      let tempServices = [...services];
      if (services.some((service) => service.service_type === serviceType)) {
        tempServices.forEach((service) => {
          if (service.uniqueServiceId === serviceUniqueId) {
            service.block_id = data.block_id;
          }
        });
        newScheduleBoardData[0].users[userIndex].services = tempServices;
      }
    }
    return newScheduleBoardData;
  };

  const onCallUpdateEventIdInBoard = (
    scheduleBoardData,
    userIndex,
    serviceIndex,
    uniqueServiceId,
    terrIndex,
    routineIndex,
    zoneId,
    data = {}
  ) => {
    let newScheduleBoardData = _.cloneDeep(scheduleBoardData);
    if (
      !_.isEmpty(upsertData) &&
      userIndex !== undefined &&
      serviceIndex !== undefined &&
      terrIndex !== undefined &&
      routineIndex !== undefined &&
      zoneId
    ) {
      let services = newScheduleBoardData[0].users[userIndex].services || [];

      if (uniqueServiceId) {
        let currentService = {};
        let currentServiceIndex = -1;
        services.forEach((ser, index) => {
          if (ser.uniqueServiceId === uniqueServiceId) {
            currentService = ser;
            currentServiceIndex = index;
          }
        });
        currentService.block_id = data.block_id;

        services[currentServiceIndex] = currentService;
        if (currentServiceIndex >= 0) {
          let events =
            services[currentServiceIndex].territories[terrIndex].events;

          const currentEvent = events[routineIndex] || {};
          let { zones = [] } = currentEvent;
          if (zones.some((zone) => zone.zone_id === zoneId)) {
            currentEvent.event_id = data.id;

            let currentZoneIndex = -1;
            zones.forEach((zone, idx) => {
              if (zone.zone_id === zoneId) {
                currentZoneIndex = idx;
              }
            });
            if (currentZoneIndex > -1) {
              zones[currentZoneIndex].event_has_facility_id =
                data.event_has_facility_id || "";
            }

            currentEvent.zones = zones;
          }
          events[routineIndex] = currentEvent;
          newScheduleBoardData[0].users[userIndex].services[
            currentServiceIndex
          ].territories[terrIndex].events = events;
        }
      } else {
        let currentService = services[serviceIndex] || {};
        currentService.block_id = data.block_id;

        services[serviceIndex] = currentService;

        let events = services[serviceIndex].territories[terrIndex].events;

        const currentEvent = events[routineIndex] || {};
        const { zones = [] } = currentEvent;
        if (zones.some((zone) => zone.zone_id === zoneId)) {
          currentEvent.event_id = data.id;

          let currentZoneIndex = -1;
          zones.forEach((zone, idx) => {
            if (zone.zone_id === zoneId) {
              currentZoneIndex = idx;
            }
          });
          if (currentZoneIndex > -1) {
            zones[currentZoneIndex].event_has_facility_id =
              data.event_has_facility_id || "";
          }

          currentEvent.zones = zones;
        }
        events[routineIndex] = currentEvent;
        newScheduleBoardData[0].users[userIndex].services[
          serviceIndex
        ].territories[terrIndex].events = events;
      }
    }
    return newScheduleBoardData;
  };

  const onCallUpdateBlockIdInBoard = (
    scheduleBoardData,
    userIndex,
    serviceType,
    serviceUniqueId,
    data = {}
  ) => {
    let newScheduleBoardData = _.cloneDeep(scheduleBoardData);
    if (
      !_.isEmpty(upsertData) &&
      userIndex !== undefined &&
      serviceType !== undefined &&
      serviceUniqueId
    ) {
      let services = newScheduleBoardData[0].users[userIndex].services || [];
      let tempServices = [...services];
      if (services.some((service) => service.service_type === serviceType)) {
        tempServices.forEach((service) => {
          if (service.uniqueServiceId === serviceUniqueId) {
            service.block_id = data.block_id;
          }
        });
        newScheduleBoardData[0].users[userIndex].services = tempServices;
      }
    }
    return newScheduleBoardData;
  };

  const constructPyloadForCheckinCheckout = (
    formData,
    userIndex,
    serviceIndex,
    uniqueServiceId,
    zoneIndex = -1,
    routineIndex = -1,
    facilityIndex = -1,
    prevId = -1
  ) => {
    // const finalPayload = {
    //   ...payload,
    //   action_date: moment(date).format("YYYY-MM-DD")
    // }
    setCheckinCheckoutDropOffData({
      userIndex,
      serviceIndex,
      uniqueServiceId,
      zoneIndex,
      routineIndex,
      facilityIndex,
      prevId,
    });
    dispatch(checkinCheckoutApiResponse(formData));
  };

  const constructPayloadToClearCheckinCheckoutSpin = (
    formData,
    userIndex,
    serviceIndex,
    uniqueServiceId,
    zoneIndex = -1,
    routineIndex = -1,
    facilityIndex = -1,
    prevId = -1
  ) => {
    setCheckinCheckoutDropOffData({
      userIndex,
      serviceIndex,
      uniqueServiceId,
      zoneIndex,
      routineIndex,
      facilityIndex,
      prevId,
    });
    dispatch(deleteRoutineActionApiResponse(formData));
  };

  const clean = (object) => {
    Object.entries(object).forEach(([k, v]) => {
      if (v && typeof v === "object") {
        clean(v);
      }
      if (
        (v && typeof v === "object" && !Object.keys(v).length) ||
        v === null ||
        v === undefined
      ) {
        if (Array.isArray(object)) {
          object.splice(k, 1);
        } else {
          delete object[k];
        }
      }
    });
    return object;
  };
  const handleDelete = (
    userIndex,
    uniqueServiceId,
    zoneIndex,
    routineIndex,
    facilityIndex,
    facilityId,
    type
  ) => {
    let newScheduleBoardData = _.cloneDeep(scheduleBoardData);
    let currentServiceIndex = -1;
    let currentService = {};
    let tempBlockId = null;

    let services = newScheduleBoardData[0].users[userIndex].services || [];
    services.forEach((ser, index) => {
      if (ser.uniqueServiceId === uniqueServiceId) {
        currentServiceIndex = index;
        currentService = ser;
        tempBlockId = ser.block_id;
      }
    });
    if (currentServiceIndex >= 0) {
      const currentUser = newScheduleBoardData[0].users[userIndex] || {};
      const currentEvent =
        currentService.zones[zoneIndex].events[routineIndex] || {};

      let facilities =
        currentService.zones[zoneIndex].events[routineIndex].facilities;

      facilities.splice(facilityIndex, 1);

      if (facilities.length === 0) {
        //removing event
        let sourceSchedules = currentService.zones[zoneIndex].events;

        delete sourceSchedules[routineIndex];

        if (_.isEmpty(sourceSchedules)) {
          //remove zone
          let sourceZones = currentService.zones;
          delete sourceZones[zoneIndex];
          // currentService.zones = sourceZones;
          if (_.isEmpty(sourceZones)) {
            services = services.filter(
              (ser) => ser.uniqueServiceId !== uniqueServiceId
            );
            newScheduleBoardData[0].users[userIndex].services = services;
          } else {
            currentService.zones = sourceZones;
            // currentService.block_id = "";
            currentService = {};
          }
        } else {
          currentService.zones[zoneIndex].events = sourceSchedules;
        }
      } else {
        currentService.zones[zoneIndex].events[routineIndex].facilities =
          facilities;
      }
      setBoardData(newScheduleBoardData);

      //updating backlog status
      const templBacklogData = _.cloneDeep(backlogData);
      const backlogService = templBacklogData[0] || {};
      const backlogZones = backlogService.zones || {};
      if (backlogZones[zoneIndex]) {
        const backlogEvents = backlogZones[zoneIndex].events || {};
        if (backlogEvents[routineIndex]) {
          const backlogFacilities =
            backlogEvents[routineIndex].facilities || [];
          backlogFacilities.forEach((fac) => {
            if (fac.facility_id == facilityId) {
              let { users = [] } = fac;
              users = users.filter(
                (user) => user.user_id != currentUser.user_id
              );
              fac.users = users;
            }
          });
        }
      }
      setBacklogData(templBacklogData);
      let data = {
        person_id: currentUser.user_id,
        service_type_id: currentEvent.service_type || 1,
        event_date: moment(date).format("YYYY-MM-DD"),
        facility_ids: [facilityId],
        block_id: tempBlockId || null,
        force_delete: false,
      };
      dispatch(deleteEvent({ data, type }));
    }
  };

  const handleDeleteOnCall = (
    userIndex,
    uniqueServiceId,
    territoryIndex,
    routineIndex,
    zoneIndex,
    zoneId,
    type
  ) => {
    let newScheduleBoardData = _.cloneDeep(onCallScheduleBoardData);
    let currentServiceIndex = -1;
    let currentService = {};
    let tempBlockId = null;

    let services = newScheduleBoardData[0].users[userIndex].services || [];

    services.forEach((ser, index) => {
      if (ser.uniqueServiceId === uniqueServiceId) {
        currentServiceIndex = index;
        currentService = ser;
        tempBlockId = ser.block_id;
      }
    });
    if (currentServiceIndex >= 0) {
      const currentUser = newScheduleBoardData[0].users[userIndex] || {};
      const currentEvent =
        currentService.territories[territoryIndex].events[routineIndex] || {};

      let zones =
        currentService.territories[territoryIndex].events[routineIndex].zones;

      zones.splice(zoneIndex, 1);

      if (zones.length === 0) {
        //removing event
        let sourceSchedules = currentService.territories[territoryIndex].events;

        delete sourceSchedules[routineIndex];

        if (_.isEmpty(sourceSchedules)) {
          //remove terr
          let sourceTerritories = currentService.territories;
          delete sourceTerritories[territoryIndex];

          if (_.isEmpty(sourceTerritories)) {
            services = services.filter(
              (ser) => ser.uniqueServiceId !== uniqueServiceId
            );
            newScheduleBoardData[0].users[userIndex].services = services;
          } else {
            currentService.territories = sourceTerritories;
            // currentService.block_id = "";
            currentService = {};
          }
        } else {
          currentService.territories[territoryIndex].events = sourceSchedules;
        }
      } else {
        currentService.territories[territoryIndex].events[routineIndex].zones =
          zones;
      }
      setOnCallBoardData(newScheduleBoardData);

      //updating backlog status
      const templBacklogData = _.cloneDeep(onCallBacklogData);
      const backlogService = templBacklogData[0] || {};
      const backlogTerritories = backlogService.territories || {};
      if (backlogTerritories[territoryIndex]) {
        const backlogEvents = backlogTerritories[territoryIndex].events || {};
        if (backlogEvents[routineIndex]) {
          const backlogZones = backlogEvents[routineIndex].zones || [];
          backlogZones.forEach((zone) => {
            if (zone.zone_id == zoneId) {
              let { count = 0, users } = zone;
              if (count > 0) {
                users.splice(userIndex, 1);
                count--;
              }
              zone.count = count;
              zone.users = users;
            }
          });
        }
      }
      setOnCallBacklogData(templBacklogData);
      let data = {
        person_id: currentUser.user_id,
        service_type_id: currentEvent.service_type || 1,
        event_date: moment(date).format("YYYY-MM-DD"),
        zone_ids: [zoneId],
        block_id: tempBlockId || null,
        force_delete: false
      };
      setOnCallDeleteTempData({ data, type, tab: "onCall" });
      dispatch(deleteEvent({ data, type, tab: "onCall" }));
    }
  };

  const handleEventForManager = (id, newDate, allPhlebs = []) => {
    const payloadDate = moment(newDate).format("YYYY-MM-DD");
    const managers = id.map((val) => parseInt(val.value)) || [];
    const data = {
      event_date: payloadDate,
      region_id: facilityRegion.value,
      territory_id: facilityTerritory.map((val) => parseInt(val.value)),
      reporting_manager_id: managers,
      user_id: allPhlebs,
    };
    // if (allPhlebs.length) {
    if (tabRef.current === "onCall") {
      managers && managers.length > 0 && dispatch(getOnCallScheduleList(data));
    } else {
      managers && managers.length > 0 && dispatch(scheduleList(data));
    }
    // }
  };

  const logout = () => {
    window.localStorage.removeItem("userData");
    window.localStorage.removeItem("userDetail");
    // window.localStorage.removeItem("scheduleFilter");
    window.location.href = "/";
  };

  const handleAuto = (ignoreModals = false) => {
    const isSessionModalOpen =
      document.getElementsByClassName("session-modal") &&
      document.getElementsByClassName("session-modal").length;

    const isPopverOpen =
      document.getElementsByClassName("sch-popover-container") &&
      document.getElementsByClassName("sch-popover-container").length;

    const isAddTimeModalOpen =
      document.getElementsByClassName("time-block-open") &&
      document.getElementsByClassName("time-block-open").length;

    const isSurgeOrNonServiceModalOpen =
      document.getElementsByClassName("surge-non-service-search-div") &&
      document.getElementsByClassName("surge-non-service-search-div").length;

    const isCountPopoverOpen =
      document.getElementsByClassName("sch-countlist-popover") &&
      document.getElementsByClassName("sch-countlist-popover").length;

    const isConfirmationModalOpen =
      document.getElementsByClassName("sc-copycal-modal") &&
      document.getElementsByClassName("sc-copycal-modal").length;

    const payloadDate = moment(date).format("YYYY-MM-DD");
    if (ignoreModals ||
      (!isSessionModalOpen &&
        !isPopverOpen &&
        !isAddTimeModalOpen &&
        !isSurgeOrNonServiceModalOpen &&
        !isCountPopoverOpen && !isConfirmationModalOpen)
    ) {
      if (tabRef.current === "routine" || tabRef.current === TYPE_HOMEHEALTH) {
        if (
          !_.isEmpty(facilityRegion) &&
          !_.isEmpty(facilityTerritory) &&
          !_.isEmpty(zone)
        ) {
          let zoneIds = zone.map((val) => parseInt(val.value));
          const data = {
            event_date: payloadDate,
            region_id: facilityRegion.value,
            territory_id: facilityTerritory.map((val) => parseInt(val.value)),
            zone_id: zoneIds,
          };
          dispatch(backlog(data));
        } else {
          dispatch(updateBacklogValue({}));
          setRefreshLoader(false);
        }

        if (
          !_.isEmpty(facilityRegion) &&
          !_.isEmpty(facilityTerritory) &&
          !_.isEmpty(manager)
        ) {
          let managers = manager.map((val) => parseInt(val.value));
          const data = {
            event_date: payloadDate,
            region_id: facilityRegion.value,
            territory_id: facilityTerritory.map((val) => parseInt(val.value)),
            reporting_manager_id: managers,
            user_id: phlebs
              ? phlebs.length
                ? phlebs.map((val) => parseInt(val.value))
                : []
              : [],
          };
          dispatch(scheduleList(data));
        } else {
          dispatch(updateScheduleListValue([]));
          setRefreshLoader(false);
        }
      }

      //fetching on call backlog and list
      if (tabRef.current === "onCall") {
        if (!_.isEmpty(facilityRegion) && !_.isEmpty(facilityTerritory)) {
          const data = {
            event_date: payloadDate,
            region_id: facilityRegion.value,
            territory_id: facilityTerritory.map((val) => parseInt(val.value)),
          };
          dispatch(getOnCallBacklog(data));
        } else {
          dispatch(updateOnCallBacklogValue({}));
          setRefreshLoader(false);
        }
        // fetching on call list
        if (
          !_.isEmpty(facilityRegion) &&
          !_.isEmpty(facilityTerritory) &&
          !_.isEmpty(manager)
        ) {
          let managers = manager.map((val) => parseInt(val.value));
          const data = {
            event_date: payloadDate,
            region_id: facilityRegion.value,
            territory_id: facilityTerritory.map((val) => parseInt(val.value)),
            reporting_manager_id: managers,
            user_id: phlebs
              ? phlebs.length
                ? phlebs.map((val) => parseInt(val.value))
                : []
              : [],
          };
          dispatch(getOnCallScheduleList(data));
        } else {
          dispatch(updateOnCallScheduleListValue([]));
          setRefreshLoader(false);
        }
      }
    }
  };

  const onDragEnd = (result) => {
    setPaused(false);
    setShowDropArea(false);
    // const dragClass = document.getElementsByClassName('scheduler-grid');
    // if (dragClass && dragClass[0] && dragClass[0].classList && dragClass[0].classList.contains('in-drag-mode')) {
    //   removeClassOnDragEnd()
    // }
    // dropped outside the list
    if (!result.destination) {
      //console.log("no-change");
      return;
    }
    const { source = {}, destination = {} } = result;
    if (
      source.droppableId === destination.droppableId &&
      source.droppableId.startsWith(BACKLOG_SECTION_TYPE) &&
      destination.droppableId.startsWith(BACKLOG_SECTION_TYPE)
    ) {
      return;
    }

    const dropResponse =
      handleDropEvent(
        result,
        initialBoardData,
        scheduleBoardData,
        backlogData
      ) || {};
    const {
      newScheduleBoardData,
      payload = {},
      resultBacklogData,
    } = dropResponse;
    if (Object.keys(payload).length > 0) {
      if (payload.is2X) {
        toggleStopDayModal({
          value: true,
          error: "",
          facility: payload.facilityData,
          dropResponse,
          dropType: "",
        });
      } else {
        continueDroppingFacility(dropResponse, false, 0, "");
      }
    } else {
      setBoardData(newScheduleBoardData);
      setBacklogData(resultBacklogData);
    }
  };

  const continueDroppingFacility = (
    dropResponse,
    is2X = false,
    stopDay,
    type
  ) => {
    const {
      newScheduleBoardData,
      payload = {},
      sectionType = "scheduleBoard",
      resultBacklogData,
    } = dropResponse;
    payload["event_date"] = moment(date).format("YYYY-MM-DD");
    payload["current_event_date"] =
      sectionType === "scheduleBoard"
        ? moment(date).format("YYYY-MM-DD")
        : null;
    if (is2X) {
      payload.notes = stopDay;
    }

    const tempPayload = _.cloneDeep(payload);
    setUpsertData(tempPayload);
    setBoardData(newScheduleBoardData);
    setBacklogData(resultBacklogData);
    delete payload["zone_id"];
    delete payload["event_id"];
    delete payload["service_index"];
    delete payload["user_index"];
    delete payload["user_index"];
    delete payload["facilityIndex"];
    payload["is2X"] && delete payload["is2X"];
    payload["facilityData"] && delete payload["facilityData"];
    !tempPayload.block_id && setDeleteServiceData(tempPayload.uniqueServiceId);
    updateEventHandler({ data: payload, type });
  };

  const onDragEndOnCall = (result) => {
    setPaused(false);
    setShowDropArea(false);
    // const dragClass = document.getElementsByClassName('scheduler-grid');
    // if (dragClass && dragClass[0] && dragClass[0].classList && dragClass[0].classList.contains('in-drag-mode')) {
    //   removeClassOnDragEnd()
    // }
    // dropped outside the list
    if (!result.destination) {
      //console.log("no-change");
      return;
    }
    const { source = {}, destination = {} } = result;
    if (
      source.droppableId === destination.droppableId &&
      source.droppableId.startsWith(BACKLOG_SECTION_TYPE) &&
      destination.droppableId.startsWith(BACKLOG_SECTION_TYPE)
    ) {
      return;
    }

    const {
      newOnCallScheduleBoardData,
      payload = {},
      sectionType = "scheduleBoard",
      resultBacklogData,
      errorMessage,
    } = handleDropEventOnCall(
      result,
      initialOnCallScheduleBoardData,
      onCallScheduleBoardData,
      onCallBacklogData
    );
    if (Object.keys(payload).length > 0) {
      payload["event_date"] = moment(date).format("YYYY-MM-DD");
      payload["current_event_date"] =
        sectionType === "scheduleBoard"
          ? moment(date).format("YYYY-MM-DD")
          : null;

      const tempPayload = _.cloneDeep(payload);
      setUpsertData({ ...tempPayload, tabType: "onCall" });
      setOnCallBoardData(newOnCallScheduleBoardData);
      setOnCallBacklogData(resultBacklogData);
      delete payload["territory_id"];
      delete payload["event_id"];
      delete payload["service_index"];
      delete payload["user_index"];
      !tempPayload.block_id &&
        setDeleteServiceData(tempPayload.uniqueServiceId);
      updateEventHandler({ data: payload, type: "" });
    } else {
      if (errorMessage) {
        setToast(false);
        setToastMessage(errorMessage);
      }
      setOnCallBoardData(newOnCallScheduleBoardData);
      setOnCallBacklogData(resultBacklogData);
    }
  };

  const onDragUpdate = (e) => {
    !paused && setPaused(true);
    // const dragClass = document.querySelector('scheduler-grid');
    // if (!dragClass || !dragClass.classList || !dragClass.classList.contains('in-drag-mode')) {
    //   addClassWhileDragging()
    // }
  };

  const updateBoardData = (userIndex, payload, serviceName) => {
    if (scheduleBoardData) {
      let newScheduleBoardData = _.cloneDeep(scheduleBoardData);
      const service = {
        end_time: payload.end_time,
        start_time: payload.start_time,
        service_type: payload.service_type_id,
        service_name: serviceName,
        description: payload.description,
        uniqueServiceId: payload.uniqueServiceId,
        timezone: payload.timezoneData ? { ...payload.timezoneData, timezone_name: payload.timezone_label, timezone_id: payload.timezone_id } : { timezone_name: payload.timezone_label, timezone_id: payload.timezone_id }
      };
      let services = newScheduleBoardData[0].users[userIndex].services || [];
      if (
        services.some((ser) => ser.service_name == serviceName) &&
        (serviceName === "On Call" || serviceName === "Routine Draws"
          ? payload.block_id
          : true)
      ) {
        let sIndex = -1;
        services.forEach((s, index) => {
          if (s.block_id == payload.block_id) {
            sIndex = index;
          }
        });
        services[sIndex] = { ...services[sIndex], ...service };
      } else {
        services.push(service);
      }
      services = services.filter((s) => s);

      // sort services
      services = sortServices(services);
      newScheduleBoardData[0].users[userIndex].services = services;
      setBoardData(newScheduleBoardData);

      const tempPayload = _.cloneDeep(payload);
      tempPayload.user_index = userIndex;
      tempPayload.isTimingChanged = true;
      setUpsertData({ ...tempPayload, tabType: "routine" });

      let newPayload = _.cloneDeep(payload);
      newPayload.timezoneData && delete newPayload.timezoneData;

      updateEventHandler({ data: newPayload, type: "" });
    }
  };

  const updateBoardDataOnCall = (userIndex, payload, serviceName) => {
    if (onCallScheduleBoardData) {
      let newScheduleBoardData = _.cloneDeep(onCallScheduleBoardData);
      const service = {
        end_time: payload.end_time,
        start_time: payload.start_time,
        service_type: payload.service_type_id,
        service_name: serviceName,
        description: payload.description,
        uniqueServiceId: payload.uniqueServiceId,
        shift_timings: payload.shift_allocation_id ? { shift_name: payload.shift_name, shift_allocation_id: payload.shift_allocation_id } : "",
        timezone: payload.timezoneData ? { ...payload.timezoneData, timezone_name: payload.timezone_label, timezone_id: payload.timezone_id } : { timezone_name: payload.timezone_label, timezone_id: payload.timezone_id }
      };
      let services = newScheduleBoardData[0].users[userIndex].services || [];
      if (
        services.some((ser) => ser.service_name == serviceName) &&
        (serviceName === SCHEDULE_SERVICE_TYPE.ON_CALL.NAME || serviceName === SCHEDULE_SERVICE_TYPE.NO_COVERAGE.NAME ? payload.block_id : true)
      ) {
        let sIndex = -1;
        services.forEach((s, index) => {
          if (s.block_id == payload.block_id) {
            sIndex = index;
          }
        });
        services[sIndex] = { ...services[sIndex], ...service };
      } else {
        services.push(service);
      }
      services = services.filter((s) => s);

      // sort services
      services = sortServices(services, "onCall");
      newScheduleBoardData[0].users[userIndex].services = services;
      setOnCallBoardData(newScheduleBoardData);

      const tempPayload = _.cloneDeep(payload);
      tempPayload.user_index = userIndex;
      tempPayload.isTimingChanged = true;
      setUpsertData({ ...tempPayload, tabType: "onCall" });

      let newPayload = _.cloneDeep(payload);
      newPayload.timezoneData && delete newPayload.timezoneData;

      updateEventHandler({ data: newPayload, type: "" });
    }
  };

  const sortServices = (services = [], type = "routine") => {
    let routineDraws = [];
    let startRoutes = [];
    let onCalls = [];
    let noCoverages = [];
    let homeHealths = [];
    let dropOffs = [];
    if (type !== "onCall") {
      if (
        services.some((service) => service.service_name === "Routine Draws")
      ) {
        services.forEach((service) => {
          if (service.service_name === "Routine Draws") {
            routineDraws.push(service);
          }
        });
        routineDraws = routineDraws.sort(
          (a, b) =>
            a.start_time &&
            b.start_time &&
            a.start_time.localeCompare(b.start_time)
        );
      }
      if (services.some((service) => service.service_type == 4)) {
        let serviceData = {};
        services.forEach((service) => {
          if (service.service_name === "Home Health") {
            serviceData = service;
          }
        });
        homeHealths.push(serviceData);
        homeHealths = homeHealths.sort(
          (a, b) =>
            a.start_time &&
            b.start_time &&
            a.start_time.localeCompare(b.start_time)
        );
      }
      if (services.some((service) => service.service_name === "Start Route")) {
        services.forEach((service) => {
          if (service.service_name === "Start Route") {
            startRoutes.push(service);
          }
        });
      }
    }
    if (services.some((service) => service.service_name === "On Call" || service.service_name === "No Coverage")) {
      services.forEach((service) => {
        if (service.service_name === "On Call") {
          onCalls.push(service);
        }
      });
      services.forEach((service) => {
        if (service.service_name === "No Coverage") {
          noCoverages.push(service);
        }
      });
      noCoverages = noCoverages.sort(
        (a, b) =>
          a.start_time &&
          b.start_time &&
          a.start_time.localeCompare(b.start_time)
      );
      onCalls = onCalls.sort(
        (a, b) =>
          a.start_time &&
          b.start_time &&
          a.start_time.localeCompare(b.start_time)
      );
    }
    if (services.some((service) => service.drop_off)) {
      let serviceData = {};
      services.forEach((service) => {
        if (service.drop_off) {
          serviceData = service;
        }
      });
      dropOffs.push(serviceData);
    }
    let orderedServices = [
      ...routineDraws,
      ...homeHealths,
      ...onCalls,
      ...noCoverages,
      ...dropOffs,
      ...startRoutes,
    ];

    return orderedServices;
  };

  const handleGetManager = (e, manager = []) => {
    const data = {
      region_id: facilityRegion.value,
      territory_id: e.map((val) => parseInt(val.value)),
    };
    if (manager) {
      data.reporting_manager_id = manager;
    }
    dispatch(getManager(data));
  };

  const handleGetZones = (event_date, ids = []) => {
    const data = {
      event_date: event_date,
      territory_id: ids,
    };
    dispatch(getZones(data));
  };

  const updateEventHandler = (data) => {
    dispatch(updateEvent(data));
  };

  const handleManager = (e) => {
    dispatch(
      setFilter({ parent: "facilityCoverage", property: "manager", value: e })
    );
    dispatch(
      setFilter({ parent: "facilityCoverage", property: "phlebs", value: [] })
    );
    localStorage.setItem(
      `${username}.scheduleFilter`,
      JSON.stringify({
        facilityCoverage: { ...facilityCoverage, manager: e, phlebs: "" },
        selectedDate: date,
      })
    );
    if (!e || !e.length) {
      if (tabRef.current === "onCall") {
        setOnCallBoardData([]);
      } else {
        setBoardData([]);
      }
    }
  };

  const handlePhleb = (e) => {
    dispatch(
      setFilter({ parent: "facilityCoverage", property: "phlebs", value: e })
    );
    localStorage.setItem(
      `${username}.scheduleFilter`,
      JSON.stringify({
        facilityCoverage: { ...facilityCoverage, phlebs: e },
        selectedDate: date,
      })
    );
    if (!e || !e.length) {
      date &&
        manager &&
        manager.length > 0 &&
        handleEventForManager(manager, date);
    }
  };

  const handleRegion2 = (e) => {
    dispatch(
      setFilter({ parent: "facilityCoverage", property: "region", value: e })
    );
    dispatch(
      setFilter({
        parent: "facilityCoverage",
        property: "territory",
        value: "",
      })
    );
    dispatch(
      setFilter({ parent: "facilityCoverage", property: "zone", value: "" })
    );
    dispatch(
      setFilter({ parent: "facilityCoverage", property: "manager", value: "" })
    );
    dispatch(
      setFilter({ parent: "facilityCoverage", property: "phlebs", value: "" })
    );
    localStorage.setItem(
      `${username}.scheduleFilter`,
      JSON.stringify({
        selectedDate: date,
        facilityCoverage: {
          ...facilityCoverage,
          region: e,
          territory: "",
          zone: "",
          manager: "",
          phlebs: "",
        },
      })
    );
    setSelectedRegion(e);

    if (tabRef.current === "onCall") {
      setOnCallBacklogData([]);
      setInitialOnCallBacklogData([]);
      setOnCallBoardData([]);
      setInitialOnCallScheduleBoardData([]);
    } else {
      setBacklogData([]);
      setInitialBacklogData([]);
      setBoardData([]);
      setInitialBoardData([]);
    }
  };
  const handleTerritory2 = (newValue) => {
    let zones = zonesListData ? zonesListData.content || [] : [];
    zones = zones.filter(z => z.status === "ACTIVE");
    const zone_modified =
      zones &&
      zones.map((val) => {
        return { value: val.id, label: val.name };
      });
    let newZones = [];
    zone &&
      zone.forEach((z) => {
        if (zoneOptions.some((zn) => zn.value === z.value)) {
          newZones.push(z);
        }
      });

    let newManagers = [];
    dispatch(
      setFilter({
        parent: "facilityCoverage",
        property: "territory",
        value: newValue,
      })
    );
    dispatch(
      setFilter({
        parent: "facilityCoverage",
        property: "zone",
        value: newZones,
      })
    );
    dispatch(
      setFilter({
        parent: "facilityCoverage",
        property: "manager",
        value: newManagers,
      })
    );
    dispatch(
      setFilter({ parent: "facilityCoverage", property: "phlebs", value: [] })
    );
    localStorage.setItem(
      `${username}.scheduleFilter`,
      JSON.stringify({
        selectedDate: date,
        facilityCoverage: {
          ...facilityCoverage,
          territory: newValue,
          zone: "",
          manager: "",
          phlebs: "",
        },
      })
    );
    setSelectedTerritory(newValue);
  };
  const handleTerritoryOnBlur = (manager) => {
    if (tabRef.current === "onCall") {
      if (
        facilityRegion &&
        facilityRegion.value &&
        facilityTerritory &&
        !_.isEmpty(facilityRegion) &&
        !_.isEmpty(facilityTerritory)
      ) {
        const payloadDate = moment(date).format("YYYY-MM-DD");
        getOnCallBacklogListData(
          facilityRegion,
          facilityTerritory.map((val) => parseInt(val.value)),
          payloadDate
        );
      } else {
        dispatch(updateOnCallBacklogValue({}));
      }
    }
    if (facilityTerritory && !_.isEmpty(facilityTerritory)) {
      let terrs = facilityTerritory
        ? Array.isArray(facilityTerritory)
          ? facilityTerritory.map((val) => parseInt(val.value))
          : []
        : [];
      const payloadDate = moment(new Date(date)).format(
        "YYYY-MM-DD"
      );
      handleGetManager(facilityTerritory);
      handleGetZones(payloadDate, terrs);
    }
  };
  const handleFacilityCoverage = (zoneId, dd) => {
    let zoneIds = zoneId && zoneId.map((val) => parseInt(val.value));
    const payloadDate = moment(dd).format("YYYY-MM-DD");
    const data = {
      event_date: payloadDate,
      region_id: facilityRegion.value,
      territory_id: facilityTerritory.map((val) => parseInt(val.value)),
      zone_id: zoneIds,
    };
    zoneIds && zoneIds.length > 0 && dispatch(backlog(data));
    zoneIds && zoneIds.length > 0 && dispatch(backlognonservice(data));
  };
  const handleZone = (e) => {
    dispatch(
      setFilter({ parent: "facilityCoverage", property: "zone", value: e })
    );
    localStorage.setItem(
      `${username}.scheduleFilter`,
      JSON.stringify({
        selectedDate: date,
        facilityCoverage: { ...facilityCoverage, zone: e },
      })
    );
    setZones(e);
  };
  const handleZoneOnBlur = () => {
    tabRef.current !== "onCall" &&
      date &&
      zone &&
      zone.length > 0 &&
      handleFacilityCoverage(zone, date);
  };
  const handleManagerOnBlur = () => {
    if (date && manager && manager.length > 0) {
      handleEventForManager(manager, date, []);
    }

    if (facilityTerritory && !_.isEmpty(facilityTerritory) && manager) {
      const managers = manager.map((val) => parseInt(val.value)) || [];
      handleGetManager(facilityTerritory, managers);
    }
  };
  const handlePhlebOnBlur = () => {
    if (date && manager && manager.length > 0 && phlebs && phlebs.length > 0) {
      let allPhlebs = [];
      allPhlebs = phlebs.map((ph) => ph.value);
      handleEventForManager(manager, date, allPhlebs);
    }
  };
  const toggleCalender = (value) => {
    setOpenCalender(value);
    setModalError("");
    setDestWeeks([]);
  };
  const toggleStopDayModal = (data) => {
    setOpenStopDayModal(data);
  };
  const copySchedule = () => {
    if (destWeeks.length > 0) {
      let weeks = [];
      destWeeks.forEach((week) => {
        weeks.push(moment(new Date(week.dateValue)).format("YYYY-MM-DD"));
      });
      let person_ids = [];
      if (tabRef.current === "onCall") {
        onCallScheduleBoardData[0] &&
          onCallScheduleBoardData[0].users.forEach((user) => {
            person_ids.push(user.user_id);
          });
      } else {
        scheduleBoardData[0] &&
          scheduleBoardData[0].users.forEach((user) => {
            person_ids.push(user.user_id);
          });
      }
      let data = {
        from_week_date: moment(new Date(date)).format("YYYY-MM-DD"),
        to_week_dates: weeks,
        person_ids,
        event_type:
          tabRef.current === "onCall"
            ? "On Call"
            : tabRef.current === TYPE_HOMEHEALTH
              ? "Home Health"
              : "Routine",
      };
      dispatch(copyScheduleApiResponse(data));
    }
  };
  const handleCheckbox = (value) => {
    setDestWeeks(value);
  };
  const getScheduleBoardData = () => {
    const { facilityCoverage: lsCoverage = {}, selectedDate = "" } =
      window.localStorage.getItem(`${username}.scheduleFilter`)
        ? JSON.parse(window.localStorage.getItem(`${username}.scheduleFilter`))
        : {};
    if (Object.keys(lsCoverage).length > 0) {
      const {
        region: fRegion = "",
        territory: fTerr = [],
        manager: sManager = [],
        phlebs = [],
      } = lsCoverage;
      if (
        !_.isEmpty(fRegion) &&
        !_.isEmpty(fTerr) &&
        !_.isEmpty(sManager) &&
        !_.isEmpty(selectedDate)
      ) {
        let managers = sManager
          ? Array.isArray(sManager)
            ? sManager.map((val) => parseInt(val.value))
            : []
          : [];
        let tempPhlebs = phlebs
          ? Array.isArray(phlebs)
            ? phlebs.map((val) => parseInt(val.value))
            : []
          : [];
        let terrs = fTerr
          ? Array.isArray(fTerr)
            ? fTerr.map((val) => parseInt(val.value))
            : []
          : [];
        const payloadDate = moment(new Date(selectedDate)).format("YYYY-MM-DD");
        const data = {
          event_date: payloadDate,
          region_id: fRegion.value,
          territory_id: terrs,
          reporting_manager_id: managers,
          user_id: tempPhlebs.length ? tempPhlebs : [],
        };
        dispatch(scheduleList(data));
      }
    }
  };
  const handleChangeTab = (value) => {
    localStorage.setItem("scheduleTab", value);
    setTabValue(value);
    const payloadDate = moment(date).format("YYYY-MM-DD");
    if (value === "onCall") {
      if (!_.isEmpty(facilityRegion) && !_.isEmpty(facilityTerritory)) {
        const data = {
          event_date: payloadDate,
          region_id: facilityRegion.value,
          territory_id: facilityTerritory.map((val) => parseInt(val.value)),
        };
        dispatch(getOnCallBacklog(data));
      }

      if (
        !_.isEmpty(facilityRegion) &&
        !_.isEmpty(facilityTerritory) &&
        !_.isEmpty(manager)
      ) {
        let managers = manager.map((val) => parseInt(val.value));
        const data = {
          event_date: payloadDate,
          region_id: facilityRegion.value,
          territory_id: facilityTerritory.map((val) => parseInt(val.value)),
          reporting_manager_id: managers,
          user_id: phlebs
            ? phlebs.length
              ? phlebs.map((val) => parseInt(val.value))
              : []
            : [],
        };
        dispatch(getOnCallScheduleList(data));
      } else {
        dispatch(updateOnCallScheduleListValue([]));
      }
    } else if (value === "routine") {
      if (
        !_.isEmpty(facilityRegion) &&
        !_.isEmpty(facilityTerritory) &&
        !_.isEmpty(zone)
      ) {
        let zoneIds = zone.map((val) => parseInt(val.value));
        const data = {
          event_date: payloadDate,
          region_id: facilityRegion.value,
          territory_id: facilityTerritory.map((val) => parseInt(val.value)),
          zone_id: zoneIds,
        };
        dispatch(backlog(data));
      } else {
        dispatch(updateBacklogValue({}));
      }

      if (
        !_.isEmpty(facilityRegion) &&
        !_.isEmpty(facilityTerritory) &&
        !_.isEmpty(manager)
      ) {
        let managers = manager.map((val) => parseInt(val.value));
        const data = {
          event_date: payloadDate,
          region_id: facilityRegion.value,
          territory_id: facilityTerritory.map((val) => parseInt(val.value)),
          reporting_manager_id: managers,
          user_id: phlebs
            ? phlebs.length
              ? phlebs.map((val) => parseInt(val.value))
              : []
            : [],
        };
        dispatch(scheduleList(data));
      } else {
        dispatch(updateScheduleListValue([]));
      }
    }
  };

  const handleChangeDate = (date) => {
    setDateSelected(date);
    dispatch(setDate(date));
  };

  const handleSearchText = (value) => {
    setSearchText(value);
  };

  const handleSearchType = (type) => {
    setSearchType(type);
    setSearchText("");
    toggleDropdown(false);
  };

  const handleChangeFilterType = (e, type = 1) => {
    let temp = filterType;
    const value = e.target.checked;
    if (type === 1) {
      if (!value && !temp.unscheduled) {
      } else {
        temp.scheduled = value;
        setFilterType({ ...filterType, ...temp });
      }
    } else if (type === 2) {
      if (!value && !temp.scheduled) {
      } else {
        temp.unscheduled = value;
        setFilterType({ ...filterType, ...temp });
      }
    } else if (type === 3) {
      temp.nocoverage = value;
      setFilterType({ ...filterType, ...temp });
    }
  };

  const addFacilityToSurgeOrNonService = (
    userIndex,
    uniqueServiceId,
    serviceType,
    facility,
    serviceTypeForSurgeAndNonService
  ) => {
    let newScheduleBoardData = _.cloneDeep(scheduleBoardData);

    const zoneIndex = facility.zone_id;
    const routineIndex = facility.event_type_id;

    let services = newScheduleBoardData[0].users[userIndex].services || [];
    let serviceIndex = -1;
    let blockId = null;
    services.forEach((ser, index) => {
      if (ser.uniqueServiceId === uniqueServiceId) {
        serviceIndex = index;
        blockId = ser.block_id || null;
      }
    });

    if (serviceIndex >= 0) {
      const currentZone = newScheduleBoardData[0].users[userIndex].services[
        serviceIndex
      ].zones
        ? newScheduleBoardData[0].users[userIndex].services[serviceIndex].zones[
        zoneIndex
        ] || {}
        : {};

      if (Object.keys(currentZone).length > 0) {
        let routines = currentZone.events || {};
        const currentRoutine = currentZone.events[routineIndex] || {};
        if (Object.keys(currentRoutine).length > 0) {
          const facilities =
            newScheduleBoardData[0].users[userIndex].services[serviceIndex]
              .zones[zoneIndex].events[routineIndex].facilities || [];
          newScheduleBoardData[0].users[userIndex].services[serviceIndex].zones[
            zoneIndex
          ].events[routineIndex].facilities = [facility, ...facilities];
        } else {
          routines = {
            ...routines,
            [routineIndex]: {
              event_type: facility.event_type,
              event_type_id: routineIndex,
              // event_id,
              service_type: serviceType,
              facilities: [facility],
            },
          };
          newScheduleBoardData[0].users[userIndex].services[serviceIndex].zones[
            zoneIndex
          ].events = routines;
        }
      } else {
        let zones = newScheduleBoardData[0].users[userIndex].services[
          serviceIndex
        ].zones
          ? newScheduleBoardData[0].users[userIndex].services[serviceIndex]
            .zones || {}
          : {};
        zones = {
          ...zones,
          [zoneIndex]: {
            zone_id: zoneIndex,
            zone: facility.zone,
            events: {
              [routineIndex]: {
                event_type: facility.event_type,
                event_type_id: routineIndex,
                // event_id,
                service_type: serviceType,
                facilities: [facility],
              },
            },
          },
        };
        newScheduleBoardData[0].users[userIndex].services[serviceIndex].zones =
          zones;
      }

      const service = services[serviceIndex];
      const user = newScheduleBoardData[0].users[userIndex];

      const { start_time, end_time, uniqueServiceId, timezone = {} } = service;

      let payload = {
        service_type_id: serviceType,
        start_time,
        end_time,
        status: "ACTIVE",
        facility_ids: [Number(facility.facility_id)],
        block_id: blockId,
        current_block_id: null,
        person_id: user.user_id,
        event_id: routineIndex,
        zone_id: zoneIndex,
        user_index: userIndex,
        service_index: serviceIndex,
        facilityIndex: 0,
        uniqueServiceId,
        serviceTypeForSurgeAndNonService,
        serviceTypeForSurgeAndNonServiceHasValue: true,
      };
      if (timezone.timezone_id) {
        payload.timezone_id = timezone.timezone_id;
      }
      if (routineIndex === "surge" && facility.service_stop_day == 2) {
        payload.is2X = true;
        payload.facilityData = facility;
        toggleStopDayModal({
          value: true,
          error: "",
          facility,
          dropResponse: {
            newScheduleBoardData,
            resultBacklogData: backlogData,
            payload,
          },
          dropType: SURGE_TYPE,
        });
      } else {
        continueDroppingFacility(
          { newScheduleBoardData, resultBacklogData: backlogData, payload },
          false,
          0,
          routineIndex === "surge" ? SURGE_TYPE : NON_SERVICE_TYPE
        );
      }
    }
  };

  const addZoneToSurge = (
    userIndex,
    uniqueServiceId,
    serviceType,
    zone,
    serviceTypeForSurge
  ) => {
    let newScheduleBoardData = _.cloneDeep(onCallScheduleBoardData);

    const terrIndex = zone.territory_id;
    const routineIndex = zone.event_type_id;

    let services = newScheduleBoardData[0].users[userIndex].services || [];
    let serviceIndex = -1;
    let blockId = null;
    services.forEach((ser, index) => {
      if (ser.uniqueServiceId === uniqueServiceId) {
        serviceIndex = index;
        blockId = ser.block_id || null;
      }
    });

    if (serviceIndex >= 0) {
    if (zone.timezone?.timezone_id == services[serviceIndex]?.timezone?.timezone_id) {
      const currentTerr = newScheduleBoardData[0].users[userIndex].services[
        serviceIndex
      ].territories
        ? newScheduleBoardData[0].users[userIndex].services[serviceIndex].territories[
        terrIndex
        ] || {}
        : {};

      if (Object.keys(currentTerr).length > 0) {
        let routines = currentTerr.events || {};
        const currentRoutine = currentTerr.events[routineIndex] || {};
        if (Object.keys(currentRoutine).length > 0) {
          const zones =
            newScheduleBoardData[0].users[userIndex].services[serviceIndex]
              .territories[terrIndex].events[routineIndex].zones || [];
          newScheduleBoardData[0].users[userIndex].services[serviceIndex].territories[
            terrIndex
          ].events[routineIndex].zones = [zone, ...zones];
        } else {
          routines = {
            ...routines,
            [routineIndex]: {
              event_type: zone.event_type,
              event_type_id: routineIndex,
              // event_id,
              service_type: serviceType,
              zones: [zone],
            },
          };
          newScheduleBoardData[0].users[userIndex].services[serviceIndex].territories[
            terrIndex
          ].events = routines;
        }
      } else {
        let territories = newScheduleBoardData[0].users[userIndex].services[
          serviceIndex
        ].territories
          ? newScheduleBoardData[0].users[userIndex].services[serviceIndex]
            .territories || {}
          : {};
        territories = {
          ...territories,
          [terrIndex]: {
            territory_id: terrIndex,
            territory_name: zone.territory_name,
            events: {
              [routineIndex]: {
                event_type: zone.event_type,
                event_type_id: routineIndex,
                // event_id,
                service_type: serviceType,
                zones: [zone],
              },
            },
          },
        };
        newScheduleBoardData[0].users[userIndex].services[serviceIndex].territories =
          territories;
      }
    } else {
      const destinationService = services[serviceIndex] || {};
      //check if there is an existing timezone block with same shift
      if (services.some(ser => ser.timezone?.timezone_id == zone.timezone?.timezone_id && (destinationService.shift_timings && destinationService.shift_timings.shift_allocation_id ? (ser?.shift_timings?.shift_allocation_id == destinationService?.shift_timings?.shift_allocation_id) : (ser.start_time === destinationService.start_time && ser.end_time === destinationService.end_time)))) {
        // yes, move the newly added zone to that block
        for (let i = 0; i < services.length; i++) {
          let ser = services[i] || {};
          if (ser.timezone?.timezone_id == zone.timezone?.timezone_id && (destinationService.shift_timings && destinationService.shift_timings.shift_allocation_id ? (ser?.shift_timings?.shift_allocation_id == destinationService?.shift_timings?.shift_allocation_id) : (ser.start_time === destinationService.start_time && ser.end_time === destinationService.end_time))) {
            //check for different territories
            let { territories = {} } = ser;
            if (!Object.keys(territories).includes(terrIndex.toString())) {
              territories[terrIndex] = {
                territory_id: terrIndex,
                territory_name: zone.territory_name,
                events: {
                  [routineIndex]: {
                    event_type: zone.event_type,
                    event_type_id: routineIndex,
                    service_type: 8,
                    zones: [zone]
                  }
                }
              };
            } else {
              //check if event type is different
              let { events = {} } = territories[terrIndex] || {};
              //check if dragged zone's event type is already present or not
              if (!Object.keys(events).includes(routineIndex.toString())) {
                events[routineIndex] = {
                  event_type: zone.event_type,
                  event_type_id: routineIndex,
                  service_type: 8,
                  zones: [zone]
                };
              } else {
                let { zones = [] } = events[routineIndex] || {};
                zones.push(zone);
              }
            }
            serviceIndex = i;
            blockId = ser.block_id;
          }
        }
      } else {
        // no, create a new block
        let newOnCallBlock = _.cloneDeep(services[serviceIndex]) || {};
        const newId = uuid();
        newOnCallBlock.uniqueServiceId = newId;
        newOnCallBlock.event_id = "";
        newOnCallBlock.timezone = zone.timezone;
        // newOnCallBlock.block_id = "";

        newOnCallBlock.territories = {
          [terrIndex]: {
            territory_id: terrIndex,
            territory_name: zone.territory_name,
            events: {
              [routineIndex]: {
                event_type: zone.event_type,
                event_type_id: routineIndex,
                service_type: 8,
                zones: [zone]
              }
            }
          }
        };

        services.push(newOnCallBlock);
        services.forEach((ser, idx) => {
          if (ser.uniqueServiceId === newId) {
            serviceIndex = idx;
            blockId = null;
          }
        });
      }
    }

      const service = services[serviceIndex];
      const user = newScheduleBoardData[0].users[userIndex];

      const { start_time, end_time, uniqueServiceId, shift_timings = {}, timezone = {} } = service;
      const { shift_allocation_id } = shift_timings;

      let payload = {
        service_type_id: 8,
        start_time,
        end_time,
        status: "ACTIVE",
        zone_ids: [Number(zone.zone_id)],
        facility_ids: [],
        block_id: blockId,
        current_block_id: null,
        person_id: user.user_id,
        event_id: routineIndex,
        territory_id: terrIndex,
        user_index: userIndex,
        service_index: serviceIndex,
        zoneIndex: 0,
        uniqueServiceId,
        serviceTypeForSurge,
        serviceTypeForSurgeAndNonServiceHasValue: true,
        shift_allocation_id
      };
      if (timezone.timezone_id) {
        payload.timezone_id = timezone.timezone_id;
      }

      const resultBacklogData = onCallBacklogData;
      payload["event_date"] = moment(date).format("YYYY-MM-DD");
      payload["current_event_date"] = moment(date).format("YYYY-MM-DD");

      const tempPayload = _.cloneDeep(payload);
      setUpsertData({ ...tempPayload, tabType: "onCall" });
      setOnCallBoardData(newScheduleBoardData);
      setOnCallBacklogData(resultBacklogData);
      delete payload["event_id"];
      delete payload["service_index"];
      delete payload["user_index"];

      updateEventHandler({ data: payload, type: SURGE_TYPE, tab: "onCall" });
    }
  };

  const continueDeletingOnCallBlock = () => {
    if (onCallDeleteTempData) {
      dispatch(deleteEvent({ ...onCallDeleteTempData, data: { ...onCallDeleteTempData.data, delete_surge: true } }));
    }
  }

  const onBeforeCapture = (e, c) => {
    if (e && e.mode === "FLUID") {
      setShowDropArea(true);
    }
  }

  const DateButton = React.forwardRef(({ value, onClick }, ref) => (
    <button className="sch-date-picker" onClick={onClick} ref={ref}>
      {value}
    </button>
  ));
  let loading =
    deleteServiceLoader || deleteEventLoader || onCallDeleteServiceLoader;
  return (
    <React.Fragment>
      <div className="ad-headertop-wrapper scheduler-header">
        <CommonHeaderRightSection
          logout={logout}
          color={color}
          name={name}
          title={"Schedule"}
          className="d-flex"
          callbackToRefreshThePage={() => { setRefreshLoader(true); handleAuto(true); }}
          children={!isPhleb ? (
            <FacilityCoverageDropdown
              // handleChangeDate={handleChangeDate}
              date={date}
              handleRegion2={handleRegion2}
              facilityRegion={facilityRegion}
              reg_modified_facilities={reg_modified_facilities}
              terr_modified_facilities={terr_modified_facilities}
              facilityTerritory={facilityTerritory}
              handleTerritory2={handleTerritory2}
              handleTerritoryOnBlur={handleTerritoryOnBlur}
              zoneOptions={zoneOptions}
              zone={zone}
              handleZone={handleZone}
              handleZoneOnBlur={handleZoneOnBlur}
              handleManager={handleManager}
              manager={manager}
              reportingOptions={reportingOptions}
              handleManagerOnBlur={handleManagerOnBlur}
              handlePhleb={handlePhleb}
              phlebs={phlebs}
              phlebOptions={phlebOptions}
              handlePhlebOnBlur={handlePhlebOnBlur}
              onDateChange={val => dispatch(setDate(val))}
              tabType={tabRef.current}
            />
          ) : null}
        />
      </div>
      <div className="scheduler-tabs-wrapper position-relative">
        <div
          className={
            "ad-loading-wrapper " +
            (loading || refreshLoader ? "d-block loader-wrapper" : "d-none")
          }
        >
          <span
            className={"loader ad-loader " + (loading || refreshLoader ? "d-block" : "d-none")}
          >
            <span className="visually-hidden">loader icon</span>
          </span>
        </div>
        <div className="sch-filter-top">
          <ul className="ad-navtab scheduler-tabs">
            <li className="nav-item" role="prduesentation">
              <button
                type="button"
                role="tab"
                data-rr-ui-event-key="routine"
                onClick={() => handleChangeTab("routine")}
                aria-controls="react-aria2424728780-6-tabpane-routine"
                aria-selected="true"
                className={`nav-link ${tabRef.current === "routine" ? "active" : ""
                  }`}
              >
                Routines
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                type="button"
                role="tab"
                data-rr-ui-event-key="routine"
                onClick={() => handleChangeTab("onCall")}
                aria-controls="react-aria2424728780-6-tabpane-routine"
                aria-selected="true"
                className={`nav-link ${tabRef.current === "onCall" ? "active" : ""
                  }`}
              >
                On Call
              </button>
            </li>
            {/* <li className="nav-item" role="presentation">
              <button type="button" role="tab" data-rr-ui-event-key="routine" onClick={() => handleChangeTab(TYPE_HOMEHEALTH)}
                aria-controls="react-aria2424728780-6-tabpane-routine" aria-selected="true"
                className={`nav-link ${tabRef.current === TYPE_HOMEHEALTH ? 'active' : ''}`}>Home Health</button>
            </li> */}
          </ul>
          {!isPhleb && (
            <div className="top-search-box align-items-center">
              <div className="scheduler-header">
                <div className="scheduler-filters">
                  <Dropdown
                    className="d-inline facility-filter filter st-filter"
                    onToggle={(val) => toggleDropdown(val)}
                    show={dropdown}
                  >
                    <Dropdown.Toggle
                      id="dropdown-autoclose-inside"
                      className="filter-title"
                    >
                      {_.isEmpty(searchType) ? "Search Type" : searchType}
                      <span className="service-icon">
                        <span className="visually-hidden">Search Type</span>
                      </span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="search-type-dropdown">
                      <ul>
                        <li
                          className={
                            'search-list-name searchType === "phlebotomist" ? "active" : ""'
                          }
                          onClick={(e) => {
                            handleSearchType("phlebotomist");
                          }}
                        >
                          Phlebotomist
                        </li>
                        <li
                          className={
                            'search-list-name searchType === "zone" ? "active" : ""'
                          }
                          onClick={(e) => {
                            handleSearchType("zone");
                          }}
                        >
                          Zone
                        </li>
                      </ul>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
              <div className="position-relative">
                <input
                  type="text"
                  className="search-box"
                  placeholder="Search"
                  value={searchText}
                  onChange={(e) => handleSearchText(e.target.value)}
                  id="search-phleb-or-zone"
                />
                <span className="search-icon">
                  <span className="visually-hidden">Search Icon</span>
                </span>
              </div>
              {(tabRef.current === "onCall"
                ? onCallScheduleBoardData.length > 0
                : scheduleBoardData.length > 0) && (
                  <div className="d-flex align-items-center ms-4 sc-checkbox-wrapper">
                    <div className="form-check me-3 mb-0">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="scheduled"
                        value={filterType.scheduled}
                        checked={filterType.scheduled}
                        onChange={(e) => {
                          handleChangeFilterType(e, 1);
                        }}
                      />
                      <label className="form-check-label" htmlFor="scheduled">
                        Scheduled
                      </label>
                    </div>
                    <div className={"me-3 " + " form-check me-0 mb-0"}>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="unscheduled"
                        value={filterType.unscheduled}
                        checked={filterType.unscheduled}
                        onChange={(e) => {
                          handleChangeFilterType(e, 2);
                        }}
                      />
                      <label className="form-check-label" htmlFor="unscheduled">
                        Unscheduled
                      </label>
                    </div>
                    {tabRef.current === "routine" &&
                      <div className="form-check me-3 mb-0">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="inactive-fac"
                          value={showInactiveFac}
                          checked={showInactiveFac}
                          onChange={(e) => {
                            setShowInactiveFac(e.target.checked);
                          }}
                        />
                        <label className="form-check-label" htmlFor="inactive-fac">
                          Inactive Facilities
                        </label>
                      </div>}
                    {tabRef.current === "onCall" &&
                      <div className="form-check me-3 mb-0">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="nocoverage"
                          value={filterType.nocoverage}
                          checked={filterType.nocoverage}
                          onChange={(e) => {
                            handleChangeFilterType(e, 3);
                          }}
                        />
                        <label className="form-check-label" htmlFor="nocoverage">
                          No Coverage
                        </label>
                      </div>
                    }
                  </div>
                )}
            </div>
          )}
        </div>
        <div className="flex-fill d-flex justify-content-end sch-copy-cal-wrapper">
          <div className="">
            {!_.isEmpty(facilityRegion) &&
              !_.isEmpty(facilityTerritory) &&
              !_.isEmpty(manager) && (tabRef.current === "routine" ? true : tabRef.current === "onCall" && isOnCallScheduleDateAfterDeployment(date)) && (
                <Button
                  className="primary-btn copy-cal-btn"
                  onClick={() => toggleCalender(true)}
                  //onClick={() => setStatusModalType("confirmation")}
                  title="Copy Schedule"
                // disabled={tabRef.current === "onCall"}
                >
                  <span className="copy-calendar">
                    <span className="visually-hidden">Copy Icon</span>
                  </span>{" "}
                  Copy Calendar
                </Button>
              )}
          </div>
        </div>
        {tabRef.current === "routine" && (
          <div className="scheduler-wrapper position-relative">
            {scheduleBoardData.length === 0 && backlogData.length === 0 ? (
              <div
                className="toast toast-wrapper text-center show sch-toast-wrapper"
                role="alert"
                aria-live="assertive"
                aria-atomic="true"
              >
                <div className="toast-info-details d-flex align-items-center toast-body">
                  <p className="sch-info-text">
                    {!isPhleb
                      ? "Please select Facilities and Staff to view the Schedule"
                      : "User does not have Permission to view the Schedule"}
                  </p>
                </div>
              </div>
            ) : scheduleBoardData.length === 0 ? (
              <div
                className="toast toast-wrapper text-center show sch-toast-wrapper sch-right-toast-wrapper"
                role="alert"
                aria-live="assertive"
                aria-atomic="true"
              >
                <div className="toast-info-details d-flex align-items-center toast-body">
                  <p className="sch-info-text">
                    Please select Staff to view the Schedule Board
                  </p>
                </div>
              </div>
            ) : backlogData.length === 0 ? (
              <div
                className="toast toast-wrapper text-center show sch-toast-wrapper sch-left-toast-wrapper"
                role="alert"
                aria-live="assertive"
                aria-atomic="true"
              >
                <div className="toast-info-details d-flex align-items-center toast-body">
                  <p className="sch-info-text">
                    Please select Facilities to view the Facilities
                  </p>
                </div>
              </div>
            ) : null}
            <DragDropContext onDragEnd={onDragEnd} onDragUpdate={onDragUpdate} onBeforeCapture={onBeforeCapture}>
              {!profileLoading && (
                <SchedulerBacklog
                  selectedDate={selectedDate}
                  backlogData={backlogData}
                  tabValue={tabRef.current}
                  searchText={searchText}
                  searchType={searchType}
                  showDropArea={showDropArea}
                  showInactiveFac={showInactiveFac}
                />
              )}
              <SchedulerBoard
                selectedDate={selectedDate}
                scheduleBoardData={scheduleBoardData}
                setBoardData={setBoardData}
                date={date}
                updateBoardData={updateBoardData}
                handleDelete={handleDelete}
                deleteIndex={deleteUserIndex}
                handleDeleteService={handleDeleteService}
                constructPyloadForCheckinCheckout={
                  constructPyloadForCheckinCheckout
                }
                constructPayloadToClearCheckinCheckoutSpin={constructPayloadToClearCheckinCheckoutSpin}
                getScheduleBoardData={getScheduleBoardData}
                deleteServiceData={deleteServiceData}
                setDeleteServiceData={setDeleteServiceData}
                tabValue={tabRef.current}
                searchText={searchText}
                searchType={searchType}
                tempCourierAddress={tempCourierAddress}
                setTempCourierAddress={setTempCourierAddress}
                setTempCourierAddressForEnRoute={
                  setTempCourierAddressForEnRoute
                }
                tempCourierAddressForEnRoute={tempCourierAddressForEnRoute}
                filterType={filterType}
                facilitiesForSurge={facilitiesForSurge}
                facilitiesForNonService={facilitiesForNonService}
                addFacilityToSurgeOrNonService={addFacilityToSurgeOrNonService}
                showDropArea={showDropArea}
                timezone_ids={timezone_ids}
              />
            </DragDropContext>
          </div>
        )}
        {tabRef.current === "onCall" && (
          <OnCallScheduler
            profileLoading={profileLoading}
            selectedDate={selectedDate}
            tabValue={tabRef.current}
            onCallScheduleBoardData={onCallScheduleBoardData}
            setOnCallBoardData={setOnCallBoardData}
            onCallBacklogData={onCallBacklogData}
            setOnCallBacklogData={setOnCallBacklogData}
            date={date}
            updateBoardData={updateBoardDataOnCall}
            handleDelete={handleDeleteOnCall}
            deleteIndex={deleteUserIndex}
            handleDeleteService={handleDeleteOnCallService}
            constructPyloadForCheckinCheckout={
              constructPyloadForCheckinCheckout
            }
            getScheduleBoardData={getScheduleBoardData}
            deleteServiceData={deleteServiceData}
            setDeleteServiceData={setDeleteServiceData}
            onDragEnd={onDragEndOnCall}
            searchText={searchText}
            searchType={searchType}
            onDragUpdate={onDragUpdate}
            filterType={filterType}
            showDropArea={showDropArea}
            onBeforeCapture={onBeforeCapture}
            shiftTimingsList={shiftTimingsList}
            timezone_ids={timezone_ids}
            zonesForSurge={zonesForSurge}
            addZoneToSurge={addZoneToSurge}
          />
        )}
        {tabRef.current === TYPE_HOMEHEALTH && (
          <HomeHealthScheduler
            profileLoading={profileLoading}
            selectedDate={selectedDate}
            tabValue={tabRef.current}
            homeHealthScheduleBoardData={scheduleBoardData}
            setHomeHealthBoardData={setBoardData}
            homeHealthBacklogData={backlogData}
            setHomeHealthBacklogData={setBacklogData}
            date={date}
            updateBoardData={updateBoardData}
            handleDelete={handleDelete}
            deleteIndex={deleteUserIndex}
            handleDeleteService={handleDeleteService}
            deleteServiceData={deleteServiceData}
            setDeleteServiceData={setDeleteServiceData}
            searchText={searchText}
            searchType={searchType}
            onDragUpdate={onDragUpdate}
            filterType={filterType}
            showDropArea={showDropArea}
            onBeforeCapture={onBeforeCapture}
          />
        )}
      </div>
      <CopyScheduleModal
        show={openCalender}
        hideModal={toggleCalender}
        setStatusModalType={setStatusModalType}
        handleCheckbox={handleCheckbox}
        destWeeks={destWeeks}
        selectedDate={date}
        modalError={modalError}
      />

      <ServiceStopDayModal
        openStopDayModal={openStopDayModal}
        toggleStopDayModal={toggleStopDayModal}
        continueDroppingFacility={continueDroppingFacility}
      />

      <StatusModal
        modalType={statusModalType}
        show={statusModalType.value || false}
        hideModal={() => {
          if (statusModalType.type === ON_CALL_DELETE_SURGE_WARN) {
            setOnCallBoardData(initialOnCallScheduleBoardData);
            setOnCallBacklogData(initialOnCallBacklogData);
          }
          setStatusModalType({ value: false });
        }}
        title={
          statusModalType.type === "copySchedule"
            ? "If the destination schedule contains existing events, applying changes will overwrite those events. Please confirm prior to clicking OK, as this action cannot be undone."
            : statusModalType.title || ""
        }
        subtitle={
          statusModalType.type === "copySchedule"
            ? "This action cannot be undone."
            : statusModalType.subtitle || ""
        }
        copySchedule={copySchedule}
        confirmUpdate={statusModalType.type === ON_CALL_DELETE_SURGE_WARN ? continueDeletingOnCallBlock : () => { }}
      />

      {statusModalType.status === "success" && <LottieAnimation />}

      <ToastMessageNew
        type={"error"}
        message={toastMessage}
        setToast={setToast}
        toast={toast}
      />
    </React.Fragment>
  );
};
