import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ahaApi from "../../../app/services/aha-api";
const initialState = {
  value: [],
  loading: false,
};

export const forgotUser = createAsyncThunk(
  "account/forgotUser",
  async (data, { rejectWithValue }) => {
    const response = await ahaApi.Account.forgotUser(data)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return rejectWithValue(err.response.data);
      });
    return response;
  }
);

export const forgotUserSlice = createSlice({
  name: "forgotUser",
  initialState,

  extraReducers: (builder) => {
    builder
      .addCase(forgotUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(forgotUser.fulfilled, (state, action) => {
        state.loading = false;
        state.value = action.payload;
      })
      .addCase(forgotUser.rejected, (state, err) => {
        state.loading = false;
        state.value = err.payload;
      });
  },
});

export const forgotUserList = (state) => state.forgotUser.value;

export default forgotUserSlice.reducer;
