/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import Logo from "../../../components/layouts/Logo";
import { HOME } from "../../../app/utils/Constants";
import { forgotPassword, forgotPasswordList } from "./forgotPasswordSlice";
import { useSelector, useDispatch } from "react-redux";
import ToastMessage from "../../../components/layouts/ToastMessage";

export default function ForgotPassword(props) {
  const forgotPass = useSelector(forgotPasswordList);
  const dispatch = useDispatch();
  const [uname, setUname] = React.useState("");
  const [status, setStatus] = React.useState("");
  const [toast, setToast] = React.useState(true);
  const [error, setError] = React.useState(false);
  const [message, setMessage] = React.useState("");
  // const usernameValid = uname.search(USERNAME) >= 0 ? true : false;

  React.useEffect(() => {
    if (forgotPass && forgotPass.status && forgotPass.status === "SUCCESS") {
      setStatus("success");
      setMessage(forgotPass && forgotPass.message);
      setToast(false);
      setUname("");
    } else if (
      forgotPass &&
      forgotPass.status &&
      forgotPass.status === "ERROR"
    ) {
      setMessage(forgotPass && forgotPass && forgotPass.message);
      setError(true);
      setStatus("error");
      setToast(false);
    }
  }, [forgotPass]);

  const handleSubmit = () => {
    const data = {
      username: uname,
    };
    dispatch(forgotPassword(data));
  };
  const toastHandler = () => {
    setToast(true);
  };
  document.documentElement.classList.add("login-flow");

  return (
    <React.Fragment>
      <Logo />
      <div>
        <h1 className="mb-10 roboto-regular login-title">Forgot Password</h1>
        <p className="mab-5 roboto-regular login-info">
          If your username is on file, then you’ll
          <br /> receive an email to reset your password.
        </p>
        <form className="aha-login-wrapper">
          <div className="aha-form-group mb-20 text-start">
            <label
              className="mb-0 roboto-medium form-label"
              htmlFor="fpp-username"
            >
              Username
            </label>
            <input
              type="text"
              className={"form-control " + (error ? "invalid" : "")}
              id="fpp-username"
              onChange={(e) => {
                setUname(e.target.value);
              }}
              value={uname}
            />
          </div>
          <button
            disabled={uname && uname.length > 0 ? false : true}
            className="primary-btn mb-10"
            type="button"
            onClick={() => {
              handleSubmit();
            }}
          >
            <span>Send Link</span>
            <span className="loader">
              <span className="visually-hidden">loader icon</span>
            </span>
          </button>
        </form>
        <div className="aha-link-wrapper">
          <a href={HOME}>Back to Sign In</a>
        </div>
      </div>
      <ToastMessage
        type={status}
        message={message}
        setToast={toastHandler}
        toast={toast}
      />
    </React.Fragment>
  );
}
