import {
  ChipDirective,
  ChipListComponent,
  ChipsDirective,
} from "@syncfusion/ej2-react-buttons";
import { phoneFormat } from "../../app/utils/testUtils";
import CustomGrid from "../../components/commons/CustomGrid";
import moment from "moment";

const getDayName = (id, days) => {
  let result = id;
  days &&
    days.forEach((day) => {
      if (day.id == id) {
        result = day.name;
      }
    });
  return result;
};

export default function Grid(props) {
  let { facilityList = [], loading = false, days = [] } = props;
  // const nonServiceValueAccessor = (field, data, column) => {
  //   const nonServiceDays = data["non_service_days"];
  //   return nonServiceDays
  //     ? nonServiceDays
  //         .map((day) => {
  //           return getDayName(day.day);
  //         })
  //         .join(", ")
  //     : "";
  // };
  return (
    <CustomGrid
      type="facility"
      data={facilityList}
      loading={loading}
      editModal={props.editModal}
      columns={[
        // {
        //   field: "account_name",
        //   headerText: "Account Name",
        //   width: "220",
        //   visible: false,
        // },
        // {
        //   field: "account_number",
        //   headerText: "Account Number",
        //   width: "220",
        //   visible: false,
        // },
        {
          field: "account_name_string",
          headerText: "Parent Account Name",
          width: "280",
        },
        {
          field: "account_number_string",
          headerText: "Parent Account Number",
          width: "280",
        },
        {
          field: "facility_type_name_string",
          headerText: "Facility Type",
          width: "220",
        },
        {
          field: "service_type_name_string",
          headerText: "Routine Service Type",
          width: "250",
        },
        {
          field: "service_stop_name_string",
          headerText: "Service Stop Day",
          width: "250",
          visible: false,
        },
        {
          field: "service_days_string",
          headerText: "Service Days",
          width: "250",
          visible: false,
        },
        // {
        //   field: "non_service_days",
        //   headerText: "Non Service Days",
        //   width: "250",
        //   visible: false,
        //   valueAccessor: nonServiceValueAccessor
        // },
        {
          field: "region_name_string",
          headerText: "Region",
          width: "221",
        },
        {
          field: "territory_name_string",
          headerText: "Territory",
          width: "221",
        },
        {
          field: "zone_name_string",
          headerText: "Zone",
          width: "220",
        },
        {
          field: "street_address1_string",
          headerText: "Street Address 1",
          width: "220",
          visible: false,
        },
        {
          field: "city_string",
          headerText: "City",
          width: "168",
        },
        {
          field: "state_name_string",
          headerText: "State",
          width: "168",
        },
        {
          field: "zip_string",
          headerText: "Zip",
          width: "168",
        },
        // {
        //   field: "street_address2",
        //   headerText: "Street Address 2",
        //   width: "220",
        //   visible: false
        // },
        {
          field: "account_manager_name_string",
          headerText: "Account Manager",
          width: "220",
        },
        {
          field: "account_manager_phone_string",
          headerText: "Account Manager Phone Number",
          width: "350"
        },
        {
          field: "account_manager_email_string",
          headerText: "Account Manager Email",
          width: "220",
          visible: false,
        },
        {
          field: "facility_phone_string",
          headerText: "Facility Contact Phone Number",
          width: "325"
        },
        {
          field: "facility_phone_extension_string",
          headerText: "Facility Phone Extension",
          width: "168",
          visible: false,
        },
        {
          field: "time_zone_name_string",
          headerText: "Timezone",
          width: "168",
        },
        {
          field: "emergency_contact_name_string",
          headerText: "Emergency Contact Name",
          width: "168",
          visible: false,
        },
        {
          field: "emergency_contact_phone_string",
          headerText: "Emergency Contact Phone",
          width: "168",
          visible: false,
        },
        {
          field: "emergency_contact_phone_extension_string",
          headerText: "Emergency Contact Phone Extension",
          width: "168",
          visible: false,
        },
        {
          field: "emergency_contact_role_string",
          headerText: "Emergency Contact Role",
          width: "168",
          visible: false,
        },
        {
          field: "facility_notes_string",
          headerText: "Facility Notes",
          width: "168",
          visible: false,
        },
        {
          field: "latitude_string",
          headerText: "Latitude",
          width: "168",
          visible: false,
        },
        {
          field: "longitude_string",
          headerText: "Longitude",
          width: "168",
          visible: false,
        },
        {
          field: "activation_date_string",
          headerText: "Activation Date",
          width: "220",
          visible: false,
          template: (props) => {
            const { activation_date = "" } =
              props;
            return (
              <span>
                {activation_date ? moment(activation_date).format("MM/DD/yyyy") : ""}
              </span>
            );
          },
        },
        {
          field: "status.status_name",
          headerText: "Facility Status",
          width: "195",
          template: (props) => {
            const { status = {} } = props;
            return status && status.status_name === "Active" ? (
              <div className="user-status-wrapper">
                <ChipListComponent id="chip-default">
                  <ChipsDirective>
                    <ChipDirective
                      text={status.status_name}
                      cssClass="e-user-active"
                    ></ChipDirective>
                  </ChipsDirective>
                </ChipListComponent>
              </div>
            ) : status && status.status_name === "Invited" ? (
              <div className="user-status-wrapper">
                <ChipListComponent id="chip-default">
                  <ChipsDirective>
                    <ChipDirective
                      text={status.status_name}
                      cssClass="e-user-invited"
                    ></ChipDirective>
                  </ChipsDirective>
                </ChipListComponent>
              </div>
            ) : status && status.status_name === "Locked" ? (
              <div className="user-status-wrapper">
                <ChipListComponent id="chip-default">
                  <ChipsDirective>
                    <ChipDirective
                      text={status.status_name}
                      cssClass="e-user-locked"
                    ></ChipDirective>
                  </ChipsDirective>
                </ChipListComponent>
              </div>
            ) : (
              status &&
              status.status_name === "Inactive" && (
                <div className="user-status-wrapper">
                  <ChipListComponent id="chip-default">
                    <ChipsDirective>
                      <ChipDirective
                        text={status.status_name}
                        cssClass="e-user-deactivated"
                      ></ChipDirective>
                    </ChipsDirective>
                  </ChipListComponent>
                </div>
              )
            );
          },
        },
      ]}
    />
  );
}
