import {
  ChipDirective,
  ChipListComponent,
  ChipsDirective,
} from "@syncfusion/ej2-react-buttons";
import {
  DropDownListComponent,
  DropDownTreeComponent,
} from "@syncfusion/ej2-react-dropdowns";
import React from "react";
import { ProgressButtonComponent } from "@syncfusion/ej2-react-splitbuttons";
import { Button, Modal, OverlayTrigger, Toast, Tooltip } from "react-bootstrap";
import { phoneFormat } from "../../../app/utils/testUtils";
import toast from "react-hot-toast";
import ToastContent from "../../../components/layouts/ToastContent";
import { hasCompanyVehicleOptions } from "../../../app/utils/Constants";

export default function AddUser(props = {}) {
  const [chipData, setChip] = React.useState([]);
  const [type, setType] = React.useState("");
  let dropDownTree = React.useRef();

  let selectedRegionTerritory = props.regionTerritory || [];
  const homeRegion = props.homeRegion || {};

  const handleRegionTerritory = (args) => {
    const { itemData } = args;
    const dropDownTreeData = props.bc || [];

    // if select-all is checked/ un-checked
    if (itemData.checked) {
      if (itemData.checked === "true") {
        selectedRegionTerritory = [];

        let regionTerritoryObject = {};

        dropDownTreeData.forEach((data) => {
          if (data.region_id) {
            if (!regionTerritoryObject[data.region_id]) {
              regionTerritoryObject[data.region_id] = [data.id];
            } else {
              regionTerritoryObject[data.region_id].push(data.id);
            }
          }
        });

        Object.keys(regionTerritoryObject).forEach((region) => {
          selectedRegionTerritory.push({
            region_id: parseInt(region),
            territory_id: regionTerritoryObject[region],
          });
        });
      } else {
        // selectedRegionTerritory = [];
        if (itemData.hasChildren) {
          //if item is a parent
          const children = dropDownTreeData.filter(
            (data) => data.region_id == itemData.id
          );

          if (
            selectedRegionTerritory.some(
              (region) => region.region_id == itemData.id
            )
          ) {
            selectedRegionTerritory = selectedRegionTerritory.filter(
              (region) => region.region_id != itemData.id
            );
          }
        } else {
          // if item is a child

          // check if selected child's parent is already present or not
          if (
            selectedRegionTerritory.some(
              (region) => region.region_id == itemData.parentID
            )
          ) {
            selectedRegionTerritory = selectedRegionTerritory.map((region) => {
              let { territory_id = [] } = region;
              if (region.region_id == itemData.parentID) {
                territory_id = territory_id.filter(
                  (territory) => territory != itemData.id
                );
              }
              region.territory_id = territory_id;
              return region;
            });
          } else {
            selectedRegionTerritory.push({
              region_id: parseInt(itemData.parentID),
              territory_id: [itemData.id],
            });
          }
        }
      }
    } else {
      if (itemData.hasChildren) {
        //if item is a parent
        const children = dropDownTreeData.filter(
          (data) => data.region_id == itemData.id
        );

        if (itemData.isChecked === "true") {
          if (
            !selectedRegionTerritory.some(
              (region) => region.region_id == itemData.id
            )
          ) {
            selectedRegionTerritory.push({
              region_id: parseInt(itemData.id),
              territory_id: children.map((child) => {
                return child.id;
              }),
            });
          } else {
            selectedRegionTerritory = selectedRegionTerritory.map((reg) => {
              return {
                region_id:
                  reg.region_id == itemData.id
                    ? parseInt(itemData.id)
                    : reg.region_id,
                territory_id:
                  reg.region_id == itemData.id
                    ? children.map((child) => {
                      return child.id;
                    })
                    : reg.territory_id,
              };
            });
          }
        } else {
          if (
            selectedRegionTerritory.some(
              (region) => region.region_id == itemData.id
            )
          ) {
            selectedRegionTerritory = selectedRegionTerritory.filter(
              (region) => region.region_id != itemData.id
            );
          }
        }
      } else {
        // if item is a child

        // check if selected child's parent is already present or not
        if (
          selectedRegionTerritory.some(
            (region) => region.region_id == itemData.parentID
          )
        ) {
          selectedRegionTerritory = selectedRegionTerritory.map((region) => {
            let { territory_id = [] } = region;
            if (region.region_id == itemData.parentID) {
              if (itemData.isChecked === "true") {
                !territory_id.includes(itemData.id) &&
                  territory_id.push(itemData.id);
              } else {
                territory_id = territory_id.filter(
                  (territory) => territory != itemData.id
                );
              }
            }
            region.territory_id = territory_id;
            return region;
          });
        } else {
          selectedRegionTerritory.push({
            region_id: parseInt(itemData.parentID),
            territory_id: [itemData.id],
          });
        }
      }
    }

    // console.log(selectedRegionTerritory, "selectedRegionTerritory");
    props.setRegionWithTerritories(selectedRegionTerritory);
    props.setErrRegionOrTerri(false);
    props.setReRenderComp(!props.reRenderComp)
  };

  const hideModal = () => {
    props.setAccessGroupError(false);
    props.setRegionWithTerritories([]);
    props.handleClose();
  };
  // console.log(props, "props of add user");
  const handlePersonalPhone = (value) => {
    props.setData({
      ...props.data,
      personal_phone_number: phoneFormat(value),
    });
  };

  const handleCPhone = (value) => {
    props.setErrPhone(false);
    props.setData({
      ...props.data,
      official_phone_number: phoneFormat(value),
    });
  };

  function open(options = []) {
    //Bind nodeChecking event of the TreeView instance.
    dropDownTree.treeObj.nodeChecking = (args) => {
      console.log(args.data[0], options, dropDownTree);
      //Check whether the node is un-selected.
      if (args.action == 'uncheck' && args.data[0] && homeRegion && Object.keys(homeRegion).length) {
        let cancelEvent = false;
        if (args.isInteracted) {
          if (args.data[0].parentID && args.data[0].parentID == homeRegion.id) {
            const selected = dropDownTree.currentValue;
            const selectedChildren = selected.filter(sel => sel.includes(":") && sel.split(":")[1] == args.data[0].parentID);
            // const allChildrenByParentID = options.filter(opt => opt.region_id == args.data[0].parentID);

            if (selectedChildren.length <= 1) {
              cancelEvent = true;
            }
          } else {
            cancelEvent = args.data[0].id == homeRegion.id;
          }
        } else {
          if (args.data[0].id == homeRegion.id || args.data[0].parentID == homeRegion.id) {
            args.cancel = true;
            toast(
              (t) => (
                <ToastContent type="error" message="The region you’re retracting is the home region for the phleb. Please change the Home Region before you retract the region" close={() => toast.remove()} />
              ),
              {
                duration: 4000,
              }
            );
          }
        }

        if (cancelEvent) {
          //Cancel the selection.
          args.cancel = true;
          toast(
            (t) => (
              <ToastContent type="error" message="The region you’re retracting is the home region for the phleb. Please change the Home Region before you retract the region" close={() => toast.remove()} />
            ),
            {
              duration: 4000,
            }
          );
        }
      }
    };
  }

  return (
    <Modal
      show={props.show}
      onHide={hideModal}
      className="ad-adduser-modal"
      backdrop="static"
      centered
      enforceFocus={false}
      data-testid="modal-unique"
    >
      <Modal.Header closeButton>
        <Modal.Title className="headline4">Add New User</Modal.Title>
        {props.error && <p className="error-message-alert">{props.error}</p>}
      </Modal.Header>
      <Modal.Body>
        <p className="headline6">User Information</p>

        <form className="aha-modalform-wrapper">
          <div
            className="e-ddt e-popup e-lib e-control e-popup-open"
            id="_popup"
          ></div>
          <div
            className={
              "aha-modalform-group " + (props.err_first ? "error-message" : "")
            }
          >
            <label className="caption" htmlFor="ad-firstname">
              First Name
            </label>
            <input
              data-testid="field-firstname"
              type="text"
              className="invalid"
              id="ad-firstname"
              placeholder="First Name"
              required
              onChange={(e) => {
                props.setErrFirst(false);
                props.setData({ ...props.data, first_name: e.target.value });
              }}
            />
            {props.err_first && (
              <p data-testid="error-text-id" className="error-message-text">
                Selection Required
              </p>
            )}
          </div>
          <div className="aha-modalform-group">
            <label className="caption" htmlFor="ad-initial">
              Middle Initial/Name (Optional)
            </label>
            <input
              data-testid="field-middlename"
              type="text"
              id="ad-initial"
              placeholder="Middle Initial/Name (Optional)"
              onChange={(e) => {
                props.setData({
                  ...props.data,
                  middle_initial: e.target.value,
                });
              }}
            />
          </div>
          <div
            className={
              "aha-modalform-group " + (props.err_last ? "error-message" : "")
            }
          >
            <label className="caption" htmlFor="ad-lastname">
              Last Name
            </label>
            <input
              data-testid="field-lastname"
              type="text"
              id="ad-lastname"
              placeholder="Last Name"
              required
              onChange={(e) => {
                props.setErrLast(false);
                props.setData({ ...props.data, last_name: e.target.value });
              }}
            />
            {props.err_last && (
              <p data-testid="error-text-id" className="error-message-text">
                Selection Required
              </p>
            )}
          </div>
          <div
            className={
              "aha-modalform-group mr-0 " +
              (props.err_emp ? "error-message" : "")
            }
          >
            <label className="caption" htmlFor="ad-empid">
              Employee ID
            </label>
            <input
              data-testid="field-employeId"
              type="text"
              id="ad-empid"
              placeholder="Employee ID"
              required
              onChange={(e) => {
                props.setErrEmp(false);
                props.setData({ ...props.data, employee_id: e.target.value });
              }}
            />
            {props.err_emp && (
              <p data-testid="error-text-id" className="error-message-text">
                Selection Required
              </p>
            )}
          </div>
          <div
            className={
              "aha-modalform-group " +
              (props.err_username ? "error-message" : "")
            }
          >
            <label className="caption" htmlFor="ad-username">
              Username
            </label>
            <input
              data-testid="field-username"
              type="text"
              id="ad-username"
              placeholder="Username"
              required
              onChange={(e) => {
                props.setErrUsername(false);
                props.setData({ ...props.data, user_name: e.target.value });
              }}
            />
            {props.err_username && (
              <p data-testid="error-text-id" className="error-message-text">
                Selection Required
              </p>
            )}
          </div>
          <div
            className={
              "aha-modalform-group " +
              (props.err_email
                ? (props.type === "both" || props.type === "desktop") &&
                "error-message"
                : "")
            }
          >
            <label className="caption" htmlFor="ad-email">
              Email{" "}
              {props.type === "both"
                ? ""
                : props.type === "desktop"
                  ? ""
                  : "(Optional)"}
            </label>
            <input
              data-testid="field-email"
              type="text"
              id="ad-email"
              placeholder={
                "Email " +
                (props.type === "both"
                  ? ""
                  : props.type === "desktop"
                    ? ""
                    : "(Optional)")
              }
              onChange={(e) => {
                props.setData({ ...props.data, email_id: e.target.value });
                props.setErrEmail(false);
              }}
            />
            {props.err_email
              ? (props.type === "both" || props.type === "desktop") && (
                <p data-testid="error-text-id" className="error-message-text">
                  Selection Required
                </p>
              )
              : null}
          </div>
          <div className="aha-modalform-group">
            <label className="caption" htmlFor="ad-per-phone">
              Personal Phone (Optional)
            </label>
            <input
              data-testid="field-phone"
              type="text"
              name="p-phone"
              className="form-control"
              id="ad-per-phone"
              placeholder="Personal Phone (Optional)"
              onChange={(e) => {
                handlePersonalPhone(e.target.value);
              }}
              value={props.data.personal_phone_number}
            />
          </div>
          <div
            className={
              "aha-modalform-group mr-0 " +
              (props.err_phone
                ? (props.type === "both" || props.type === "mobile") &&
                "error-message"
                : "")
            }
          >
            <label className="caption" htmlFor="ad-comp-phone">
              Company Phone{" "}
              {props.type === "both"
                ? ""
                : props.type === "mobile"
                  ? ""
                  : "(Optional)"}
            </label>
            <input
              data-testid="field-companyphone"
              className="form-control"
              type="text"
              name="c-phone"
              id="ad-comp-phone"
              placeholder={
                "Company Phone " +
                (props.type === "both"
                  ? ""
                  : props.type === "mobile"
                    ? ""
                    : "(Optional)")
              }
              onChange={(e) => {
                handleCPhone(e.target.value);
                props.setErrPhone(false);
              }}
              value={props.data.official_phone_number}
            />
            {props.err_phone
              ? (props.type === "both" || props.type === "mobile") && (
                <p data-testid="error-text-id" className="error-message-text">
                  Selection Required
                </p>
              )
              : null}
          </div>

          <div className="aha-modalform-group2">
            <div
              className={
                "aha-modalform-group " + (props.err_job ? "error-message" : "")
              }
            >
              <label className="caption" htmlFor="job-title">
                Job Title
              </label>
              <DropDownListComponent
                data-testid="field-jobtitle"
                id="job-title"
                cssClass="modal-popup-dropdown"
                dataSource={props.job_title}
                fields={{ text: "name", value: "id" }}
                change={props.handleJob}
                placeholder="Job Title"
                required
                allowFiltering={true}
                filterBarPlaceholder="Search Job Title"
              />
              {props.err_job && (
                <p data-testid="error-text-id" className="error-message-text">
                  Selection Required
                </p>
              )}
            </div>

            <div
              className={
                "aha-modalform-group " +
                (props.err_manager ? "error-message" : "")
              }
            >
              {!props.executive && (
                <>
                  <label className="caption" htmlFor="reporting-manager">
                    Reporting Manager
                  </label>
                  <DropDownListComponent
                    data-testid="field-reportingManager"
                    id="reporting-manager"
                    cssClass="modal-popup-dropdown"
                    dataSource={props ? props.userList ? props.userList.filter(user => user.status !== "Inactive") : [] : []}
                    fields={{ text: "full_name", value: "id" }}
                    change={props.handleManager}
                    placeholder="Reporting Manager"
                    // value={this.value}
                    allowFiltering={true}
                    filterBarPlaceholder="Search Reporting Manager"
                  />
                  {props.err_manager && (
                    <p
                      data-testid="error-text-id"
                      className="error-message-text"
                    >
                      Selection Required
                    </p>
                  )}
                </>
              )}
            </div>
            <div
              className={
                "aha-modalform-group rt-dropdown-wrapper " +
                (props.err_regionOrTerri ? "error-message" : "")
              }
            >
              <label className="caption" htmlFor="region-territories">
                Regions/Territories
              </label>
              <DropDownTreeComponent
                id="region-territories"
                data-testid="field-region"
                cssClass="rt-dropdown-dialog"
                fields={{
                  dataSource: props.bc,
                  value: "id",
                  parentValue: "region_id",
                  text: "name",
                  hasChildren: "hasTrue",
                }}
                mode={"Delimiter"}
                showClearButton={false}
                treeSettings={{ autoCheck: true }}
                showSelectAll={true}
                filterBarPlaceholder="Search Regions/Territories"
                showCheckBox={true}
                placeholder="Select Regions/Territories"
                popupHeight="200px"
                select={handleRegionTerritory}
                allowMultiSelection={true}
                ref={(dropdown) => {
                  dropDownTree = dropdown;
                }}
              // open={() => open(props.bc)}
              />

              {props.err_regionOrTerri && (
                <p data-testid="error-text-id" className="error-message-text">
                  Selection Required
                </p>
              )}
            </div>
            <div
              data-testid="error-text-id"
              className={
                "aha-modalform-group  rt-dropdown-wrapper " +
                (props.err_homeRegion ? "error-message" : "")
              }
            >
              <label className="caption d-inline-flex align-items-center" htmlFor="region-territories">
                <span className="pe-2">Home Region </span>
                <OverlayTrigger
                  overlay={<Tooltip
                    id="tooltip-disabled"
                    className="service-location-tooltip"
                  >
                    <p>This field is used for Payroll purposes only and not scheduling</p>

                  </Tooltip>} >
                  <span className="info-icon  font-size-12"></span>
                </OverlayTrigger>
              </label>
              <DropDownListComponent
                id="home-region"
                cssClass="modal-popup-dropdown"
                data-testid="home-region"
                dataSource={props.regionOptions}
                fields={{ text: "name", value: "id" }}
                change={props.handleHomeRegion}
                placeholder="Home Region"
                allowFiltering={true}
                filterBarPlaceholder="Search Home Region"
                showClearButton
              />
              {props.err_homeRegion && (
                <p data-testid="error-text-id" className="error-message-text">
                  Selection Required
                </p>
              )}
            </div>
            <div
              className={
                "aha-modalform-group  rt-dropdown-wrapper "
              }
            >
              <label htmlFor="has-company-vehicle">Has Company Vehicle</label>
              <DropDownListComponent
                id="has-company-vehicle"
                data-testid="has-company-vehicle"
                dataSource={hasCompanyVehicleOptions}
                fields={{ text: "text", value: "id" }}
                change={(args) => {
                  const { itemData } = args;
                  props.setData({
                    ...props.data, has_company_vehicle: {
                      id: itemData.id,
                      value: itemData.text
                    }
                  });
                }}
                select={(args) => {
                  const { itemData } = args;
                  props.setData({
                    ...props.data, has_company_vehicle: {
                      id: itemData.id,
                      value: itemData.text
                    }
                  });
                }}
                placeholder="Has Company Vehicle"
                value={props.data.has_company_vehicle || 0}
              />
            </div>
          </div>
        </form>

        <div className="chipsetcomponent-wrapper">
          <p className="headline6 mb-15">Access Group(s)</p>
          <div className="chipsetcomponent-roles">
            <ChipListComponent
              data-testid="field-accessgroup"
              id="chip-default-adduser"
              selection="Multiple"
              // selectedChips={chipData.map((e) => {
              //   return e.index;
              // })}
              click={props.handleList}
            >
              <ChipsDirective>
                {props.roles &&
                  props.roles.map((val, i) => {
                    return (
                      <ChipDirective
                        key={i}
                        text={val.name}
                        cssClass="e-primary"
                        value={{ value: val.id, type: val.type }}
                        index={val.id}
                      ></ChipDirective>
                    );
                  })}
              </ChipsDirective>
            </ChipListComponent>
          </div>
          {props.accessGroupError && (
            <p data-testid="error-text-id" className="error-message-text">
              Selection Required
            </p>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          data-testid="button-cancel"
          className="primary-btn primary-btn-outline outline"
          onClick={() => {
            hideModal();
            props.handleError();
          }}
        >
          Cancel
        </Button>
        {/* <Button
          className="primary-btn primary-btn-outline"
          onClick={() => props.handleSave()}
        >
          Save User &amp; Invite to system
        </Button> */}
        <ProgressButtonComponent
          data-testid="button-save"
          content="Save User &amp; Invite to system"
          enableProgress={props.addLoader}
          spinSettings={{ position: "Center" }}
          duration={props.addLoader ? "3000" : "300"}
          animationSettings={{ effect: "SlideLeft" }}
          cssClass="primary-btn primary-btn-outline ad-progress-btn"
          onClick={() => props.handleSave()}
        ></ProgressButtonComponent>
      </Modal.Footer>
    </Modal>
  );
}
