import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ahaApi from "../../../app/services/aha-api";
const initialState = {
  value: [],
  loading: false,
  filters: {
    managers: [],
    phlebs: [],
    states: []
  },
  date: new Date(),
};

export const homeHealthScheduleFilter = createAsyncThunk(
  "homeHealthSchedule/filter",
  async (data, { rejectWithValue }) => {
    const response = await ahaApi.Account.eventFilterList(data)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return rejectWithValue(err.response.data);
      });
    return response;
  }
);

export const homeHealthScheduleFilterSlice = createSlice({
  name: "homeHealthScheduleFilter",
  initialState,
  reducers: {
    setFilter(state, action) {
      const { parent, property, value } = action.payload;
      state[parent] = {
        ...state[parent],
        [property]: value,
      };
    },
    setHomeHealthScheduleDate(state, action) {
      state.date = action.payload;
    },
    setHomeHealthFilterObject(state, action) {
      const { property, value } = action.payload;
      state[property] = value;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(homeHealthScheduleFilter.pending, (state) => {
        state.loading = true;
      })
      .addCase(homeHealthScheduleFilter.fulfilled, (state, action) => {
        state.loading = false;
        state.value = action.payload;
      })
      .addCase(homeHealthScheduleFilter.rejected, (state, err) => {
        state.loading = false;
        state.value = err.payload;
      });
  },
});

export const scheduleFilterList = (state) => state.homeHealthScheduleFilter.value;

export const homeHealthScheduleFilterState = (state) => state.homeHealthScheduleFilter;

export const { setFilter, setHomeHealthScheduleDate, setHomeHealthFilterObject } =
homeHealthScheduleFilterSlice.actions;

export default homeHealthScheduleFilterSlice.reducer;
