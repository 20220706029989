import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ahaApi from "../../../app/services/aha-api";
const initialState = {
  value: [],
  loading: false
};

export const addFacilities = createAsyncThunk(
  "facilities/addFacilities",
  async (data, { rejectWithValue }) => {
    const response = await ahaApi.Account.addFacilities(data)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return rejectWithValue(err.response.data);
      });

    return response;
  }
);

export const addFacilitesSlice = createSlice({
  name: "addFacilities",
  initialState,
  reducers: {
    clearAddFacilityResponse(state, action) {
      state.value = {}
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(addFacilities.pending, (state) => {
        state.loading = true;
      })
      .addCase(addFacilities.fulfilled, (state, action) => {
        state.loading = false;
        state.value = action.payload;
        state.days = action.payload.days;
      })
      .addCase(addFacilities.rejected, (state, err) => {
        state.loading = false;
        state.value = err.payload;
      });
  },
});

export const addFacilitiesList = (state) => state.addFacilities.value;
export const {clearAddFacilityResponse} = addFacilitesSlice.actions;
export default addFacilitesSlice.reducer;
