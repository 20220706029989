import * as React from "react";
import { Routine } from "./Routine";

export const Zone = React.memo((props) => {
  const { zones = {}, userIndex, serviceIndex, sectionType, date, constructPyloadForCheckinCheckout, constructPayloadToClearCheckinCheckoutSpin, user_id, getScheduleBoardData,
  blockId, uniqueServiceId, searchType, searchText = "", hasRouteStarted, showDropArea, timezone_ids, showInactiveFac } = props;
  const showNoResults = !Object.keys(zones).some(zone_id=> (!searchText
		|| zones[zone_id].zone.toLowerCase()
		  .search(searchText.toLowerCase()) !== -1))
  return (
    <div className="service-zone sch-backlog-bodycontainer">
      {Object.keys(zones).map((zone_id) => {
        const { zone: zoneName, events = {} } = zones[zone_id];

        return ((!searchText
          || zoneName.toLowerCase()
            .search(searchText.toLowerCase()) !== -1) &&
          <div
            className="service-details"
            key={userIndex + "-" + serviceIndex + "-" + zone_id}
          >
            <div className="service-rtz">
              <p className="title">{zoneName}</p>
            </div>

            <Routine
              date={date}
              events={events}
              userIndex={userIndex}
              serviceIndex={serviceIndex}
              zoneIndex={zone_id}
              sectionType={sectionType}
              handleDelete={props.handleDelete}
              constructPyloadForCheckinCheckout={constructPyloadForCheckinCheckout}
              constructPayloadToClearCheckinCheckoutSpin={constructPayloadToClearCheckinCheckoutSpin}
              user_id={user_id}
              getScheduleBoardData={getScheduleBoardData}
              uniqueServiceId={uniqueServiceId}
              blockId={blockId}
              hasRouteStarted={hasRouteStarted}
              showDropArea={showDropArea}
              timezone_ids={timezone_ids}
              showInactiveFac={showInactiveFac}
            />
            {/* <div className="facility-type">
                <span className="service-icon"></span>
                <p>Facilities: Scheduled Service Day</p>
              </div>
              <div className="service-locations">
                <p>Eastgatespring</p>
                <span className="service-icon"></span>
              </div> */}
          </div>
        );
      })}
      {showNoResults && <div className="search-no-records backlog-search-no-records d-flex justify-content-center align-items-center">
				<p className="no-result-text">	No results found</p>
			</div>}
    </div>
  );
});
