import React from "react";
import CreatePassword from "../../features/accounts/SetPassword/CreatePassword";
import { Redirect, Route, Switch } from "react-router-dom";
import {
  HOME,
  SET_PASSWORD,
  TROUBLE_LOGIN,
  FORGOT_USER,
  FORGOT_PASSWORD,
  GOOGLE_AUTH,
  VERIFY_DEVICE,
  ACCESS_STATUS,
  RESET_PASSWORD,
  LINK_EXPIRED,
  INVALID_INVITE,
  CONTACT,
  LOST_DEVICE,
  DEVICE_LINK_EXPIRED,
  MAINTENANCE,
} from "../../app/utils/Constants";
import Login from "../../features/accounts/Login/Login";
import TroubleLogin from "../../features/accounts/TroubleLogin/TroubleLogin";
import Layout from "../layouts/LoginLayout";
import ForgotPassword from "../../features/accounts/TroubleLogin/ForgotPassword";
import ForgotUser from "../../features/accounts/TroubleLogin/ForgotUser";
import GoogleAuth from "../../features/accounts/SetPassword/GoogleAuthentication";
import VerifyCode from "../../features/accounts/Login/VerifyCode";
import AccessStatus from "../../features/accounts/Login/AccessStatus";
import ResetPassword from "../../features/accounts/TroubleLogin/ResetPassword";
import LinkExpired from "../../features/accounts/TroubleLogin/LinkExpired";
import InvalidInvite from "../../features/accounts/Login/Invalid";
import Contact from "../../features/accounts/TroubleLogin/Contact";
import LostDevice from "../../features/accounts/Login/LostDevice";
import DeviceLinkExpired from "../../features/accounts/Login/DeviceLinkExpired";

export default function PublicRoute() {
  return (
    <Layout>
      <Switch>
        <Route exact path={DEVICE_LINK_EXPIRED} component={DeviceLinkExpired} />
        <Route exact path={LOST_DEVICE} component={LostDevice} />
        <Route exact path={TROUBLE_LOGIN} component={TroubleLogin} />
        <Route exact path={SET_PASSWORD} component={CreatePassword} />
        <Route exact path={HOME} component={Login} />
        <Route exact path={FORGOT_USER} component={ForgotUser} />
        <Route exact path={FORGOT_PASSWORD} component={ForgotPassword} />
        <Route exact path={GOOGLE_AUTH} component={GoogleAuth} />
        <Route exact path={VERIFY_DEVICE} component={VerifyCode} />
        <Route exact path={ACCESS_STATUS} component={AccessStatus} />
        <Route exact path={RESET_PASSWORD} component={ResetPassword} />
        <Route exact path={LINK_EXPIRED} component={LinkExpired} />
        <Route exact path={INVALID_INVITE} component={InvalidInvite} />
        <Route exact path={CONTACT} component={Contact} />
        <Redirect to={HOME} component={Login} />
      </Switch>
    </Layout>
  );
}
