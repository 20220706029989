import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../app/utils/Constants";
import {
  getProfile,
  profileList,
} from "../features/LandingPage/Profile/profileSlice";
import { CommonHeaderRightSection } from "./commons/CommonHeaderRightSection";
import { getFilter } from "../features/LandingPage/AddUser/filterSlice";
export const CommonHeader = (props) => {
  const dispatch = useDispatch();
  const profile = useSelector(profileList);
  const [color, setColor] = React.useState("");
  const [name, setName] = React.useState("");
  React.useEffect(() => {
    dispatch(getProfile());
  }, []);
  React.useEffect(() => {
    if (profile && profile.status === "SUCCESS") {
      if (profile && profile.content) {
        const userPermission =
          profile &&
          profile.content &&
          profile.content.permission &&
          profile.content.permission.filter((val, i) => {
            return val.page === "users";
          });
        const uPer =
          userPermission && userPermission[0] && userPermission[0].access;

        const filter_list =
          uPer && uPer.find((item) => item === "filter-list") === "filter-list"
            ? true
            : false;
        localStorage.setItem(
          "userDetail",
          JSON.stringify(profile && profile.content)
        );
        if (filter_list) {
          dispatch(getFilter());
        }
        setName(profile && profile.content && profile.content.name);
        setColor(profile && profile.content && profile.content.icon_color_code);
      }
    }
  }, [profile]);

  return (
    <CommonHeaderRightSection
      logout={logout}
      color={color}
      name={name}
      title={props.title || 'Messenger'}
      className="scheduler-header"
    />
  );
};
