import _ from "lodash";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ahaApi from "../../../app/services/aha-api";
const initialState = {
  value: [],
  loading: false,
};

export const backlog = createAsyncThunk(
  "schedule/backlog",
  async (data, { rejectWithValue }) => {
    const response = await ahaApi.Account.eventBacklog(data)
      .then((res) => {
        res.content = backlogData(res.content);
        return res;
      })
      .catch((err) => {
        return rejectWithValue(err.response.data);
      });
    return response;
  }
);

const backlogData = (backlogData) => {
  let resultBacklogData = _.cloneDeep(backlogData);

  resultBacklogData.services.forEach((service, serviceIndex) => {
    if (service.zones) {
      service.zones.forEach((zone, zoneIndex) => {
        if (zone.events) {
          resultBacklogData.services[serviceIndex].zones[zoneIndex].events =
            _.keyBy(zone.events, "event_type_id");
        }
      });

      let resultZones = resultBacklogData.services[serviceIndex].zones;

      resultBacklogData.services[serviceIndex].zones = _.keyBy(
        resultZones,
        "zone_id"
      );
    }
  });

  return resultBacklogData;
};

export const backlogSlice = createSlice({
  name: "backlog",
  initialState,
  reducers: {
    updateBacklogValue(state, action) {
      state.value = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(backlog.pending, (state) => {
        state.loading = true;
      })
      .addCase(backlog.fulfilled, (state, action) => {
        state.loading = false;
        state.value = action.payload;
      })
      .addCase(backlog.rejected, (state, err) => {
        state.loading = false;
        state.value = err.payload;
      });
  },
});

export const backlogList = (state) => state.backlog.value;
export const { updateBacklogValue } = backlogSlice.actions;

export default backlogSlice.reducer;
