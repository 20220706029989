import React from "react";
import { Modal } from "react-bootstrap";
const StatOrderHeader = (props) => {

	const { mode, error } = props;
	return (
		<React.Fragment>
			{mode === "view" ? (
				<Modal.Title data-testid="stat-order-modal-header-view" className="headline4">View Stat Order</Modal.Title>
			) : mode === "create" ? (
				<React.Fragment>
					<Modal.Title data-testid="stat-order-modal-header-create" className="headline4"> Create Stat Order</Modal.Title>
					{error && <p className="error-message-alert" data-testid="stat-order-modal-header-create-error">{error}</p>}
				</React.Fragment>
			) : (
				<React.Fragment>
					<Modal.Title data-testid="stat-order-modal-header-edit" className="headline4"> Edit Stat Order</Modal.Title>
					{error && <p className="error-message-alert" data-testid="stat-order-modal-header-edit-error">{error}</p>}
				</React.Fragment>
			)}
		</React.Fragment>
	);
}

export default StatOrderHeader;
