import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import React from "react";
import { Button, InputGroup, Modal, Tab, Table, Tabs } from "react-bootstrap";
import { ProgressButtonComponent } from "@syncfusion/ej2-react-splitbuttons";
import { phoneFormat } from "../../../app/utils/testUtils";
import Select from "react-select";
import _ from "lodash";
import { isEmpty } from "@aws-amplify/core";
import { MapBoxInputSearch } from "../../../components/commons/mapBoxInputSearch";
import toast from "react-hot-toast";
import ToastContent from "../../../components/layouts/ToastContent";
import { LABLIST, escapeRegExp } from "../../../app/utils/Constants";
import * as XLSX from 'xlsx';
import moment from "moment";

const regexNumber = /^[0-9-()]*$/;
// const alphanumeric = /^[\w-_.]*$/;
const timeRegex = new RegExp(':', 'g');
const notaplha = /^[0-9.-]*$/;

const checkOption = (sTime, eTime, option, type = "start") => {
	return type === "start"
		? Object.keys(eTime).length === 0
			? false
			: parseInt(option.value.replace(timeRegex, ''), 10) >= parseInt(eTime.value.replace(timeRegex, ''), 10)
		: Object.keys(sTime).length === 0
			? false
			: parseInt(option.value.replace(timeRegex, ''), 10) <= parseInt(sTime.value.replace(timeRegex, ''), 10)
};


const constructTimeOptions = () => {
	let options = [];
	for (let i = 0; i < 24; i++) {
		options.push({
			label: `${i < 10 ? "0" : ""}${i}:00`,
			value: `${i < 10 ? "0" : ""}${i}:00`,
		});
		options.push({
			label: `${i < 10 ? "0" : ""}${i}:15`,
			value: `${i < 10 ? "0" : ""}${i}:15`,
		});
		options.push({
			label: `${i < 10 ? "0" : ""}${i}:30`,
			value: `${i < 10 ? "0" : ""}${i}:30`,
		});
		options.push({
			label: `${i < 10 ? "0" : ""}${i}:45`,
			value: `${i < 10 ? "0" : ""}${i}:45`,
		});
	}
	options = options.map((opt, index) => {
		return { ...opt, id: index };
	});
	return options;
};

class LabAddEdit extends React.PureComponent {
	constructor(props) {
		super(props);
		this.searchRef = React.createRef('');
		this.state = {
			errors: {},
			data: {},
			initialData: {},
			mode: this.props.mode ? this.props.mode : "view",
			isFormChanged: false,
			activeTab: 'info',
			searchText: '',
			clearMapboxSearchTextLabHospital: false
		};
	  }

	componentDidMount = () => {
		const { labData = {} } = this.props;
		this.updateLabData(labData);
	};
	componentDidUpdate = (prevProps, prevState) => {
		const {
			labData = {},
			mode,
			updateLabList = {},
			addLabList = {},
		} = this.props;
		if (prevProps.labData !== labData) {
			this.updateLabData(labData);
		}
		if (prevProps.mode !== mode) {
			if (mode === "edit") {

			}
			this.setState({ mode });
		}
		if (
			updateLabList !== prevProps.updateLabList &&
			Object.keys(updateLabList).length !== 0
		) {
			if (updateLabList.status === "SUCCESS") {
				this.setData({});
				this.setState({ errors: {} });
			}
		}
		if (
			addLabList !== prevProps.addLabList &&
			Object.keys(addLabList).length !== 0
		) {
			if (addLabList.status === "SUCCESS") {
				this.setData({});
				this.setState({ errors: {} });
			}
		}
		if (this.state.initialData !== this.state.data) {
			this.setState({
				isFormChanged: this.validateEditFields(this.state.initialData)
			})
		}
	};

	validateEditFields = (initialData) => {
		let result = false;

		const {
			name = "",
			address1 = "",
			labType = "",
			city = "",
			stateName = "",
			zip = "",
			timezone = "",
			phoneNumber = "",
			phoneNumberExt = "",
			notes = "",
			status = "",
			timings = {},
			longitudeName = "",
			latitudeName = "",
			primaryContactOfficePhoneNumberExt = "",
			primaryContactOfficePhoneNumber = "",
			primaryContactName = "",
			primaryContactCellPhoneNumber = "",
			primaryContactEmail = "",
		} = this.state.data;

		const {
			name: prevName = "",
			address1: prevAddress = "",
			labType: prevLabType = "",
			city: prevCity = "",
			stateName: prevStateName = "",
			zip: prevZip = "",
			timezone: prevTimezone = "",
			phoneNumber: prevPhoneNumber = "",
			phoneNumberExt: prevPhoneNumberExt = "",
			notes: prevNotes = "",
			status: prevStatus = "",
			timings: prevTimings = {},
			longitudeName: prevLongitudeName = "",
			latitudeName: prevLatitudeName = "",
			primaryContactOfficePhoneNumberExt: prevPrimaryContactOfficePhoneNumberExt = "",
			primaryContactOfficePhoneNumber: prevPrimaryContactOfficePhoneNumber = "",
			primaryContactName: prevPrimaryContactName = "",
			primaryContactCellPhoneNumber: prevPrimaryContactCellPhoneNumber = "",
			primaryContactEmail: prevPrimaryContactEmail = "",
		} = initialData;

		const allFieldsExceptTimingsFlag = !_.isEqual(name, prevName) || !_.isEqual(labType, prevLabType) || !_.isEqual(phoneNumber, prevPhoneNumber)
			|| !_.isEqual(phoneNumberExt, prevPhoneNumberExt) || !_.isEqual(timezone, prevTimezone) || !_.isEqual(primaryContactName, prevPrimaryContactName) ||
			!_.isEqual(primaryContactCellPhoneNumber, prevPrimaryContactCellPhoneNumber) || !_.isEqual(primaryContactOfficePhoneNumber, prevPrimaryContactOfficePhoneNumber) ||
			!_.isEqual(primaryContactOfficePhoneNumberExt, prevPrimaryContactOfficePhoneNumberExt) || !_.isEqual(primaryContactEmail, prevPrimaryContactEmail) ||
			!_.isEqual(address1, prevAddress) || !_.isEqual(city, prevCity) || !_.isEqual(stateName, prevStateName) || !_.isEqual(zip, prevZip) ||
			!_.isEqual(latitudeName ? latitudeName.toString() : latitudeName, prevLatitudeName ? prevLatitudeName.toString() : prevLatitudeName) || !_.isEqual(longitudeName ? longitudeName.toString() : longitudeName, prevLongitudeName ? prevLongitudeName.toString() : prevLongitudeName) || !_.isEqual(notes, prevNotes) || !_.isEqual(status, prevStatus);

		if (allFieldsExceptTimingsFlag) {
			result = allFieldsExceptTimingsFlag;
		} else {
			let timings1 = {};
			let timings2 = {};
			Object.keys(prevTimings).forEach(tim1 => {
				if (prevTimings[tim1] && prevTimings[tim1].value) {
					const { startTime = {}, endTime = {} } = prevTimings[tim1] || {};
					timings1[tim1] = {
						startTime: startTime.value,
						endTime: endTime.value
					}
				}
			});

			Object.keys(timings).forEach(tim1 => {
				if (timings[tim1] && timings[tim1].value) {
					const { startTime = {}, endTime = {} } = timings[tim1] || {};
					timings2[tim1] = {
						startTime: startTime.value || "",
						endTime: endTime.value || ""
					}
				}
			});
			if (Object.keys(timings1).length === Object.keys(timings2).length) {
				let counter = 0;
				Object.keys(timings1).forEach(tim1 => {
					if (Object.keys(timings2).includes(tim1)) {
						counter++;
					}
				});
				if (counter === Object.keys(timings1).length) {
					let timeCounter = 0;
					Object.keys(timings1).forEach(tim1 => {
						if (timings1[tim1].startTime === timings2[tim1].startTime && timings1[tim1].endTime === timings2[tim1].endTime) {
							timeCounter++;
						}
					});
					if (timeCounter === Object.keys(timings1).length) {
						result = allFieldsExceptTimingsFlag;
					} else {
						result = true;
					}
				} else {
					result = true;
				}
			} else {
				result = true;
			}
		}
		return result;
	}

	updateLabData = (labData) => {
		const { days = [], mode } = this.props;
		const {
			lab_name,
			timezone = {},
			lab_contact_phone_number,
			street_address1,
			street_address2,
			city: selCity,
			state = {},
			zip: selZip,
			lab_notes,
			id,
			status = {},
			lab_timings = [],
			lab_contact_phone_number_ext,
			latitude,
			longitude,
			lab_type = {},
			primary_contact_cell_phoneNumber,
			primary_contact_email,
			primary_contact_office_phone_number,
			primary_contact_office_phone_number_ext,
			primary_contact_name,
			audit_histories = []
		} = labData;
		const timings = {};
		const formattedDays = {};
		days.forEach((day) => {
			formattedDays[day.id] = day.name;
		});
		if (lab_timings && lab_timings.length) {
			lab_timings.forEach((day) => {
				let eTimeTemp = "";
				const eTimeArray = day.end_time ? day.end_time.split(":") : [];
				if (eTimeArray.length > 1) {
					eTimeTemp = eTimeArray[0] + ":" + eTimeArray[1];
				}

				let sTimeTemp = "";
				const sTimeArray = day.start_time ? day.start_time.split(":") : [];
				if (sTimeArray.length > 1) {
					sTimeTemp = sTimeArray[0] + ":" + sTimeArray[1];
				}
				timings[formattedDays[day.day_id]] = {
					value: true,
					startTime: sTimeTemp ? { label: sTimeTemp, value: sTimeTemp } : "",
					endTime: eTimeTemp ? { label: eTimeTemp, value: eTimeTemp } : ""
				};
			});
		}

		const finalObj = {
			name: lab_name,
			labType: lab_type ? lab_type.id : "",
			phoneNumber: lab_contact_phone_number
				? phoneFormat(lab_contact_phone_number.toString())
				: null,
			phoneNumberExt: lab_contact_phone_number_ext || "",
			primaryContactCellPhoneNumber: primary_contact_cell_phoneNumber
				? phoneFormat(primary_contact_cell_phoneNumber.toString())
				: null,
			primaryContactOfficePhoneNumber: primary_contact_office_phone_number
				? phoneFormat(primary_contact_office_phone_number.toString())
				: null,
			primaryContactOfficePhoneNumberExt: primary_contact_office_phone_number_ext || "",
			primaryContactEmail: primary_contact_email || "",
			primaryContactName: primary_contact_name || "",
			timezone: timezone
				? timezone.timezone_id
					? timezone.timezone_id
					: ""
				: "",
			address1: street_address1,
			address2: street_address2,
			city: selCity,
			stateName: state ? (state.state_code ? state.state_code : "") : "",
			zip: selZip,
			notes: lab_notes,
			id,
			status: status ? (status.id ? status.id : "") : "",
			latitudeName: latitude ? latitude : "",
			longitudeName: longitude ? longitude : "",
			timings:
				mode === "create"
					? {}
					: timings,
			audit_histories
		};
		this.setState({
			data: finalObj,
			initialData: finalObj
		});
	};
	setData = (data, fieldName) => {
		const errors = this.state.errors;
		errors[fieldName] = false;
		this.setState({ data, errors });
	};

	handleSubmit = () => {
		const {
			// territory = [],
			days = [],
			history,
			paramId
		} = this.props;
		const { data, mode = "" } = this.state;
		const errors = this.validateFields(data) || {};
		if (Object.keys(errors).length === 0) {
			const {
				name = "",
				labType,
				phoneNumberExt = "",
				phoneNumber = "",
				timezone = "",
				address1 = "",
				address2 = "",
				city = "",
				stateName = "",
				zip = "",
				timings = {},
				notes = "",
				id,
				status = "",
				latitudeName = "",
				longitudeName = "",
				primaryContactOfficePhoneNumberExt = "", primaryContactOfficePhoneNumber = "", primaryContactName = "",
				primaryContactCellPhoneNumber = "", primaryContactEmail = ""
			} = data;

			let labTimings = [];
			Object.keys(timings).forEach((timing) => {
				const { startTime = {}, endTime = {} } = timings[timing];
				if (days.some((d) => d.name === timing) && startTime.value && endTime.value) {
					const id = days.filter((d) => d.name === timing)
						? days.filter((d) => d.name === timing)[0]
							? days.filter((d) => d.name === timing)[0].id
							: 0
						: 0;

					id && labTimings.push({ day_id: id, start_time: startTime.value, end_time: endTime.value });
				}
			});

			let phone = "";
			if (typeof phoneNumber === "string" && !_.isEmpty(phoneNumber)) {
				phone = phoneNumber.match(/\d/g);
				phone = phone.join("");
			} else {
				phone = phoneNumber;
			}

			let formattedPrimaryContactOfficeNumber = "";
			if (typeof primaryContactOfficePhoneNumber === "string" && !_.isEmpty(primaryContactOfficePhoneNumber)) {
				formattedPrimaryContactOfficeNumber = primaryContactOfficePhoneNumber.match(/\d/g);
				formattedPrimaryContactOfficeNumber = formattedPrimaryContactOfficeNumber.join("");
			} else {
				formattedPrimaryContactOfficeNumber = primaryContactOfficePhoneNumber;
			}

			let formattedPrimaryContactCellPhoneNumber = "";
			if (typeof primaryContactCellPhoneNumber === "string" && !_.isEmpty(primaryContactCellPhoneNumber)) {
				formattedPrimaryContactCellPhoneNumber = primaryContactCellPhoneNumber.match(/\d/g);
				formattedPrimaryContactCellPhoneNumber = formattedPrimaryContactCellPhoneNumber.join("");
			} else {
				formattedPrimaryContactCellPhoneNumber = primaryContactCellPhoneNumber;
			}

			const payload = {
				id,
				lab_name: name,
				lab_type_id: labType,
				lab_contact_phone_number: phone ? Number(phone) : null,
				lab_contact_phone_number_ext: phoneNumberExt ? Number(phoneNumberExt) : null,
				timezone_id: timezone,
				street_address1: address1,
				street_address2: address2,
				city,
				state: stateName,
				zip: Number(zip),
				lab_notes: notes,
				status: status,
				lab_timings: labTimings,
				latitude: latitudeName,
				longitude: longitudeName,
				primary_contact_office_phone_number: formattedPrimaryContactOfficeNumber ? Number(formattedPrimaryContactOfficeNumber) : null,
				primary_contact_office_phone_number_ext: primaryContactOfficePhoneNumberExt ? Number(primaryContactOfficePhoneNumberExt) : null,
				primary_contact_cell_phone_number: formattedPrimaryContactCellPhoneNumber ? Number(formattedPrimaryContactCellPhoneNumber) : null,
				primary_contact_email: primaryContactEmail,
				primary_contact_name: primaryContactName
			};
			if (mode === "create") {
				this.props.handleCreateLab(payload);
			} else {
				this.props.handleUpdateLab(payload);
			}
		} else {
			this.setState({ errors }, () => {
				toast(
					(t) => (
						<ToastContent type="error" message={`Check if all required fields have been filled in before you ${mode === "edit" ? "update" : "create"} a lab`} close={() => toast.remove()} />
					),
					{
						duration: 4000,
					}
				);
				if (document.querySelector('.error-message')) {
					document.querySelector('.error-message').scrollIntoView({
						behavior: 'smooth'
					});
				} else if (document.querySelector('.error-message-text')) {
					document.querySelector('.error-message-text').scrollIntoView({
						behavior: 'smooth'
					});
				}
			});
		}
		if (paramId) {
			history.replace(LABLIST, { shallow: true });
		}
	};

	validateFields = (data) => {
		const errors = {};

		const {
			name = "",
			phoneNumber = "",
			timezone = "",
			address1 = "",
			city = "",
			stateName = "",
			status = "",
			zip = "",
			timings = {},
			labType,
			primaryContactName = "",
			primaryContactCellPhoneNumber = "",
			// primaryContactOfficePhoneNumber = ""
		} = data;

		if (_.isEmpty(name)) {
			errors["name"] = true;
		}
		if (!labType) {
			errors["labType"] = true;
		}
		if (_.isEmpty(phoneNumber)) {
			errors["phoneNumber"] = true;
		}
		if (_.isEmpty(phoneNumber)) {
			errors["phoneNumber"] = true;
		}
		if (_.isEmpty(primaryContactName)) {
			errors["primaryContactName"] = true;
		}
		if (_.isEmpty(primaryContactCellPhoneNumber)) {
			errors["primaryContactCellPhoneNumber"] = true;
		}
		if (!timezone) {
			errors["timezone"] = true;
		}
		if (_.isEmpty(address1)) {
			errors["address1"] = true;
		}
		if (_.isEmpty(city)) {
			errors["city"] = true;
		}
		if (_.isEmpty(stateName)) {
			errors["stateName"] = true;
		}
		if (_.isEmpty(zip)) {
			errors["zip"] = true;
		}
		if (_.isEmpty(status)) {
			errors["status"] = true;
		}
		if (
			!Object.keys(timings).length ||
			Object.keys(timings).every((day) => !timings[day].value) ||
			Object.keys(timings).every((day) => (!timings[day].startTime && !timings[day].endTime) || (!timings[day].startTime || !timings[day].endTime))
		) {
			errors["timings"] = true;
		}
		return errors;
	};
	handleClose = (e) => {
		const { mode, activeTab } = this.state;
		const {paramId, history} = this.props;
		if (activeTab === 'audit') {
			this.setState({ errors: {}, searchText: '', activeTab: 'info', clearMapboxSearchTextLabHospital: true });
			this.props.handleLabError();
			this.props.toggleLabModal(false);
		} else {
			if (mode === "view" || mode === "create") {
				this.setState({ errors: {}, searchText: '', activeTab: 'info', clearMapboxSearchTextLabHospital: true });
				this.props.handleLabError();
				this.props.toggleLabModal(false);
			} else {
				const { labData = {} } = this.props;
				this.updateLabData(labData);
				this.setState({ errors: {}, searchText: '', activeTab: 'info', clearMapboxSearchTextLabHospital: true });
				this.props.handleLabError();
			}
		}
		if (paramId) {
			history.replace(LABLIST, { shallow: true });
		}
	};

	onFocus = (event) => {
		event.target.setAttribute('autocomplete', 'none');
	}

	handleExcelExport = (e, data) => {
		e.preventDefault();
		const modifiedData = [...data];
		const modifiedHeaders = this.renameHeaders(modifiedData);
		const ws = XLSX.utils.json_to_sheet(modifiedHeaders);
		const wb = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');
		XLSX.writeFile(wb, `${'Lab-AuditLogs'}.xlsx`);
	}

	handleInputChange = (e) => {
		const userInput = e.target.value;
		const escapedInput = escapeRegExp(userInput);
		const regex = new RegExp(`(${escapedInput})`);
		this.setState({ searchText: escapedInput });
	};

	handleChangeTab = (val) => {
		this.setState({ searchText: '', activeTab: val });
	}

	renameHeaders = (originalData) => {
		return originalData.map((item) => ({
			'Date/Time': item.datetimeString,
			'Changed By': item.changedByString,
			'Change': item.activity,
		}));
	};

	clearSearchInput = () => {
		this.setState({searchText: ''}, () => {
		  if (this.searchRef && this.searchRef.current) {
			this.searchRef.current.value = "";
		  }
		})
	  }

	render() {
		const {
			error,
			days = [],
			timeZones = [],
			// states = [],
			isOpen = false,
			labData = {},
			labTypes = [],
			labObjLoading
		} = this.props;

		const permission =
			(window.localStorage.getItem("userDetail") &&
				JSON.parse(window.localStorage.getItem("userDetail")).permission &&
				JSON.parse(window.localStorage.getItem("userDetail")).permission) ||
			[];
		const labPermission = permission.filter(
			(perm) => perm.page === "labs"
		);
		const labAccess =
			labPermission.length === 1 ? labPermission[0].access : [];
		const canUpdateLab =
			labAccess &&
				labAccess.find((item) => item === "update") === "update"
				? true
				: false;

		const { errors, mode, data, isFormChanged, activeTab, searchText, clearMapboxSearchTextLabHospital } = this.state;
		const { setData, handleSubmit, handleClose, onFocus, handleInputChange, handleExcelExport, handleChangeTab } = this;

		const {
			name = "",
			address1 = "",
			labType = "",
			city = "",
			stateName = "",
			zip = "",
			timezone = "",
			phoneNumber = "",
			phoneNumberExt = "",
			notes = "",
			status = "",
			timings = {},
			longitudeName = "",
			latitudeName = "",
			primaryContactOfficePhoneNumberExt = "",
			primaryContactOfficePhoneNumber = "",
			primaryContactName = "",
			primaryContactCellPhoneNumber = "",
			primaryContactEmail = "",
		} = data;

		const {audit_histories = []} = labData;

		const headers = [
			{ label: "Date/ Time", key: "datetimeString" },
			{ label: "Changed By", key: "changedByString" },
			{ label: "Change", key: "activity" }
		];

		let auditLogs = [];
		audit_histories && audit_histories.length > 0 && audit_histories.forEach((history) => {
			const {
				user = {},
				activity,
				datetime = "",
				type,
			} = history;
			const datetimeString = moment(datetime).format("MM/DD/YYYY HH:mm:ss");
			const changedByString = `${user
				? `${user.name}${user.username ? ` (${user.username})` : ""
				}` || ""
				: ""}
			${type
					? ` (${type})`
					: activity === "Order Updated"
						? ` (DESKTOP)`
						: ""}`
			if ((!searchText || activity.toLowerCase().search(searchText.toLowerCase()) !== -1 ||
				datetimeString.toLowerCase().search(searchText.toLowerCase()) !== -1 || changedByString.toLowerCase().search(searchText.toLowerCase()) !== -1)) {
				auditLogs.push({
					...history,
					activity, datetimeString, changedByString
				})
			}
		});
console.log(isFormChanged, 'isFormChanged');
		return (
			<div>
				<Modal
					show={isOpen}
					onHide={handleClose}
					className="ad-adduser-modal"
					backdrop="static"
					centered
					enforceFocus={false}
					data-testid="modal-unique"
				>
					<div
						className={"ad-loading-wrapper " + (labObjLoading ? "d-block" : "d-none")}
					>
						<span
							className={"loader ad-loader " + (labObjLoading ? "d-block" : "d-none")}
						>
							<span className="visually-hidden">loader icon</span>
						</span>
					</div>
					<Modal.Header closeButton>
						{mode === "view" ? (
							<Modal.Title className="headline4">Lab</Modal.Title>
						) : mode === "create" ? (
							<React.Fragment>
								<Modal.Title className="headline4">
									{" "}
									Add New Lab
								</Modal.Title>
								{error && <p className="error-message-alert">{error}</p>}
							</React.Fragment>
						) : (
							<React.Fragment>
								<Modal.Title className="headline4">
									{" "}
									Edit Lab
								</Modal.Title>
								{error && <p className="error-message-alert">{error}</p>}
							</React.Fragment>
						)}
					</Modal.Header>
					<Modal.Body>
						{mode === "create" ? <p className="headline6">Account Information</p>
							: <div className="ad-headerbottom-wrapper">
								<Tabs className="ad-navtab help-nav-tab" activeKey={activeTab} onSelect={(k) => handleChangeTab(k)} >
									<Tab
										eventKey={'info'}
										title="Account Information"
										className="stat-order-wrapper"
									></Tab>
									<Tab
										eventKey={'audit'}
										title="Audit Logs"
										className="stat-order-wrapper"
									></Tab>
								</Tabs>
							</div>}
						{activeTab === 'info' && <form className="aha-modalform-wrapper ad-care-modalform-wrapper">
							<div
								className={
									"aha-modalform-group  flex-50 " +
									(errors.name ? "error-message" : "")
								}
							>
								<label className="caption" htmlFor="acf-name">
									Lab Name
								</label>
								<input
									data-testid="field-accountName"
									type="text"
									id="acf-name"
									value={name}
									placeholder="Lab Name"
									autoComplete="nope"
									onFocus={onFocus}
									required
									onChange={(e) => {
										setData(
											{
												...data,
												name: e.target.value,
											},
											"name"
										);
									}}
									disabled={mode === "view"}
								/>
								{errors.name && (
									<p data-testid="error-text-id" className="error-message-text">
										Selection Required
									</p>
								)}
							</div>
							<div
								className={
									"aha-modalform-group " +
									(errors.labType ? "error-message" : "")
								}
							>
								<label className="caption" htmlFor="lab-type">
									Lab Type
								</label>
								<DropDownListComponent
									id="lab-type"
									cssClass="modal-droprdown-input modal-popup-dropdown"
									placeholder="Please choose"
									dataSource={labTypes}
									data-testid="field-timezone"
									fields={{ text: "name", value: "id" }}
									required
									filterBarPlaceholder="lab Type"
									allowFiltering={true}
									enabled={mode !== "view"}
									value={labType}
									change={(e) => {
										if (e.isInteracted) {
											const { itemData = {} } = e;
											setData(
												{
													...data,
													labType: itemData.id,
												},
												"labType"
											);
										}
									}}
								/>
								{errors.labType && (
									<p data-testid="error-text-id" className="error-message-text">
										Selection Required
									</p>
								)}
							</div>
							<div
								className={
									"aha-modalform-group ch-form-group " +
									(errors.phoneNumber ? "error-message" : "")
								}
							>
								<div className="form-phext-wrapper">
									<div className="phno-field">
										<label className="caption" htmlFor="acf-lab-phno">
											Lab Phone Number
										</label>
										<input
											type="text"
											name="fphone"
											id="acf-lab-phno"
											className="form-phext-input"
											data-testid="field-phoneNumber"
											autoComplete="nope"
											onFocus={onFocus}
											placeholder="Lab Phone Number"
											required
											value={phoneNumber}
											onChange={(e) => {
												this.setData(
													{
														...this.state.data,
														phoneNumber: phoneFormat(e.target.value),
													},
													"phoneNumber"
												);
											}}
											disabled={mode === "view"}
										/>
									</div>
									<div className="extension-field">
										<label
											className="caption"
											htmlFor="acf-facility-phno-extension"
										>
											<span className="visually-hidden">
												Extension (optional)
											</span>
										</label>
										<input
											// type="number"
											className="form-phext-input"
											autoComplete="nope"
											onFocus={onFocus}
											id="acf-facility-phno-extension"
											data-testid="field-facExt"
											placeholder="Ext"
											onChange={(e) => {
												regexNumber.test(e.target.value) &&
													setData({
														...data,
														phoneNumberExt: e.target.value,
													});
											}}
											value={phoneNumberExt}
											disabled={mode === "view"}
										/>
									</div>
								</div>
								{errors.phoneNumber && (
									<p data-testid="error-text-id" className="error-message-text">
										Selection Required
									</p>
								)}
							</div>
							<div
								className={
									"aha-modalform-group ch-form-group mr-0 " +
									(errors.timezone ? "error-message" : "")
								}
							>
								<label className="caption" htmlFor="acf-lab-timezone">
									Timezone
								</label>
								<DropDownListComponent
									id="acf-lab-timezone"
									cssClass="modal-droprdown-input modal-popup-dropdown"
									placeholder="Please choose"
									dataSource={timeZones}
									data-testid="field-timezone"
									fields={{ text: "name", value: "id" }}
									required
									filterBarPlaceholder="Timezone"
									allowFiltering={true}
									enabled={mode !== "view"}
									value={timezone}
									change={(e) => {
										if (e.isInteracted) {
											const { itemData = {} } = e;
											setData(
												{
													...data,
													timezone: itemData.id,
												},
												"timezone"
											);
										}
									}}
								/>
								{errors.timezone && (
									<p data-testid="error-text-id" className="error-message-text">
										Selection Required
									</p>
								)}
							</div>
							<div
								className={
									"aha-modalform-group  ch-form-group " +
									(errors.primaryContactName ? "error-message" : "")
								}
							>
								<label className="caption" htmlFor="acf-lab-name">
									Primary Contact Name
								</label>
								<input
									data-testid="field-accountName"
									type="text"
									id="acf-lab-name"
									value={primaryContactName}
									placeholder="Primary Contact Name"
									autoComplete="nope"
									onFocus={onFocus}
									required
									onChange={(e) => {
										setData(
											{
												...data,
												primaryContactName: e.target.value,
											},
											"primaryContactName"
										);
									}}
									disabled={mode === "view"}
								/>
								{errors.primaryContactName && (
									<p data-testid="error-text-id" className="error-message-text">
										Selection Required
									</p>
								)}
							</div>
							<div className={"aha-modalform-group ch-form-group"}>
								<div className="form-phext-wrapper">
									<div className="phno-field">
										<label className="caption" htmlFor="acf-lab-phno1">
											Primary Contact Office Phone Number (optional)
										</label>
										<input
											type="text"
											name="fphone"
											id="acf-lab-phno1"
											className="form-phext-input"
											data-testid="field-phoneNumber"
											autoComplete="nope"
											onFocus={onFocus}
											placeholder="Lab Phone Number"
											required
											value={primaryContactOfficePhoneNumber}
											onChange={(e) => {
												this.setData(
													{
														...this.state.data,
														primaryContactOfficePhoneNumber: phoneFormat(e.target.value),
													},
													// "primaryContactOfficePhoneNumber"
												);
											}}
											disabled={mode === "view"}
										/>
									</div>
									<div className="extension-field">
										<label
											className="caption"
											htmlFor="acf-lab-phno-extension"
										>
											<span className="visually-hidden">
												Extension (optional)
											</span>
										</label>
										<input
											// type="number"
											className="form-phext-input"
											autoComplete="nope"
											onFocus={onFocus}
											id="acf-lab-phno-extension"
											data-testid="field-facExt"
											placeholder="Ext"
											onChange={(e) => {
												regexNumber.test(e.target.value) &&
													setData({
														...data,
														primaryContactOfficePhoneNumberExt: e.target.value,
													});
											}}
											value={primaryContactOfficePhoneNumberExt}
											disabled={mode === "view"}
										/>
									</div>
								</div>
								{/* {errors.primaryContactOfficePhoneNumber && (
									<p data-testid="error-text-id" className="error-message-text">
										Selection Required
									</p>
								)} */}
							</div>
							<div
								className={
									"aha-modalform-group ch-form-group " +
									(errors.primaryContactCellPhoneNumber ? "error-message" : "")
								}
							>
								<div className="form-phext-wrapper">
									<div className="phno-field cell-phno-field">
										<label className="caption" htmlFor="acf-lab-cellphno">
											Primary Contact Cell Phone Number
										</label>
										<input
											type="text"
											name="fphone"
											id="acf-lab-cellphno"
											className="form-phext-input"
											data-testid="field-phoneNumber"
											autoComplete="nope"
											onFocus={onFocus}
											placeholder="Primary Contact Cell Phone Number"
											required
											value={primaryContactCellPhoneNumber}
											onChange={(e) => {
												this.setData(
													{
														...this.state.data,
														primaryContactCellPhoneNumber: phoneFormat(e.target.value),
													},
													"primaryContactCellPhoneNumber"
												);
											}}
											disabled={mode === "view"}
										/>
									</div>
								</div>
								{errors.primaryContactCellPhoneNumber && (
									<p data-testid="error-text-id" className="error-message-text">
										Selection Required
									</p>
								)}
							</div>
							<div
								className={
									"aha-modalform-group ch-form-group "
								}
							>
								<label className="caption" htmlFor="acf-lab-email">
									Primary Contact Email (optional)
								</label>
								<input
									data-testid="field-accountName"
									type="text"
									id="acf-lab-email"
									value={primaryContactEmail}
									placeholder="Primary Contact Email"
									autoComplete="nope"
									onFocus={onFocus}
									required
									onChange={(e) => {
										setData(
											{
												...data,
												primaryContactEmail: e.target.value,
											}
										);
									}}
									disabled={mode === "view"}
								/>
							</div>
							<div
								className={
									"aha-modalform-group flex-75 mr-3 " +
									(errors.isInvalid ? "error-message" : "")
								}
							>
								<label className="caption" htmlFor="ad-search-address">
									Search Address
								</label>
								<MapBoxInputSearch
									id="ad-search-lab-address"
									pageType="lab"
									handleChange={(newData, isInvalid = false) => {
										errors["isInvalid"] = isInvalid;
										errors["address1"] = false;
										this.setState({
											data: {
												...data,
												...newData
											}, errors
										})

									}}
									stateData={data}
									disabled={mode === "view"}
									clearMapboxSearchTextLabHospital={clearMapboxSearchTextLabHospital}
									setClearMapboxSearchTextLabHospital={(val) => this.setState({ clearMapboxSearchTextLabHospital: val })}
								/>
								{errors.isInvalid &&
									<p data-testid="error-text-id" className="error-message-text">
										Invalid Address
									</p>}
							</div>
							<div className={
								"aha-modalform-group flex-75 aha-modalform-group flex-75 mr-3 " +
								(errors.address1 ? "error-message" : "")
							}>
								<label className="caption" htmlFor="ad-street-address2">
									Street Address 1
								</label>
								<input
									type="text"
									id="ad-lab-street-address"
									placeholder="Street Address 1"
									autoComplete="nope"
									onFocus={onFocus}
									value={address1}
									data-testid="field-address1"
									onChange={(e) => {
										setData(
											{
												...data,
												address1: e.target.value,
											},
											"address1"
										);
									}}
									// disabled={mode === "view"}
									disabled={true}
								/>
								{errors.address1 && (
									<p data-testid="error-text-id" className="error-message-text">
										Selection Required
									</p>
								)}
							</div>

							<div
								className={
									"aha-modalform-group " + (errors.city ? "error-message" : "")
								}
							>
								<label className="caption" htmlFor="ad-city">
									City
								</label>
								<input
									type="text"
									id="ad-city"
									placeholder="City"
									data-testid="field-city"
									autoComplete="nope"
									onFocus={onFocus}
									value={city}
									onChange={(e) => {
										setData(
											{
												...data,
												city: e.target.value,
											},
											"city"
										);
									}}
									// disabled={mode === "view"}
									disabled={true}
								/>
								{errors.city && (
									<p data-testid="error-text-id" className="error-message-text">
										Selection Required
									</p>
								)}
							</div>
							<div
								className={
									"aha-modalform-group " +
									(errors.stateName ? "error-message" : "")
								}
							>
								<label className="caption" htmlFor="ad-state">
									State
								</label>
								<input
									type="text"
									id="ad-state"
									placeholder="State"
									data-testid="field-state"
									autoComplete="nope"
									onFocus={onFocus}
									value={stateName}
									onChange={(e) => {
										setData(
											{
												...data,
												stateName: e.target.value,
											},
											"stateName"
										);
									}}
									// disabled={mode === "view"}
									disabled={true}
								/>
								{errors.stateName && (
									<p data-testid="error-text-id" className="error-message-text">
										Selection Required
									</p>
								)}
							</div>
							<div
								className={
									"aha-modalform-group " + (errors.zip ? "error-message" : "")
								}
							>
								<label className="caption" htmlFor="ad-zip">
									Zip
								</label>
								<input
									type="text"
									name="zip"
									id="ad-zip"
									placeholder="Zip"
									data-testid="field-zip"
									autoComplete="nope"
									onFocus={onFocus}
									value={zip}
									onChange={(e) => {
										const val = e.target.value.replace(/\D/g, "");
										setData({ ...data, zip: val }, "zip");
									}}
									// disabled={mode === "view"}
									disabled={true}
								/>
								{errors.zip && (
									<p data-testid="error-text-id" className="error-message-text">
										Selection Required
									</p>
								)}
							</div>
							<div className="aha-modalform-group">
								<label className="caption" htmlFor="acf-lat">
									Latitude (Optional)
								</label>
								<input
									data-testid="field-Lattitude"
									id="acf-lat"
									name="latitude"
									value={latitudeName}
									autoComplete="nope"
									onFocus={onFocus}
									placeholder="Latitude (Optional)"
									onChange={(e) => {
										if (
											(notaplha.test(e.target.value) &&
												(isNaN(parseFloat(e.target.value)) ||
													(parseFloat(e.target.value) <= 90 &&
														parseFloat(e.target.value) >= -90))) ||
											e.target.value === ""
										) {
											setData({
												...data,
												latitudeName: e.target.value,
											});
										}
									}}
									disabled={mode === "view"}
								/>
							</div>
							<div className="aha-modalform-group">
								<label className="caption" htmlFor="acf-longn">
									Longitude (Optional)
								</label>
								<input
									data-testid="field-longitude"
									id="acf-longn"
									name="longitude"
									value={longitudeName}
									autoComplete="nope"
									onFocus={onFocus}
									placeholder="Longitude (Optional)"
									onChange={(e) => {
										if (
											(notaplha.test(e.target.value) &&
												(isNaN(parseFloat(e.target.value)) ||
													(parseFloat(e.target.value) <= 180 &&
														parseFloat(e.target.value) >= -180))) ||
											e.target.value === ""
										) {
											setData({
												...data,
												longitudeName: e.target.value,
											});
										}
									}}
									disabled={mode === "view"}
								/>
							</div>
						</form>}
						{activeTab === 'info' && <p className="headline6">Service Information</p>}
						{activeTab === 'info' && <form className="aha-modalform-wrapper ad-care-modalform-wrapper">
							<div className="flex-50">
								<div>
									<div className="aha-form-group ad-weekday-group mb-10">
										<label data-testid="select-lab-timings" className="caption mb-10">
											Select Lab Timings
										</label>
										<div className="mr-20 ml-weekday-wrapper">
											{days &&
												days.map((val) => {
													const { startTime = "", endTime = "", value = false } = timings[val.name] || {};
													return (
														<React.Fragment key={val.id}>
															<div className="d-flex ml-selectdays-wrapper align-items-center">
																<div className="ml-weekday">
																	<label className="ml-label-container"
																		htmlFor={`ac-weekday-${val.name.toLowerCase()}`}
																	>
																		<input
																			style={{ display: "block !important" }}
																			type="checkbox"
																			id={`ac-weekday-${val.name.toLowerCase()}`}
																			data-testid="field-timings"
																			onChange={(e) => {
																				if (!isEmpty(startTime) || !_.isEmpty(endTime)) {

																				}
																				setData(
																					{
																						...data,
																						timings: {
																							...timings,
																							[val.name]: {
																								value: e.target.checked,
																								startTime: !e.target.checked ?
																									"" : startTime,
																								endTime: !e.target.checked ?
																									"" : endTime
																							},
																						},
																					},
																					"timings"
																				)
																			}
																			}
																			value={value}
																			checked={value}
																			disabled={mode === "view"}
																		/>
																		<span className="ml-checkmark"></span>
																		{val.name}
																	</label>
																</div>
																{value ?
																	<div className="ml-choose-time-wrapper d-flex">
																		<div className="schedule-time me-3">
																			<InputGroup className="mb-3">
																				<label className="caption">
																					Opens At
																				</label>
																				<div className="position-relative w-100">
																					<Select
																						placeholder="Choose"
																						name="schedule-end-time"
																						id="check-in-time"
																						className="sch-schedule-dropdown"
																						classNamePrefix="sch-schedule-select"
																						options={constructTimeOptions()}
																						value={startTime}
																						onChange={(e) => {
																							const currentData = timings[val.name] || {};
																							setData(
																								{
																									...data,
																									timings: {
																										...timings,
																										[val.name]: {
																											...currentData,
																											startTime: e
																										},
																									},
																								},
																								"timings"
																							)
																						}}
																						isDisabled={mode === "view"}
																						isOptionDisabled={(option) =>
																							checkOption(
																								timings[val.name] ? (timings[val.name].startTime || "") : "",
																								timings[val.name] ? (timings[val.name].endTime || "") : "",
																								option,
																								"start",
																							)
																						}
																					/>
																				</div>
																			</InputGroup>
																		</div>

																		<div className="schedule-time me-3">
																			<InputGroup className="mb-3 ">
																				<label className="caption">
																					Closes At
																				</label>
																				<div className="position-relative w-100">
																					<Select
																						placeholder="Choose"
																						name="schedule-end-time"
																						id="check-out-time"
																						className="sch-schedule-dropdown"
																						classNamePrefix="sch-schedule-select"
																						value={endTime}
																						options={constructTimeOptions()}
																						onChange={(e) => {
																							const currentData = timings[val.name] || {};
																							setData(
																								{
																									...data,
																									timings: {
																										...timings,
																										[val.name]: {
																											...currentData,
																											endTime: e
																										},
																									},
																								},
																								"timings"
																							)
																						}}
																						isDisabled={mode === "view"}
																						isOptionDisabled={(option) =>
																							checkOption(
																								timings[val.name] ? (timings[val.name].startTime || "") : "",
																								timings[val.name] ? (timings[val.name].endTime || "") : "",
																								option,
																								"end",
																							)
																						}
																					/>
																				</div>
																			</InputGroup>
																		</div>
																	</div>
																	: <div className="ml-closed-text">Closed</div>}
															</div>
														</React.Fragment>
													)
												})}
										</div>
										{errors.timings && (
											<p
												data-testid="error-text-id"
												className="error-message-text font-size-12 facility-service-error"
											>
												Selection Required
											</p>
										)}
									</div>
								</div>
							</div>

							<div className="aha-modalform-group flex-50 mr-0">
								<label className="caption" htmlFor="acf-facility-note">
									Lab Notes
								</label>
								<textarea
									placeholder="Lab Notes"
									id="acf-facility-note"
									value={notes}
									data-testid="field-labNotes"
									onChange={(e) => {
										setData({
											...data,
											notes: e.target.value,
										});
									}}
									disabled={mode === "view"}
								/>
								<div
									className={
										"aha-modalform-group facility-status-drop " +
										(errors.status ? "error-message" : "")
									}
								>
									<label htmlFor="status">Status</label>
									<DropDownListComponent
										id="status"
										cssClass="modal-droprdown-input modal-popup-dropdown"
										dataSource={[
											{ id: "ACTIVE", name: "Active" },
											{ id: "INACTIVE", name: "Inactive" },
										]}
										fields={{ text: "name", value: "id" }}
										data-testid="field-status"
										placeholder="Status"
										value={status}
										change={(e) => {
											if (e.isInteracted) {
												const { itemData = {} } = e;
												setData(
													{
														...data,
														status: itemData.id,
													},
													"status"
												);
											}
										}}
										enabled={mode !== "view"}
									/>
									{errors.status && (
										<p data-testid="error-text-id" className="error-message-text">
											Selection Required
										</p>
									)}
								</div>
							</div>
						</form>}
						{activeTab === 'audit' && <form className="auditlog-wrapper">
							<div className="auditlog-top">
							<div
								className={
									"search-box"
								}
							>
								<input
									type="text"
									id="ad-search"
									placeholder="Search"
									data-testid="search"
									onChange={(e) => handleInputChange(e)}
									// value={searchText}
									onFocus={onFocus}
									ref={this.searchRef}
								/>
								{searchText && <span className="default-clear-btn" onClick={e => this.clearSearchInput()}><span className="visually-hidden">Clear Icon</span></span>}
                				<span className="search-icon"><span className="visually-hidden">Search Icon</span></span>
              				</div>
							<div className="export-excel-btn">
								<button disabled={!auditLogs.length} onClick={(e) => handleExcelExport(e, auditLogs)}>
								<span className="e-excelexport"></span>Excel Export
								</button>
							</div>
							</div>
							<div
								className={
									"auditlog-list"
								}
							>
								{audit_histories && audit_histories.length > 0 &&
									<Table responsive borderless className="audit-log-table">
										<thead>
											<tr>
												{headers.map((header, index) => {
													return (
														<th key={index}>{header.label}</th>
													)
												})}
											</tr>
										</thead>
										<tbody>
											{auditLogs.map((history, index) => {
												const {
													changedByString = "",
													activity = "",
													datetimeString = "",
													abbreviation
												} = history;
												return ((!searchText || activity.toLowerCase().search(searchText.toLowerCase()) !== -1 ||
													datetimeString.toLowerCase().search(searchText.toLowerCase()) !== -1 || changedByString.toLowerCase().search(searchText.toLowerCase()) !== -1) &&
													<tr key={index}>
														<td>
															{datetimeString} {abbreviation || ""}
														</td>
														<td>
															{changedByString}
														</td>
														<td>{activity}</td>
													</tr>
												);
											})}
										</tbody>
									</Table>}
								{auditLogs.length == 0 && <p>No logs.</p>}
							</div>

						</form>}
					</Modal.Body>
					<Modal.Footer>
						<Button
							data-testid="button-cancel"
							className="primary-btn primary-btn-outline outline"
							onClick={() => {
								handleClose();
							}}
						>
							Cancel
						</Button>
						{activeTab === 'info' ? mode === "view" ? (
							canUpdateLab && (
								<Button
									data-testid="button-edit"
									className="primary-btn primary-btn-outline outline"
									onClick={() => {
										this.setState({
											isFormChanged: false
										}, () => {
											this.props.toggleLabModal(true, { data: labData }, "edit");
										})
									}}
								>
									Edit Lab
								</Button>
							)
						) : (
							<ProgressButtonComponent
								data-testid="button-submit"
								content={mode === "edit" ? "Save" : "Create Lab"}
								enableProgress={true}
								spinSettings={{ position: "Center" }}
								animationSettings={{ effect: "SlideLeft" }}
								cssClass="primary-btn primary-btn-outline ad-progress-btn"
								onClick={() => handleSubmit()}
								disabled={mode === "edit" ? !isFormChanged : false}
							></ProgressButtonComponent>
						) : null}
					</Modal.Footer>
				</Modal>
			</div>
		);
	}
}
export default LabAddEdit;
