import * as React from "react";
import { Droppable } from "react-beautiful-dnd";
import { TYPE_HOMEHEALTH, serviceTypes } from "../../../app/utils/Constants";
import { Form, InputGroup, Accordion } from "react-bootstrap";
import Select from "react-select";
import moment from "moment";
import _ from "lodash";
import uuid from 'react-uuid'

const timeRegex = new RegExp(':', 'g');

const uuid2 = uuid();

const constructTimeOptions = () => {
	let options = [];
	for (let i = 0; i < 24; i++) {
		options.push({
			label: `${i < 10 ? "0" : ""}${i}:00`,
			value: `${i < 10 ? "0" : ""}${i}:00`,
		  });
		  options.push({
			label: `${i < 10 ? "0" : ""}${i}:15`,
			value: `${i < 10 ? "0" : ""}${i}:15`,
		  });
		  options.push({
			label: `${i < 10 ? "0" : ""}${i}:30`,
			value: `${i < 10 ? "0" : ""}${i}:30`,
		  });
		  options.push({
			label: `${i < 10 ? "0" : ""}${i}:45`,
			value: `${i < 10 ? "0" : ""}${i}:45`,
		  });
	}
	options = options.map((opt, index) => {
		return { ...opt, id: index };
	});
	return options;
};

const constructEndTimeOptions = (startTime) => {
	let options = [];
	for (let i = 0; i < 24; i++) {
		options.push({
			label: `${i < 10 ? "0" : ""}${i}:00`,
			value: `${i < 10 ? "0" : ""}${i}:00`,
		  });
		  options.push({
			label: `${i < 10 ? "0" : ""}${i}:15`,
			value: `${i < 10 ? "0" : ""}${i}:15`,
		  });
		  options.push({
			label: `${i < 10 ? "0" : ""}${i}:30`,
			value: `${i < 10 ? "0" : ""}${i}:30`,
		  });
		  options.push({
			label: `${i < 10 ? "0" : ""}${i}:45`,
			value: `${i < 10 ? "0" : ""}${i}:45`,
		  });
	}
	options = options.map((opt, index) => {
		return { ...opt, id: index, label: startTime.id >= index ? `${opt.label} +1` : opt.label };
	});
	return options;
};

const getFormattedServices = (services = []) => {
	let formattedServices = [];
	if (services.some((service) => service.service_type == 4)) {
		services.forEach((service, index) => {
		  if (service.service_name === "Home Health") {
			formattedServices.push({
			  ...service,
			  class: "home-health",
			  isPresent: true,
			  // id: index,
			  // uniqueServiceId: uuid2
			});
		  }
		});
	  } else {
		formattedServices.push({
		  service_type: 4,
		  service_name: serviceTypes[4].name,
		  start_time: null,
		  end_time: null,
		  class: "home-health",
		  isPresent: false,
		  uniqueServiceId: uuid2
		});
	  }
	return formattedServices;
};

export const HomeHealthService = (props) => {
	const {
		services = [],
		sectionType,
		userIndex,
		user_id,
		date,
		updateBoardData,
		handleDeleteService,
		deleteServiceData,
		setDeleteServiceData,
		constructPyloadForCheckinCheckout,
		getScheduleBoardData,
		isPastDate
	} = props;
	const [serviceModal, setServiceModal] = React.useState({});


	React.useEffect(() => {
		if (deleteServiceData) {
			setDeleteServiceData(null);
		}

	}, [deleteServiceData])

	const handleServiceModalChange = (
		property,
		value,
		userId,
		serviceType = 1,
		serviceIndex,
		sTime,
		eTime,
		description
	) => {
		if (
			(property === "selectedAcc" && value === null) ||
			property === "isEdit"
		) {
			setServiceModal({
				...serviceModal,
				[`${userId}|${serviceType}|${serviceIndex}`]: {
					...serviceModal[`${userId}|${serviceType}|${serviceIndex}`],
					sTime: value
						? sTime
							? { label: sTime, value: sTime, id: getId(sTime) }
							: ""
						: "",
					eTime: value
						? eTime
							? { label: eTime, value: eTime, id: getId(eTime) }
							: ""
						: "",
					[property]: value,
					isEdit: property === "isEdit" ? value : false,
					description: value ? description : "",
				},
			});
		} else {
			setServiceModal({
				...serviceModal,
				[`${userId}|${serviceType}|${serviceIndex}`]: {
					...serviceModal[`${userId}|${serviceType}|${serviceIndex}`],
					[property]: value,
				},
			});
		}
	};

	const checkOption = (sTime, eTime, option, type = "start") => {
		return type === "start"
			? Object.keys(eTime).length === 0
				? false
				: option.id >= (eTime.id || 0)
			: Object.keys(sTime).length === 0
				? false
				: option.id <= (sTime.id || 0);
	};

	const getId = (time) => {
		let result = 0;
		const options = constructTimeOptions();
		options.forEach((op) => {
			if (op.value === time) {
				result = op.id;
			}
		});
		return result;
	};

	const addFacilityToSchedule = (
		sTime = {},
		eTime = {},
		description,
		userId,
		serviceType,
		uniqueServiceId,
		userIndex,
		serviceName,
		blockId,
		serviceIndex
	) => {
		if (Object.keys(sTime).length > 0 && Object.keys(eTime).length > 0) {
			const payload = {
				service_type_id: serviceType || 1,
				start_time: sTime.value,
				end_time: eTime.value,
				status: "ACTIVE",
				facility_ids: [],
				person_id: user_id,
				current_person_id: null,
				event_date: moment(date).format("YYYY-MM-DD"),
				current_event_date: null,
				description,
				block_id: blockId,
				service_index: serviceIndex,
				uniqueServiceId: uniqueServiceId
			};
			updateBoardData(userIndex, payload, serviceName, TYPE_HOMEHEALTH);
			let tempServiceModal = { ...serviceModal };
			delete tempServiceModal[`${userId}|${serviceType}|${uniqueServiceId}`];

			setServiceModal(tempServiceModal);
		}
	};


	const getDateTime = (startTime = "", endTime = "") => {
		const tempDate = new Date(date);
		tempDate.setDate(tempDate.getDate() + 1);
		const dateAndTime = parseInt(startTime.replace(timeRegex, ''), 10) >= parseInt(endTime.replace(timeRegex, ''), 10) ?
			moment(tempDate).format("MMM DD").toUpperCase()
			: moment(date).format("MMM DD").toUpperCase()
		return dateAndTime;
	}

	const { serviceAreas = {}, service_name: serviceName } = services[0] || {};

	let finalServices = getFormattedServices(services);

	let permission =
	(window.localStorage.getItem("userDetail") &&
	  JSON.parse(window.localStorage.getItem("userDetail")).permission &&
	  JSON.parse(window.localStorage.getItem("userDetail")).permission) ||
	[];
	permission = permission.filter((perm) => perm.page === "events");
	const eventAccess = permission.length === 1 ? permission[0].access : [];
	const canDeleteBlock = eventAccess && eventAccess.find((item) => item === "delete") === "delete" ? true : false;
	const canUpdatePastDate = eventAccess && eventAccess.find((item) => item === "upsert-past-date") === "upsert-past-date" ? true : false;

	return (
		<React.Fragment>
			{sectionType === "scheduleBoard" ? (
				finalServices.map((service, serviceIndex) => {
					const {
						start_time: startTime,
						end_time: endTime,
						serviceAreas = {},
						service_name: name,
						service_type: type = 3,
						isPresent,
						description: desc = "",
						block_id = null,
						isTemp = false,
						uniqueServiceId,
						start_day,
						end_day
					} = service;
					const {
						selectedAcc = "",
						sTime = "",
						eTime = "",
						description = "",
						isEdit = false,
					} = serviceModal[`${user_id}|${type}|${uniqueServiceId}`] || {};
					const droppableId =
						`emptyUser/${block_id}/${uniqueServiceId}` + "|" + userIndex + "|" + serviceIndex;

					const addEditTimeBlock = type !== 5 && (isEdit || !isPresent);

					const activeKey = isPresent || type === 5 ? service.class : selectedAcc;
					return (
						<Droppable
							droppableId={droppableId}
							isDropDisabled={true}
							key={uniqueServiceId}
						>
							{(provided, snapshot) => (
								<div
									ref={provided.innerRef}
									style={{ opacity: snapshot.isDraggingOver ? 0.5 : 1 }}
									{...provided.droppableProps}
								>
									<Accordion
										activeKey={activeKey}
										className="schedule-accordian-wrapper"
										onSelect={(val) =>
											type !== 5 &&
											(isPresent ?
												handleServiceModalChange(
													"isEdit",
													!isEdit,
													user_id,
													type,
													uniqueServiceId,
													startTime,
													endTime,
													desc
												)
												:

												handleServiceModalChange(
													"selectedAcc",
													val,
													user_id,
													type,
													uniqueServiceId
												)
											)
										}
									>
										<Accordion.Item eventKey={service.class}>
											<div className="schedule-options">
												{type != 5 && (!isPresent ? isTemp : true) && canDeleteBlock && (
													<>
														<span
															className={`schedule-delete active`}
															onClick={(e) => {
																e.preventDefault();
																if (!isTemp)
																handleDeleteService(
																	user_id,
																	type,
																	userIndex,
																	service
																)
															}}
														><span className="visually-hidden">Schedule Delete Icon</span></span>
													</>
												)}
												{type != 5 && selectedAcc && (!isPresent ? !isTemp : false) && (
													<span className={`schedule-delete disable`}></span>
												)}
											</div>
											<Accordion.Header className={`${service.class} ${addEditTimeBlock && activeKey === service.class ? "time-block-open" : ""}`}>
												<span className="service-icon"><span className="visually-hidden">Service Type Icon</span></span>
												<div className="accordian-head">
													<p className={service.class === "drop-off" ? "sub-title" : "title"}>{name}</p>
													<p className="sub-title">
														{sectionType === "scheduleBoard" && type != 5 && <p>
															{startTime && endTime
																? `${start_day ? start_day : `${moment(date).format("MMM DD").toUpperCase()}, `} ${startTime} - ${end_day ? end_day : startTime && endTime ? `${getDateTime(startTime, endTime)}, ` : ""} ${endTime}`
																: "Add To Schedule"}
														</p>}
													</p>
												</div>
											</Accordion.Header>
											<Accordion.Body>
												{addEditTimeBlock && (
													<React.Fragment>
														<div className="choose-time-wrapper">
															<div className="schedule-start-time">
																<InputGroup className="mb-3">
																	<Form.Label htmlFor="schedule-start-time">
																		Start Time
																	</Form.Label>
																	<Select
																		name="schedule-start-time"
																		id="schedule-start-time"
																		className="sch-schedule-dropdown"
																		classNamePrefix="sch-schedule-select"
																		value={sTime}
																		options={constructTimeOptions()}
																		onChange={(e) =>
																			handleServiceModalChange(
																				"sTime",
																				e,
																				user_id,
																				type,
																				uniqueServiceId
																			)
																		}
																		isOptionDisabled={(option) =>
																			(isPastDate && !canUpdatePastDate) ? true : type !== 3 && checkOption(
																				sTime,
																				eTime || 0,
																				option,
																				"start"
																			)
																		}
																	/>
																</InputGroup>
															</div>
															<div className="seperator">
																<span className="service-icon"><span className="visually-hidden">Seperator</span></span>
															</div>
															<div className="schedule-end-time">
																<InputGroup className="mb-3">
																	<Form.Label htmlFor="schedule-end-time">
																		End Time
																	</Form.Label>
																	<Select
																		name="schedule-end-time"
																		id="schedule-end-time"
																		className="sch-schedule-dropdown"
																		classNamePrefix="sch-schedule-select"
																		value={eTime}
																		options={type == 3 ? sTime ? constructEndTimeOptions(sTime) : constructTimeOptions() : constructTimeOptions()}
																		onChange={(e) =>
																			handleServiceModalChange(
																				"eTime",
																				e,
																				user_id,
																				type,
																				uniqueServiceId
																			)
																		}
																		isOptionDisabled={(option) =>
																			(isPastDate && !canUpdatePastDate) ? true : type !== 3 && checkOption(
																				sTime || {},
																				eTime || {},
																				option,
																				"end"
																			)
																		}
																	/>
																</InputGroup>
															</div>
														</div>
														{(type == 3 || type == 4) && (
															<Form.Group className="sch-choosetime-description">
																<Form.Label htmlFor="sch-choosetime-description">
																	<Form.Control as="textarea" id="sch-choosetime-description" rows={3} placeholder="Description" value={description} onChange={(e) => {
																		handleServiceModalChange(
																			"description",
																			e.target.value,
																			user_id,
																			type,
																			uniqueServiceId
																		);
																	}} />
																</Form.Label>
															</Form.Group>
														)}
														<div className="scheduler-button-wrapper">
															<button
																className="btn btn-cancel"
																onClick={() =>
																	handleServiceModalChange(
																		"selectedAcc",
																		null,
																		user_id,
																		type,
																		uniqueServiceId
																	)
																}
															>
																Cancel
															</button>
															<button
																className="btn btn-add"
																onClick={() =>
																	addFacilityToSchedule(
																		sTime,
																		eTime,
																		description,
																		user_id,
																		type,
																		uniqueServiceId,
																		userIndex,
																		name,
																		block_id,
																		serviceIndex
																	)
																}
																disabled={
																	!(
																		Object.keys(sTime).length > 0 &&
																		Object.keys(eTime).length
																	)
																}
															>
																{startTime && endTime
																	? "Save Changes"
																	: "Add To Schedule"}
															</button>
														</div>
													</React.Fragment>
												)}
												{isPresent && (
													<React.Fragment>
														{(type == 3 || type == 4) && !isEdit && !_.isEmpty(desc) && (
															<p className="drop-facility-zone-text">
																{desc}
															</p>
														)}
													</React.Fragment>
												)}
											</Accordion.Body>
										</Accordion.Item>
									</Accordion>
								</div>
							)}
						</Droppable>
						// )
					);
				})
			) : Object.keys(serviceAreas).length > 0 ? (
				<React.Fragment>
					<div className="service-type routine-draws">
						<span className="service-icon"><span className="visually-hidden">Home Health</span></span>
						<p className="title">{serviceName}</p>
					</div>
				</React.Fragment>
			) : (
				<p className="no-routine-alert">
					No Home Health for the selected region, territory & zone combination
				</p>
			)}
		</React.Fragment>
	);
};
