import _ from "lodash";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ahaApi from "../../../app/services/aha-api";
import uuid from "react-uuid";
import { SCHEDULE_SERVICE_TYPE } from "../../../app/utils/Constants";

const initialState = {
  value: [],
  loading: false,
  openPopover: ""
};

export const getOnCallScheduleList = createAsyncThunk(
  "schedule/onCallList",
  async (data, { rejectWithValue }) => {
    const response = await ahaApi.Account.onCallScheduleList(data)
      .then((res) => {
        let {users=[]} = res.content[0] || {}
        let tempUsers = [...users];
        tempUsers = tempUsers.sort((a, b) => {
          if (a.type_id === SCHEDULE_SERVICE_TYPE.NO_COVERAGE.ID) {
            return -1; // If 'a' is type nocoverage, it should come first
          } else if (b.type_id === SCHEDULE_SERVICE_TYPE.NO_COVERAGE.ID) {
            return 1; // If 'b' is type nocoverage, 'a' should come after it
          }
          return a.name.localeCompare(b.name);
        });
  
        tempUsers = tempUsers.map(user => {
          return {
            ...user,
            services: user.services ? user.services.filter(ser => (ser.start_time && ser.end_time)) : []
          }
        })
        // tempUsers = [tempUsers[0], tempUsers[1]]
        res.content[0].users = tempUsers
        res.content[0] = scheduleData(res.content[0]);
        return res;
      })
      .catch((err) => {
        return rejectWithValue(err.response.data);
      });

    return response;
  }
);

const scheduleData = (boardData) => {
  let resultBoardData = _.cloneDeep(boardData);

  boardData.users.forEach((user, userIndex) => {
    user.services.forEach((service, serviceIndex) => {
      resultBoardData.users[userIndex].services[serviceIndex] = {
        ...resultBoardData.users[userIndex].services[serviceIndex],
        uniqueServiceId: uuid()
      }
      if (service.territories) {
        service.territories.forEach((territory, territoryIndex) => {
          if (territory.events) {
            resultBoardData.users[userIndex].services[serviceIndex].territories[
              territoryIndex
            ].events = _.keyBy(territory.events, "event_type_id");
          }
        });

        let resultTerritories =
          resultBoardData.users[userIndex].services[serviceIndex].territories;

        resultBoardData.users[userIndex].services[serviceIndex].territories = _.keyBy(
          resultTerritories,
          "territory_id"
        );
      }
    });
  });

  return resultBoardData;
};
export const onCallScheduleListSlice = createSlice({
  name: "onCallScheduleList",
  initialState,
  reducers: {
    updateOnCallScheduleListValue(state, action) {
      state.value = action.payload;
    },
    setOpenPopover(state, action) {
      state.openPopover = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOnCallScheduleList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getOnCallScheduleList.fulfilled, (state, action) => {
        state.loading = false;
        state.value = action.payload;
      })
      .addCase(getOnCallScheduleList.rejected, (state, err) => {
        state.loading = false;
        state.value = err.payload;
      });
  },
});

export const onCallScheduleListResponse = (state) => state.onCallScheduleList.value;
export const onCallScheduleListLoading = (state) => state.onCallScheduleList.loading;
export const openPopover = (state) => state.onCallScheduleList.openPopover;

export const { updateOnCallScheduleListValue, setOpenPopover } = onCallScheduleListSlice.actions;
export default onCallScheduleListSlice.reducer;
