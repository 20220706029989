import _ from "lodash";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ahaApi from "../../../app/services/aha-api";
import uuid from "react-uuid";

const initialState = {
  value: [],
  loading: false,
  menuIsOpenValue: false,
  routeAuditKey: "",
  showPopover: "",
  disableSaveForStartRoute: false,
  disableSaveForDropOff: false,
  showSchedulePopover: false,
  menuIsOpenTimezoneValue: false,
};

export const scheduleList = createAsyncThunk(
  "schedule/list",
  async (data, { rejectWithValue }) => {
    const response = await ahaApi.Account.scheduleList(data)
      .then((res) => {
        let {users=[]} = res.content[0] || {}
        let tempUsers = [...users];
        tempUsers = tempUsers.sort((a, b) => a.name.localeCompare(b.name))
        // tempUsers = [tempUsers[0], tempUsers[1]]
        res.content[0].users = tempUsers
        res.content[0] = scheduleData(res.content[0]);
        return res;
      })
      .catch((err) => {
        return rejectWithValue(err.response.data);
      });

    return response;
  }
);

const scheduleData = (boardData) => {
  let resultBoardData = _.cloneDeep(boardData);

  boardData.users.forEach((user, userIndex) => {
    user.services.forEach((service, serviceIndex) => {
      resultBoardData.users[userIndex].services[serviceIndex] = {
        ...resultBoardData.users[userIndex].services[serviceIndex],
        uniqueServiceId: uuid()
      }
      if (service.zones) {
        service.zones.forEach((zone, zoneIndex) => {
          if (zone.events) {
            resultBoardData.users[userIndex].services[serviceIndex].zones[
              zoneIndex
            ].events = _.keyBy(zone.events, "event_type_id");
          }
        });

        let resultZones =
          resultBoardData.users[userIndex].services[serviceIndex].zones;

        resultBoardData.users[userIndex].services[serviceIndex].zones = _.keyBy(
          resultZones,
          "zone_id"
        );
      }
    });
  });

  return resultBoardData;
};
export const scheduleListSlice = createSlice({
  name: "scheduleList",
  initialState,
  reducers: {
    updateScheduleListValue(state, action) {
      state.value = action.payload;
    },
    setMenuIsOpenRoute(state, action) {
      state.menuIsOpenValue = action.payload;
    },
    setMenuIsOpenTimezoneRoute(state, action) {
      state.menuIsOpenTimezoneValue = action.payload;
    },
    setRouteAuditKey(state, action) {
      state.routeAuditKey = action.payload;
    },
    setShowPopover(state, action) {
      state.showPopover = action.payload;
    },
    setDisableSaveForStartRoute(state, action) {
      state.disableSaveForStartRoute = action.payload;
    },
    setDisableSaveForDropOff(state, action) {
      state.disableSaveForDropOff = action.payload;
    },
    setShowSchedulePopover(state, action) {
      state.showSchedulePopover = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(scheduleList.pending, (state) => {
        state.loading = true;
      })
      .addCase(scheduleList.fulfilled, (state, action) => {
        state.loading = false;
        state.value = action.payload;
      })
      .addCase(scheduleList.rejected, (state, err) => {
        state.loading = false;
        state.value = err.payload;
      });
  },
});

export const scheduleListList = (state) => state.scheduleList.value;
export const scheduleListLoading = (state) => state.scheduleList.loading;
export const menuIsOpenRouteValue = (state) => state.scheduleList.menuIsOpenValue;
export const menuIsOpenTimezoneValue = (state) => state.scheduleList.menuIsOpenTimezoneValue;
export const routeAuditKeyVal = (state) => state.scheduleList.routeAuditKey;
export const showPopoverVal = (state) => state.scheduleList.showPopover;
export const disableSaveForStartRoute = (state) => state.scheduleList.disableSaveForStartRoute;
export const disableSaveForDropOff = (state) => state.scheduleList.disableSaveForDropOff;
export const showSchedulePopover = (state) => state.scheduleList.showSchedulePopover;

export const { updateScheduleListValue, setMenuIsOpenRoute, setRouteAuditKey, setShowPopover, setDisableSaveForStartRoute, setDisableSaveForDropOff, setShowSchedulePopover, setMenuIsOpenTimezoneRoute } = scheduleListSlice.actions;

export default scheduleListSlice.reducer;
