import _ from "lodash";
import { Button, Modal } from "react-bootstrap";
import ErrorImage from "../../assets/images/no-preview.jpeg";

export const FileDetailsModal = (props) => {
	const { fileModal, handleCloseFileModal, addDefaultSrc, downloadFile } = props;
	return (
		<Modal
			show={fileModal.show || false}
			onHide={handleCloseFileModal}
			className="ad-adduser-modal sc-copycal-modal view-atachment-modal"
			backdrop="static"
			enforceFocus={false}
			id="attachments-modal"
			data-testid="statorder-filemodal"
		>
			<Modal.Header closeButton data-testid="statorder-filemodal-close" />
			<Modal.Body>
				<img data-testid="statorder-filemodal-image" id={`order-image`} src={fileModal.file_url || ErrorImage} alt="image" onError={addDefaultSrc} />
				{fileModal.file_name && <p data-testid="statorder-filemodal-name">{fileModal.file_name}</p>}
				<div className="d-flex justify-content-center mb-2 mt-2">
					<Button data-testid="statorder-filemodal-download" className="primary-btn primary-btn-outline" onClick={() => downloadFile(fileModal.url)}>
						Download
					</Button>
				</div>
			</Modal.Body>
		</Modal>
	);
};
