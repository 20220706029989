import React, { useRef } from "react";
import Select, { components, createFilter } from "react-select";

const multiValueContainer = ({ selectProps, data }) => {
	const label = data.label;
	const allSelected = selectProps.value;
	const index = allSelected.findIndex(selected => selected.label === label);
	const isLastSelected = index === allSelected.length - 1;
	const labelSuffix = isLastSelected ? `` : ", ";
	const val = `${label}${labelSuffix}`;
	return val;
  };
  const customStyles = {
	  valueContainer: (provided, state) => ({
		...provided,
		display: "initial",
		textOverflow: "ellipsis",
		maxWidth: "90%",
		whiteSpace: "nowrap",
		overflow: "hidden", 
		fontSize: 14,
		color: '#121212'
	  })
	};
	const Option = (props) => {
		  // eslint-disable-next-line no-unused-vars
		  const { onMouseMove, onMouseOver, ...rest } = props.innerProps;
		  const newProps = { ...props, innerProps: rest };
		return (
			<div>
				<components.Option {...newProps}>
					<input
						type="checkbox"
						checked={props.isSelected}
						onChange={() => null}
					/>{" "}
					<label>{props.label}</label>
				</components.Option>
			</div>
		);
	};

export const MultiSelect = (props) => {
	const { placeholder, name, id, className, classNamePrefix, isDisabled, options = [], onBlur, loading = false } = props;
	// isOptionSelected sees previous props.value after onChange
	const valueRef = useRef(props.value);
	valueRef.current = props.value;

	const selectAllOption = {
		value: "<SELECT_ALL>",
		label: "Select All"
	};

	const isSelectAllSelected = () =>
	valueRef.current?.length > 0 && valueRef.current.length === (options ? options.length : 0);

	const isOptionSelected = (option) =>
		valueRef.current.some(({ value }) => value === option.value) ||
		isSelectAllSelected();

	const getOptions = () => options.length > 0 ? [selectAllOption, ...options] : options;

	const getValue = () =>
		isSelectAllSelected() ? [selectAllOption] : props.value;

	const onChange = (newValue, actionMeta) => {
		const { action, option, removedValue } = actionMeta;

		if (action === "select-option" && option.value === selectAllOption.value) {
			props.onChange(options, actionMeta);
		} else if (
			(action === "deselect-option" &&
				option.value === selectAllOption.value) ||
			(action === "remove-value" &&
				removedValue.value === selectAllOption.value)
		) {
			props.onChange([], actionMeta);
		} else if (
			actionMeta.action === "deselect-option" &&
			isSelectAllSelected()
		) {
			props.onChange(
				options.filter(({ value }) => value !== option.value),
				actionMeta
			);
		} else {
			props.onChange(newValue || [], actionMeta);
		}
	};

	const handleBlur = (textInput, { action }) => {
		if (action === "input-blur") {
			onBlur();
		  }
	}
	return (
		<Select
			isOptionSelected={isOptionSelected}
			options={getOptions()}
			value={loading ? "" : getValue()}
			onChange={onChange}
			hideSelectedOptions={false}
			closeMenuOnSelect={false}
			isMulti
			onInputChange={handleBlur}
			name={name}
			id={id}
			className={className}
			classNamePrefix={classNamePrefix}
			isDisabled={isDisabled}
			placeholder={placeholder}
			isLoading={loading}
			components={{
				Option,
				// ValueContainer,
				MultiValueContainer: multiValueContainer
			}}
			allowSelectAll={true}
			styles={customStyles}
			isSearchable={false}
			filterOption={createFilter({ ignoreAccents: false })}
		/>
	);
};
