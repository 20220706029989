import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import ahaApi from "../../app/services/aha-api";

const initialState = {
  value: [],
  loading: false,
};

export const uncancelHomeHealth = createAsyncThunk(
  "homeHealth/uncancelHomeHealth",
  async (data, { rejectWithValue }) => {
    const response = await ahaApi.Account.uncancelHomeHealthOrder(data)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return rejectWithValue(err.response.data);
      });

    return response;
  }
);

export const uncancelHomeHealthSlice = createSlice({
  name: "uncancelHomeHealth",
  initialState,
  reducers: {
    clearUncancelHomeHealthResponse(state, action) {
      state.value = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(uncancelHomeHealth.pending, (state) => {
        state.loading = true;
      })
      .addCase(uncancelHomeHealth.fulfilled, (state, action) => {
        state.loading = false;
        state.value = action.payload;
      })
      .addCase(uncancelHomeHealth.rejected, (state, err) => {
        state.loading = false;
        state.value = err.payload;
      });
  },
});

export const uncancelHomeHealthList = (state) => state.uncancelHomeHealth.value;
export const { clearUncancelHomeHealthResponse } = uncancelHomeHealthSlice.actions;
export default uncancelHomeHealthSlice.reducer;
