import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  value: false
};

export const sessionTimeoutSlice = createSlice({
  name: "sessionTimeout",
  initialState,
  reducers: {
    setOpen(state, action) {
      state.value = action.payload;
    }
  }
});

export const isOpenValue = (state) => state.sessionTimeout.value;
export const {setOpen} = sessionTimeoutSlice.actions;
export default sessionTimeoutSlice.reducer;
