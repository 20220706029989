import * as React from "react";
import { Dropdown, Form, InputGroup } from "react-bootstrap";
import ReactSelect, { components } from "react-select";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import _ from "lodash";
import { MultiSelect } from "../../components/commons/multiSelect";
import { setDate } from "./Operations/filterSlice";
import { VirtualMultiSelect } from "../../components/commons/virtualMultiSelect";

export const multiValueContainer = ({ selectProps, data }) => {
    const label = data.label;
    const allSelected = selectProps.value;
    const index = allSelected.findIndex((selected) => selected.label === label);
    const isLastSelected = index === allSelected.length - 1;
    const labelSuffix = isLastSelected ? `` : ", ";
    const val = `${label} ${labelSuffix}`;
    return val;
};

const RegionOption = ({ children, ...props }) => {
    const { onMouseMove, onMouseOver, ...rest } = props.innerProps;
    const newProps = Object.assign(props, { innerProps: rest });
    return (
      <components.Option
        {...newProps}
      >
        {children}
      </components.Option>);
};
    
export const Option = ({ children, ...props }) => {
    const { onMouseMove, onMouseOver, ...rest } = props.innerProps;
    const newProps = Object.assign(props, { innerProps: rest });
    return (
        <div>
            <components.Option {...newProps}>
                <input
                    type="checkbox"
                    checked={newProps.isSelected}
                    onChange={() => null}
                />{" "}
                <label>{newProps.label}</label>
            </components.Option>
        </div>
    );
};
export const customStyles = {
    valueContainer: (provided, state) => ({
        ...provided,
        display: "initial",
        maxWidth: "90%",
        height: "28px",
        lineHeight: "26px",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        fontSize: 14,
        color: "#121212",
    }),
};

class FacilityCoverageDropdown extends React.PureComponent {
    constructor(props) {
        super(props);
    }
    // shouldComponentUpdate(nextProps, nextState) {
    //     console.log(nextProps, 'render-shouldcomp', this.props);
    //     if (nextProps.date != this.props.date) {
    //     console.log('render-shouldcomp-true');
    //         return true;
    //     }
    //     return false;
    // }
    render () {
        const { date, handleRegion2, facilityRegion, reg_modified_facilities, terr_modified_facilities,
            facilityTerritory, handleTerritory2, handleTerritoryOnBlur, zoneOptions,
            zone, handleZone, handleZoneOnBlur, handleManager, manager, reportingOptions,
            handleManagerOnBlur, handlePhleb, phlebs, phlebOptions, handlePhlebOnBlur,
            onDateChange, tabType } = this.props;
    
        const DateButton = React.forwardRef(({ value, onClick }, ref) => (
            <button className="sch-date-picker" onClick={onClick} ref={ref}>
                {value}
            </button>
        ));
        const handleChangeDate = (date) => {
            onDateChange(date);
          };
        console.log(date, 'render-datepicker');
        return (
            <div className="scheduler-filters">
                <div className="day-filter filter d-flex position-relative align-items-center">
                    <DatePicker
                        selected={date}
                        onChange={(date) => {
                            handleChangeDate(date);
                        }}
                        onCalendarClose={() => { }}
                        onCalendarOpen={() => { }}
                        customInput={<DateButton />}
                        dateFormat="eeee, MMM dd yyyy"
                    />
                </div>
                <Dropdown 
                className="d-inline facility-filter filter">
                    <Dropdown.Toggle
                        id="dropdown-autoclose-inside"
                        className="filter-title"
                    >
                        Facility Coverage And Staff
                        <span className="service-icon">
                            <span className="visually-hidden">
                                Facility Coverage And Staff
                            </span>
                        </span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <InputGroup className="mb-3">
                            <Form.Label htmlFor="facility-region">Region</Form.Label>
                            <Select
                                name="facility-region"
                                id="facility-region"
                                className="sch-facility-dropdown"
                                classNamePrefix="sch-facility-select"
                                onChange={handleRegion2}
                                value={facilityRegion}
                                options={reg_modified_facilities}
                                placeholder="Select Region"
                                isDisabled={
                                    reg_modified_facilities &&
                                    reg_modified_facilities.length === 1 &&
                                    facilityRegion
                                }
                                components={{
                                    Option: RegionOption,
                                }}
                            />
                        </InputGroup>
                        <InputGroup className="mb-3">
                            <Form.Label htmlFor="facility-territory">
                                Territory
                            </Form.Label>
                            <Select
                                name="destination-week"
                                id="destination-week"
                                className="sch-copycal-dropdown sch-facility-dropdown"
                                classNamePrefix="sch-copycal-select sch-facility-select"
                                isDisabled={facilityRegion ? false : true}
                                placeholder="Select Territory"
                                options={terr_modified_facilities || []}
                                value={facilityTerritory || []}
                                onChange={handleTerritory2}
                                onBlur={handleTerritoryOnBlur}
                                isMulti
                                closeMenuOnSelect={false}
                                hideSelectedOptions={false}
                                components={{
                                    Option,
                                    // ValueContainer: CustomValueContainer,
                                    MultiValueContainer: multiValueContainer,
                                }}
                                allowSelectAll={true}
                                styles={customStyles}
                                isSearchable={false}
                            />
                        </InputGroup>
    
                        <InputGroup className="mb-3">
                            <Form.Label htmlFor="facility-zone">Zone</Form.Label>
                            <VirtualMultiSelect
                                name="destination-week"
                                id="destination-week"
                                className="sch-copycal-dropdown sch-facility-dropdown rv-dropdown"
                                classNamePrefix="sch-copycal-select  sch-virtual-select sch-facility-select"
                                isDisabled={!_.isEmpty(facilityTerritory) ? false : true}
                                placeholder="Select Zone(s)"
                                options={zoneOptions || []}
                                value={zone || []}
                                onChange={handleZone}
                                onBlur={handleZoneOnBlur}
                               
                            />
                        </InputGroup>
                        <InputGroup className="mb-3">
                            <Form.Label htmlFor="facility-staff-rpmanage">
                                Reporting Manager
                            </Form.Label>
                            <VirtualMultiSelect
                                name="destination-week"
                                id="destination-week"
                                className="sch-copycal-dropdown sch-facility-dropdown rv-dropdown"
                                classNamePrefix="sch-copycal-select sch-virtual-select sch-facility-select"
                                onChange={handleManager}
                                value={manager ? tabType === "onCall" ? manager.filter(phleb => phleb.type !== "Courier") : manager : []}
                                options={reportingOptions ? tabType === "onCall" ? reportingOptions.filter(phleb => phleb.type !== "Courier") : reportingOptions : []}
                                isDisabled={!_.isEmpty(facilityTerritory) ? false : true}
                                placeholder="Select Reporting Manager"
                                onBlur={handleManagerOnBlur}
                            />
                        </InputGroup>
                        <InputGroup className="mb-3">
                            <Form.Label htmlFor="facility-staff-phleb">
                                Phlebotomist
                            </Form.Label>
                            <VirtualMultiSelect
                                name="destination-week"
                                id="facility-staff-phleb"
                                className="sch-copycal-dropdown sch-facility-dropdown rv-dropdown"
                                classNamePrefix="sch-copycal-select sch-virtual-select sch-facility-select"
                                onChange={handlePhleb}
                                value={phlebs ? tabType === "onCall" ? phlebs.filter(phleb => phleb.type !== "Courier") : phlebs : []}
                                options={phlebOptions ? tabType === "onCall" ? phlebOptions.filter(phleb => phleb.type !== "Courier") : phlebOptions : []}
                                isDisabled={!_.isEmpty(facilityTerritory) ? false : true}
                                placeholder="Select Phlebotomist"
                                onBlur={handlePhlebOnBlur}
                                isSearchable={false}
                                // isOptionDisabled={true}
                            />
                        </InputGroup>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        );
    }
};
export default FacilityCoverageDropdown;
