/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import {
  ACCESS_STATUS,
  CONTACT,
  HOME,
  LOST_DEVICE,
} from "../../../app/utils/Constants";
import { useSelector, useDispatch } from "react-redux";
import Logo from "../../../components/layouts/Logo";
import { postVerifyCode, verifyCodeList } from "./verifyCodeSlice";
import {
  postSetPassword,
  setPasswordList,
} from "../SetPassword/setPasswordSlice";
import ToastMessage from "../../../components/layouts/ToastMessage";
import { lostDeviceList, postLostDevice } from "./lostDeviceSlice";
import { appConfig } from "../../Chat/Config";
import jwt_decode from "jwt-decode";

export default function VerifyCode(props) {
  const verify = useSelector(verifyCodeList);
  const codeResponse = useSelector(setPasswordList);
  const lostDevice = useSelector(lostDeviceList);

  const dispatch = useDispatch();
  const [code, setCode] = React.useState("");
  const [error, setError] = React.useState(false);
  const [toast, setToast] = React.useState(true);
  const [status, setStatus] = React.useState("");
  const [errMsg, setErrMsg] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [disableButton, setDisableButton] = React.useState(false);

  React.useEffect(() => {
    if (lostDevice && lostDevice.status && lostDevice.status === "SUCCESS") {
      {
        props.history.push(LOST_DEVICE);
      }
      setStatus("success");
      setMessage(lostDevice && lostDevice.message);
    } else if (
      lostDevice &&
      lostDevice.status &&
      lostDevice.status === "ERROR"
    ) {
      setMessage(lostDevice && lostDevice && lostDevice.message);
      setError(true);
      setStatus("error");
      setToast(false);
    }
  }, [lostDevice]);

  const session_id =
    props &&
    props.location &&
    props.location.state &&
    props.location.state.session_id;
  const challenge_name =
    props &&
    props.location &&
    props.location.state &&
    props.location.state.challenge_name;
  const username =
    props &&
    props.location &&
    props.location.state &&
    props.location.state.username;
  const reset =
    props &&
    props.location &&
    props.location.state &&
    props.location.state.reset
      ? props &&
        props.location &&
        props.location.state &&
        props.location.state.reset
      : false;

  const toastHandler = () => {
    setToast(true);
  };

  const handleSubmit = () => {
    const data = {
      username: username,
      // reset_device: true,
    };
    dispatch(postLostDevice(data));
  };

  const handleChange = () => {
    if (disableButton) {
      return;
  } else {
    setDisableButton(true);
    if (challenge_name === "SOFTWARE_TOKEN_MFA") {
      const random = Math.floor(Math.random() * 999);
      const data = {
        username: username,
        session_id: session_id,
        user_code: code,
        challenge_name: challenge_name,
        device_name: username + random,
      };
      dispatch(postSetPassword(data));
    } else if (challenge_name === "VERIFY_DEVICE") {
      const data = {
        session_id: session_id,
        user_code: code,
        reset_device: reset,
      };
      dispatch(postVerifyCode(data));
    }
  }
  };
  const handleClick = (e) => {
    e.preventDefault();
    window.open(
      "https://support.google.com/accounts/answer/1066447?hl=en&co=GENIE.Platform%3DAndroid"
    );
  };
  React.useEffect(() => {
    if (props && props.location && props.location.state === undefined) {
      props.history.push(HOME);
    }
  }, []);
  React.useEffect(() => {
    if (verify && verify.status && verify.status === "SUCCESS") {
      setStatus("success");
      setMessage(verify.message);
      setToast(false);
      setTimeout(() => {
        window.location.href = HOME;
      }, 3000);
    } else if (verify && verify.status && verify.status === "ERROR") {
      if (
        verify &&
        verify.message &&
        verify.message === "Invalid session for the user, session is expired."
      ) {
        setMessage(verify.message);
        setStatus("error");
        setError(true);
        setToast(false);
        setTimeout(() => {
          window.location.href = HOME;
        }, 3000);
      } else {
        setDisableButton(false);
        setMessage(verify.message);
        setStatus("error");
        setError(true);
        setToast(false);
      }
    }
  }, [verify]);
  React.useEffect(() => {
    if (
      props &&
      props.location &&
      props.location.state &&
      !props.location.state.password &&
      codeResponse &&
      codeResponse.status &&
      codeResponse.status === "SUCCESS"
    ) {
      const username = props.location.state.username;
      const token =
        codeResponse &&
        codeResponse.content &&
        codeResponse.content.access_token;
      const refreshToken =
        codeResponse &&
        codeResponse.content &&
        codeResponse.content.refresh_token;
      const idToken =
        codeResponse && codeResponse.content && codeResponse.content.id_token;
        const deviceInfo = (codeResponse && codeResponse.content && codeResponse.content.device_info) || "";
      localStorage.setItem(
        "userData",
        JSON.stringify(codeResponse && codeResponse.content)
      );
      const user = username.toLowerCase();
      localStorage.setItem("username", user);
      if (deviceInfo) {
        const decodedData = jwt_decode(deviceInfo) || {};
        localStorage.setItem(
          `CognitoIdentityServiceProvider.${appConfig.cognitoAppClientId}.${username}.deviceKey`,
          decodedData.k
        );
      } else if (localStorage.getItem("device_info")) {
        const decodedData = jwt_decode(localStorage.getItem("device_info")) || {};
        localStorage.setItem(
          `CognitoIdentityServiceProvider.${appConfig.cognitoAppClientId}.${username}.deviceKey`,
          decodedData.k
        );
      }
      localStorage.setItem(
        `CognitoIdentityServiceProvider.${appConfig.cognitoAppClientId}.${user}.accessToken`,
        token
      );
      localStorage.setItem(
        `CognitoIdentityServiceProvider.${appConfig.cognitoAppClientId}.${user}.idToken`,
        idToken
      );
      localStorage.setItem(
        `CognitoIdentityServiceProvider.${appConfig.cognitoAppClientId}.LastAuthUser`,
        user
      );
      localStorage.setItem(
        `CognitoIdentityServiceProvider.${appConfig.cognitoAppClientId}.${user}.refreshToken`,
        refreshToken
      );
      if (codeResponse && codeResponse.content.device_info)
        localStorage.setItem(
          "device_info",
          JSON.stringify(codeResponse && codeResponse.content.device_info)
        );
      props.history.push(ACCESS_STATUS);
    } else if (
      codeResponse &&
      codeResponse.status &&
      codeResponse.status === "ERROR"
    ) {
      if (
        codeResponse &&
        codeResponse.message_code &&
        codeResponse.message_code === "SESSION_INVALID"
      ) {
        setTimeout(() => {
          window.location.href = HOME;
        }, 3000);
      } else if (
        codeResponse &&
        codeResponse.message_code &&
        codeResponse.message_code === "INVALID_CODE_LIMIT_EXCEEDED"
      ) {
        setTimeout(() => {
          window.location.href = CONTACT;
        }, 3000);
      } else {
        let err = `${codeResponse.message} ${
          codeResponse && codeResponse.content && codeResponse.content.message_2
        }`;
        setMessage(
          codeResponse && codeResponse.content && codeResponse.content.message_2
            ? err
            : codeResponse.message
        );
        setStatus("error");
        setError(true);
        setToast(false);
        setDisableButton(false);
      }
    }
  }, [codeResponse]);
  const handleKeyPress = (e) => {
    if (e.charCode == 13) {
      handleChange();
    }
  };
  return (
    <React.Fragment>
      <Logo />
      <h1 className="mb-10 roboto-regular login-title">Verify Code</h1>
      <p className="mb-1 roboto-regular login-info">
        Please Check your Google Authenticator app, and enter the validation
        code below.
      </p>
      <div className="aha-login-wrapper">
        <div className="aha-form-group mb-20 text-start position-relative">
          <label
            className="form-label mb-0 roboto-medium"
            htmlFor="code-validation"
          >
            Validation Code
          </label>
          <input
            type="text"
            className={"form-control " + (error ? "invalid" : "")}
            id="code-validation"
            onChange={(e) => {
              setCode(e.target.value);
            }}
            onKeyPress={handleKeyPress}
          />
        </div>
        <button
          className="primary-btn mb-15"
          type="button"
          onClick={() => {
            handleChange();
          }}
          disabled={code.length < 6 ? true : disableButton}
        >
          <span>verify code</span>
          <span className="loader">
            <span className="visually-hidden">loader icon</span>
          </span>
        </button>
        <div onClick={handleClick} className="aha-link-wrapper mb-10">
          <a href="#">Google Authenticator Help</a>
        </div>
        <div className="d-flex justify-content-between vc-link-wrapper">
          <div className="aha-link-wrapper">
            <a href={HOME}>Back to Sign In</a>
          </div>
          <div onClick={handleSubmit} className="aha-link-wrapper">
            <a href="#">Register New Device</a>
          </div>
        </div> 
      </div>
     
      <ToastMessage
        message={message}
        type={status}
        setToast={toastHandler}
        toast={toast}
      />
    </React.Fragment>
  );
}
