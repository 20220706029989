import React from "react";
import Avatar from "react-avatar";
import { Button, Dropdown, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { FACILITYLIST, HOSPITALLIST, LABLIST, logout, ROLE_COURIER, TERRITORYLIST, USERLIST, ZONELIST } from "../../app/utils/Constants";
import AddUser from "../../features/LandingPage/AddUser/AddUser";
import {
  addLoading,
  addUser,
  addUserList,
  clearAddUserResponse,
} from "../../features/LandingPage/AddUser/addUserSlice";
import { getFacilities } from "../../features/LandingPage/AddUser/facilitiesSlice";
import { filterList } from "../../features/LandingPage/AddUser/filterSlice";
import CareGrid from "../../features/LandingPage/CareGrid";
import Grid from "../../features/LandingPage/Grid";
import { getUsers } from "../../features/LandingPage/userSlice";
import LottieAnimation from "./LottieAnimation";
import { postToken } from "../../features/accounts/Login/tokenSlice";
import HospitalList from "../../features/LandingPage/HospitalList";
import { getHospitals } from "../../features/LandingPage/HospitalAddEdit/HospitalsSlice";
import { getLabs } from "../../features/LandingPage/LabAddEdit/LabsSlice";
import LabList from "../../features/LandingPage/LabList";
import { getLabTypes } from "../../features/LandingPage/LabAddEdit/LabDataSlice";
import toast from "react-hot-toast";
import { useHistory } from "react-router-dom";
import ToastContent from "./ToastContent";
import { CommonHeaderRightSection } from "../commons/CommonHeaderRightSection";
import { getTerritories } from "../../features/LandingPage/TerritoryAddEdit/TerritoriesSlice";
import TerritoryList from "../../features/LandingPage/TerritoryList";
import { getZones } from "../../features/LandingPage/ZoneAddEdit/ZonesSlice";
import ZoneList from "../../features/LandingPage/ZoneList";

export default function InnerHeader(props) {

  const { handleChangeDeactivate, facilityInactive, hospitalInactive, labInactive, inactive_user, zoneInactive,
    tabRef, tabValue
   } = props;

  let permission =
    (window.localStorage.getItem("userDetail") &&
      JSON.parse(window.localStorage.getItem("userDetail")).permission &&
      JSON.parse(window.localStorage.getItem("userDetail")).permission) ||
    [];

  // user-permission
  const userPermission = permission.filter((perm) => perm.page === "users");
  const access = userPermission.length === 1 ? userPermission[0].access : [];
  const create =
    access && access.find((item) => item === "create") === "create"
      ? true
      : false;
  const list =
    access && access.find((item) => item === "list") === "list" ? true : false;
  const filter_list =
    access && access.find((item) => item === "filter-list") === "filter-list"
      ? true
      : false;

  // facility-permission
  const facilityPermission = permission.filter(
    (perm) => perm.page === "facilities"
  );
  const facilityAccess =
    facilityPermission.length === 1 ? facilityPermission[0].access : [];
  const facilityCreate =
    facilityAccess &&
      facilityAccess.find((item) => item === "create") === "create"
      ? true
      : false;
  const facilityList =
    facilityAccess && facilityAccess.find((item) => item === "list") === "list"
      ? true
      : false;

  // hospital-permission
  const hospitalPermission = permission.filter(
    (perm) => perm.page === "hospitals"
  );
  const hospitalAccess =
    hospitalPermission.length === 1 ? hospitalPermission[0].access : [];

  const canCreateHospital =
    hospitalAccess &&
      hospitalAccess.find((item) => item === "create") === "create"
      ? true
      : false;
  const canViewHospitals =
    hospitalAccess && hospitalAccess.find((item) => item === "list") === "list"
      ? true
      : false;


  // lab-permission
  const labPermission = permission.filter(
    (perm) => perm.page === "labs"
  );
  const labAccess =
    labPermission.length === 1 ? labPermission[0].access : [];

  const canCreateLab =
    labAccess &&
      labAccess.find((item) => item === "create") === "create"
      ? true
      : false;
  const canViewLabs =
    labAccess && labAccess.find((item) => item === "list") === "list"
      ? true
      : false;
  const hasFilterList =
    labAccess && labAccess.find((item) => item === "filter-list") === "filter-list"
      ? true
      : false;

  // territory-permission
  const territoryPermission = permission.filter(
    (perm) => perm.page === "territories"
  );
  const territoryAccess =
    territoryPermission.length === 1 ? territoryPermission[0].access : [];

  const canCreateTerritory =
    territoryAccess &&
      territoryAccess.find((item) => item === "create") === "create"
      ? true
      : false;
  const canViewTerritories =
    territoryAccess && territoryAccess.find((item) => item === "list") === "list"
      ? true
      : false;
  const hasFilterTerritory =
    territoryAccess && territoryAccess.find((item) => item === "filter-list") === "filter-list"
      ? true
      : false;

  // zone-permission
  const zonePermission = permission.filter(
    (perm) => perm.page === "zones"
  );
  const zoneAccess =
    zonePermission.length === 1 ? zonePermission[0].access : [];

  const canCreateZone =
    zoneAccess &&
      zoneAccess.find((item) => item === "create") === "create"
      ? true
      : false;
  const canViewZones =
    zoneAccess && zoneAccess.find((item) => item === "list") === "list"
      ? true
      : false;
  const hasFilterZone =
    zoneAccess && zoneAccess.find((item) => item === "filter-list") === "filter-list"
      ? true
      : false;

  const history = useHistory();
  const filter = useSelector(filterList);
  const addUsers = useSelector(addUserList);
  const addLoader = useSelector(addLoading);
  const dispatch = useDispatch();
  const [show, setShow] = React.useState(false);
  const [careShow, setCareShow] = React.useState(false);
  const [data, setData] = React.useState("");
  const [chipData, setChip] = React.useState([]);
  const [executive, setExecutive] = React.useState(false);
  const [regionTerritory, setRegionWithTerritories] = React.useState([]);
  const [jobData, setJob] = React.useState({});
  const [homeRegion, setHomeRegion] = React.useState({});
  const [managerData, setManager] = React.useState({});
  const [territoryData, setTerritory] = React.useState([]);
  const [terriData, setTerri] = React.useState([]);
  const [err_first, setErrFirst] = React.useState(false);
  const [err_last, setErrLast] = React.useState(false);
  const [err_emp, setErrEmp] = React.useState(false);
  const [err_username, setErrUsername] = React.useState(false);
  const [err_job, setErrJob] = React.useState(false);
  const [err_manager, setErrManager] = React.useState(false);
  const [err_regionOrTerri, setErrRegionOrTerri] = React.useState(false);
  const [err_email, setErrEmail] = React.useState(false);
  const [err_homeRegion, setErr_homeRegion] = React.useState(false);
  const [err_phone, setErrPhone] = React.useState(false);
  const [lottie, setLottie] = React.useState(false);
  const [error, setError] = React.useState("");
  const [re, setRe] = React.useState([]);
  const [te, setTe] = React.useState([]);
  const [type, setType] = React.useState("");
  const [accessGroupError, setAccessGroupError] = React.useState(false);
  const [reRenderComp, setReRenderComp] = React.useState(false);


  const handleClose = () => {
    setErrFirst(false);
    setErrLast(false);
    setErrEmp(false);
    setErrUsername(false);
    setErrJob(false);
    setErrManager(false);
    setErrRegionOrTerri(false);
    setShow(false);
    setData("");
    setRe([]);
    setTe([]);
    setJob([]);
    setHomeRegion({});
    setErr_homeRegion(false);
    setExecutive(false);
    setErrEmail(false);
    setErrPhone(false);
    setError("");
    setType("");
    setChip([]);
    setRegionWithTerritories([]);
    setManager({});
  };
  const handleShow = () => setShow(true);
  const handleCareShow = () => setCareShow(true);
  const handleCareClose = () => {
    setCareShow(false);
  };
  const handleError = () => setError("");

  React.useEffect(() => {
    initializeTab(tabRef.current)
  }, [])

  React.useEffect(() => {
    if (addUsers) {
      const { status, message_code } = addUsers;
      if (status === "SUCCESS") {
        setData("");
        setChip([]);
        setRegionWithTerritories([]);
        setLottie(true);
        setError("");
        // alert(addUsers && addUsers && addUsers.message);
        handleClose();
        dispatch(getUsers(false));
        setTimeout(() => setLottie(false), 2000);
      } else if (status === "ERROR") {
        if (message_code === "ACCESS_TOKEN_EXPIRED") {
        } else if (
          message_code === "SESSION_EXPIRED_LOGIN_AGAIN" ||
          message_code === "ACCESS_TOKEN_INVALID"
        ) {
        } else {
          setError(addUsers && addUsers.message);
        }
      }
    }
  }, [addUsers]);

  React.useEffect(
    () => () => {
      //clear redux state
      dispatch(clearAddUserResponse());
    },
    []
  );

  const initializeTab = (tab) => {
    if (tab === "manageUsers") {
      if (list) {
        dispatch(getUsers(inactive_user));
      } else {
        tabNavigation();
      }
    } else if (tab === "manageCare") {
      if (facilityList) {
        dispatch(getFacilities(facilityInactive));
      } else {
        tabNavigation();
      }
    } else if (tab === "manageHospitals") {
      if (canViewHospitals) {
        dispatch(getHospitals(hospitalInactive));
      } else {
        tabNavigation();
      }
    }
    else if (tab === "manageLabs") {
      if (hasFilterList) {
        dispatch(getLabTypes());
      }
      if (canViewLabs) {
        dispatch(getLabs(labInactive));
      } else {
        tabNavigation();
      }
    } else if (tab === "manageTerritories") {
      if (canViewTerritories) {
        dispatch(getTerritories());
      } else {
        tabNavigation();
      }
    } else if (tab === "manageZones") {
      if (canViewZones) {
        dispatch(getZones(zoneInactive));
      } else {
        tabNavigation();
      }
    }
  }

  const tabNavigation = () =>{
    if (list) {
      dispatch(getUsers(inactive_user));
      history.push(USERLIST);
    } else if (facilityList) {
      dispatch(getFacilities(facilityInactive));
      history.push(FACILITYLIST);
    } else if (canViewHospitals) {
      dispatch(getHospitals(hospitalInactive));
      history.push(HOSPITALLIST);
    } else if (canViewLabs) {
      if (hasFilterList) {
        dispatch(getLabTypes());
      }
      dispatch(getLabs(labInactive));
      history.push(LABLIST);
    } else if(canViewTerritories) {
      dispatch(getTerritories());
      history.push(TERRITORYLIST);
    } else if(canViewZones) {
      dispatch(getZones(zoneInactive));
      history.push(ZONELIST);
    }
  }

  const handleJob = (args) => {
    const { itemData } = args;
    setJob({ id: itemData.id, value: itemData.name });
    if (itemData.id === 1) {
      setExecutive(true);
    } else {
      setExecutive(false);
    }
    setErrJob(false);
  };

  const handleHomeRegion = (args) => {
    const { itemData } = args;
    setHomeRegion(itemData ? { id: itemData.id, value: itemData.name } : {});
    setErr_homeRegion(false)
  };

  const handleManager = (args) => {
    const { itemData } = args;
    setManager({ id: itemData.id, value: itemData.first_name });
    setErrManager(false);
  };
  const handleTerritory = (args) => {
    const terr = terriData;

    // const { itemData } = args;
    // setTerri({ id: itemData && itemData.id, name: itemData && itemData.name });
    // console.log(territoryData);

    const { name, itemData } = args;
    if (name === "select") {
      terr.push({
        id: itemData && itemData.id,
        name: itemData && itemData.name,
        region_id: itemData && itemData.region_id,
      });
      setTerri(terr);
    } else if (name === "removed") {
      const index = terr.findIndex((item) => item.id === itemData.id);
      if (index !== -1) {
        terr.splice(index, 1);
        setTerri(terr);
      }
    }
  };

  const handleList = (args) => {
    let dataArray = chipData;
    const { selected, data } = args;
    if (selected) {
      if ((data.text === ROLE_COURIER && dataArray.some(item => item.name !== ROLE_COURIER) || data.text !== ROLE_COURIER && dataArray.some(item => item.name === ROLE_COURIER))) {
        var instance = document.getElementById('chip-default-adduser').ej2_instances[0];
        instance.select(args.index);
        setTimeout(() => {
          toast(
            (t) => (
              <ToastContent type="error" message="The Courier role cannot be assigned in combination with any other role." close={() => toast.remove()} />
            ),
            {
              duration: 4000,
            }
          );
          if (document.querySelector('.chipsetcomponent-wrapper')) {
            document.querySelector('.chipsetcomponent-wrapper').scrollIntoView({
              behavior: 'smooth'
            });
          }
        }, 1)
        return;
      }
      dataArray.push({
        value: data.value.value,
        name: data.text,
        index: args.index,
        type: data.value.type,
      });
      if (dataArray.find((x) => x.type === "both") !== undefined) {
        setType("both");
      } else if (
        dataArray.find((x) => x.type === "mobile") !== undefined &&
        dataArray.find((x) => x.type === "desktop") !== undefined
      ) {
        setType("both");
      } else if (dataArray.find((x) => x.type === "mobile") !== undefined) {
        setType("mobile");
      } else if (dataArray.find((x) => x.type === "desktop") !== undefined) {
        setType("desktop");
      }
    } else {
      const index = dataArray.findIndex(
        (item) => item.value === data.value.value
      );
      if (index !== -1) {
        dataArray.splice(index, 1);
        if (dataArray.find((x) => x.type === "both") !== undefined) {
          setType("both");
        } else if (
          dataArray.find((x) => x.type === "mobile") !== undefined &&
          dataArray.find((x) => x.type === "desktop") !== undefined
        ) {
          setType("both");
        } else if (dataArray.find((x) => x.type === "desktop") !== undefined) {
          setType("desktop");
        } else if (dataArray.find((x) => x.type === "mobile") !== undefined) {
          setType("mobile");
        } else {
          setType("");
        }
      }
    }
    setChip(dataArray);
    setAccessGroupError(false);
  };
  const handleSave = () => {
    let officialphonenumber;
    if (typeof data.official_phone_number === "string") {
      officialphonenumber = data.official_phone_number.match(/\d/g);
      officialphonenumber = officialphonenumber ? officialphonenumber.join("") : "";
    } else {
      officialphonenumber = data.official_phone_number;
    }

    let personalphonenumber;
    if (typeof data.personal_phone_number === "string") {
      personalphonenumber = data.personal_phone_number.match(/\d/g);
      personalphonenumber = personalphonenumber ? personalphonenumber.join("") : "";
    } else {
      personalphonenumber = data.personal_phone_number;
    }
    let errors = {};
    if (
      data &&
      data.first_name &&
      data.last_name &&
      data.employee_id &&
      data.user_name &&
      jobData &&
      jobData.id &&
      homeRegion && homeRegion.id &&
      regionTerritory.length !== 0 &&
      type !== "" &&
      type !== null
    ) {
      const roles = chipData.map((val) => val.value);

      if (data) {
        const payload = {
          first_name: data.first_name,
          middle_initial: data.middle_initial,
          last_name: data.last_name,
          user_name: data.user_name,
          employee_id: data.employee_id,
          reporting_manager_id: executive ? null : managerData.id,
          job_title_id: jobData.id,
          role_id: roles,
          region_territory: regionTerritory,
          phone_number: Number(officialphonenumber) || null,
          personal_phone_number: Number(personalphonenumber) || null,
          home_region_id: homeRegion.id,
          email_id: data.email_id ? data.email_id : null,
          has_company_vehicle: data.has_company_vehicle ? data.has_company_vehicle.id ? 1 : 0 : 0
        };

        if (type === "both" && data.email_id && data.official_phone_number) {
          dispatch(addUser(payload));
          // setRegionWithTerritories([]);
        } else if (type === "desktop" && data.email_id) {
          dispatch(addUser(payload));
          // setRegionWithTerritories([]);
        } else if (type === "mobile" && data.official_phone_number) {
          dispatch(addUser(payload));
          // setRegionWithTerritories([]);
        }
        if (type === "both" && !data.email_id && !data.official_phone_number) {
          errors["email"] = true;
          errors["phone"] = true;
          setErrEmail(true);
          setErrPhone(true);
        }
        if (type === "both" && data.email_id && !data.official_phone_number) {
          errors["phone"] = true;
          setErrEmail(false);
          setErrPhone(true);
        }
        if (type === "both" && !data.email_id && data.official_phone_number) {
          errors["email"] = true;
          setErrEmail(true);
          setErrPhone(false);
        }
        if (type === "desktop" && !data.email_id) {
          errors["email"] = true;
          setErrEmail(true);
          setErrPhone(false);
        }
        if (type === "mobile" && !data.official_phone_number) {
          errors["phone"] = true;
          setErrEmail(false);
          setErrPhone(true);
        }
        if (errors && Object.keys(errors).length) {
          setError("");
          setTimeout(() => {
            toast(
              (t) => (
                <ToastContent message="Check if all required fields have been filled in before you create an user" close={() => toast.remove()} type="error" />
              ),
              {
                duration: 4000,
              }
            );
            if (document.querySelector('.error-message')) {
              document.querySelector('.error-message').scrollIntoView({
                behavior: 'smooth'
              });
            } else if (document.querySelector('.error-message-text')) {
              document.querySelector('.error-message-text').scrollIntoView({
                behavior: 'smooth'
              });
            }
          }, 1)
        }
      }
    } else {
      if (!data) {
        errors = {
          firstName: true,
          lastName: true,
          emp: true,
          username: true.valueOf,
          manager: true,
          err_homeRegion: true
        }
        if (!jobData || !jobData.id) {
          errors["job"] = true;
          setErrJob(true);
        }
        setErrFirst(true);
        setErrLast(true);
        setErrEmp(true);
        setErrUsername(true);
        // setErrManager(true);
        // setErrRegionOrTerri(true);
      }
      if (data && !data.first_name) {
        errors["firstName"] = true;
        setErrFirst(true);
      }
      if (data && !data.last_name) {
        errors["lastName"] = true;
        setErrLast(true);
      }
      if (data && !data.employee_id) {
        errors["emp"] = true;
        setErrEmp(true);
      }
      if (data && !data.user_name) {
        errors["username"] = true;
        setErrUsername(true);
      }
      if (!jobData || !jobData.id) {
        errors["job"] = true;
        setErrJob(true);
      }
      if (!homeRegion || !homeRegion.id) {
        errors["err_homeRegion"] = true;
        setErr_homeRegion(true);
      }
      if (regionTerritory.length === 0) {
        errors["regionTerr"] = true;
        setErrRegionOrTerri(true);
      }
      if (!managerData || !managerData.id) {
        setErrManager(true);
      }
      if (type === "both" && !data.email_id && !data.official_phone_number) {
        errors["email"] = true;
        errors["phone"] = true;
        setErrEmail(true);
        setErrPhone(true);
      }
      if (type === "desktop" && !data.email_id) {
        errors["email"] = true;
        setErrEmail(true);
        setErrPhone(false);
      }
      if (type === "mobile" && !data.official_phone_number) {
        errors["phone"] = true;
        setErrEmail(false);
        setErrPhone(true);
      }
      if (type === "" || type === null) {
        errors["accessGroup"] = true;
        setAccessGroupError(true);
      }
      if (errors && Object.keys(errors).length) {
        setError("");
        setTimeout(() => {
          toast(
            (t) => (
              <ToastContent message="Check if all required fields have been filled in before you create an user" close={() => toast.remove()} type="error" />
            ),
            {
              duration: 4000,
            }
          );
          if (document.querySelector('.error-message')) {
            document.querySelector('.error-message').scrollIntoView({
              behavior: 'smooth'
            });
          } else if (document.querySelector('.error-message-text')) {
            document.querySelector('.error-message-text').scrollIntoView({
              behavior: 'smooth'
            });
          }
        }, 1)
      }
    }
  };

  const handleChangeTab = (value) => {
    if (value === "manageUsers" && tabValue !== "manageUsers") {
      if (list) {
        dispatch(getUsers(inactive_user));
      }
      history.push(USERLIST);
    } else if (value === "manageCare" && tabValue !== "manageCare") {
      if (facilityList) {
        dispatch(getFacilities(facilityInactive));
      }
      history.push(FACILITYLIST);
    } else if (value === "manageHospitals" && tabValue !== "manageHospitals") {
      if (canViewHospitals) {
        dispatch(getHospitals(hospitalInactive));
      }
      history.push(HOSPITALLIST);
    }
    else if (value === "manageLabs" && tabValue !== "manageLabs") {
      if (hasFilterList) {
        dispatch(getLabTypes());
      }
      if (canViewLabs) {
        dispatch(getLabs(labInactive));
      }
      history.push(LABLIST);
    } else if (value === "manageTerritories" && tabValue !== "manageTerritories") {
      if (canViewTerritories) {
        dispatch(getTerritories());
      }
      history.push(TERRITORYLIST);
    } else if (value === "manageZones" && tabValue !== "manageZones") {
      if (canViewZones) {
        dispatch(getZones(zoneInactive));
      }
      history.push(ZONELIST);
    }
  };

  const getDayName = (id, days) => {
    let result = id;
    days &&
      days.forEach((day) => {
        if (day.id == id) {
          result = day.name;
        }
      });
    return result;
  };

  let chip = React.useRef();
  var temp = [];
  var a = [];
  // var terri = regionData.map((val) => {
  //   a = territory.filter((t) => t.region_id === val.id);
  //   temp = [...temp, ...a];
  // });

  let days = filter && filter && filter.content && filter.content.days;

  let options = [];
  if (props.bc) {
    props.bc.forEach(d => {
      if (d.hasTrue) {
        const hasChildren = props.bc.some(d2 => d2.region_id == d.id);
        if (hasChildren) {
          options.push(d)
        }
      } else {
        options.push(d);
      }
    })
  }

  let regionOptions = options ? options.filter(opt => opt.hasTrue) : [];
  // if (regionTerritory && regionTerritory.length) {
  //   let selectedRegionsOutOfTerritories = [];
  //   regionTerritory.forEach(rTerr => {
  //     !selectedRegionsOutOfTerritories.some(val => val == rTerr.region_id) && selectedRegionsOutOfTerritories.push(rTerr.region_id);
  //   })
  //   regionOptions.forEach(opt => {
  //     if (!selectedRegionsOutOfTerritories.some(val => val == opt.id)) {
  //       regionOptions = regionOptions.filter(opt2 => opt2.id != opt.id)
  //     }
  //   })
  // } else {
  //   regionOptions = [];
  // }
  return (
    <React.Fragment>
      <CommonHeaderRightSection
        logout={logout}
        color={props.color}
        name={props.full_name}
        title="Administration Panel"
        className="d-flex"
      />
        <div className="ad-headerbottom-wrapper">
          <ul className="ad-navtab nav-tabs" role="tablist">
            {list && <li className="nav-item" role="presentation">
              <button type="button" onClick={() => handleChangeTab("manageUsers")} role="tab" className={"nav-link " + (tabValue === "manageUsers" ? "active" : "")}>Manage Users</button>
            </li>}
            {facilityList && <li className="nav-item" role="presentation">
              <button type="button" onClick={() => handleChangeTab("manageCare")} role="tab" className={"nav-link " + (tabValue === "manageCare" ? "active" : "")}>Manage Care Facilities</button>
            </li>}
            {canViewHospitals && <li className="nav-item" role="presentation">
              <button type="button" onClick={() => handleChangeTab("manageHospitals")} role="tab" className={"nav-link " + (tabValue === "manageHospitals" ? "active" : "")}>Manage Hospitals</button>
            </li>}
            {canViewLabs && <li className="nav-item" role="presentation">
              <button type="button" onClick={() => handleChangeTab("manageLabs")} role="tab" className={"nav-link " + (tabValue === "manageLabs" ? "active" : "")}>Manage Labs</button>
            </li>}
            {canViewTerritories && <li className="nav-item" role="presentation">
              <button type="button" onClick={() => handleChangeTab("manageTerritories")} role="tab" className={"nav-link " + (tabValue === "manageTerritories" ? "active" : "")}>Manage Territories</button>
            </li>}
            {canViewZones && <li className="nav-item" role="presentation">
              <button type="button" onClick={() => handleChangeTab("manageZones")} role="tab" className={"nav-link " + (tabValue === "manageZones" ? "active" : "")}>Manage Zones</button>
            </li>}
          </ul>
          <div
            className="ad-navtab h-100 d-block"
          >
            {tabValue === "manageUsers" ? list ? (
              <React.Fragment>
                <Form className="d-flex justify-content-between mb-10 mt-10 adduser-row w-100">
                  {create && (
                    <Button
                      className="add-user-btn roboto-medium font-size-14 d-flex align-items-center"
                      onClick={() => handleShow()}
                    >
                      <span className="icon-add-user">
                        <span className="visually-hidden">Add user icon</span>
                      </span>
                      <span>Add User</span>
                    </Button>
                  )}
                  <div className="ad-toggle-switch d-flex align-items-center">
                    <span className="ad-switch font-size-14 roboto-regular ">
                      Show Deactivated
                    </span>
                    <label className="switch position-relative">
                      <span className="visually-hidden">
                        Show Deactivated
                      </span>
                      <input
                        type="checkbox"
                        onChange={(e) => {
                          if (e.target.checked === true) {
                            props.setInactive(true);
                          } else {
                            props.setInactive(false);
                          }
                        }}
                        checked={inactive_user}
                        value={inactive_user}
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </Form>
                <Grid
                  data={props.userList}
                  loading={props.loading}
                  editModal={props.editModal}
                  filterTerr={props.filterTerr}
                  type="user"
                />

              </React.Fragment>
            ) : <p className="black-color font-size-12">You do not have access to view this page</p> : null}
            {tabValue === "manageCare" ? facilityList ? (
              <React.Fragment>
                <Form className="mb-10 mt-10 adduser-row w-100">
                  {facilityCreate && (
                    <Button
                      className="add-user-btn roboto-medium font-size-14 d-flex align-items-center"
                      onClick={() =>
                        props.toggleFacilityModal(true, {}, "create")
                      }
                    >
                      <span className="icon-care-facility">
                        <span className="visually-hidden">
                          Add Care facility icon
                        </span>
                      </span>
                      <span>Add Care Facility</span>
                    </Button>
                  )}
                  <div className="ad-toggle-switch d-flex align-items-center">
                    <span className="ad-switch font-size-14 roboto-regular ">
                      Show Deactivated
                    </span>
                    <label className="switch position-relative">
                      <span className="visually-hidden">
                        Show Deactivated
                      </span>
                      <input
                        type="checkbox"
                        onChange={(e) => {
                          if (e.target.checked === true) {
                            handleChangeDeactivate("facility", true);
                          } else {
                            handleChangeDeactivate("facility", false);
                          }
                        }}
                        value={facilityInactive}
                        checked={facilityInactive}
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </Form>
                <CareGrid
                  facilityList={props.facilityList ? props.facilityList.map(fac => {
                    return {
                      ...fac,
                      service_days_string: fac.service_days
                        ? fac.service_days
                          .map((day) => {
                            return getDayName(day.day, days);
                          })
                          .join(", ")
                        : "-"
                    }
                  }) : []}
                  loading={props.faciLoading}
                  editModal={props.toggleFacilityModal}
                  filterTerr={props.filterTerr}
                  facilityServiceType={props.facilityServiceType}
                  days={days}
                />

              </React.Fragment>
            ) : <p className="black-color font-size-12">You do not have access to view this page</p> : null}
            {tabValue === "manageHospitals" ? canViewHospitals ? <React.Fragment>
              <Form className="d-flex justify-content-between mb-10 mt-10 adduser-row w-100">
                {canCreateHospital && (
                  <Button
                    className="add-user-btn roboto-medium font-size-14 d-flex align-items-center"
                    onClick={() =>
                      props.toggleHospitalModal(true, {}, "create")
                    }
                  >
                    <span className="icon-add-hospital">
                      <span className="visually-hidden">Add hospital icon</span>
                    </span>
                    <span>Add Hospital</span>
                  </Button>
                )}
                <div className="ad-toggle-switch d-flex align-items-center">
                  <span className="ad-switch font-size-14 roboto-regular ">
                    Show Deactivated
                  </span>
                  <label className="switch position-relative">
                    <span className="visually-hidden">
                      Show Deactivated
                    </span>
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        if (e.target.checked === true) {
                          handleChangeDeactivate("hospital", true);
                        } else {
                          handleChangeDeactivate("hospital", false);
                        }
                      }}
                      value={hospitalInactive}
                      checked={hospitalInactive}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
              </Form>
              <HospitalList
                data={props.hospitalList}
                loading={props.hospitalLoader}
                editModal={props.toggleHospitalModal}
                days={days}
              />
            </React.Fragment> : <p className="black-color font-size-12">You do not have access to view this page</p> : null}
            {tabValue === "manageLabs" ? canViewLabs ? <React.Fragment>
              <Form className="d-flex justify-content-between mb-10 mt-10 adduser-row w-100">
                {canCreateLab && (
                  <Button
                    className="add-user-btn roboto-medium font-size-14 d-flex align-items-center"
                    onClick={() =>
                      props.toggleLabModal(true, {}, "create")
                    }
                  >
                    <span className="icon-add-lab">
                      <span className="visually-hidden">Add lab icon</span>
                    </span>
                    <span>Add Lab</span>
                  </Button>
                )}
                <div className="ad-toggle-switch d-flex align-items-center">
                  <span className="ad-switch font-size-14 roboto-regular ">
                    Show Deactivated
                  </span>
                  <label className="switch position-relative">
                    <span className="visually-hidden">
                      Show Deactivated
                    </span>
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        if (e.target.checked === true) {
                          handleChangeDeactivate("lab", true);
                        } else {
                          handleChangeDeactivate("lab", false);
                        }
                      }}
                      value={labInactive}
                      checked={labInactive}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
              </Form>
              <LabList
                data={props.labList ? props.labList.map(lab => {
                  return {
                    ...lab,
                    lab_timings_string: lab.lab_timings
                      ? lab.lab_timings.map(day => {
                        return `( day: ${getDayName(day.day_id)}, startTime: ${day.start_time}, endTime: ${day.end_time} )`
                      }).join(", ")
                      : "-"
                  }
                }) : []}
                loading={props.labLoader}
                editModal={props.toggleLabModal}
                days={days}
              />
            </React.Fragment> : <p className="black-color font-size-12">You do not have access to view this page</p> : null}
            {tabValue === "manageTerritories" ? canViewTerritories ? <React.Fragment>
              <Form className="d-flex justify-content-between mb-10 mt-10 adduser-row w-100">
                {canCreateTerritory && (
                  <Button
                    className="add-user-btn roboto-medium font-size-14 d-flex align-items-center"
                    onClick={() =>
                      props.toggleTerritoryModal(true, {}, "create")
                    }
                  >
                    {/* <span className="icon-add-lab">
                      <span className="visually-hidden">Add territory icon</span>
                    </span> */}
                    <span>Add Territory</span>
                  </Button>
                )}
              </Form>
              <TerritoryList
                data={props.territoryList || []}
                loading={props.territoryLoader}
                editModal={props.toggleTerritoryModal}
              />
            </React.Fragment> : <p className="black-color font-size-12">You do not have access to view this page</p> : null}
            {tabValue === "manageZones" ? canViewZones ? <React.Fragment>
              <Form className="d-flex justify-content-between mb-10 mt-10 adduser-row w-100">
                {canViewZones && (
                  <Button
                    className="add-user-btn roboto-medium font-size-14 d-flex align-items-center"
                    onClick={() =>
                      props.toggleZoneModal(true, {}, "create")
                    }
                  >
                    {/* <span className="icon-add-lab">
                      <span className="visually-hidden">Add zone icon</span>
                    </span> */}
                    <span>Add Zone</span>
                  </Button>
                )}
                <div className="ad-toggle-switch d-flex align-items-center">
                  <span className="ad-switch font-size-14 roboto-regular ">
                    Show Deactivated
                  </span>
                  <label className="switch position-relative">
                    <span className="visually-hidden">
                      Show Deactivated
                    </span>
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        if (e.target.checked === true) {
                          handleChangeDeactivate("zone", true);
                        } else {
                          handleChangeDeactivate("zone", false);
                        }
                      }}
                      value={zoneInactive}
                      checked={zoneInactive}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
              </Form>
              <ZoneList
                data={props.zoneList || []}
                loading={props.zoneLoader}
                editModal={props.toggleZoneModal}
              />
            </React.Fragment> : <p className="black-color font-size-12">You do not have access to view this page</p> : null}
          </div>
        </div>
      <AddUser
        show={show}
        handleClose={handleClose}
        err_first={err_first}
        setErrFirst={setErrFirst}
        setData={setData}
        data={data}
        err_last={err_last}
        setErrLast={setErrLast}
        err_emp={err_emp}
        setErrEmp={setErrEmp}
        err_username={err_username}
        setErrUsername={setErrUsername}
        job_title={props.job_title}
        handleJob={handleJob}
        handleHomeRegion={handleHomeRegion}
        handleManager={handleManager}
        err_job={err_job}
        regionOptions={regionOptions}
        err_homeRegion={err_homeRegion}
        setErr_homeRegion={setErr_homeRegion}
        err_regionOrTerri={err_regionOrTerri}
        region={props.region}
        territoryData={territoryData}
        handleTerritory={handleTerritory}
        handleList={handleList}
        chip={chip}
        roles={props.roles}
        userList={props.userList}
        handleSave={handleSave}
        error={error}
        handleError={handleError}
        bc={options}
        executive={executive}
        re={re}
        jobData={jobData}
        homeRegion={homeRegion}
        te={te}
        chipData={chipData}
        setChip={setChip}
        err_email={err_email}
        err_phone={err_phone}
        setErrEmail={setErrEmail}
        setErrPhone={setErrPhone}
        type={type}
        setRegionWithTerritories={setRegionWithTerritories}
        setErrRegionOrTerri={setErrRegionOrTerri}
        accessGroupError={accessGroupError}
        setAccessGroupError={setAccessGroupError}
        regionTerritory={regionTerritory}
        addLoader={addLoader}
        setManager={setManager}
        err_manager={err_manager}
        setReRenderComp={setReRenderComp}
        reRenderComp={reRenderComp}
      />

      {lottie && <LottieAnimation />}
    </React.Fragment>
  );
}
