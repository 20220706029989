import React from "react";
import { Accordion } from "react-bootstrap";
import Avatar from "react-avatar";
import Select from "react-select";
import { Form, InputGroup } from "react-bootstrap";
export default function SchedulerComponent() {
  return (
    <div>
      {" "}
      <React.Fragment>
        <div className="ad-headertop-wrapper d-flex justify-content-between">
          <div className="header-left">
            <h1 className="headline4 pab-5">Scheduler Components</h1>
          </div>
        </div>
        {/* <DateTimePickerComponent></DateTimePickerComponent> */}
        <div className="scheduler-wrapper">
          <div className="scheduler-backlogs">
            <h4 className="text-center">Service Types</h4> <br />
            <div className="service-type routine-draws">
              <span className="service-icon"></span>
              <p className="title">Routine Draws</p>
            </div>
            <br />
            <div className="service-type home-health">
              <span className="service-icon"></span>
              <p className="title" data-testid="sch-home-health-1">
                Home Health{" "}
              </p>
            </div>
            <br />
            <div className="service-type on-call">
              <span className="service-icon"></span>
              <p data-testid="sch-on-call-1" className="title">
                On Call
              </p>
            </div>
            <br />
            <div className="service-type drop-off">
              <span className="service-icon"></span>
              <p data-testid="sch-drop-off-1" className="title">
                Drop Off
              </p>
            </div>
            <div className="service-details">
              <div className="service-rtz">
                <p className="title">CINCINNATI: EAST</p>
              </div>
              <div className="sub-serivce-type">
                <span className="service-icon"></span>
                <p className="title" data-testid="sch-facilities-1">
                  Facilities: Scheduled Service Day
                </p>
              </div>
              <div className="service-locations active">
                <p className="title" data-testid="sch-eastgatespring-1">
                  Eastgatespring
                </p>
                <span className="service-icon"></span>
              </div>
            </div>
          </div>
          <div className="row">
            <h2 className="text-center">Facility Chips, Routine</h2>
            <div className="col-md-6">
              <h4 className="text-center">On Task Bar</h4> <br />
              <p data-testid="sch-active-state-1">Active State</p>
              <div className="service-locations active">
                <p className="title" data-testid="sch-eastgatespring-2">
                  Eastgatespring
                </p>
                <span className="service-icon"></span>
              </div>
              <p data-testid="sch-assigned-state-1">Assigned State</p>
              <div className="service-locations assigned">
                <Avatar
                  data-testid="sch-avatar-1"
                  color="#333745"
                  name="Anjan Kumar"
                  maxInitials={2}
                  size={35}
                  round="50px"
                  className="sch-avatar-wrapper"
                />
                <p className="title">Arbors Milford</p>
                <span className="service-icon"></span>
              </div>
              <p>Checked In State</p>
              <div className="service-locations checked-in">
                <Avatar
                  data-testid="sch-avatar-1"
                  color="#333745"
                  name="Anjan Kumar"
                  maxInitials={2}
                  size={35}
                  round="50px"
                  className="sch-avatar-wrapper"
                />
                <p className="title" data-testid="sch-artis-senior">
                  Artis Senior Living Bridget
                </p>
                <span className="service-icon"></span>
              </div>
              <p>Inactive State</p>
              <div className="service-locations inactive">
                <p className="title" data-testid="sch-madeira-hc">
                  Madeira H.C.
                </p>
                <span className="service-icon"></span>
              </div>
              <p>Inactive Assigned State</p>
              <div className="service-locations inactive-assigned">
                <Avatar
                  data-testid="sch-avatar-1"
                  color="#333745"
                  name="Anjan Kumar"
                  maxInitials={2}
                  size={35}
                  round="50px"
                  className="sch-avatar-wrapper"
                />
                <p className="title" data-testid="sch-artis-senior">
                  Artis Senior Living Bridget…
                </p>
                <span className="service-icon"></span>
              </div>
              <p>Visit Complete State</p>
              <div className="service-locations visit-complete">
                <Avatar
                  data-testid="sch-avatar-1"
                  color="#333745"
                  name="Anjan Kumar"
                  maxInitials={2}
                  size={35}
                  round="50px"
                  className="sch-avatar-wrapper"
                />
                <p className="title" data-testid="sch-artis-senior">
                  Artis Senior Living Bridget
                </p>
                <span className="service-icon"></span>
              </div>
            </div>
            <div className="col-md-6">
              <h4 className="text-center">On Calendar</h4> <br />
              <p>Default Assigned On Calendar State</p>
              <div className="service-locations default-assigned-calender">
                <p className="title" data-testid="sch-madeira-hc">
                  <span className="service-icon2"></span>
                  Madeira H.C.
                </p>
                <span className="service-icon"></span>
              </div>
              <p>Assigned On Calendar State</p>
              <div className="service-locations assigned-on-calender">
                <p className="title" data-testid="sch-madeira-hc">
                  <span className="service-icon2"></span>
                  Madeira H.C.
                </p>
                <span className="service-icon"></span>
              </div>
              <p>Checked In Calendar State</p>
              <div className="service-locations checked-in-calender">
                <p className="title">Madeira H.C.</p>
                <span className="service-icon"></span>
              </div>
              <p>Inactive Assigned Calendar State</p>
              <div className="service-locations inactive-assigned-calender">
                <p className="title" data-testid="sch-madeira-hc">
                  <span className="service-icon2"></span>
                  Madeira H.C.
                </p>
                <span className="service-icon"></span>
              </div>
              <p>Complete Calendar State</p>
              <div className="service-locations visit-calender-complete">
                <p className="title" data-testid="sch-artis-senior">
                  Artis Senior Living Bridget
                </p>
                <span className="service-icon icon"></span>
              </div>
              <p>Drop Off Courier</p>
              <div className="service-locations drop-off-service courier">
                <p data-testid="sch-courier-time" className="title">
                  <span>12:12</span> Courier
                </p>
                <span className="service-icon icon"></span>
              </div>
              <p>Drop Off Lab Name</p>
              <div className="service-locations drop-off-service labname">
                <p data-testid="sch-lab-time" className="title">
                  <span>14:07</span> Lab Name
                </p>
                <span className="service-icon icon"></span>
              </div>
              <p>Drop Off Hospital</p>
              <div className="service-locations drop-off-service hospital">
                <p data-testid="sch-hospital-time" className="title">
                  <span>16:08</span> Hospital
                </p>
                <span className="service-icon icon"></span>
              </div>
            </div>
          </div>
          <div className="row">
            <Accordion className="schedule-accordian-wrapper">
              <Accordion.Item eventKey="0">
                <div className="schedule-options">
                  <span className="schedule-edit"></span>
                  <span className="schedule-delete disable"></span>
                </div>
                <Accordion.Header className="routine-draws">
                  <span className="service-icon"></span>
                  <div className="accordian-head">
                    <p className="title">ROUTINE Draws</p>
                    <p className="sub-title" data-testid="sch-add-to-sch">
                      Add To Schedule
                    </p>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  {/* <div className="sch-close-wrapper">
                    <span className="schedule-remove" title="Remove"
                        ><span className="visually-hidden">Schedule Remove Icon</span></span>
                    </div> */}
                  <div className="choose-time-wrapper">
                    <div className="schedule-start-time">
                      <InputGroup className="mb-3">
                        <Form.Label htmlFor="schedule-start-time">
                          Start Time
                        </Form.Label>
                        <Select
                          name="schedule-start-time"
                          id="schedule-start-time"
                          className="sch-schedule-dropdown"
                          classNamePrefix="sch-schedule-select"
                        />
                      </InputGroup>
                    </div>
                    <div className="seperator">
                      <span className="service-icon"></span>
                    </div>
                    <div className="schedule-end-time">
                      <InputGroup className="mb-3">
                        <Form.Label htmlFor="schedule-end-time">
                          End Time
                        </Form.Label>
                        <Select
                          name="schedule-end-time"
                          id="schedule-end-time"
                          className="sch-schedule-dropdown"
                          classNamePrefix="sch-schedule-select"
                        />
                      </InputGroup>
                    </div>
                  </div>
                  <div className="scheduler-button-wrapper">
                    <button className="btn btn-cancel" data-testid="sch-cancel">
                      Cancel
                    </button>
                    <button
                      className="btn btn-add"
                      data-testid="sch-add-to-sch"
                    >
                      Add To Schedule
                    </button>
                  </div>
                  <p
                    data-testid="sch-drop-facility"
                    className="drop-facility-zone-text"
                  >
                    Drag facilities here from the{" "}
                    <span className="roboto-medium">Task Bar</span> to add to a
                    person’s schedule
                  </p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <div className="schedule-options">
                  <span className="schedule-edit"></span>
                  <span className="schedule-delete disable"></span>
                </div>
                <Accordion.Header className="home-health">
                  <span className="service-icon"></span>
                  <div className="accordian-head">
                    <p className="title" data-testid="sch-home-accordion">
                      Home Health
                    </p>
                    <p className="sub-title" data-testid="sch-add-to-sch">
                      Add To Schedule
                    </p>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="choose-time-wrapper">
                    <div className="schedule-start-time">
                      <InputGroup className="mb-3">
                        <Form.Label htmlFor="schedule-start-time">
                          Start Time
                        </Form.Label>
                        <Select
                          name="schedule-start-time"
                          id="schedule-start-time"
                          className="sch-schedule-dropdown"
                          classNamePrefix="sch-schedule-select"
                        />
                      </InputGroup>
                    </div>
                    <div className="seperator">
                      <span className="service-icon"></span>
                    </div>
                    <div className="schedule-end-time">
                      <InputGroup className="mb-3">
                        <Form.Label htmlFor="schedule-end-time">
                          End Time
                        </Form.Label>
                        <Select
                          name="schedule-end-time"
                          id="schedule-end-time"
                          className="sch-schedule-dropdown"
                          classNamePrefix="sch-schedule-select"
                        />
                      </InputGroup>
                    </div>
                  </div>
                  <div className="scheduler-button-wrapper">
                    <button className="btn btn-cancel" data-testid="sch-cancel">
                      Cancel
                    </button>
                    <button
                      className="btn btn-add"
                      data-testid="sch-add-to-sch"
                    >
                      Add To Schedule
                    </button>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <div className="schedule-options">
                  <span className="schedule-edit"></span>
                  <span className="schedule-delete disable"></span>
                </div>
                <Accordion.Header className="on-call">
                  <span className="service-icon"></span>
                  <div className="accordian-head">
                    <p className="title" data-testid="sch-oncall-accordion">
                      On Call
                    </p>
                    <p className="sub-title" data-testid="sch-add-to-sch">
                      Add To Schedule
                    </p>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="choose-time-wrapper">
                    <div className="schedule-start-time">
                      <InputGroup className="mb-3">
                        <Form.Label htmlFor="schedule-start-time">
                          Start Time
                        </Form.Label>
                        <Select
                          name="schedule-start-time"
                          id="schedule-start-time"
                          className="sch-schedule-dropdown"
                          classNamePrefix="sch-schedule-select"
                        />
                      </InputGroup>
                    </div>
                    <div className="seperator">
                      <span className="service-icon"></span>
                    </div>
                    <div className="schedule-end-time">
                      <InputGroup className="mb-3">
                        <Form.Label htmlFor="schedule-end-time">
                          End Time
                        </Form.Label>
                        <Select
                          name="schedule-end-time"
                          id="schedule-end-time"
                          className="sch-schedule-dropdown"
                          classNamePrefix="sch-schedule-select"
                        />
                      </InputGroup>
                    </div>
                  </div>
                  <div className="scheduler-button-wrapper">
                    <button className="btn btn-cancel" data-testid="sch-cancel">
                      Cancel
                    </button>
                    <button
                      className="btn btn-add"
                      data-testid="sch-add-to-sch"
                    >
                      Add To Schedule
                    </button>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <div className="schedule-options">
                  <span className="schedule-edit"></span>
                  <span className="schedule-delete disable"></span>
                </div>
                <Accordion.Header className="drop-off">
                  <span className="service-icon"></span>
                  <div className="accordian-head">
                    <p
                      className="sub-title"
                      data-testid="sch-dropoff-accordion"
                    >
                      Drop Off
                    </p>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="service-locations drop-off-service hospital">
                    <p data-testid="sch-16:08-hospital" className="title">
                      <span>16:08</span> Hospital
                    </p>
                    <span className="service-icon icon"></span>
                  </div>
                  <div className="service-locations drop-off-service hospital">
                    <p data-testid="sch-16:08-hospital" className="title">
                      <span>16:08</span> Hospital
                    </p>
                    <span className="service-icon icon"></span>
                  </div>
                  <div className="service-locations drop-off-service hospital">
                    <p data-testid="sch-16:08-hospital" className="title">
                      <span>16:08</span> Hospital
                    </p>
                    <span className="service-icon icon"></span>
                  </div>
                  <div className="choose-time-wrapper">
                    <div className="schedule-start-time">
                      <InputGroup className="mb-3">
                        <Form.Label htmlFor="schedule-start-time">
                          Start Time
                        </Form.Label>
                        <Select
                          name="schedule-start-time"
                          id="schedule-start-time"
                          className="sch-schedule-dropdown"
                          classNamePrefix="sch-schedule-select"
                        />
                      </InputGroup>
                    </div>
                    <div className="seperator">
                      <span className="service-icon"></span>
                    </div>
                    <div className="schedule-end-time">
                      <InputGroup className="mb-3">
                        <Form.Label htmlFor="schedule-end-time">
                          End Time
                        </Form.Label>
                        <Select
                          name="schedule-end-time"
                          id="schedule-end-time"
                          className="sch-schedule-dropdown"
                          classNamePrefix="sch-schedule-select"
                        />
                      </InputGroup>
                    </div>
                  </div>
                  <div className="scheduler-button-wrapper">
                    <button className="btn btn-cancel" data-testid="sch-cancel">
                      Cancel
                    </button>
                    <button
                      className="btn btn-add"
                      data-testid="sch-add-to-sch"
                    >
                      Add To Schedule
                    </button>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>

        <div className="row scheduler-wrapper">
          <div className="col-md-4">
            <h2 className="text-center">2X Facility Chips</h2>

            <p data-testid="sch-active-2">Active State</p>
            <div className="service-locations active-2X">
              <p className="title" data-testid="sch-2x-madeira">
                2X Madeira H.C.
              </p>
              <span className="service-icon"></span>
            </div>
            <p data-testid="sch-assigned-1x">Assigned 1x State</p>
            <div className="service-locations assigned-1X sc-border-red">
              <p className="title" data-testid="sch-2x-madeira">
                2X Madeira H.C.
              </p>
              <span className="service-icon"></span>
            </div>
            <p data-testid="sch-assigned-2x-default">
              Assigned 2X Default State
            </p>
            <div className="service-locations assigned-2X-default">
              <p className="title" data-testid="sch-2x-madeira">
                2X Madeira H.C.
              </p>
              <span className="service-icon"></span>
            </div>
            <p data-testid="sch-assigned-2X-combo">Assigned 2x Combo States</p>
            <div className="service-locations assigned-2X-combo">
              <p className="title" data-testid="sch-2x-madeira">
                2X Madeira H.C.
              </p>
              <div
                className="states-combo"
                data-testid="sch-user-count-assigned"
              >
                <span className="service-icon2 icon">
                  <span className="user-count">1</span>
                </span>
                <span className="service-icon icon">
                  <span className="user-count">1</span>
                </span>
              </div>
            </div>
            <p>
              Not Assigned to one user but one user checked in or checked out
              with drag
            </p>
            <div className="service-locations assigned-2X-combo sc-border-red">
              <p className="title" data-testid="sch-2x-madeira">
                2X Madeira H.C.
              </p>
              <div className="states-combo pr-0">
                <span className="service-icon icon checked-out">
                  <span className="user-count">1</span>
                </span>
                <span className="service-icon icon drag"></span>
              </div>
            </div>
            <p data-testid="sch-completed-state">Completed 2X State</p>
            <div className="service-locations assigned-2X-combo visit-complete">
              <p className="title" data-testid="sch-2x-madeira">
                2X Madeira H.C.
              </p>
              <div
                className="states-combo"
                data-testid="sch-user-count-completed"
              >
                <span className="service-icon icon">
                  <span className="user-count">2</span>
                </span>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="icon-tracker-facility-default icon-tracker-wh"></div>
            <div className="icon-tracker-facility-toggle icon-tracker-wh"></div>
            <div className="icon-tracker-hospital-default icon-tracker-wh"></div>
            <div className="icon-tracker-hospital-toggle icon-tracker-wh"></div>
            <div className="icon-tracker-phleb-default icon-tracker-wh"></div>
            <div className="icon-tracker-phleb-toggle icon-tracker-wh"></div>
            <div className="icon-tracker-lab-default icon-tracker-wh"></div>
            <div className="icon-tracker-lab-toggle icon-tracker-wh"></div>
          </div>
        </div>
      </React.Fragment>
    </div>
  );
}
