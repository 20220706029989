import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { HOME, REGEX_EMAIL_ADDRESS } from "../../../app/utils/Constants";
import Logo from "../../../components/layouts/Logo";
import ToastMessage from "../../../components/layouts/ToastMessage";
import { forgotUser, forgotUserList } from "./forgotUserSlice";
export default function ForgotUser() {
  document.documentElement.classList.add("login-flow");
  document.documentElement.classList.add("forgot-user");
  const dispatch = useDispatch();
  const user = useSelector(forgotUserList);
  // const [error, setError] = React.useState(false);
  const [toast, setToast] = React.useState(true);
  const [status, setStatus] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [type, setType] = React.useState("email");
  const [data, setData] = React.useState("");

  const handleChange = () => {
    let payload = [];
    payload = {
      first_name: data && data.first_name,
      last_name: data.last_name,
      contact_information: data && data.contact,
    };

    dispatch(forgotUser(payload));
  };
  const handleRadio = (e) => {
    setType(e);
    setData({ ...data, contact: "" });
  };
  React.useEffect(() => {
    if (user && user.status && user.status === "SUCCESS") {
      setStatus("success");
      setMessage(user.message);
      setToast(false);
      setData("");
    } else if (user && user.status && user.status === "ERROR") {
      // setError(true);
      setMessage(user.message);
      setStatus("error");
      // setErrMsg(user.message);
      setToast(false);
    }
  }, [user]);
  const toastHandler = () => {
    setToast(true);
  };

  return (
    <React.Fragment>
      <Logo />

      <div>
        <h1 className="mab-5 roboto-regular login-title">
          Contact Administrator
        </h1>
        <p className="roboto-regular login-info">
          Please enter your name and the best way to contact you, and an
          administrator will reach out shortly
        </p>
        <form className="aha-login-wrapper">
          <div className="d-flex fup-wrapper">
            <div className="aha-form-group mab-5 text-start">
              <label
                className="mb-0 roboto-medium form-label"
                htmlFor="fup-firstname"
              >
                First Name
              </label>
              <input
                type="text"
                className="form-control"
                id="fup-firstname"
                onChange={(e) => {
                  setData({ ...data, first_name: e.target.value });
                }}
                value={data && data.first_name}
              />
            </div>
            <div className="aha-form-group mab-5 text-start">
              <label
                className="mb-0 roboto-medium form-label"
                htmlFor="fup-lastname"
              >
                Last Name
              </label>
              <input
                type="text"
                className="form-control"
                id="fup-lastname"
                onChange={(e) => {
                  setData({ ...data, last_name: e.target.value });
                }}
                value={data && data.last_name}
              />
            </div>
          </div>
          <div className="aha-form-group mb-15 text-start position-relative ad-radio-group">
            <label className="mb-0 roboto-medium form-label">
              Preferred Contact Method?
            </label>
            <div className="d-flex">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  id="phone-radio"
                  name="preffered"
                  value={"phone"}
                  checked={type === "phone" ? true : false}
                  onChange={(e) => {
                    handleRadio(e.target.value);
                  }}
                />
                <label className="form-check-label" for="phone-radio">
                  Phone
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  id="email-radio"
                  name="preffered"
                  value={"email"}
                  checked={type === "email" ? true : false}
                  onChange={(e) => {
                    handleRadio(e.target.value);
                  }}
                />
                <label className="form-check-label" for="email-radio">
                  Email
                </label>
              </div>
            </div>

            {type === "email" ? (
              <>
                <label
                  className="mb-0 roboto-medium form-label"
                  htmlFor="contact-you-email"
                >
                  Email
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="contact-you-email"
                  placeholder="Enter Email"
                  onChange={(e) => {
                    setData({ ...data, contact: e.target.value });
                  }}
                  value={data && data.contact}
                />
              </>
            ) : (
              <>
                <label
                  className="mb-0 roboto-medium form-label"
                  htmlFor="contact-you-phone"
                >
                  Phone
                </label>
                <input
                  type="number"
                  pattern="[0-9]"
                  className="form-control"
                  id="contact-you-phone"
                  placeholder="Enter Phone"
                  onChange={(e) => {
                    if (e.target.value.length <= 10)
                      setData({ ...data, contact: e.target.value });
                  }}
                  value={data && data.contact}
                />
              </>
            )}
          </div>
          <button
            disabled={
              data && data.first_name && data.last_name && data.contact
                ? type === "phone"
                  ? data.contact.length === 10
                    ? false
                    : true
                  : type === "email" &&
                    data &&
                    data.contact.search(REGEX_EMAIL_ADDRESS) >= 0
                  ? false
                  : true
                : true
            }
            className="primary-btn mb-10"
            type="button"
            onClick={() => {
              handleChange();
            }}
          >
            <span>Send Request</span>
            <span className="loader">
              <span className="visually-hidden">loader icon</span>
            </span>
          </button>
        </form>
        <div className="aha-link-wrapper">
          <a href={HOME}>Back to Sign In</a>
        </div>
      </div>
      <ToastMessage
        type={status}
        message={message}
        setToast={toastHandler}
        toast={toast}
      />
    </React.Fragment>
  );
}
