import { useRef, useState } from "react";
import { ProgressButtonComponent } from "@syncfusion/ej2-react-splitbuttons";
import { Button, Modal, Form, InputGroup } from "react-bootstrap";
import _ from "lodash";
import { menuOpenStopDayValue, setMenuOpenStopDay } from "./Operations/checkinCheckoutSlice";
import { CustomDropdown } from "../../components/commons/CustomDropdown";
import { useDispatch, useSelector } from "react-redux";
import { SURGE_TYPE } from "../../app/utils/Constants";

export default function ServiceStopDayModal(props = {}) {
	const { openStopDayModal = {}, toggleStopDayModal, continueDroppingFacility } = props;
	const { value, facility = {}, error, dropResponse = {}, stopDay = "", dropType } = openStopDayModal;
	const { account_name, street_address_1, street_address_2, city, state, zip } = facility;

	const dispatch = useDispatch();
	const stopDayRef = useRef(null);
	const menuOpenStopDay = useSelector(menuOpenStopDayValue);
	return (
		<Modal
			show={value}
			onHide={() => toggleStopDayModal({value: false})}
			className="ad-adduser-modal sc-copycal-modal"
			backdrop="static"
			enforceFocus={false}
		>
			<Modal.Header closeButton>
				<p className="error-message-alert sch-error-message-alert">{!_.isEmpty(error) && error}</p>
			</Modal.Header>
			<Modal.Body>
				<div className="scheduler-wrapper popover-wrapper x2-facility-popover sch-popover">
					<div className="sub-serivce-type">
						<span className="service-icon"></span>
						<div>
							<h3 className="title roboto-regular">
								{account_name}
							</h3>
							<p>
								{street_address_1} {street_address_2}
							</p>
							<p>
								{city} {state ? `, ${state}` : ""} {zip || ""}
							</p>
						</div>
					</div>
					<div className="schedule-accordian-wrapper accordion ">
						<div className="schedule-time">
							<InputGroup className="mb-3 w-auto ms-3">
								<Form.Label htmlFor="service-stop-day">
									Service Stop Day
								</Form.Label>
								<div className="position-relative w-100">
									<CustomDropdown
										placeholder="Choose"
										name="schedule-end-time"
										id="service-stop-day"
										className="sch-schedule-dropdown sch-schedule-select__control"
										value={stopDay}
										wrapperRef={stopDayRef}
										menuIsOpen={menuOpenStopDay || false}
										setMenuIsOpen={(val) => dispatch(setMenuOpenStopDay(val))}
										onChange={(e) => toggleStopDayModal({...openStopDayModal, stopDay: e})}
										options={[{ label: "1", value: "1" }, { label: "2", value: "2" }]}
									/>
								</div>
							</InputGroup>
						</div>

					</div>
				</div>
				<div className="d-flex justify-content-end mb-2">
					<Button
						className="primary-btn primary-btn-outline outline"
						onClick={() => {
							toggleStopDayModal({value: false})
						}}
					>
						Cancel
					</Button>
					<ProgressButtonComponent
						data-testid="button-save"
						content="Apply Changes"
						spinSettings={{ position: "Center" }}
						animationSettings={{ effect: "SlideLeft" }}
						cssClass="primary-btn primary-btn-outline ad-progress-btn"
						onClick={() => continueDroppingFacility(dropResponse, true, stopDay ? stopDay.value : 0, dropType)}
						disabled={!stopDay}
					></ProgressButtonComponent>
				</div>
			</Modal.Body>
		</Modal>
	);
}
