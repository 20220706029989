import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ahaApi from "../../../app/services/aha-api";
const initialState = {
  value: [],
  loading: false,
};

export const getZone = createAsyncThunk(
  "zone/getZone",
  async (data, { rejectWithValue }) => {
    const response = await ahaApi.Account.getZone(data)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return rejectWithValue(err.response.data);
      });

    return response;
  }
);

export const zoneSlice = createSlice({
  name: "zone",
  initialState,
  reducers: {
    setZoneList(state, action) {
      state.value = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getZone.pending, (state) => {
        state.loading = true;
      })
      .addCase(getZone.fulfilled, (state, action) => {
        state.loading = false;
        state.value = action.payload;
      })
      .addCase(getZone.rejected, (state, err) => {
        state.loading = false;
        state.value = err.payload;
      });
  },
});

export const zoneObj = (state) => state.zone.value;
export const zoneObjLoader = (state) => state.zone.loading;
export const { setZoneList } = zoneSlice.actions;

export default zoneSlice.reducer;
