import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ahaApi from "../../../app/services/aha-api";
const initialState = {
  value: [],
  loading: false,
};

export const getLabTypes = createAsyncThunk("labs/getLabTypes", async () => {
  const response = await ahaApi.Account.labTypes().then((res) => {
    return res;
  });

  return response;
});

export const labDataSlice = createSlice({
  name: "labData",
  initialState,

  extraReducers: (builder) => {
    builder
      .addCase(getLabTypes.pending, (state) => {
        state.loading = true;
      })
      .addCase(getLabTypes.fulfilled, (state, action) => {
        state.loading = false;
        state.value = action.payload;
      })
      .addCase(getLabTypes.rejected, (state, err) => {
        state.loading = false;
        state.value = err.payload;
      });
  },
});

export const labTypes = (state) => state&&state.labData&&state.labData.value;

export default labDataSlice.reducer;
