import React from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import App from "./App";
import { store } from "./app/store";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";
import "./assets/styles/main.scss";

import { configureAmplify } from "../src/features/Chat/services/servicesConfig";
import { registerLicense } from "@syncfusion/ej2-base";
import Storage from '@aws-amplify/storage';
import { appConfig } from "./features/Chat/Config";

// Registering Syncfusion license key
registerLicense(
  process.env.REACT_APP_SYNCFUSION_KEY
);

configureAmplify();
Storage.configure({ region:appConfig.region });

ReactDOM.render(
  <React.StrictMode>
      <Provider store={store}>
        <App />
      </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
