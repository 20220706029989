import * as React from "react";
import _ from "lodash";
import "mapbox-gl/dist/mapbox-gl.css";
// eslint-disable-next-line import/no-webpack-loader-syntax
import * as mapboxgl from "mapbox-gl";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import { TYPE_HOMEHEALTH, TYPE_ROUTINE, TYPE_STAT, TYPE_TIMEDDRAW } from "../../app/utils/Constants";

mapboxgl.accessToken =
  "pk.eyJ1IjoibWFkaGFucmFqIiwiYSI6ImNrdncwNnE5ODNrZTkycWtsaWZmNG00cG0ifQ.nd-4mXLZFx02rKavSTsKsw";

export const MapBoxInputSearch = (props) => {
  const {
    handleChangeLatLng,
    newDropOff,
    pageType = "dropOff",
    handleChange,
    stateData,
    disabled = false,
    id,
    tempCourierAddress,
    setTempCourierAddress,
    setClearStartRoutineAddress,
    clearStartRoutineAddress,
    clearMapboxSearchText,
    setClearMapboxSearchText,
    updateHomeHealthValidateByMapbox,
    setClearMapboxSearchTextFacility,
    clearMapboxSearchTextFacility,
    clearMapboxSearchTextLabHospital,
    setClearMapboxSearchTextLabHospital
  } = props;

  const mapContainer = React.useRef(null);
  const map = React.useRef(null);
  const [lng, setLng] = React.useState(-85.13);
  const [lat, setLat] = React.useState(40.38);
  const [zoom, setZoom] = React.useState(3.18);

  const newDropOffRef = React.useRef({});
  newDropOffRef.current = newDropOff;

  const stateDataRef = React.useRef({});
  stateDataRef.current = stateData;

  const [geocoder, setGeocoder] = React.useState(() => { });

  const dropOffSearch =
    id !== "child-account-address" &&
    id !== "ad-search-address" &&
    id !== "ad-search-hospital-address" &&
    id !== "ad-search-lab-address";

  React.useEffect(() => {
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/streets-v11",
      center: [lng, lat],
      zoom: zoom,
    });
    // Add the control to the map.
    const geocoder = new MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      mapboxgl: mapboxgl,
      countries: "us",
    });
    geocoder.on("result", (event) => {
      const coordinates = event.result.geometry.coordinates;
      const { properties = {}, place_type = [] } = event.result;
      console.log(event.result, 'event.result');
      let city = "",
        state = null,
        zip = "";
      function getAddressByType(value) {
        if (value.id.match(/region.*/)) {
          let shortCode = value.short_code;
          shortCode = shortCode.split("-")[1];
          state = shortCode;
        } else if (value.id.match(/postcode.*/)) {
          zip = value.text;
        } else if (value.id.match(/place.*/)) {
          city = value.text;
        }
      }
      pageType !== "dropOff" &&
        event.result.context &&
        event.result.context.forEach(getAddressByType);
      if (
        pageType === `dropOff-${TYPE_STAT}` ||
        pageType === `dropOff-${TYPE_ROUTINE}` ||
        pageType === `dropOff-${TYPE_TIMEDDRAW}`
      ) {
        handleChange(
          {
            ...stateDataRef.current,
            dropOffLocation: {
              label: `${event.result.address ? `${event.result.address} ` : ""
                }${event.result.text}${properties.address ? `, ${properties.address}` : ""
                }`,
              value: {
                city,
                stateName: state,
                zip,
                address1: `${event.result.address ? `${event.result.address} ` : ""
                  }${event.result.text}${properties.address ? `, ${properties.address}` : ""
                  }`,
                longitudeName: coordinates[0],
                latitudeName: coordinates[1],
              },
            },
          },
          !city || !state || !zip
        );
      } else {
        if (pageType === "dropOff") {
          handleChangeLatLng({
            ...newDropOffRef.current,
            dropOffLng: coordinates[0],
            dropOffLat: coordinates[1],
          });
          if (dropOffSearch) {
            const inputDiv = document.getElementsByClassName(
              "mapboxgl-ctrl-geocoder mapboxgl-ctrl"
            );
            if (inputDiv && inputDiv.length) {
              for (var i = 0, len = inputDiv.length; i < len; i++) {
                inputDiv[i].style["display"] = "none";
              }
            }
            setTempCourierAddress({
              ...newDropOffRef.current,
              ...event.result,
              dropOffLng: coordinates[0],
              dropOffLat: coordinates[1],
            });
          }
        } else if (pageType === TYPE_HOMEHEALTH) {
          console.log(event, 'eventevent');
          handleChange(
            {
              ...stateDataRef.current,
              city,
              stateName: state,
              zip,
              address1: `${event.result.address ? `${event.result.address} ` : ""
                }${event.result.text}${properties.address ? `, ${properties.address}` : ""
                }`,
              longitudeName: coordinates[0],
              latitudeName: coordinates[1],
            },
            !city || !state || !zip
          );
        } else {
          handleChange(
            {
              ...stateDataRef.current,
              city,
              stateName: state,
              zip,
              address1: `${event.result.address ? `${event.result.address} ` : ""
                }${event.result.text}${properties.address ? `, ${properties.address}` : ""
                }`,
              longitudeName: coordinates[0],
              latitudeName: coordinates[1],
            },
            !city || !state || !zip
          );
        }
      }
    });
    geocoder.on("clear", (event) => {
      if (
        pageType === `dropOff-${TYPE_STAT}` ||
        pageType === `dropOff-${TYPE_ROUTINE}` ||
        pageType === `dropOff-${TYPE_TIMEDDRAW}`
      ) {
        handleChange(
          {
            ...stateDataRef.current,
            dropOffLocation: {
              city: "",
              stateName: "",
              zip: "",
              address1: "",
              longitudeName: "",
              latitudeName: "",
            },
          },
          false
        );
      } else {
        if (pageType === "dropOff") {
          handleChangeLatLng({
            ...newDropOffRef.current,
            dropOffLng: "",
            dropOffLat: "",
            timezone: ""
          });
          if (dropOffSearch) {
            const inputDiv = document.getElementsByClassName(
              "mapboxgl-ctrl-geocoder mapboxgl-ctrl"
            );
            if (inputDiv && inputDiv.length) {
              for (var i = 0, len = inputDiv.length; i < len; i++) {
                inputDiv[i].style["display"] = "block";
              }
            }
          }
        } else if (pageType === TYPE_HOMEHEALTH) {
          updateHomeHealthValidateByMapbox(true, true);
        } else {
          let newData = {
            ...stateDataRef.current,
            city: "",
            stateName: "",
            zip: "",
            address1: "",
            address2: "",
            longitudeName: "",
            latitudeName: "",
            timezone: ""
          }
          if (pageType === "facility") {
            newData.timezone = "";
          }
          handleChange(newData, false);
        }
      }
    });
    geocoder.on("results", (event) => {
      if (pageType === TYPE_HOMEHEALTH) {
        const { features = [], query = [] } = event;
        if (query.length) {
          if (!features.length) {
            updateHomeHealthValidateByMapbox(false);
          } else {
            updateHomeHealthValidateByMapbox(true);
          }
        } else {
          updateHomeHealthValidateByMapbox(true);
        }
        console.log(event, 'event');
      }
    });
    setGeocoder(geocoder);
    const node = document.getElementById(`geocoder-${id}`);
    geocoder.onAdd(map.current) &&
      node.appendChild(geocoder.onAdd(map.current));
  }, [lat, lng]);

  const clearTempAddressForHomeHealth = () => {
    const inputDiv = document.getElementsByClassName(
      "mapboxgl-ctrl-geocoder mapboxgl-ctrl"
    );
    if (inputDiv && inputDiv.length) {
      for (var i = 0, len = inputDiv.length; i < len; i++) {
        inputDiv[i].style["display"] = "block";
      }
    }
    const inputElement = document.querySelector(".mapboxgl-ctrl-geocoder--input");

    if (inputElement) {
      inputElement.value = "";
      inputElement.blur();
    }
  };

  React.useEffect(() => {
    if (clearStartRoutineAddress) {
      clearTempAddress();
      setClearStartRoutineAddress(false);
    }
  }, [clearStartRoutineAddress]);

  React.useEffect(() => {
    if (clearMapboxSearchText) {
      clearTempAddressForHomeHealth();
      setClearMapboxSearchText(false);
    }
  }, [clearMapboxSearchText, clearTempAddressForHomeHealth]);

  React.useEffect(() => {
    if (clearMapboxSearchTextFacility) {
      clearTempAddressForHomeHealth();
      setClearMapboxSearchTextFacility(false);
    }
  }, [clearMapboxSearchTextFacility, setClearMapboxSearchTextFacility]);

  React.useEffect(() => {
    if (clearMapboxSearchTextLabHospital) {
      clearTempAddressForHomeHealth();
      setClearMapboxSearchTextLabHospital(false);
    }
  }, [clearMapboxSearchTextLabHospital, setClearMapboxSearchTextLabHospital]);

  const clearTempAddress = () => {
    setTempCourierAddress({});
    handleChangeLatLng({
      ...newDropOffRef.current,
      dropOffLng: "",
      dropOffLat: "",
      timezone: ""
    });
    const inputDiv = document.getElementsByClassName(
      "mapboxgl-ctrl-geocoder mapboxgl-ctrl"
    );
    if (inputDiv && inputDiv.length) {
      for (var i = 0, len = inputDiv.length; i < len; i++) {
        inputDiv[i].style["display"] = "block";
      }
    }
    if (geocoder) {
      geocoder.clear();
      document
        .getElementsByClassName("mapboxgl-ctrl-geocoder--input")[0]
        .blur();
    }
  };

  return (
    <React.Fragment>
      <div
        id={`geocoder-${id}`}
        className={`geocoder courier-m-search  ${disabled ? "input-disabled" : " "
          } ${dropOffSearch && tempCourierAddress && tempCourierAddress.place_name
            ? " courier-value-selected"
            : " courier-value"
          }`}
      ></div>
      {dropOffSearch && tempCourierAddress && tempCourierAddress.place_name && (
        <div className="do-attachment-wrapper co-location-wrapper">
          <>
            <p className="do-selected-file">
              <span className="courier-value">
                {tempCourierAddress.place_name}
              </span>
            </p>
            <span
              className="close-icon co-location-close"
              onClick={(e) => clearTempAddress()}
            ></span>
          </>
        </div>
      )}
      <div ref={mapContainer} />
    </React.Fragment>
  );
};
