/* eslint-disable import/no-unresolved */
// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import React from "react";

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalButtonGroup,
  ModalButton,
} from "amazon-chime-sdk-component-library-react";

import "../../../../assets/styles/pluginchat.scss";
import {
  GROUP_CHANNEL_TYPE,
  GROUP_CHANNEL_TYPE_TWO,
  INDIVIDUAL_CHANNEL_TYPE,
} from "../../../../app/utils/Constants";
import { useAuthContext } from "../../providers/AuthProvider";
import { useIdentityService } from "../../providers/IdentityProvider";
import _ from "lodash";

export const ViewMembersModal = ({ onClose, channel, members, moderators }) => {
  // TODO: Add search functionality

  const identityClient = useIdentityService();
  const { useCognitoIdp } = useAuthContext();
  const { username } = useAuthContext().member;
  const [formattedMembers, setFormattedMembers] = React.useState([]);

  const modArns = moderators.map((m) => m.Moderator.Arn);
  const channelMembers = members.map((m) => {
    if (modArns.indexOf(m.Member.Arn) >= 0) {
      return { name: m.Member.Name, role: "Moderator", arn: m.Member.Arn };
    }
    return { name: m.Member.Name, role: "Member", arn: m.Member.Arn };
  });

  const sortedMembers = channelMembers.sort((a, b) =>
    a.role.length > b.role.length ? -1 : 1
  );

  React.useEffect(() => {
    if (!identityClient) return;
    if (useCognitoIdp) {
      identityClient.setupClient();
    }
    // getAllUsers();
  }, [identityClient]);

  React.useEffect(async () => {
    const addedUsersPromises = sortedMembers.map(async (user) => {
      const tempUser = { ...user };
      const searchText = "username = " + '"' + user.name + '"';
      let usersInfo = (await identityClient.getUsers(60, searchText)) || [];
      if (usersInfo.length) {
        const currentUser = usersInfo[0] || {};
        const { Attributes = [], Username = "" } = currentUser;
        const firstName = Attributes.filter((att) => att.Name === "given_name")
          ? Attributes.filter((att) => att.Name === "given_name")[0]
            ? Attributes.filter((att) => att.Name === "given_name")[0].Value
            : ""
          : "";
        const middleName = Attributes.filter(
          (att) => att.Name === "middle_name"
        )
          ? Attributes.filter((att) => att.Name === "middle_name")[0]
            ? Attributes.filter((att) => att.Name === "middle_name")[0].Value
            : ""
          : "";
        const lastName = Attributes.filter((att) => att.Name === "family_name")
          ? Attributes.filter((att) => att.Name === "family_name")[0]
            ? Attributes.filter((att) => att.Name === "family_name")[0].Value
            : ""
          : "";
        const iconColorCode = Attributes.filter(
          (att) => att.Name === "nickname"
        )
          ? Attributes.filter((att) => att.Name === "nickname")[0]
            ? Attributes.filter((att) => att.Name === "nickname")[0].Value
            : ""
          : "";
        // const fullName = firstName + `${_.isEmpty(middleName) ? " " : ` ${middleName} `}` + lastName;
        const fullName = firstName + " " + lastName;
        const fullNameForInitials = firstName + " " + lastName;

        tempUser.fullName = fullName;
        tempUser.fullNameForInitials = fullNameForInitials;
        tempUser.iconColorCode = iconColorCode;
      }
      return tempUser;
    });
    const addedUsers = await Promise.all(addedUsersPromises);
    setFormattedMembers(addedUsers);
  }, []);

  let { Name = "" } = channel || {};
  if (Name.includes(GROUP_CHANNEL_TYPE) || Name.includes(GROUP_CHANNEL_TYPE_TWO)) {
    Name = Name.replace(GROUP_CHANNEL_TYPE, "");
    Name = Name.replace(GROUP_CHANNEL_TYPE_TWO, '');
  } else if (Name.includes(INDIVIDUAL_CHANNEL_TYPE)) {
    Name = Name.replace(INDIVIDUAL_CHANNEL_TYPE, "");
    let allUsers = Name.split(", ");
    if (allUsers.includes(username)) {
      allUsers = allUsers.filter((user) => user !== username);
      Name = allUsers.join(", ");
    }
  }

  return (
    <Modal onClose={onClose} className="view-members ch-main-modal">
      <ModalHeader title={`${Name}`} className="modal-header" />
      <ModalBody className="main-section modal-body">
        <ul className="list ch-view-mem-wrapper">
          <li className="list-header row">
            {/* <div className="name">Name</div>
            <div className="role">Role</div> */}
            <p className="roboto-medium font-size-14 text-uppercase">Members</p>
          </li>
          {formattedMembers.map((m) => (
            <li key={m.arn} className="row">
              <div className="name">{m.fullName || m.name}</div>
              <div className="role">{m.role}</div>
            </li>
          ))}
        </ul>
      </ModalBody>
      <ModalButtonGroup
        primaryButtons={[
          <ModalButton
            label="OK"
            variant="primary"
            closesModal
            className="primary-btn ad-progress-btn primary-btn-outline"
          />,
        ]}
      />
    </Modal>
  );
};

export default ViewMembersModal;
