import _ from "lodash";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ahaApi from "../../../app/services/aha-api";

const initialState = {
	value: [],
	loading: false,
};

export const hospitalsList = createAsyncThunk(
	"hospitals/list",
	async (data, { rejectWithValue }) => {
		const response = await ahaApi.Account.hospitalsList(data)
			.then((res) => {
				return res;
			})
			.catch((err) => {
				return rejectWithValue(err.response.data);
			});

		return response;
	}
);

export const getHospitalsSlice = createSlice({
	name: "hospitalsList",
	initialState,
	reducers: {
		clearHospitalsList(state, action) {
			state.value = {}
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(hospitalsList.pending, (state) => {
				state.loading = true;
			})
			.addCase(hospitalsList.fulfilled, (state, action) => {
				state.loading = false;
				state.value = action.payload;
			})
			.addCase(hospitalsList.rejected, (state, err) => {
				state.loading = false;
				state.value = err.payload;
			});
	},
});

export const hospitalsListList = (state) => state.hospitalsList.value;
export const { clearHospitalsList } = getHospitalsSlice.actions;

export default getHospitalsSlice.reducer;
