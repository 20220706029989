import React from "react";
import Logo from "../../../components/layouts/Logo";
import { forgotPassword, forgotPasswordList } from "./forgotPasswordSlice";
import {
  postResendInvite,
  resendInviteList,
} from "../SetPassword/resendInviteSlice";
import { useSelector, useDispatch } from "react-redux";
import ToastMessage from "../../../components/layouts/ToastMessage";

export default function LinkExpired(props) {
  const dispatch = useDispatch();
  const [status, setStatus] = React.useState("");
  const [toast, setToast] = React.useState(true);
  const [message, setMessage] = React.useState("");
  const forgotPass = useSelector(forgotPasswordList);
  const resendInvite = useSelector(resendInviteList);
  document.documentElement.classList.add("link-expired-screen");
  React.useEffect(() => {
    if (forgotPass && forgotPass.status && forgotPass.status === "SUCCESS") {
      setStatus("success");
      setMessage(forgotPass && forgotPass && forgotPass.message);
      // " A new Password Reset Link which expires in xx minutes has been sent to the user’s Email/Phone Number on this account. Don’t see the link? Try sending again."
      // );
      setToast(false);
    } else if (
      forgotPass &&
      forgotPass.status &&
      forgotPass.status === "ERROR"
    ) {
      setMessage(forgotPass && forgotPass && forgotPass.message);
      setStatus("error");
      setToast(false);
    }
  }, [forgotPass]);

  React.useEffect(() => {
    if (
      resendInvite &&
      resendInvite.status &&
      resendInvite.status === "SUCCESS"
    ) {
      setStatus("success");
      setMessage(resendInvite && resendInvite.message);
      setToast(false);
    } else if (
      resendInvite &&
      resendInvite.status &&
      resendInvite.status === "ERROR"
    ) {
      setMessage(resendInvite && resendInvite && resendInvite.message);
      setStatus("error");
      setToast(false);
    }
  }, [resendInvite]);

  const handleSubmit = () => {
    if (
      props.location &&
      props.location.state &&
      props.location.state.path &&
      props.location.state.path === "reset"
    ) {
      const uname =
        props &&
        props.location &&
        props.location.state &&
        props.location.state.uname;
      const data = {
        username: uname,
        resend_request: true,
      };
      dispatch(forgotPassword(data));
    } else {
      const inviteId =
        props.location &&
        props.location.state &&
        props.location.state.invite_id;
      const data = {
        invite_id: inviteId,
      };
      dispatch(postResendInvite(data));
    }
  };
  const toastHandler = () => {
    setToast(true);
  };

  return (
    <React.Fragment>
      <Logo />
      <div className="mb-15 auth-success-wrapper position-relative">
        <span className="icon-sand-complete success-info">
          <span className="visually-hidden">Sand Complete Icon</span>
        </span>
      </div>
      <div>
        <h1 className="mb-15 roboto-regular login-title">
          {props &&
          props.location &&
          props.location.state &&
          props.location.state.path &&
          props.location.state.path === "reset"
            ? "Password Reset Link Expired"
            : props.location.state.path === "invite" &&
              "Create Account Link Expired"}
        </h1>
        <p className="mb-20 roboto-regular login-info">
          This link was not used within 15 minutes. <br />
          Please request a new password reset link.
        </p>
        <button
          className="primary-btn"
          type="button"
          onClick={() => handleSubmit()}
          disabled={status === "success" ? true : false}
        >
          <span>Resend Link</span>
          <span className="loader">
            <span className="visually-hidden">loader icon</span>
          </span>
        </button>
      </div>
      <ToastMessage
        type={status}
        message={message}
        setToast={toastHandler}
        toast={toast}
      />
    </React.Fragment>
  );
}
