import _ from "lodash";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ahaApi from "../../../app/services/aha-api";
const initialState = {
  value: [],
  loading: false,
};

export const getOnCallBacklog = createAsyncThunk(
  "schedule/onCallBacklog",
  async (data, { rejectWithValue }) => {
    const response = await ahaApi.Account.onCallBacklog(data)
      .then((res) => {
        res.content = backlogData(res.content);
        return res;
      })
      .catch((err) => {
        return rejectWithValue(err.response.data);
      });
    return response;
  }
);

const backlogData = (backlogData) => {
  let resultBacklogData = _.cloneDeep(backlogData);

  resultBacklogData.services.forEach((service, serviceIndex) => {
	if (service.territories) {
		service.territories.forEach((terr, terrIndex) => {
			if (terr.events) {
				resultBacklogData.services[serviceIndex].territories[terrIndex].events =
					_.keyBy(terr.events, "event_type_id");
			}
		});

		let resultTerr = resultBacklogData.services[serviceIndex].territories;

		resultBacklogData.services[serviceIndex].territories = _.keyBy(
			resultTerr,
			"territory_id"
		);
	}
});

  return resultBacklogData;
};

export const onCallBacklogSlice = createSlice({
  name: "getOnCallBacklog",
  initialState,
  reducers: {
    updateOnCallBacklogValue(state, action) {
      state.value = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOnCallBacklog.pending, (state) => {
        state.loading = true;
      })
      .addCase(getOnCallBacklog.fulfilled, (state, action) => {
        state.loading = false;
        state.value = action.payload;
      })
      .addCase(getOnCallBacklog.rejected, (state, err) => {
        state.loading = false;
        state.value = err.payload;
      });
  },
});

export const onCallBacklogList = (state) => state.onCallBacklog.value;
export const { updateOnCallBacklogValue } = onCallBacklogSlice.actions;

export default onCallBacklogSlice.reducer;
