import Footer from "./Footer";
import logo from "../../assets/images/AHA-logo-white.svg";

export default function Maintenance() {
  return (
    <div className="main-wrapper mt-main-wrapper h-100 align-items-center justify-content-center text-center">
      <div className="w-100 mt-info-section">
        <img className="mt-aha-logo" src={logo} alt="AHA logo" />
        <p className="roboto-medium font-size-20 white-color">Site is down for maintenance.</p>
        <p className="roboto-regular font-size-16 white-color">Check again in a few minutes.</p>
      </div>
      <Footer />
    </div>
    );
}
