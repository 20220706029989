import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import ahaApi from "../../../app/services/aha-api";
import { phoneFormat } from "../../../app/utils/testUtils";

const initialState = {
  value: [],
  loading: false,
};

export const getFacilities = createAsyncThunk(
  "facilities/getFacilities",
  async (data, { rejectWithValue }) => {
    const response = await ahaApi.Account.facilities(data)
      .then((res) => {
        let facilities = res.content || [];
        facilities = facilities.map(fac => {
          return {
            ...fac,
            account_name_string: fac.account_name || "-",
            account_number_string: fac.account_number || "-",
            facility_type_name_string: fac.facility_type ? fac.facility_type.facility_type_name || "-" : "-",
            service_type_name_string: fac.service_type ? fac.service_type.service_type_name || "-" : "-",
            service_stop_name_string: fac.service_stop_day ? fac.service_stop_day.stop_day_name || "-" : "-",
            region_name_string: fac.region ? fac.region.region_name || "-" : "-",
            territory_name_string: fac.territory ? fac.territory.territory_name || "-" : "-",
            zone_name_string: fac.zone ? fac.zone.zone_name || "-" : "-",
            street_address1_string: fac.street_address1 || "-",
            city_string: fac.city || "-",
            state_name_string: fac.state ? fac.state.state_name || "-" : "-",
            zip_string: fac.zip || "-",
            account_manager_name_string: fac.account_manager_name || "-",
            account_manager_phone_string: fac.account_manager_phone ? phoneFormat(fac.account_manager_phone.toString()) : "-",
            account_manager_email_string: fac.account_manager_email || "-",
            facility_phone_string: fac.facility_phone ? `${phoneFormat(fac.facility_phone.toString())} ${fac.facility_phone_extension ? ` Ext. ${fac.facility_phone_extension}` : ""}` : "-",
            facility_phone_extension_string: fac.facility_phone_extension || "-",
            time_zone_name_string: fac.timezone ? fac.timezone.timezone_name || "-" : "-",
            emergency_contact_name_string: fac.emergency_contact_name || "-",
            emergency_contact_phone_string: fac.emergency_contact_phone ? `${phoneFormat(fac.emergency_contact_phone.toString())} ${fac.emergency_contact_phone_extension ? ` Ext. ${fac.emergency_contact_phone_extension}` : ""}` : "-",
            emergency_contact_phone_extension_string: fac.emergency_contact_phone_extension || "-",
            emergency_contact_role_string: fac.emergency_contact_role || "-",
            facility_notes_string: fac.facility_notes || "-",
            latitude_string: fac.latitude ? fac.latitude : "-",
            longitude_string: fac.longitude ? fac.longitude : "-",
            activation_date_string: fac.activation_date ? moment(fac.activation_date).format("MM/DD/yyyy") : "-",
          }
        })
        res.content = facilities
        return res;
      })
      .catch((err) => {
        return rejectWithValue(err.response.data);
      });

    return response;
  }
);

export const facilitiesSlice = createSlice({
  name: "facilities",
  initialState,

  extraReducers: (builder) => {
    builder
      .addCase(getFacilities.pending, (state) => {
        state.loading = true;
      })
      .addCase(getFacilities.fulfilled, (state, action) => {
        state.loading = false;
        state.value = action.payload;
      })
      .addCase(getFacilities.rejected, (state, err) => {
        state.loading = false;
        state.value = err.payload;
      });
  },
});

export const facilitiesList = (state) => state.facilities.value;
export const facilitiesLoader = (state) => state.facilities.loading;

export default facilitiesSlice.reducer;
