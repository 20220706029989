import React from "react";
import { Modal } from "react-bootstrap";
const HomeHealthHeader = (props) => {
  const { mode, error, cloneIsEnabled } = props;
  return (
    <React.Fragment>
      <React.Fragment>
        <Modal.Title
          data-testid={`time-draw-modal-header-${mode}`}
          className="headline4"
        >
          {" "}
          {mode === "view" ? "View Care at Home" : mode === "create" ? cloneIsEnabled ? "Cloned Order" : "Create Order" : "Edit Care at Home"}
        </Modal.Title>
        {error && (
          <p
            className="error-message-alert"
            data-testid="time-draw-modal-header-create-error"
          >
            {error}
          </p>
        )}
      </React.Fragment>
    </React.Fragment>
  );
};

export default HomeHealthHeader;
