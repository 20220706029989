// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import React from "react";
import {
  ChannelList,
  ChannelItem,
} from "amazon-chime-sdk-component-library-react";

import { useChatChannelState } from "../../providers/ChatMessagesProvider";
import { useAuthContext } from "../../providers/AuthProvider";
import _ from "lodash";

import "../../../../assets/styles/pluginchat.scss";

const ChannelPresence = (props) => {
  const { cognitoUsers = [] } = props;
  const { member } = useAuthContext();
  const { activeChannelMembershipsWithPresence } = useChatChannelState();
  return (
    <>
      <div className="channel-members-list-wrapper">
        <div className="channel-members-list-header">
          <div className="channel-list-header-title">Members</div>
        </div>
        {activeChannelMembershipsWithPresence &&
          activeChannelMembershipsWithPresence.length !== 0 && (
            <ChannelList className="channel-members-list-item">
              {activeChannelMembershipsWithPresence
                .filter(
                  (membership) => !membership.Member.Name.includes("DEACTIVATED-") && membership.Member.Name !== member.username
                )
                .map((membership) => {
                  const currentMember = cognitoUsers[membership.Member.Name] || {};
                  const { name } = currentMember;
                  return (
                    <ChannelItem
                      key={membership.Member.Arn}
                      name={`${
                        name || membership.Member.Name
                      } (${
                        membership.Member.Presence.Status
                          ? membership.Member.Presence.Status
                          : "loading"
                      })`}
                    />
                  );
                })}
            </ChannelList>
          )}
      </div>
    </>
  );
};

export default ChannelPresence;
