import React from "react";
import { ProgressButtonComponent } from "@syncfusion/ej2-react-splitbuttons";
import { Button, Modal, Dropdown, Form, InputGroup } from "react-bootstrap";
import moment from "moment";
import _ from "lodash";
import Select, { components } from "react-select";

const Option = (props) => {
	return (
		<div>
			<components.Option {...props}>
				<input
					type="checkbox"
					checked={props.isSelected}
					onChange={() => null}
				/>{" "}
				<label>{props.label}</label>
			</components.Option>
		</div>
	);
};

const getDestinationWeeks = (firstMonth, lastMonth, destWeeks = [], isFirst = false) => {
	let tempFirstMonth = new Date(firstMonth.getTime());
	const tempFirstDay = tempFirstMonth.getDate() + 8;
	tempFirstMonth = new Date(tempFirstMonth.setDate(tempFirstDay));
	tempFirstMonth.setHours(0, 0, 0, 0);
	const todayDate = new Date();
	todayDate.setHours(0, 0, 0, 0);
	if(tempFirstMonth <= lastMonth) {
		const val = (isFirst ? 1 : 8);
		const nextWeekFirst = firstMonth.getDate() - firstMonth.getDay() + val;
		const nextWeekFirstDay = new Date(firstMonth.setDate(nextWeekFirst));

		const tempNextWeekForComparison = new Date(nextWeekFirstDay.getTime());
		tempNextWeekForComparison.setHours(0, 0, 0, 0);
		// tempNextWeekForComparison.getTime() !== todayDate.getTime() && 
		destWeeks.push(nextWeekFirstDay);

		getDestinationWeeks(firstMonth, lastMonth, destWeeks);
	}
	return destWeeks;
}
const { ValueContainer } = components

const CustomValueContainer = ({ children, ...rest }) => {
  const selectedCount = rest.getValue().length
  const conditional = (selectedCount < 3)

  let firstChild = []

  if (!conditional) {
    firstChild = [children[0].shift(), children[1]]
  }

  return (
    <ValueContainer {...rest}>
      {conditional ? children : firstChild}
      {!conditional && ` and ${selectedCount - 1} others`}
    </ValueContainer>
  )
}
const multiValueContainer = ({ selectProps, data }) => {
  const label = data.label;
  const allSelected = selectProps.value;
  const index = allSelected.findIndex(selected => selected.label === label);
  const isLastSelected = index === allSelected.length - 1;
  const labelSuffix = isLastSelected ? `` : ", ";
  const val = `${label}${labelSuffix}`;
  return val;
};
const customStyles = {
    valueContainer: (provided, state) => ({
      ...provided,
	  display: "initial",
      textOverflow: "ellipsis",
      maxWidth: "90%",
      whiteSpace: "nowrap",
      overflow: "hidden", 
	  fontSize: 14,
	  color: '#121212'
    })
  };

export default function CopyScheduleModal(props = {}) {
	const { show, hideModal, setStatusModalType, handleCheckbox, modalError, selectedDate, destWeeks } = props;
	
	const tempDate = new Date(selectedDate.getTime());
	const tempDateTwo = new Date(selectedDate.getTime());
	const tempDateForStart = new Date();
	const tempDateForEnd = new Date();

	var first = tempDate.getDay() === 0 ? tempDate.getDate() - 6 : tempDate.getDate() - tempDate.getDay() + 1;
	var last = first + 6;

	const firstday = new Date(tempDate.setDate(first));
	const lastday = new Date(tempDateTwo.setDate(last));

	const sixthMonth = new Date(tempDateForEnd.setMonth(tempDateForEnd.getMonth() + 1));

	const destinationWeeks = getDestinationWeeks(tempDateForStart, tempDateForEnd, [], true);

	const options = destinationWeeks.map(week => {
		return {
			label: `${moment(new Date(week)).format("MMM DD")}`,
			value: `${moment(new Date(week)).format("MMM DD")}`,
			dateValue: week,
			isDisabled: moment(new Date(firstday)).format("MMM DD") == moment(new Date(week)).format("MMM DD")
		}
	})
	return (
		<Modal
			show={show}
			onHide={() => hideModal(false)}
			className="ad-adduser-modal sc-copycal-modal"
			backdrop="static"
			enforceFocus={false}
		>
			<Modal.Header closeButton>
				<p className="error-message-alert sch-error-message-alert">{!_.isEmpty(modalError)&& modalError}</p>
				{/* <Modal.Title className="headline4">Add New User</Modal.Title>
		  {props.error && <p className="error-message-alert">{props.error}</p>} */}
			</Modal.Header>
			<Modal.Body>
				<div className="d-flex justify-content-between scheduler-filters align-items-start">
					<div>
						<p className="mb-15 font-size-14 roboto-medium sc-week-title">From Week</p>
						<p className="font-size-14 roboto-regular ps-2 label-color">{moment(new Date(firstday)).format("MMM DD")} to {moment(new Date(lastday)).format("MMM DD")}</p>
					</div>
					<div>
						{/* <p className="d-flex flex-column align-items-center mt-15 font-size-9 roboto-bold copy-wrapper"><span className="copy-calendar"><span className="visually-hidden">Copy Icon</span></span>COPY</p> */}
					</div>
					<div>
						<p className="mab-5 font-size-14 roboto-medium">To Week</p>
						<InputGroup className="">
							<Select
								name="destination-week"
								id="destination-week"
								className="sch-copycal-dropdown"
								classNamePrefix="sch-copycal-select"
								onChange={(val) => handleCheckbox(val)}
								// value={destWeeks}
								isMulti
								options={options}
								placeholder="Select Destination Week(s)"
								closeMenuOnSelect={false}
								hideSelectedOptions={false}
								components={{
									Option,
									// ValueContainer: CustomValueContainer,
									MultiValueContainer: multiValueContainer
								}}
								allowSelectAll={true}
								styles={customStyles}
								isSearchable={false}
								//menuIsOpen
							/>
						</InputGroup>
					</div>
				</div>
				<p className="mb-4 mt-4 font-size-13">These changes only apply to the filtered users.</p>
				<div className="d-flex justify-content-end mb-2">
					<Button
						className="primary-btn primary-btn-outline outline"
						onClick={() => {
							hideModal(false)
						}}
					>
						Cancel
					</Button>
					<ProgressButtonComponent
						data-testid="button-save"
						content="Apply Changes"
						spinSettings={{ position: "Center" }}
						animationSettings={{ effect: "SlideLeft" }}
						cssClass="primary-btn primary-btn-outline ad-progress-btn"
						onClick={() => setStatusModalType({value: true, status:"confirmation", type: "copySchedule"})}
						disabled={!destWeeks.length}
					// disabled={!selectedDate || (_.isEmpty(destWeeks) || Object.keys(destWeeks).every(week => !destWeeks[week]))}
					></ProgressButtonComponent>
				</div>
			</Modal.Body>
		</Modal>
	);
}
