/* eslint-disable no-console */
// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import React from 'react';

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalButtonGroup,
  ModalButton,
} from 'amazon-chime-sdk-component-library-react';

import '../../../../assets/styles/pluginchat.scss';
import { GROUP_CHANNEL_TYPE, GROUP_CHANNEL_TYPE_TWO, INDIVIDUAL_CHANNEL_TYPE } from '../../../../app/utils/Constants';
import { useAuthContext } from '../../providers/AuthProvider';

export const ViewChannelDetailsModal = ({ onClose, channel, moderators, channelFlow}) => {
  const { username } = useAuthContext().member;
  const modNames = moderators.map((m) => (
    <div key={m.Moderator.Arn}>{m.Moderator.Name}</div>
  ));

  let {Name=""} = channel || {};
  if (Name.includes(GROUP_CHANNEL_TYPE) || Name.includes(GROUP_CHANNEL_TYPE_TWO)) {
    Name = Name.replace(GROUP_CHANNEL_TYPE, '');
    Name = Name.replace(GROUP_CHANNEL_TYPE_TWO, '');
  }
  else if (Name.includes(INDIVIDUAL_CHANNEL_TYPE)) {
    Name = Name.replace(INDIVIDUAL_CHANNEL_TYPE, '');
    let allUsers = Name.split(", ");
    if (allUsers.includes(username)) {
      allUsers = allUsers.filter(user => user !== username);
      Name = allUsers.join(', ');
    }
  }

  return (
    <Modal onClose={onClose} className="view-details ch-main-modal">
      <ModalHeader title="Room Details" />
      <ModalBody>
        <div className="container">
          <div className="row">
            <div className="key">Room Name</div>
            <div className="value">{Name}</div>
          </div>

          {moderators.length > 0 ? (
            <div className="row">
              <div className="key">Moderators</div>
              <div className="value">{modNames}</div>
            </div>
          ) : null}

          <div className="row">
            <div className="key">Type</div>
            <div className="value">
              {channel.Privacy === 'PRIVATE' && (
                <span>
                  <span className="main">Private</span>
                  <span className="detail">
                    (non-members can read and send messages)
                  </span>
                </span>
              )}
              {channel.Privacy === 'PUBLIC' && (
                <span>
                  <span className="main">Public</span>
                  <span className="detail">
                    (only members can read and send messages)
                  </span>
                </span>
              )}
            </div>
          </div>

          <div className="row">
            <div className="key">Mode</div>
            <div className="value">
              {channel.Mode === 'RESTRICTED' && (
                <span>
                  <span className="main">Restricted</span>
                  <span className="detail">
                    (administrators and moderators can add members)
                  </span>
                </span>
              )}
              {channel.Mode === 'UNRESTRICTED' && (
                <span>
                  <span className="main">Unrestricted</span>
                  <span className="detail">
                    (any member can add other members)
                  </span>
                </span>
              )}
            </div>
          </div>
          <div className="row">
            <div className="key">Room Flow</div>
            <div className="value">
              {channel.ChannelFlowArn == null ?
                <span>
                  <span className="main">No flow configured</span>
                </span>
                :
                <span>
                  <span className="main">{channelFlow.Name}</span>
                </span>
              }
            </div>
          </div>
        </div>  
      </ModalBody>
      <ModalButtonGroup
        primaryButtons={[
          <ModalButton label="OK" variant="primary" closesModal className="primary-btn ad-progress-btn primary-btn-outline" />,
        ]}
      />
    </Modal>
  );
};

export default ViewChannelDetailsModal;
