import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ahaApi from "../../app/services/aha-api";
const initialState = {
  value: [],
  loading: false,
};

export const getSubSetUsers = createAsyncThunk(
  "users/getSubSetUsers",
  async (data, { rejectWithValue }) => {
    const response = await ahaApi.Account.subSetUsers(data)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return rejectWithValue(err.response.data);
      });

    return response;
  }
);

export const subSetUsersSlice = createSlice({
  name: "subSetUsers",
  initialState,

  extraReducers: (builder) => {
    builder
      .addCase(getSubSetUsers.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSubSetUsers.fulfilled, (state, action) => {
        state.loading = false;
        state.value = action.payload;
      })
      .addCase(getSubSetUsers.rejected, (state, err) => {
        state.loading = false;
        state.value = err.payload;
      });
  },
});

export const subSetUsersList = (state) => state.subSetUsers.value;
export const subSetUsersLoader = (state) => state.subSetUsers.loading;

export default subSetUsersSlice.reducer;
