import React from "react";
import animationData from "../../app/success.json";
import Lottie from "react-lottie";

const defaultOptions = {
  loop: false,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};
function LottieAnimation() {
  return (
    <div className="ad-modal-success-wrapper" data-testid="lottie-div">
      <Lottie options={defaultOptions} height={200} width={200} />
    </div>
  );
}

export default LottieAnimation;
