import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ahaApi from "../../../app/services/aha-api";
const initialState = {
  value: {},
  loading: false,
  activeFacilityId: "",
  activeFacility: {},
  menuIsOpenValue: {
    checkIn: false,
    checkOut: false,
    stopDay: false
  },
  scrollPos: 0,
  auditKey: "",
  auditKeyDropOff: "",
  menuOpenStopDay: false,
  disableSaveButton: false
};

export const deleteRoutineActionApiResponse = createAsyncThunk(
  "schedule/deleteRoutineAction",
  async (data, { rejectWithValue }) => {
    const response = await ahaApi.Account.deleteRoutineAction(data)
      .then((res) => {
        return res
      })
      .catch((err) => {
        return rejectWithValue(err.response.data);
      });
    return response;
  }
);
export const deleteRoutineActionSlice = createSlice({
  name: "deleteRoutineAction",
  initialState,
  reducers: {
    clearDeleteRoutineAction(state, action) {
      state.value = {};
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(deleteRoutineActionApiResponse.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteRoutineActionApiResponse.fulfilled, (state, action) => {
        state.loading = false;
        state.value = action.payload;
      })
      .addCase(deleteRoutineActionApiResponse.rejected, (state, err) => {
        state.loading = false;
        state.value = err.payload;
      });
  },
});

export const deleteRoutineActionResponse = (state) => state.deleteRoutineAction.value;

export const {clearDeleteRoutineAction} = deleteRoutineActionSlice.actions;

export default deleteRoutineActionSlice.reducer;
