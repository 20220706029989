import * as React from "react";
import { OnCallZone } from "./onCallZone";
import { BACKLOG_SECTION_TYPE } from "../../../app/utils/Constants";

export const OnCallRoutine = (props) => {
	const { events, userIndex, serviceIndex, territoryIndex, sectionType, date, user_id,
		blockId, uniqueServiceId, handleDelete, searchText, searchType, users, userType, showDropArea } =
		props;
	return (
		<React.Fragment>
			{Object.keys(events).map((event_type_id) => {
				const { event_type: eventType, zones = [], event_id } =
					events[event_type_id];

				const lastZoneObject = zones ? zones.length ? zones[zones.length - 1] : {} : {};
				const lastZoneId = lastZoneObject.zone_id || "";
				return (
					<React.Fragment
						key={
							userIndex +
							"-" +
							serviceIndex +
							"-" +
							territoryIndex +
							"-" +
							event_type_id
						}
					>
						<div
							className={sectionType === BACKLOG_SECTION_TYPE ? "" : `${showDropArea ? "droppable-area-border " : " "}`}
						// style={sectionType === BACKLOG_SECTION_TYPE ? {} : { opacity: showDropArea ? 0.8 : 1, border: showDropArea ? '5px dotted green' : "none" }}
						>
							<OnCallZone
								zones={zones}
								date={date}
								userIndex={userIndex}
								serviceIndex={serviceIndex}
								territoryIndex={territoryIndex}
								routineIndex={event_type_id}
								sectionType={sectionType}
								eventType={eventType}
								eventId={events[event_type_id].event_id}
								handleDelete={handleDelete}
								event_id={event_id}
								user_id={user_id}
								uniqueServiceId={uniqueServiceId}
								blockId={blockId}
								searchText={searchText}
								searchType={searchType}
								users={users}
								userType={userType}
								showDropArea={showDropArea}
								lastZoneId={lastZoneId}
							/>
						</div>
					</React.Fragment>
				);
			})}
		</React.Fragment>
	);
};
