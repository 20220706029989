import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ahaApi from "../../../app/services/aha-api";
const initialState = {
  value: [],
  loading: false,
};

export const downloadUnassigned = createAsyncThunk(
  "schedule/downloadUnassigned",
  async (data, { rejectWithValue }) => {
    const response = await ahaApi.Account.download(data)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return rejectWithValue(err.response.data);
      });
    return response;
  }
);
const saveData = (data) => {
  const blob = new Blob([Buffer.from(data)], {
    type: "text/csv",
  });
  const URL = window.URL || window.webkitURL;
  const downloadUrl = URL.createObjectURL(blob);
  let a = document.createElement("a");
  a.style.display = "none";
  a.href = downloadUrl;
  a.setAttribute("download", `unassigned_facilities.csv`);
  document.body.appendChild(a);

  a.click();
  window.URL.revokeObjectURL(downloadUrl);
};
export const downloadSlice = createSlice({
  name: "download",
  initialState,
  reducers: {
    clearUpdateEventResponse(state, action) {
      state.value = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(downloadUnassigned.pending, (state) => {
        state.loading = true;
      })
      .addCase(downloadUnassigned.fulfilled, (state, action) => {
        state.loading = false;
        state.value = action.payload;
        saveData(action.payload);
      })
      .addCase(downloadUnassigned.rejected, (state, err) => {
        state.loading = false;
        state.value = err.payload;
      });
  },
});

export const downloadUnassignedList = (state) => {
  return state.download.value;
};

export default downloadSlice.reducer;
