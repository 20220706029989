import React from "react";
import { Toast } from "react-bootstrap";
export default function ToastMessage(props) {
  return (
    <div className="position-relative w-100 h-100 overflow-hidden d-flex justify-content-center toast-container">
      <Toast
        className="toast-wrapper text-end"
        style={{ display: props.toast ? "none" : "" }}
      >
        <Toast.Header closeButton={false}>
          <span
            className="close-icon"
            data-testid="toast-header"
            onClick={() => {
              props.setToast(true);
            }}
            aria-label="Close"
          >
            <span className="visually-hidden">Close Icon</span>
          </span>
        </Toast.Header>
        <Toast.Body className="toast-info-details d-flex align-items-center">
          <span
            data-testid="toast-body"
            className={
              props.type === "success"
                ? "checkmark-with-circle pr-10"
                : props.type === "error" && "close-with-circle pr-10"
            }
          >
            {props.type === "error" ? (
              <span className="visually-hidden">
                error-close icon with circle
              </span>
            ) : (
              props.type === "success" && (
                <span className="visually-hidden">
                  Checkmark icon with circle
                </span>
              )
            )}
          </span>
          <p className="font-size-12">{props.message}</p>
        </Toast.Body>
      </Toast>
    </div>
  );
}
