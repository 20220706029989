import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ahaApi from "../../../app/services/aha-api";

const initialState = {
  value: [],
  loading: false,
};

export const updateUser = createAsyncThunk(
  "users/updateUser",
  async (data, { rejectWithValue }) => {
    const response = await ahaApi.Account.updateUser(data)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return rejectWithValue(err.response.data);
      });

    return response;
  }
);

export const updateUserSlice = createSlice({
  name: "updateUser",
  initialState,
  reducers: {
    clearUpdateUserResponse(state, action) {
      state.value = {}
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.loading = false;
        state.value = action.payload;
      })
      .addCase(updateUser.rejected, (state, err) => {
        state.loading = false;
        state.value = err.payload;
      });
  },
});
export const updateUserList = (state) =>
  state && state.updateUser && state.updateUser.value;
export const updateLoader = (state) =>
  state && state.updateUser && state.updateUser.loading;
export const {clearUpdateUserResponse} = updateUserSlice.actions;

export default updateUserSlice.reducer;
