import { ProgressButtonComponent } from "@syncfusion/ej2-react-splitbuttons";
import React from "react";
import { Button } from "react-bootstrap";
import { CANCELLED_STATUS } from "../../app/utils/Constants";
const HomeHealthFooter = (props) => {
  const {
    mode,
    updateHomeHealthOrder,
    status,
    editOrder,
    saveChanges,
    originalStatus,
    originalStatusText,
    handleSubmit,
    cancelModal,
    showErrorForNatera,
    disableCreateForNatera,
    isFormChanged,
    allow_uncancel,
    enableCancellationForUncancel,
    canUnCancelHomeHealthOrder,
    bulkCheckinCheckoutHomeHealthLoader,
    updateHomeHealthLoading,
    addHomeHealthListLoader,
    cloneHomeHealthListLoader,
    isCloneable,
    cloneIsEnabled,
    handleEnableClone,
    loading,
    cloneLoader,
    canCloneHomeHealthOrder
  } = props;
  return (
    <React.Fragment>
      <Button
        data-testid="button-cancel"
        className="primary-btn primary-btn-outline outline"
        onClick={cancelModal}
      >
        Cancel
      </Button>
      {mode === "view" ? (
        updateHomeHealthOrder &&
        status != CANCELLED_STATUS && status != 15 && (
          <Button
            data-testid="time-draw-button-view"
            className="primary-btn primary-btn-outline outline"
            onClick={editOrder}
          >
            Edit Care at Home
          </Button>
        )
      ) : !cloneIsEnabled && (
        <ProgressButtonComponent
          data-testid="time-draw-button-submit"
          content={mode === "edit" ? "Save Changes" : "Create Order"}
          enableProgress={true}
          spinSettings={{ position: "Center" }}
          animationSettings={{ effect: "SlideLeft" }}
          cssClass="primary-btn primary-btn-outline ad-progress-btn"
          onClick={saveChanges}
          disabled={mode === "create" ? (showErrorForNatera || disableCreateForNatera || addHomeHealthListLoader) : mode === "edit" ? (!isFormChanged || bulkCheckinCheckoutHomeHealthLoader || updateHomeHealthLoading) : false}
        ></ProgressButtonComponent>
      )}
      {allow_uncancel && enableCancellationForUncancel &&
        <ProgressButtonComponent
          data-testid="time-draw-button-submit-with-notify"
          content={"Save Changes"}
          enableProgress={true}
          spinSettings={{ position: "Center" }}
          animationSettings={{ effect: "SlideLeft" }}
          cssClass="primary-btn primary-btn-outline ad-progress-btn"
          onClick={handleSubmit}
          disabled={bulkCheckinCheckoutHomeHealthLoader || updateHomeHealthLoading}
        ></ProgressButtonComponent>
      }
      {mode === "view" && canCloneHomeHealthOrder && isCloneable && !enableCancellationForUncancel &&
        <Button
            data-testid="time-draw-button-view"
            className="primary-btn primary-btn-outline outline"
            onClick={handleEnableClone}
            disabled={loading || cloneLoader}
          >
            Clone Order
          </Button>}
      {mode === "create" && canCloneHomeHealthOrder && isCloneable && cloneIsEnabled && !enableCancellationForUncancel &&
        <ProgressButtonComponent
          data-testid="time-draw-button-submit-with-notify"
          content={"Save Clone"}
          enableProgress={true}
          spinSettings={{ position: "Center" }}
          animationSettings={{ effect: "SlideLeft" }}
          cssClass="primary-btn primary-btn-outline ad-progress-btn"
          onClick={saveChanges}
          disabled={loading || cloneLoader || showErrorForNatera || disableCreateForNatera || cloneHomeHealthListLoader}
        ></ProgressButtonComponent>
      }
    </React.Fragment>
  );
};

export default HomeHealthFooter;
