/* eslint-disable import/no-unresolved */
// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import React, { useState, setState } from "react";
import {
  Heading,
  Grid,
  Cell,
  Flex,
  Select,
} from "amazon-chime-sdk-component-library-react";
import { useTheme } from "styled-components";
import LoginWithCognito from "../../containers/loginWithCognito/LoginWithCognito";
import LoginWithCredentialExchangeService from "../../containers/loginWithCredentialExchangeService/LoginWithCredentialExchangeService";
import { useAuthContext } from "../../providers/AuthProvider";

import "../../../../assets/styles/pluginchat.scss";
import { appConfig } from "../../Config";
import InnerHeader from "../../../../components/layouts/InnerHeader";

const Signin = () => {
  const [signinProvider, updateSigninProvider] = useState("cognito");
  const { userSignIn, userSignUp, userExchangeTokenForAwsCreds, member } =
    useAuthContext();
  const currentTheme = useTheme();

  // const provider =
  //     signinProvider === 'cognito' ?
  //         <LoginWithCognito register={userSignUp} login={userSignIn} /> :
  //         <LoginWithCredentialExchangeService exchangeCreds={userExchangeTokenForAwsCreds}/>;

  // const signInMessage =
  //     signinProvider === 'cognito' ? 'Sign in with ' : 'Get AWS creds via ';

  return <div></div>;
};

export default Signin;
