import {
	ChipDirective,
	ChipListComponent,
	ChipsDirective,
} from "@syncfusion/ej2-react-buttons";
import { phoneFormat } from "../../app/utils/testUtils";
import CustomGrid from "../../components/commons/CustomGrid";

export default function LabList(props) {
	const { data = [], loading = false } = props;
	return (
		<CustomGrid
			type="lab"
			data={data}
			loading={loading}
			editModal={props.editModal}
			columns={[
				{
					field: "lab_name",
					headerText: "Lab Name",
					width: "220",
				},
				{
					field: "fullAddress",
					headerText: "Address",
					width: "220",
					template: (props) => {
						const { street_address1 = "", street_address2 = "" } = props;
						return (
							<span>{street_address1} {street_address2}</span>
						)
					}
				},
				{
					field: "city_string",
					headerText: "City",
					width: "220",
				},
				{
					field: "state_name_string",
					headerText: "State",
					width: "220",
				},
				{
					field: "zip_string",
					headerText: "Zip",
					width: "168",
					visible: false
				},
				{
					field: "zone_name_string",
					headerText: "Zone",
					width: "220",
					visible: false
				},
				// {
				// 	field: "street_address1",
				// 	headerText: "Street Address 1",
				// 	width: "220",
				// 	visible: false
				//   },
				//   {
				// 	field: "street_address2",
				// 	headerText: "Street Address 2",
				// 	width: "220",
				// 	visible: false
				//   },
				{
					field: "time_zone_name_string",
					headerText: "Timezone",
					width: "168",
					visible: false
				},
				{
					field: "latitude_string",
					headerText: "Latitude",
					width: "168",
					visible: false
				},
				{
					field: "longitude_string",
					headerText: "Longitude",
					width: "168",
					visible: false
				},
				{
					field: "lab_type_name_string",
					headerText: "Lab Type",
					width: "220",
				},
				{
					field: "lab_contact_phone_number_string",
					headerText: "Lab Phone Number",
					width: "250",
					visible: false
				},
				{
					field: "lab_contact_phone_number_ext_string",
					headerText: "Lab Phone Number Ext",
					width: "250",
					visible: false
				},
				{
					field: "primary_contact_name_string",
					headerText: "Primary Contact Name",
					width: "221",
				},
				{
					field: "primary_contact_office_phone_number_string",
					headerText: "Primary Contact Office Phone Number",
					width: "250",
					visible: false
				},
				{
					field: "primary_contact_office_phone_number_ext_string",
					headerText: "Primary Contact Office Phone Number Ext",
					width: "250",
					visible: false
				},
				{
					field: "primary_contact_cell_phoneNumber_string",
					headerText: "Primary Contact Cell Phone number",
					width: "250"
				},
				{
					field: "primary_contact_email_string",
					headerText: "Primary Contact Email",
					width: "221",
				},
				{
					field: "lab_notes_string",
					headerText: "Lab Notes",
					width: "250",
					visible: false
				},
				{
					field: "lab_timings_string",
					headerText: "Timings",
					width: "250",
					visible: false
				},
				{
					field: "status.status_name",
					headerText: "Status",
					width: "168",
					template: (props) => {
						const { status = {} } = props;
						return status && status.status_name === "Active" ? (
							<div className="user-status-wrapper">
								<ChipListComponent id="chip-default">
									<ChipsDirective>
										<ChipDirective
											text={status.status_name}
											cssClass="e-user-active"
										></ChipDirective>
									</ChipsDirective>
								</ChipListComponent>
							</div>
						) : status && status.status_name === "Invited" ? (
							<div className="user-status-wrapper">
								<ChipListComponent id="chip-default">
									<ChipsDirective>
										<ChipDirective
											text={status.status_name}
											cssClass="e-user-invited"
										></ChipDirective>
									</ChipsDirective>
								</ChipListComponent>
							</div>
						) : status && status.status_name === "Locked" ? (
							<div className="user-status-wrapper">
								<ChipListComponent id="chip-default">
									<ChipsDirective>
										<ChipDirective
											text={status.status_name}
											cssClass="e-user-locked"
										></ChipDirective>
									</ChipsDirective>
								</ChipListComponent>
							</div>
						) : (
							status &&
							status.status_name === "Inactive" && (
								<div className="user-status-wrapper">
									<ChipListComponent id="chip-default">
										<ChipsDirective>
											<ChipDirective
												text={status.status_name}
												cssClass="e-user-deactivated"
											></ChipDirective>
										</ChipsDirective>
									</ChipListComponent>
								</div>
							)
						);
					},
				}
			]}
		/>
	);
}
