import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ahaApi from "../../app/services/aha-api";
const initialState = {
  value: [],
  loading: false,
};

export const assignReportingManager = createAsyncThunk(
  "users/updateReportingManager",
  async (data, { rejectWithValue }) => {
    const response = await ahaApi.Account.assignReportingManager(data)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return rejectWithValue(err.response.data);
      });

    return response;
  }
);

export const assignReportingManagerSlice = createSlice({
  name: "updateReportingManager",
  initialState,
  reducers: {
    clearUpdateReportingManagerVal(state, action) {
      state.value = {}
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(assignReportingManager.pending, (state) => {
        state.loading = true;
      })
      .addCase(assignReportingManager.fulfilled, (state, action) => {
        state.loading = false;
        state.value = action.payload;
      })
      .addCase(assignReportingManager.rejected, (state, err) => {
        state.loading = false;
        state.value = err.payload;
      });
  },
});

export const updateReportingManagerVal = (state) => state.updateReportingManager.value;
export const updateReportingManagerLoading = (state) => state.updateReportingManager.loading;
export const {clearUpdateReportingManagerVal} = assignReportingManagerSlice.actions;
export default assignReportingManagerSlice.reducer;
