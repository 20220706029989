import React from "react";
import {
  Aggregate,
  ColumnChooser,
  ColumnDirective,
  ColumnsDirective,
  Filter,
  GridComponent,
  Group,
  Inject,
  Page,
  Sort,
  Toolbar,
  Reorder,
  ExcelExport,
  Resize
} from "@syncfusion/ej2-react-grids";
import { withRouter } from "react-router-dom";
import { TYPE_HOMEHEALTH, TYPE_ROUTINE, TYPE_STAT, TYPE_TIMEDDRAW } from "../../app/utils/Constants";

export const sortComparer = (reference, comparer) => {
  const parseDate = (dateStr) => {
    if (dateStr === "-") {
      return null;
    }
    const cleanDateStr = dateStr.replace(/\s+[A-Z]{2,4}$/, ''); // Remove space followed by 2 to 4 uppercase letters (timezone)

    // Parse the cleaned-up date string into a Date object
    return cleanDateStr.length > 0 ? new Date(cleanDateStr) : null;
  };

  const newRef = parseDate(reference);
  const newComparer = parseDate(comparer);

  // custom sort comparer
  if (newRef === null && newComparer === null) return 0; // Both dates are "-"
  if (newRef === null) return -1; // Sort "-" before actual dates
  if (newComparer === null) return 1; // Sort "-" after actual dates
  if (newRef < newComparer) return -1;
  if (newRef > newComparer) return 1;
  return 0;
};

class CustomGrid extends React.PureComponent {
  constructor(props) {
    super(props);
    this.toolbar = ["Search", "ExcelExport", "ColumnChooser"];
    this.toolbarClick = (args) => {
      const { type } = this.props;
      if (
        this[`customGrid-${type}`] &&
        args.item.id === `customGrid-${this.props.type}_excelexport`
      ) {
        const excelExportProperties = {
          enableFilter: true,
          includeHiddenColumn: true,
          exportType: "All",
        };
        this[`customGrid-${type}`].excelExport(excelExportProperties);
      }
    };
    this.sortingOptions = {
      columns:
        props.type === TYPE_STAT ||
          props.type === TYPE_ROUTINE ||
          props.type === TYPE_TIMEDDRAW || props.type === TYPE_HOMEHEALTH
          ? [{ field: "order_creation_datetime", direction: "Ascending" }]
          : [],
    };
    this[`gridColumns-${this.props.type}`] = [];
    // this[`gridInstance-${this.props.type}`];
    // this[`group-${this.props.type}`];
  }
  componentWillUnmount() {
    if (this[`customGrid-${this.props.type}`]) {
      this[`customGrid-${this.props.type}`].destroy();
    }
  }
  componentDidUpdate = (prevProps, prevState) => {
    const { type } = this.props;
    if (prevProps.isClicked !== this.props.isClicked && this.props.isClicked) {
      this.clearFilter();
    }
    if (prevProps.clearRowSelection !== this.props.clearRowSelection) {
      if (
        type === TYPE_STAT ||
        type === TYPE_ROUTINE ||
        type === TYPE_TIMEDDRAW ||
        type === TYPE_HOMEHEALTH
      ) {
        this.updateRowSelection();
      }
    }
    if (prevProps.isModalOpen !== this.props.isModalOpen && !this.props.isModalOpen) {
      if (
        type === TYPE_STAT ||
        type === TYPE_ROUTINE ||
        type === TYPE_TIMEDDRAW ||
        type === TYPE_HOMEHEALTH
      ) {
        this.clearSelectedRow();
      }
    }
    // if (prevProps.hideFilter !== this.props.hideFilter) {
    //   const gridElement = this[`customGrid-${type}`] && this[`customGrid-${type}`].element;
    //   if (gridElement) {
    //     let dropArea = gridElement
    //       ? gridElement.getElementsByClassName("e-groupdroparea")
    //       : [];
    //     let dropWrapper = gridElement
    //       ? gridElement.getElementsByClassName("drag-column-wrapper")
    //       : [];
    //     if (dropArea.length > 0 && !dropWrapper.length) {
    //       dropArea = dropArea[0];

    //       let divWrapper = document.createElement("div");
    //       divWrapper.className = "drag-column-wrapper";

    //       dropArea.parentNode.insertBefore(divWrapper, dropArea);
    //       divWrapper.appendChild(dropArea);
    //     }
    //     if (this.props.hideFilter) {
    //       const dragWrapper = gridElement.getElementsByClassName("drag-column-wrapper") || [];
    //       dragWrapper && dragWrapper[0] && dragWrapper[0].parentNode.removeChild(dragWrapper[0]);

    //       const toolbarDiv = document && document.getElementById(gridElement.id + "_toolbarItems");
    //       if (toolbarDiv) {
    //         toolbarDiv.classList.add('d-none');
    //       }
    //     } else {
    //       const toolbarDiv = document && document.getElementById(gridElement.id + "_toolbarItems");
    //       if (toolbarDiv) {
    //         toolbarDiv.classList.remove('d-none');
    //       }
    //     }
    //   }
    // }
  };
  updateRowSelection = () => {
    const { type, setClearRowSelection } = this.props;
    let persistedData = this[`customGrid-${type}`].getPersistData()
      ? JSON.parse(this[`customGrid-${type}`].getPersistData())
      : {};
    var value = window.localStorage.getItem(`gridgrid-component-${type}`); //"gridGrid" is component name + component id.
    var model = value ? { ...JSON.parse(value) } : {};
    model["selectedRowIndex"] = -1; // remove the column from localStorage
    model["pageSettings"] = {};
    model.searchSettings = {
      key: "",
      fields: [],
      operator: "contains",
      ignoreCase: true,
      ignoreAccent: false,
    };
    this[`customGrid-${type}`].setProperties(
      JSON.stringify({ ...persistedData, ...model })
    );
    window.localStorage.setItem(
      `gridgrid-component-${type}`,
      JSON.stringify(model)
    );
    setClearRowSelection(false);
  };
  clickHandler = () => {
    const { type } = this.props;
    this[`customGrid-${type}`].searchSettings.key = "";
    const element = this[`customGrid-${type}`].element.querySelector(
      ".e-input-group.e-search .e-input"
    );
    element.value = "";
    var grid2 = this[`customGrid-${type}`];
    if (
      grid2 &&
      grid2.element &&
      grid2.element
        .getElementsByClassName("e-search")[0]
        .classList.contains("clear")
    ) {
      const parent = document.getElementById(
        `customGrid-${this.props.type}clear`
      );
      parent && parent.parentNode && parent.parentNode.removeChild(parent);
      grid2.element
        .getElementsByClassName("clear")[0]
        .classList.remove("clear");
      grid2.element
        .getElementsByClassName("e-search-icon")[0]
        .classList.remove("hide");
    }
  };
  created = (args) => {
    const { type, newColumns = [], columns = [] } = this.props;
    var gridElement = this[`customGrid-${type}`] && this[`customGrid-${type}`].element;

    // add new columns dynamically
    if (type === TYPE_STAT || type === TYPE_ROUTINE || type === TYPE_TIMEDDRAW || type === TYPE_HOMEHEALTH && this[`customGrid-${type}`]) {
      let existingColumns = this[`customGrid-${type}`].getColumns();
      let existingFields = existingColumns.map(col => col.field);
      const addNewCols = newColumns ? newColumns.every(col => existingFields.includes(col.field)) : true;
      let grid = gridElement.ej2_instances[0];
      if (!addNewCols) {
        newColumns.forEach(col => {
          if (existingColumns.some(col2 => col2.headerText === col.field)) {
            const tempCol = grid.columns.find((column) => column.field === col.field) || {};
            tempCol.headerText = col.headerText;
            tempCol.sortComparer = col.type === "Date" ? sortComparer : null;

            const index = grid.columns.findIndex(obj => obj.field === col.field);
            if (index !== -1) {
              grid.columns.splice(index, 1, { ...col, ...tempCol }); // Replace the object at index with newObj
            }
          } else {
            grid.columns.push({ ...col, type: "string", uid: `grid-column${grid.columns.length}`, sortComparer: col.type === "Date" ? sortComparer : null });
          }
        })
      } else {
        if (newColumns && newColumns.length) {
          newColumns.forEach(col => {
            const tempCol = grid.columns.find((column) => column.field === col.field) || {};
            tempCol.headerText = col.headerText;
            tempCol.sortComparer = col.type === "Date" ? sortComparer : null;

            const index = grid.columns.findIndex(obj => obj.field === col.field);
            if (index !== -1) {
              grid.columns.splice(index, 1, { ...col, ...tempCol }); // Replace the object at index with newObj
            }
          });
        }
      }
      existingFields.forEach(field => {
        if (![...columns, ...newColumns].some(col => col.field == field)) {
          grid.columns = grid.columns.filter(col => col.field !== field);
        }
      });
      columns.forEach(col => {
        if (!grid.columns.some(col2 => col2.field === col.field)) {
          grid.columns.push({ ...col, headerText: col.headerText, type: "string", uid: `grid-column${grid.columns.length}`, sortComparer: col.type === "Date" ? sortComparer : null });
        } else {
          const tempCol = grid.columns.find((column) => column.field === col.field) || {};
          tempCol.headerText = col.headerText;
          tempCol.sortComparer = col.type === "Date" ? sortComparer : null;

          const index = grid.columns.findIndex(obj => obj.field === col.field);
          if (index !== -1) {
            grid.columns.splice(index, 1, { ...col, ...tempCol }); // Replace the object at index with newObj
          }
        }
      })
      grid.refreshColumns();

      // update the page size to 50 to imporve performance
      let storedObject = JSON.parse(localStorage.getItem(`gridcustomGrid-${type}`));
      if (storedObject) {
        storedObject.pageSettings.pageSize = 50;
        storedObject.columns = grid.columns;
      } else {
        storedObject = {
          pageSettings: {
            pageSize: 50,
            pageCount: 4
          }
        }
      }
      localStorage.setItem(`gridcustomGrid-${type}`, JSON.stringify(storedObject));
      grid.pageSettings.pageSize = 50;
      grid.refreshColumns();
    }
    if (gridElement) {
      var grid2 = this[`customGrid-${type}`];
      var emptyRow = grid2 && grid2.element.querySelector(".e-emptyrow");
      if (emptyRow) {
        emptyRow.cells[0].innerText = "No records to display.";
      }

      document &&
        document.getElementById(gridElement.id + "_searchbar") &&
        document
          .getElementById(gridElement.id + "_searchbar")
          .addEventListener("keyup", (e) => {
            this[`customGrid-${type}`].search(e.target.value);
          });

      // wrapper for drop area
      let dropArea = gridElement
        ? gridElement.getElementsByClassName("e-groupdroparea")
        : [];
      let dropWrapper = gridElement
        ? gridElement.getElementsByClassName("drag-column-wrapper")
        : [];
      if (dropArea.length > 0 && !dropWrapper.length) {
        dropArea = dropArea[0];

        let divWrapper = document.createElement("div");
        divWrapper.className = "drag-column-wrapper";

        dropArea.parentNode.insertBefore(divWrapper, dropArea);
        divWrapper.appendChild(dropArea);
      }

      if (
        document.getElementById(`${gridElement.id}_searchbar`) &&
        document.getElementById(`${gridElement.id}_searchbar`).value
      ) {
        document.getElementsByClassName("e-search-icon") &&
          document.getElementsByClassName("e-search-icon")[0] &&
          document
            .getElementsByClassName("e-search-icon")[0]
            .classList.add("hide");
      }
    }
  };
  dataBound = (args) => {
    const { type } = this.props;
    const gridElement = this[`customGrid-${type}`] && this[`customGrid-${type}`].element;
    if (gridElement) {
      let dropArea = gridElement
        ? gridElement.getElementsByClassName("e-groupdroparea")
        : [];
      let dropWrapper = gridElement
        ? gridElement.getElementsByClassName("drag-column-wrapper")
        : [];
      if (dropArea.length > 0 && !dropWrapper.length) {
        dropArea = dropArea[0];

        let divWrapper = document.createElement("div");
        divWrapper.className = "drag-column-wrapper";

        dropArea.parentNode.insertBefore(divWrapper, dropArea);
        divWrapper.appendChild(dropArea);
      }
    }
    const grid = this[`customGrid-${type}`];
    var grid2 = this[`customGrid-${type}`];
    document &&
      grid2 &&
      document.getElementById(grid2.element.id + "_searchbar") &&
      document
        .getElementById(grid2.element.id + "_searchbar")
        .addEventListener("keyup", (event) => {
          if (!event.target.value) {
            if (
              grid2 &&
              grid2.element &&
              grid2.element.getElementsByClassName("e-search") &&
              grid2.element.getElementsByClassName("e-search")[0] &&
              grid2.element
                .getElementsByClassName("e-search")[0]
                .classList.contains("clear")
            ) {
              const span = document.getElementsByClassName("e-clear-icon");
              while (span && span.length > 0 && span[0]) {
                grid2.element
                  .getElementsByClassName("clear")[0]
                  .removeChild(span[0]);
              }
              grid2.element
                .getElementsByClassName("clear")[0]
                .classList.remove("clear");
              grid2.element
                .getElementsByClassName("e-search-icon")[0]
                .classList.remove("hide");
            }
          } else {
            //  checks whether the cancel icon is already present or not
            if (
              grid2 &&
              grid2.element &&
              !grid2.element
                .getElementsByClassName("e-search")[0]
                .classList.contains("clear")
            ) {
              var gridElement = this[`customGrid-${type}`].element;
              if (
                grid2.element.getElementsByClassName(`e-sicon`) &&
                grid2.element.getElementsByClassName(`e-sicon`)[0]
              ) {
                grid2.element
                  .getElementsByClassName(`e-sicon`)[0]
                  .classList.add("hide");
              }
              var span = document.createElement("span");
              span.className = "e-clear-icon";
              span.id = gridElement.id + "clear";
              span.onclick = this.clickHandler.bind(this);
              grid2.element
                .getElementsByClassName("e-search")[0]
                .appendChild(span);
              grid2.element
                .getElementsByClassName("e-search")[0]
                .classList.add("clear");
              grid2.element
                .getElementsByClassName("e-search-icon")[0]
                .classList.add("hide");
            }
          }
        });

    var emptyRow = grid2 && grid2.element.querySelector(".e-emptyrow");
    if (emptyRow) {
      emptyRow.cells[0].innerText = "No records to display";
    }
    // let cloned = grid.addOnPersist;
    // grid.addOnPersist = function (key) {
    //     key = key.filter((item) => item !== "columns");
    //     return cloned.call(this, key);
    // };
  };
  handleAction = (args) => {
    const { hideFilter, type } = this.props;
    if (args.requestType == "filterafteropen") {
      let grid =
        this[`customGrid-${type}`].element.getElementsByClassName(
          `e-popup-open`
        );
      if (grid && grid.length) {
        grid[0].ej2_instances[0].header = args.filterModel.options.displayName;
      }
    }
    if (args.requestType == 'filterchoicerequest') {
      // hide 'Add current selection to filter' option
      var addCurrent = args.filterModel.dlg.querySelector('.e-add-current');

      if (addCurrent) {
        addCurrent.closest('.e-ftrchk').style.display = 'none';
      }
    }
  };
  actionBegin = (args) => {
    if (
      args.requestType === "filterchoicerequest" ||
      args.requestType === "filtersearchbegin"
    ) {
      args.filterChoiceCount = 100000;
    }
  };
  clearFilter = () => {
    const { type, callback, columns, newColumns = [] } = this.props;
    let grid = this[`customGrid-${type}`];
    if (grid) {
      callback();
      // console.log(grid.getPersistData(), 'getPersistData');
      // var value = window.localStorage.getItem(`gridgrid-component-${type}`); //"gridGrid" is component name + component id.
      // var model = JSON.parse(value);
      // model["groupSettings"] = {};    // remove the column from localStorage
      // model["sortSettings"] = {};
      // window.localStorage.setItem(`gridgrid-component-${type}`, JSON.stringify(model));
      // grid.enablePersistence = false;
      // localStorage.setItem(`gridgrid-component-${type}`, "");
      // localStorage.setItem(`toolbargrid-component-${type}_toolbarItems`, "");
      grid.clearFiltering();
      grid.clearSorting();
      grid.searchSettings.key = "";
      // const existingColumns = grid.columns;
      // grid.columns = [];
      // [...columns, ...newColumns].forEach(col => {
      //   const tempCol = existingColumns.find((column) => column.field === col.field) || {};
      //   const obj = { ...col, ...tempCol, uid: `grid-column${grid.columns.length}`, visible: tempCol.visible || false };
      //   grid.columns.push(obj);
      // })
      // grid.refreshColumns();
      // grid.columns = iterateExtend(this[`gridColumns-${type}`]);
      setTimeout(() => {
        grid.clearGrouping();
      }, 1);
    }
  };
  groupFootertCount(props) {
    return <span>Total sub rows: {props.Count}</span>;
  }
  rowSelected = (e) => {
    const { homeHealthListForModal = [], type } = this.props;
    if (e.isInteracted) {
      if (this.props.type === "user") {
        this.props.editModal(e);
      } else if (type === TYPE_HOMEHEALTH) {
        const dataId = e.data && e.data.id;
        let data = {};
        homeHealthListForModal.forEach(dd => {
          if (dd.id == dataId) {
            data = dd;
          }
        })
        this.props.editModal(true, ((data && Object.keys(data).length > 0) ? { data: data } : e), "view");
      } else {
        this.props.editModal(true, e, "view");
      }
    }
  };
  
  clearSelectedRow = () => {
    let grid = this[`customGrid-${this.props.type}`];
    grid && grid.clearRowSelection()
  }

  render() {
    let {
      data = [],
      loading = false,
      editModal,
      columns = [],
      type,
      hideFilter
    } = this.props;
    // filter = filter.map(f => f.value)
    // console.log(filter, 'filter');
    return (
      <div className={"ad-grid-wrapper grid-common-wrapper"}>
        {type !== TYPE_STAT &&
          type !== TYPE_ROUTINE &&
          type !== TYPE_TIMEDDRAW &&
          type !== TYPE_HOMEHEALTH && (
            <div
              className={
                "ad-loading-wrapper " + (loading ? "d-block" : "d-none")
              }
            >
              <span
                className={
                  "loader ad-loader " + (loading ? "d-block" : "d-none")
                }
              >
                <span className="visually-hidden">loader icon</span>
              </span>
            </div>
          )}
        <GridComponent
          enablePersistence={
            type === TYPE_STAT ||
            type === TYPE_ROUTINE ||
            type === TYPE_TIMEDDRAW ||
            type === TYPE_HOMEHEALTH
          }
          id={"customGrid-" + type}
          dataSource={data}
          pageSettings={{
            pageSize:
              type === TYPE_STAT ||
                type === TYPE_ROUTINE ||
                type === TYPE_HOMEHEALTH ||
                type === TYPE_TIMEDDRAW
                ? 50
                : 100,
            pageCount: 4,
          }}
          allowSorting={true}
          rowSelected={this.rowSelected}
          toolbar={this.toolbar}
          toolbarClick={this.toolbarClick}
          showColumnChooser={true}
          allowFiltering={true}
          filterSettings={{ type: "CheckBox", enableCaseSensitivity: false }}
          allowPaging={type !== TYPE_STAT &&
            type !== TYPE_ROUTINE &&
            type !== TYPE_TIMEDDRAW &&
            type !== TYPE_HOMEHEALTH}
          ref={(comp) => {
            this[`customGrid-${type}`] = comp;
          }}
          sortSettings={this.sortingOptions}
          created={this.created}
          dataBound={this.dataBound}
          actionComplete={this.handleAction}
          actionBegin={this.actionBegin}
          groupSettings={{ showDropArea: true }}
          allowGrouping={
            (type === TYPE_STAT ||
              type === TYPE_ROUTINE ||
              type === TYPE_TIMEDDRAW ||
              type === TYPE_HOMEHEALTH)
          }
          allowReordering={
            type === TYPE_STAT ||
            type === TYPE_ROUTINE ||
            type === TYPE_TIMEDDRAW ||
            type === TYPE_HOMEHEALTH
          }
          allowMultiSorting={
            type === TYPE_STAT ||
            type === TYPE_ROUTINE ||
            type === TYPE_TIMEDDRAW ||
            type === TYPE_HOMEHEALTH
          }
          allowExcelExport={true}
          allowResizing={true}
          delayUpdate
        >
          <Inject
            services={hideFilter ? [
              // ExcelExport,
              Reorder,
              Aggregate,
              // Group,
              Sort,
              Filter,
              Aggregate,
              Resize,
              // Toolbar,
              // ColumnChooser,
              Page
            ] : [ExcelExport,
              Reorder,
              Aggregate,
              Group,
              Sort,
              Filter,
              Aggregate,
              Toolbar,
              ColumnChooser,
              Resize,
              Page]}
          />

          <ColumnsDirective>
            {columns.map((column, index) => {
              return (
                <ColumnDirective
                  field={column.field}
                  headerText={column.headerText}
                  width={column.width}
                  template={column.template || null}
                  visible={column.visible}
                  key={index}
                  filter={{ operators: "contains" }}
                  sortComparer={column.type === "Date" ? sortComparer : undefined}
                />
              );
            })}
          </ColumnsDirective>
        </GridComponent>
      </div>
    );
  }
}
export default CustomGrid;
