/* eslint-disable no-plusplus */
/* eslint-disable no-console */
// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import { areEqualDatesTwo, routes } from "../../../app/utils/Constants";
import { appConfig } from "../Config";
import AWS from "aws-sdk";
import {
  CognitoUser,
  CognitoUserPool,
} from 'amazon-cognito-identity-js';
import Auth from "@aws-amplify/auth";

// eslint-disable-next-line no-unused-vars
const ChimeIdentity = require("aws-sdk/clients/chimesdkidentity");
const ChimeMessaging = require("aws-sdk/clients/chimesdkmessaging");

export const BASE_URL = routes.SIGNIN;
export const createMemberArn = (userId) =>
  `${appConfig.appInstanceArn}/user/${userId}`;

export const Persistence = {
  PERSISTENT: "PERSISTENT",
  NON_PERSISTENT: "NON_PERSISTENT",
};

export const MessageType = {
  STANDARD: "STANDARD",
  CONTROL: "CONTROL",
};

const appInstanceUserArnHeader = "x-amz-chime-bearer";

let chimeIdentity = null;
let client = null;

// Setup Chime Messaging Client lazily
async function chimeMessagingClient() {
  client = new ChimeMessaging();
  return client;
}

// Setup Chime Identity Client lazily
async function chimeIdentityClient() {
  // if (chimeIdentity == null) {
  chimeIdentity = new ChimeIdentity();
  // }
  return chimeIdentity;
}

export const updateAwsCredentialsFromCognito = async () => {
  let userPool = new CognitoUserPool({
    UserPoolId: appConfig.cognitoUserPoolId,
    ClientId: appConfig.cognitoAppClientId
  });
  let cognitoUser = new CognitoUser({
    Username: localStorage.getItem("username"),
    Pool: userPool
  });
  if (cognitoUser != null) {
    cognitoUser.getSession(function (err, session) {
      if (err) {
        console.log(err);
        return;
      }
      AWS.config.region = appConfig.region;
      AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: appConfig.cognitoIdentityPoolId,
        Logins: {
          [`cognito-idp.${appConfig.region}.amazonaws.com/${appConfig.cognitoUserPoolId}`]: session.getIdToken().getJwtToken()
        }
      });

      AWS.config.credentials.get(async (err, data) => {
        if (!err) {
          const creds = await Auth.currentCredentials();
          if (Date.parse(creds.expiration) <= Date.parse(new Date())) {
            const essentialCreds = await Auth.essentialCredentials(creds);
            // AWS.config.update({
            //   region: appConfig.region,
            //   credentials: essentialCreds,
            //   accessKeyId: essentialCreds.accessKeyId,
            //   secretAccessKey: essentialCreds.secretAccessKey,
            //   sessionToken: essentialCreds.sessionToken
            // })
            AWS.config.region = appConfig.region;
            AWS.config.credentials = essentialCreds;
            await chimeMessagingClient()
            return essentialCreds;
          }
        }
      });
    });
  }
}

async function getMessagingSessionEndpoint() {
  let request = (await chimeMessagingClient()).getMessagingSessionEndpoint();
  request.on("error", async function (err, data) {
    if (err.message === "The security token included in the request is expired" || (err._type && err._type === "AccessDeniedException")) {
      await updateAwsCredentialsFromCognito();
      request = (await chimeMessagingClient()).getMessagingSessionEndpoint();
      const response = await request.promise();
      return response;
    }
  });
  const response = await request.promise();
  return response;
}

async function sendChannelMessage(
  channelArn,
  messageContent,
  persistence,
  type,
  member,
  options = null,
  callback
) {
  const chimeBearerArn = createMemberArn(member.userId);

  const params = {
    ChimeBearer: chimeBearerArn,
    ChannelArn: channelArn,
    Content: messageContent,
    Persistence: persistence, // Allowed types are PERSISTENT and NON_PERSISTENT
    Type: type, // Allowed types are STANDARD and CONTROL
    PushNotification: {
      "Body": member.username,
      "Title": "Chat",
      "Type": 'DEFAULT'
    }
  };
  if (options && options.Metadata) {
    params.Metadata = options.Metadata;
  }
  let request = (await chimeMessagingClient()).sendChannelMessage(params)
  request.on("error", async function (err, data) {
    if (err.message === "The security token included in the request is expired" || (err._type && err._type === "AccessDeniedException")) {
      await updateAwsCredentialsFromCognito();
      request = (await chimeMessagingClient()).sendChannelMessage(params)
      const response = await request.promise();
      const sentMessage = {
        response: response,
        CreatedTimestamp: new Date(),
        Sender: { Arn: createMemberArn(member.userId), Name: member.username },
      };
      return sentMessage;
    }
  });
  request.on("success", async function (data) {
    callback && !data.error && callback(false)
  });
  const response = await request.promise();
  const sentMessage = {
    response: response,
    CreatedTimestamp: new Date(),
    Sender: { Arn: createMemberArn(member.userId), Name: member.username },
  };
  return sentMessage;
}

async function getChannelMessage(channelArn, member, messageId) {
  const chimeBearerArn = createMemberArn(member.userId);

  const params = {
    ChannelArn: channelArn,
    MessageId: messageId,
    ChimeBearer: chimeBearerArn,
  };

  let request = (await chimeMessagingClient()).getChannelMessage(params);
  request.on("error", async function (err, data) {
    if (err.message === "The security token included in the request is expired" || (err._type && err._type === "AccessDeniedException")) {
      await updateAwsCredentialsFromCognito();
      request = (await chimeMessagingClient()).getChannelMessage(params);
      const response = await request.promise();
      return response.ChannelMessage;
    }
  });
  const response = await request.promise();
  return response.ChannelMessage;
}

async function listChannelMessages(channelArn, userId, nextToken = null, NotBefore, NotAfter) {
  const chimeBearerArn = createMemberArn(userId);

  const params = {
    ChannelArn: channelArn,
    NextToken: nextToken,
    ChimeBearer: chimeBearerArn
  };
  if (NotBefore && NotAfter) {
    const areEqual = areEqualDatesTwo(NotBefore, NotAfter);
    const newDate = new Date(NotBefore);
    newDate.setHours(0);
    newDate.setMinutes(0);
    newDate.setSeconds(0);
    newDate.setMilliseconds(0);
    params.NotBefore = newDate;
    if (!areEqual) {
      const newDate = new Date(NotAfter);
      newDate.setHours(24);
      newDate.setMinutes(0);
      newDate.setSeconds(0);
      newDate.setMilliseconds(0);
      params.NotAfter = newDate;
    }
  } else {
    if (NotBefore) {
      const newDate = new Date(NotBefore);
      newDate.setHours(0);
      newDate.setMinutes(0);
      newDate.setSeconds(0);
      newDate.setMilliseconds(0);
      params.NotBefore = newDate;
    }
    if (NotAfter) {
      const newDate = new Date(NotAfter);
      newDate.setHours(24);
      newDate.setMinutes(0);
      newDate.setSeconds(0);
      newDate.setMilliseconds(0);
      params.NotAfter = newDate;
    }
  }

  let request = (await chimeMessagingClient()).listChannelMessages(params);
  request.on("error", async function (err, data) {
    if (err.message === "The security token included in the request is expired" || (err._type && err._type === "AccessDeniedException")) {
      await updateAwsCredentialsFromCognito();
      request = (await chimeMessagingClient()).listChannelMessages(params);
      const response = await request.promise();
      const messageList = response.ChannelMessages;
      messageList.sort(function (a, b) {
        // eslint-disable-next-line no-nested-ternary
        return a.CreatedTimestamp < b.CreatedTimestamp
          ? -1
          : a.CreatedTimestamp > b.CreatedTimestamp
            ? 1
            : 0;
      });

      const messages = [];
      for (let i = 0; i < messageList.length; i++) {
        const message = messageList[i];
        messages.push(message);
      }
      return { Messages: messages, NextToken: response.NextToken };
    }
  });
  const response = await request.promise();
  const messageList = response.ChannelMessages;
  messageList.sort(function (a, b) {
    // eslint-disable-next-line no-nested-ternary
    return a.CreatedTimestamp < b.CreatedTimestamp
      ? -1
      : a.CreatedTimestamp > b.CreatedTimestamp
        ? 1
        : 0;
  });

  const messages = [];
  for (let i = 0; i < messageList.length; i++) {
    const message = messageList[i];
    messages.push(message);
  }
  return { Messages: messages, NextToken: response.NextToken };
}

async function listAppInstanceUsers(appInstanceArn, userId, nextToken = null) {
  const chimeBearerArn = createMemberArn(userId);
  const params = {
    AppInstanceArn: appInstanceArn,
    NextToken: nextToken,
    ChimeBearer: chimeBearerArn,
  };

  let request = (await chimeIdentityClient()).listAppInstanceUsers(params);
  request.on("build", function () {
    request.httpRequest.headers[appInstanceUserArnHeader] =
      createMemberArn(userId);
  });
  request.on("error", async function (err, data) {
    if (err.message === "The security token included in the request is expired" || (err._type && err._type === "AccessDeniedException")) {
      await updateAwsCredentialsFromCognito();
      request = (await chimeIdentityClient()).listAppInstanceUsers(params);
      const response = await request.promise();
      return response.AppInstanceUsers;
    }
  });
  const response = await request.promise();
  return response.AppInstanceUsers;
}

async function createChannelMembership(channelArn, memberArn, userId) {
  const chimeBearerArn = createMemberArn(userId);
  const params = {
    ChannelArn: channelArn,
    MemberArn: memberArn,
    Type: "DEFAULT", // OPTIONS ARE: DEFAULT and HIDDEN
    ChimeBearer: chimeBearerArn,
  };

  let request = (await chimeMessagingClient()).createChannelMembership(
    params
  );
  request.on("error", async function (err, data) {
    if (err.message === "The security token included in the request is expired" || (err._type && err._type === "AccessDeniedException")) {
      await updateAwsCredentialsFromCognito();
      request = (await chimeMessagingClient()).createChannelMembership(
        params
      );
      const response = await request.promise();
      return response.Member;
    }
  });
  const response = await request.promise();
  return response.Member;
}

async function deleteChannelMembership(channelArn, memberArn, userId) {
  const chimeBearerArn = createMemberArn(userId);

  const params = {
    ChannelArn: channelArn,
    MemberArn: memberArn,
    ChimeBearer: chimeBearerArn,
  };

  let request = (await chimeMessagingClient()).deleteChannelMembership(
    params
  );
  request.on("error", async function (err, data) {
    if (err.message === "The security token included in the request is expired" || (err._type && err._type === "AccessDeniedException")) {
      await updateAwsCredentialsFromCognito();
      request = (await chimeMessagingClient()).deleteChannelMembership(
        params
      );
      const response = await request.promise();
      return response;
    }
  });
  const response = await request.promise();
  return response;
}

async function createChannelBan(channelArn, memberArn, userId) {
  const chimeBearerArn = createMemberArn(userId);

  const params = {
    ChannelArn: channelArn,
    MemberArn: memberArn,
    ChimeBearer: chimeBearerArn,
  };

  let request = (await chimeMessagingClient()).createChannelBan(params);
  request.on("error", async function (err, data) {
    if (err.message === "The security token included in the request is expired" || (err._type && err._type === "AccessDeniedException")) {
      await updateAwsCredentialsFromCognito();
      request = (await chimeMessagingClient()).createChannelBan(params);
      const response = await request.promise();
      return response;
    }
  });
  const response = await request.promise();
  return response;
}

async function deleteChannelMessage(channelArn, messageId, userId) {
  const chimeBearerArn = createMemberArn(userId);

  const params = {
    ChannelArn: channelArn,
    MessageId: messageId,
    ChimeBearer: chimeBearerArn,
  };

  const request = (await chimeMessagingClient()).deleteChannelMessage(
    params
  );
  const response = await request.promise();
  return response;
}

async function deleteChannelBan(channelArn, memberArn, userId) {
  const chimeBearerArn = createMemberArn(userId);
  const params = {
    ChannelArn: channelArn,
    MemberArn: memberArn,
    ChimeBearer: chimeBearerArn,
  };

  let request = (await chimeMessagingClient()).deleteChannelBan(params);
  request.on("error", async function (err, data) {
    if (err.message === "The security token included in the request is expired" || (err._type && err._type === "AccessDeniedException")) {
      await updateAwsCredentialsFromCognito();
      request = (await chimeMessagingClient()).deleteChannelBan(params);
      const response = await request.promise();
      return response;
    }
  });
  const response = await request.promise();
  return response;
}

async function listChannelBans(channelArn, maxResults, nextToken, userId) {
  const chimeBearerArn = createMemberArn(userId);
  const params = {
    ChannelArn: channelArn,
    MaxResults: maxResults,
    NextToken: nextToken,
    ChimeBearer: chimeBearerArn,
  };

  let request = (await chimeMessagingClient()).listChannelBans(params);
  request.on("error", async function (err, data) {
    if (err.message === "The security token included in the request is expired" || (err._type && err._type === "AccessDeniedException")) {
      await updateAwsCredentialsFromCognito();
      request = (await chimeMessagingClient()).listChannelBans(params);
      const response = await request.promise();
      return response;
    }
  });
  const response = await request.promise();

  return response;
}

async function listChannelMemberships(channelArn, userId, token) {
  const chimeBearerArn = createMemberArn(userId);

  const params = {
    ChannelArn: channelArn,
    ChimeBearer: chimeBearerArn,
  };

  if (token) {
    params.NextToken = token;
  }

  let request = (await chimeMessagingClient()).listChannelMemberships(params);
  request.on("error", async function (err, data) {
    if (err.message === "The security token included in the request is expired" || (err._type && err._type === "AccessDeniedException")) {
      await updateAwsCredentialsFromCognito();
      request = (await chimeMessagingClient()).listChannelMemberships(params);
      const response = await request.promise();
      let members = response.ChannelMemberships || [];
      if (response.NextToken) {
        let moreMembers = await listChannelMemberships(channelArn, userId, response.NextToken) || [];
        members = [...members, ...moreMembers];
      }
      return members;
    }
  });
  const response = await request.promise();
  let members = response.ChannelMemberships || [];
  if (response.NextToken) {
    let moreMembers = await listChannelMemberships(channelArn, userId, response.NextToken) || [];
    members = [...members, ...moreMembers];
  }
  return members;
}

async function associateChannelFlow(channelArn, channelFlowArn, userId) {
  const chimeBearerArn = createMemberArn(userId);
  const params = {
    ChannelArn: channelArn,
    ChannelFlowArn: channelFlowArn,
    ChimeBearer: chimeBearerArn,
  };

  let request = (await chimeMessagingClient()).associateChannelFlow(params);
  request.on("error", async function (err, data) {
    if (err.message === "The security token included in the request is expired" || (err._type && err._type === "AccessDeniedException")) {
      await updateAwsCredentialsFromCognito();
      request = (await chimeMessagingClient()).associateChannelFlow(params);
      const response = await request.promise();
      return response;
    }
  });

  const response = await request.promise();

  return response;
}

async function disassociateChannelFlow(channelArn, channelFlowArn, userId) {
  const chimeBearerArn = createMemberArn(userId);
  const params = {
    ChannelArn: channelArn,
    ChannelFlowArn: channelFlowArn,
    ChimeBearer: chimeBearerArn,
  };

  let request = (await chimeMessagingClient()).disassociateChannelFlow(
    params
  );
  request.on("error", async function (err, data) {
    if (err.message === "The security token included in the request is expired" || (err._type && err._type === "AccessDeniedException")) {
      await updateAwsCredentialsFromCognito();
      request = (await chimeMessagingClient()).disassociateChannelFlow(
        params
      );
      const response = await request.promise();
      return response;
    }
  });

  const response = await request.promise();
  return response;
}

async function describeChannelFlow(channelFlowArn) {
  const params = {
    ChannelFlowArn: channelFlowArn,
  };

  let request = (await chimeMessagingClient()).describeChannelFlow(params);
  request.on("error", async function (err, data) {
    if (err.message === "The security token included in the request is expired" || (err._type && err._type === "AccessDeniedException")) {
      request = (await chimeMessagingClient()).describeChannelFlow(params);
      const response = await request.promise();
      return response.ChannelFlow;
    }
  });
  const response = await request.promise();
  return response.ChannelFlow;
}

async function listChannelFlows(appInstanceArn, maxResults, nextToken) {
  const params = {
    AppInstanceArn: appInstanceArn,
    MaxResults: maxResults,
    NextToken: nextToken,
  };

  let request = (await chimeMessagingClient()).listChannelFlows(params);
  request.on("error", async function (err, data) {
    if (err.message === "The security token included in the request is expired" || (err._type && err._type === "AccessDeniedException")) {
      await updateAwsCredentialsFromCognito();
      request = (await chimeMessagingClient()).listChannelFlows(params);
      const response = await request.promise();
      return response.ChannelFlows;
    }
  });
  const response = await request.promise();

  return response.ChannelFlows;
}

async function createChannel(
  appInstanceArn,
  metadata,
  name,
  mode,
  privacy,
  userId
) {
  const chimeBearerArn = createMemberArn(userId);
  const params = {
    AppInstanceArn: appInstanceArn,
    Metadata: metadata,
    Name: name,
    Mode: mode,
    Privacy: privacy,
    ChimeBearer: chimeBearerArn,
    Tags: [
      {
        Key: "name",
        Value: "abcdef",
      },
    ],
  };

  let request = (await chimeMessagingClient()).createChannel(params);
  request.on("build", function () {
    request.httpRequest.headers[appInstanceUserArnHeader] =
      createMemberArn(userId);
  });
  request.on("error", async function (err, data) {
    if (err.message === "The security token included in the request is expired" || (err._type && err._type === "AccessDeniedException")) {
      await updateAwsCredentialsFromCognito();
      request = (await chimeMessagingClient()).createChannel(params);
      const response = await request.promise();
      return response.ChannelArn;
    }
  });
  const response = await request.promise();
  return response.ChannelArn;
}
async function updateChannelReadMarker(appInstanceArn, channelArn, userId) {
  const chimeBearerArn = createMemberArn(userId);
  let request;
  const params = {
    ChannelArn: channelArn,
    ChimeBearer: chimeBearerArn,
  };
  request = (await chimeMessagingClient()).updateChannelReadMarker(params);

  request.on("build", function () {
    request.httpRequest.headers[appInstanceUserArnHeader] =
      createMemberArn(userId);
  });
  request.on("error", async function (err, data) {
    if (err.message === "The security token included in the request is expired" || (err._type && err._type === "AccessDeniedException")) {
      await updateAwsCredentialsFromCognito();
      request = (await chimeMessagingClient()).updateChannelReadMarker(params);
      const response = await request.promise();
      return response;
    }
  });
  const response = await request.promise();
  return response;

  // return response;
}

async function describeChannelMembershipForAppInstanceUser(
  appInstanceArn,
  channelArn,
  userId
) {
  const chimeBearerArn = createMemberArn(userId);
  let request;
  const params = {
    ChannelArn: channelArn,
    AppInstanceUserArn: createMemberArn(userId),
    ChimeBearer: chimeBearerArn,
  };
  request = (
    await chimeMessagingClient()
  ).describeChannelMembershipForAppInstanceUser(params);

  request.on("build", function () {
    request.httpRequest.headers[appInstanceUserArnHeader] =
      createMemberArn(userId);
  });
  request.on("error", async function (err, data) {
    if (err.message === "The security token included in the request is expired" || (err._type && err._type === "AccessDeniedException")) {
      await updateAwsCredentialsFromCognito();
      request = (
        await chimeMessagingClient()
      ).describeChannelMembershipForAppInstanceUser(params);
      const response = await request.promise();
      return response.ChannelMembership;
    }
  });
  const response = await request.promise();

  return response.ChannelMembership;

  // return response;
}
async function createChannelModerator(channelArn, userId, memberArn) {
  const chimeBearerArn = createMemberArn(userId);
  const member = createMemberArn(memberArn);
  let request;
  const params = {
    ChannelModeratorArn: member,
    ChimeBearer: chimeBearerArn,
    ChannelArn: channelArn,
  };
  request = (await chimeMessagingClient()).createChannelModerator(params);

  request.on("build", function () {
    request.httpRequest.headers[appInstanceUserArnHeader] =
      createMemberArn(userId);
  });
  request.on("error", async function (err, data) {
    if (err.message === "The security token included in the request is expired" || (err._type && err._type === "AccessDeniedException")) {
      await updateAwsCredentialsFromCognito();
      request = (await chimeMessagingClient()).createChannelModerator(params);
      const response = await request.promise();
      return response;

    }
  });
  const response = await request.promise();
  return response;

  // return response;
}

async function describeChannel(channelArn, userId) {
  const chimeBearerArn = createMemberArn(userId);

  const params = {
    ChannelArn: channelArn,
    ChimeBearer: chimeBearerArn,
  };

  let request = (await chimeMessagingClient()).describeChannel(params);
  request.on("error", async function (err, data) {
    if (err.message === "The security token included in the request is expired" || (err._type && err._type === "AccessDeniedException")) {
      await updateAwsCredentialsFromCognito();
      request = (await chimeMessagingClient()).describeChannel(params);
      const response = await request.promise();
      return response.Channel;
    }
  });
  const response = await request.promise();
  return response.Channel;
}

async function updateChannel(channelArn, name, mode, metadata, userId) {
  const chimeBearerArn = createMemberArn(userId);

  const params = {
    ChannelArn: channelArn,
    Name: name,
    Mode: mode,
    Metadata: metadata,
    ChimeBearer: chimeBearerArn,
  };

  let request = (await chimeMessagingClient()).updateChannel(params);
  request.on("error", async function (err, data) {
    if (err.message === "The security token included in the request is expired" || (err._type && err._type === "AccessDeniedException")) {
      await updateAwsCredentialsFromCognito();
      request = (await chimeMessagingClient()).updateChannel(params)
      const response = await request.promise();
      return response;
    }
  });
  const response = await request.promise();
  return response;
}

async function listChannelMembershipsForAppInstanceUser(loggedInUser, token, listChannelUser) {
  // listChannelUser  = '10219'
  // listChannelUser = "us-east-1:911e3d51-114e-4902-ab9b-f6b49df4f965"
  const chimeBearerArn = createMemberArn(loggedInUser);

  const params = {
    ChimeBearer: chimeBearerArn,
  };
  if (listChannelUser) {
    const searchUserArn = createMemberArn(listChannelUser);
    params.AppInstanceUserArn = searchUserArn;
  }
  if (token) {
    params.NextToken = token;
  }

  let request = (
    await chimeMessagingClient()
  ).listChannelMembershipsForAppInstanceUser(params);
  request.on("error", async function (err, data) {
    if (err.message === "The security token included in the request is expired" || (err._type && err._type === "AccessDeniedException")) {
      await updateAwsCredentialsFromCognito();
      request = (
        await chimeMessagingClient()
      ).listChannelMembershipsForAppInstanceUser(params);
      const response = await request.promise();
      let channels = response.ChannelMemberships || [];
      if (response.NextToken) {
        let moreChannels = await listChannelMembershipsForAppInstanceUser(loggedInUser, response.NextToken, listChannelUser) || [];
        channels = [...channels, ...moreChannels];
      }
      return channels;
    }
  });
  const response = await request.promise();
  let channels = response.ChannelMemberships || [];
  if (response.NextToken) {
    let moreChannels = await listChannelMembershipsForAppInstanceUser(loggedInUser, response.NextToken, listChannelUser) || [];
    channels = [...channels, ...moreChannels];
  }
  return channels;
}

async function listChannels(appInstanceArn, userId, token) {
  const chimeBearerArn = createMemberArn(userId);
  const params = {
    AppInstanceArn: appInstanceArn,
    ChimeBearer: chimeBearerArn,
  };
  if (token) {
    params.NextToken = token;
  }

  let request = (await chimeMessagingClient()).listChannels(params);
  request.on("error", async function (err, data) {
    if (err.message === "The security token included in the request is expired" || (err._type && err._type === "AccessDeniedException")) {
      await updateAwsCredentialsFromCognito();
      request = (await chimeMessagingClient()).listChannels(params);
      const response = await request.promise();
      let channels = response.Channels || [];
      if (response.NextToken) {
        let moreChannels = await listChannels(appInstanceArn, userId, response.NextToken) || [];
        channels = [...channels, ...moreChannels];
      }
      return channels;
    }
  });
  const response = await request.promise();
  let channels = response.Channels || [];
  if (response.NextToken) {
    let moreChannels = await listChannels(appInstanceArn, userId, response.NextToken) || [];
    channels = [...channels, ...moreChannels];
  }
  return channels;
}

async function listChannelsForAppInstanceUser(userId) {
  const chimeBearerArn = createMemberArn(userId);
  const params = {
    ChimeBearer: chimeBearerArn,
  };
  let request = (await chimeMessagingClient()).listChannelsForAppInstanceUser(
    params
  );
  request.on("error", async function (err, data) {
    if (err.message === "The security token included in the request is expired" || (err._type && err._type === "AccessDeniedException")) {
      await updateAwsCredentialsFromCognito();
      request = (await chimeMessagingClient()).listChannelsForAppInstanceUser(
        params
      );
      const response = await request.promise();
      const channels = response.Channels;

      return channels;
    }
  });
  const response = await request.promise();
  const channels = response.Channels;

  return channels;
}

async function deleteChannel(channelArn, userId) {
  const chimeBearerArn = createMemberArn(userId);
  const params = {
    ChannelArn: channelArn,
    ChimeBearer: chimeBearerArn,
  };

  let request = (await chimeMessagingClient()).deleteChannel(params);
  request.on("error", async function (err, data) {
    if (err.message === "The security token included in the request is expired" || (err._type && err._type === "AccessDeniedException")) {
      await updateAwsCredentialsFromCognito();
      request = (await chimeMessagingClient()).deleteChannel(params);
      await request.promise();
    }
  });
  await request.promise();
}

async function listChannelModerators(channelArn, userId) {
  const chimeBearerArn = createMemberArn(userId);

  const params = {
    ChannelArn: channelArn,
    ChimeBearer: chimeBearerArn,
  };

  let request = (await chimeMessagingClient()).listChannelModerators(params);
  request.on("error", async function (err, data) {
    if (err.message === "The security token included in the request is expired" || (err._type && err._type === "AccessDeniedException")) {
      await updateAwsCredentialsFromCognito();
      request = (await chimeMessagingClient()).listChannelModerators(params);
      const response = await request.promise();
      return response ? response.ChannelModerators : null;
    }
  });
  const response = await request.promise();
  return response ? response.ChannelModerators : null;
}

async function updateChannelMessage(
  channelArn,
  messageId,
  content,
  metadata,
  userId
) {
  const chimeBearer = createMemberArn(userId);
  const params = {
    ChannelArn: channelArn,
    MessageId: messageId,
    Content: content,
    Metadata: metadata,
    ChimeBearer: chimeBearer,
  };

  let request = (await chimeMessagingClient()).updateChannelMessage(params);
  request.on("error", async function (err, data) {
    if (err.message === "The security token included in the request is expired" || (err._type && err._type === "AccessDeniedException")) {
      await updateAwsCredentialsFromCognito();
      request = (await chimeMessagingClient()).updateChannelMessage(params);
      const response = await request.promise();
      return response;
    }
  });
  const response = await request.promise();
  return response;
}

async function redactChannelMessage(channelArn, messageId, userId) {
  const chimeBearerArn = createMemberArn(userId);
  const params = {
    ChannelArn: channelArn,
    MessageId: messageId,
    ChimeBearer: chimeBearerArn,
  };

  let request = (await chimeMessagingClient()).redactChannelMessage(params);
  request.on("error", async function (err, data) {
    if (err.message === "The security token included in the request is expired" || (err._type && err._type === "AccessDeniedException")) {
      await updateAwsCredentialsFromCognito();
      request = (await chimeMessagingClient()).redactChannelMessage(params);
      const response = await request.promise();

      return response;
    }
  });
  const response = await request.promise();

  return response;
}

async function createMeeting(name, userId, channelArn) {
  const response = await fetch(
    `${appConfig.apiGatewayInvokeUrl}create?name=${encodeURIComponent(
      name
    )}&userId=${encodeURIComponent(userId)}&channel=${encodeURIComponent(
      channelArn
    )}`,
    {
      method: "POST",
    }
  );
  const data = await response.json();

  if (data.error) {
    throw new Error(`Server error: ${data.error}`);
  }

  return data;
}

async function createAttendee(name, userId, channelArn, meeting) {
  const response = await fetch(
    `${appConfig.apiGatewayInvokeUrl}join?name=${encodeURIComponent(
      name
    )}&userId=${encodeURIComponent(userId)}&channel=${encodeURIComponent(
      channelArn
    )}&meeting=${encodeURIComponent(meeting)}`,
    {
      method: "POST",
    }
  );
  const data = await response.json();

  if (data.error) {
    throw new Error(`Server error: ${data.error}`);
  }

  return data;
}

function createGetAttendeeCallback() {
  return async (chimeAttendeeId, externalUserId) => {
    return {
      name: externalUserId,
    };
  };
}

async function endMeeting(meetingId) {
  const res = await fetch(
    `${appConfig.apiGatewayInvokeUrl}end?meetingId=${encodeURIComponent(
      meetingId
    )}`,
    {
      method: "POST",
    }
  );

  if (!res.ok) {
    throw new Error("Server error ending meeting");
  }
}

export {
  associateChannelFlow,
  disassociateChannelFlow,
  describeChannelFlow,
  listChannelFlows,
  sendChannelMessage,
  getChannelMessage,
  listChannelMessages,
  createChannelMembership,
  listChannelMemberships,
  deleteChannelMembership,
  createChannelBan,
  deleteChannelBan,
  listChannelBans,
  createChannel,
  describeChannel,
  updateChannel,
  listChannels,
  listChannelsForAppInstanceUser,
  deleteChannel,
  listChannelModerators,
  updateChannelMessage,
  redactChannelMessage,
  getMessagingSessionEndpoint,
  listChannelMembershipsForAppInstanceUser,
  listAppInstanceUsers,
  createMeeting,
  createAttendee,
  createGetAttendeeCallback,
  endMeeting,
  updateChannelReadMarker,
  describeChannelMembershipForAppInstanceUser,
  createChannelModerator,
  deleteChannelMessage
};
