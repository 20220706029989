import * as React from "react";
import { FacilityService } from "./FacilityService";
import { OnCallService } from "./OnCall/onCallService";
import { BACKLOG_SECTION_TYPE } from "../../app/utils/Constants";

// import { backlogData } from './scheduleHelper';
export const SchedulerBacklog = (props) => {
  const { backlogData = [], tabValue, searchText = "", searchType, onCallScheduleBoardData, showDropArea, showInactiveFac } = props;
  const {users = []} = onCallScheduleBoardData ? onCallScheduleBoardData[0] || {} : {};
  return (
    <div className="scheduler-backlogs">
      {tabValue === "routine" && backlogData.length > 0 && <FacilityService showDropArea={showDropArea} sectionType={BACKLOG_SECTION_TYPE} services={backlogData} searchText={searchType==="zone" ? searchText : ""} searchType={searchType} showInactiveFac={showInactiveFac} />}
      {tabValue === "onCall" && backlogData.length > 0 && <OnCallService showDropArea={showDropArea} sectionType={BACKLOG_SECTION_TYPE} users={users} services={backlogData} searchText={searchType==="zone" ? searchText : ""} searchType={searchType} />}
    </div>
  );
};
