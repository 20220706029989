import React from "react";
import { ProgressButtonComponent } from "@syncfusion/ej2-react-splitbuttons";
import { Button, Modal } from "react-bootstrap";

export default function TestCodeWarningModal(props = {}) {
  const { title, subtitle, hideModal, confirmUpdate, show } = props;
  return (
    <>
      <Modal
        show={show}
        onHide={hideModal}
        className="ad-adduser-modal sc-copycal-modal"
        backdrop="static"
        enforceFocus={false}
        data-testid="status-modal"
      >
        <Modal.Header
          data-testid="status-modal-close"
          closeButton
        ></Modal.Header>
        <Modal.Body className="text-center">
          <div
            className="sch-confirmation-wrapper"
            data-testid="status-modal-title-icon"
          >
            <span className="confirmation-icon"></span>
          </div>
          <p
            data-testid="status-modal-confirmation-title"
            className={`confirmation-title`}
          >
            {title || ""}
          </p>
          <p
            data-testid="status-modal-subtitle"
            className="font-size-14 roboto-medium confirmation-subtitle mb-20 edit-inactive-user"
          >
            {subtitle || ""}
          </p>
          <div className="d-flex justify-content-center mb-2">
            <Button
              data-testid="status-modal-back"
              className="primary-btn primary-btn-outline outline"
              onClick={hideModal}
            >
              Cancel
            </Button>
            <ProgressButtonComponent
              data-testid="status-modal-save-button"
              content={"Confirm"}
              spinSettings={{ position: "Center" }}
              animationSettings={{ effect: "SlideLeft" }}
              cssClass="primary-btn primary-btn-outline ad-progress-btn"
              onClick={confirmUpdate}
            ></ProgressButtonComponent>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
