import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ahaApi from "../../../app/services/aha-api";
const initialState = {
  value: [],
  loading: false
};

export const addTerritories = createAsyncThunk(
  "territoriess/addTerritories",
  async (data, { rejectWithValue }) => {
    const response = await ahaApi.Account.createTerritory(data)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return rejectWithValue(err.response.data);
      });

    return response;
  }
);

export const addTerritoriesSlice = createSlice({
  name: "addTerritories",
  initialState,
  reducers: {
    clearAddTerritoryResponse(state, action) {
      state.value = {}
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(addTerritories.pending, (state) => {
        state.loading = true;
      })
      .addCase(addTerritories.fulfilled, (state, action) => {
        state.loading = false;
        state.value = action.payload;
        state.days = action.payload.days;
      })
      .addCase(addTerritories.rejected, (state, err) => {
        state.loading = false;
        state.value = err.payload;
      });
  },
});

export const addTerritoriesList = (state) => state.addTerritories.value;
export const {clearAddTerritoryResponse} = addTerritoriesSlice.actions;
export default addTerritoriesSlice.reducer;
