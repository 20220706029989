import _ from "lodash";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ahaApi from "./app/services/aha-api";
const initialState = {
  loading: false,
  flag: {},
  isUm: "wait"
};

export const mCheck = createAsyncThunk(
	"mCheck",
	async (data, { rejectWithValue }) => {
	  const response = await ahaApi.Account.cmf()
		.then((res) => {
		  return res;
		})
		.catch((err) => {
		  return rejectWithValue(err.response.data);
		});
	  return response;
	}
  );

export const mSlice = createSlice({
	name: "mCheck",
	initialState,
	reducers: {
		setIsUm(state, action) {
			state.isUm = action.payload;
		}
	},
	extraReducers: (builder) => {
		builder
		.addCase(mCheck.pending, (state) => {
		  state.loading = true;
		})
		.addCase(mCheck.fulfilled, (state, action) => {
		  state.loading = false;
		  state.flag = action.payload;
		})
		.addCase(mCheck.rejected, (state, err) => {
		  state.loading = false;
		  state.flag = err.payload;
		});
	},
  });

export const mFlag = (state) => state.md.flag;
export const mLoader = (state) => state.md.loading;
export const isUm = (state) => state.md.isUm;
export const { setIsUm } = mSlice.actions;

export default mSlice.reducer;