import { ProgressButtonComponent } from "@syncfusion/ej2-react-splitbuttons";
import { Modal } from "react-bootstrap";

export default function UpdateReporteesWarningModal(props = {}) {
	const { handleAssignReportingManager, userReporteesState, setUserReporteesState } = props;

	const { searchText = "", value = false, response = {}, selectedManager = {}, selectedReportees = {} } = userReporteesState;
	const { error_content = {} } = response;
	let { reporting_managers = [], users = [] } = error_content;
	reporting_managers = reporting_managers.map(man => {return {...man, full_name: `${man.firstname} ${man.lastname}`}})

	const showNoResults = !reporting_managers.some(result => (!searchText || result.full_name.toLowerCase().search(searchText.toLowerCase()) !== -1))

	return (
		<Modal
			show={value}
			onHide={() => setUserReporteesState({ value: false })}
			className="ad-adduser-modal sc-copycal-modal ad-deactivation-confirmation"
			backdrop="static"
			enforceFocus={false}
			data-testid="modal-status"
			backdropClassName="network-error-backdrop"
		>
			<Modal.Header closeButton></Modal.Header>
			<Modal.Body className="text-center">
				<div className="sch-confirmation-wrapper">
					<span className="assignment-confirm"></span>
				</div>
				<p
					data-testid="status-confirmation"
					className="confirmation-title"
				>
					<p>There are user accounts that still report to this manager. Please reassign the following user(s) before changing to <span className='roboto-bold'>Inactive</span> status.</p>
				</p>
				<p
					data-testid="status-subtitle"
					className="font-size-14 roboto-medium confirmation-subtitle mb-20 edit-inactive-user"
				>

					<div className="tracker-search scheduler-tabs-wrapper surge-non-service-search-div d-flex align-items-start w-100 ad-reporting-wrapper">
						<div className="flex-50">
							<div className="position-relative text-start  m-0">
								<label htmlFor="tracker-search" className="roboto-regular">
									Reporting Manager
								</label>
								<input
									id="tracker-search"
									type="text"
									placeholder="Search"
									className="form-control search-box w-100 min-w-auto"
									autoComplete="off"
									value={searchText || ""}
									onChange={e => {
										setUserReporteesState({ ...userReporteesState, searchText: e.target.value })
									}}
								/>
								<span className="search-icon"><span className="visually-hidden">Search Icon</span></span>
							</div>

							{searchText && !showNoResults &&
								<div className="tracker-wrapper">
									<div className="tracker-result sr-dropdown-container">
										 <ul id="facilities-list-wrapper" className=" w-100">
											{reporting_managers.map((result, index) => {
												return ((!searchText || result.full_name.toLowerCase().search(searchText.toLowerCase()) !== -1) &&
													<li
														key={index}
														onClick={() => setUserReporteesState({ ...userReporteesState, selectedManager: result, searchText: "" })}
														className="me-0 text-start text-capitalize"
													>
														{result.full_name}
													</li>
												);
											})}
										</ul>  
										
									</div>
								</div>}
								{showNoResults && <p className="d-flex no-manager-found"><span className="light-black-color font-size-11">
								No manager found</span>	</p> }

							{selectedManager && selectedManager.full_name && <div className="mt-2">
								<p className="roboto-regular font-size-12 w-100 text-start">Assign to:</p>
								<div className="assigned-manager d-flex align-items-center">
									<span>{selectedManager.full_name}</span>
								</div>
							</div>}

						</div>

						<div className="d-flex flex-column align-items-center text-start ms-4 sc-checkbox-wrapper w-100">
							<p className="roboto-regular font-size-12 w-100">{users ? users.length : ""} Phleb(s) remaining</p>
							<div className="ad-reporting-manager-wrapper">
								<ul className="ad-reporting-manager-list">
									{users.map(con => {
										return (
											<li>
												<div className="form-check text-start">
													<input
														className="form-check-input"
														type="checkbox"
														id={`scheduled-${con.id}`}
														checked={selectedReportees[con.id] || false}
														value={selectedReportees[con.id] || false}
														onChange={e => {
															setUserReporteesState({
																...userReporteesState,
																selectedReportees: {
																	...selectedReportees,
																	[con.id]: e.target.checked
																}
															})
														}}
													/>
													<label
														className="form-check-label pb-0 mt-1"
														htmlFor={`scheduled-${con.id}`}
													>
														{con.firstname} {con.lastname}
													</label>
												</div>
											</li>
										)
									})}
								</ul>
							</div>

						</div>
					</div>
				</p>
				<div className="d-flex justify-content-center mb-2 mt-2">
					<ProgressButtonComponent
						data-testid="button-save"
						content={"Assign"}
						spinSettings={{ position: "Center" }}
						animationSettings={{ effect: "SlideLeft" }}
						cssClass="primary-btn primary-btn-outline ad-progress-btn"
						onClick={handleAssignReportingManager}
						disabled={!selectedManager || !selectedManager.id || !selectedReportees || Object.keys(selectedReportees).every(id => !selectedReportees[id])}
					></ProgressButtonComponent>
				</div>
			</Modal.Body>
		</Modal>
	);
}
