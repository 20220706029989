import * as React from "react";
import { SchedulerBacklog } from "../SchedulerBacklog";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import _ from "lodash";
import { SchedulerBoard } from "../SchedulerBoard";

export const OnCallScheduler = (props) => {
	const { profileLoading, selectedDate, tabValue, date, updateBoardData,
		handleDelete, deleteUserIndex, handleDeleteService, constructPyloadForCheckinCheckout, getScheduleBoardData,
		deleteServiceData, setDeleteServiceData, onCallScheduleBoardData, setOnCallBoardData,
		onCallBacklogData, setOnCallBacklogData, onDragEnd, searchText = "", searchType, onDragUpdate, filterType, showDropArea,
		onBeforeCapture, shiftTimingsList, timezone_ids, zonesForSurge, addZoneToSurge } = props;

		const roles =
		(window.localStorage.getItem("userDetail") &&
		JSON.parse(window.localStorage.getItem("userDetail")).roles &&
		JSON.parse(window.localStorage.getItem("userDetail")).roles.length === 1 &&
		JSON.parse(window.localStorage.getItem("userDetail")).roles) || [];
	
		const admin = roles && roles.includes(
		  "Admin"
		);
	
		const isPhleb = roles && roles.includes(
		  "Phlebotomist"
		);
	
	return (
		<React.Fragment>
			<div className="scheduler-wrapper">
				{onCallScheduleBoardData.length === 0 && onCallBacklogData.length === 0 ? (
					<div
						className="toast toast-wrapper text-center show sch-toast-wrapper"
						role="alert"
						aria-live="assertive"
						aria-atomic="true"
					>
						<div className="toast-info-details d-flex align-items-center toast-body">
							<p className="sch-info-text">
								{!isPhleb
									? "Please select Facilities and Staff to view the Schedule"
									: "User does not have Permission to view the Schedule"}
							</p>
						</div>
					</div>
				) : onCallScheduleBoardData.length === 0 ? (
					<div
						className="toast toast-wrapper text-center show sch-toast-wrapper sch-right-toast-wrapper"
						role="alert"
						aria-live="assertive"
						aria-atomic="true"
					>
						<div className="toast-info-details d-flex align-items-center toast-body">
							<p className="sch-info-text">
								Please select Staff to view the Schedule Board
							</p>
						</div>
					</div>
				) : onCallBacklogData.length === 0 ? (
					<div
						className="toast toast-wrapper text-center show sch-toast-wrapper sch-left-toast-wrapper"
						role="alert"
						aria-live="assertive"
						aria-atomic="true"
					>
						<div className="toast-info-details d-flex align-items-center toast-body">
							<p className="sch-info-text">
								Please select Facilities to view the Facilities
							</p>
						</div>
					</div>
				) : null}
				<DragDropContext onDragEnd={onDragEnd} onDragUpdate={e => onDragUpdate(e)} onBeforeCapture={onBeforeCapture}>
					{!profileLoading && (
						<SchedulerBacklog
							selectedDate={selectedDate}
							backlogData={onCallBacklogData}
							tabValue={tabValue}
							searchText={searchText}
							searchType={searchType}
							onCallScheduleBoardData={onCallScheduleBoardData}
							showDropArea={showDropArea}
						/>
					)}
					<SchedulerBoard
						selectedDate={selectedDate}
						scheduleBoardData={onCallScheduleBoardData}
						setBoardData={setOnCallBoardData}
						date={date}
						updateBoardData={updateBoardData}
						handleDelete={handleDelete}
						deleteIndex={deleteUserIndex}
						handleDeleteService={handleDeleteService}
						constructPyloadForCheckinCheckout={
							constructPyloadForCheckinCheckout
						}
						getScheduleBoardData={getScheduleBoardData}
						deleteServiceData={deleteServiceData}
						setDeleteServiceData={setDeleteServiceData}
						tabValue={tabValue}
						searchText={searchText}
						searchType={searchType}
						filterType={filterType}
						showDropArea={showDropArea}
						shiftTimingsList={shiftTimingsList}
						timezone_ids={timezone_ids}
						zonesForSurge={zonesForSurge}
						addZoneToSurge={addZoneToSurge}
					/>
				</DragDropContext>
			</div>
		</React.Fragment>
	);
};
