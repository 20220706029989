/* eslint-disable import/no-unresolved */
// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import React, { useState } from 'react';

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalButtonGroup,
  ModalButton,
  Input,
  RadioGroup,
  useNotificationDispatch,
} from 'amazon-chime-sdk-component-library-react';

import { updateChannel } from '../../api/ChimeAPI';

import '../../../../assets/styles/pluginchat.scss';
import { GROUP_CHANNEL_TYPE, INDIVIDUAL_CHANNEL_TYPE, GROUP_CHANNEL_TYPE_TWO } from '../../../../app/utils/Constants';
import { useAuthContext } from '../../providers/AuthProvider';

export const EditChannelModal = ({ onClose, channel, userId }) => {
  const { username } = useAuthContext().member;
  let {Name=""} = channel || {};
  if (Name.includes(GROUP_CHANNEL_TYPE) || Name.includes(GROUP_CHANNEL_TYPE_TWO)) {
    Name = Name.replace(GROUP_CHANNEL_TYPE, '');
    Name = Name.replace(GROUP_CHANNEL_TYPE_TWO, '');
  }
  else if (Name.includes(INDIVIDUAL_CHANNEL_TYPE)) {
    Name = Name.replace(INDIVIDUAL_CHANNEL_TYPE, '');
    let allUsers = Name.split(", ");
    if (allUsers.includes(username)) {
      allUsers = allUsers.filter(user => user !== username);
      Name = allUsers.join(', ');
    }
  }
  
  const [newName, setNewName] = useState(Name);
  const [newMode, setNewMode] = useState(channel.Mode);
  const dispatch = useNotificationDispatch();

  const onChange = (e) => {
    setNewName(e.target.value);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    try {
      const newChannelName = '$group$'+ newName;
      updateChannel(channel.ChannelArn, newChannelName, newMode, channel.Metadata, userId);
      dispatch({
        type: 0,
        payload: {
          message: 'Successfully updated room.',
          severity: 'success',
          autoClose: true,
        },
      });
    } catch {
      dispatch({
        type: 0,
        payload: {
          message: 'Unable to update room.',
          severity: 'error',
        },
      });
    }
    onClose();
  };
  return (
    <Modal onClose={onClose} className="edit-channel ch-main-modal">
      <ModalHeader title={`Edit ${Name}`} />
      <ModalBody className="modal-body">
        <form onSubmit={(e) => onSubmit(e)} id="edit-channel">
          <Input
            className="input modal-input"
            onChange={onChange}
            value={newName}
            type="text"
          />
          {/* <div className="radio-buttons">
            <RadioGroup
              options={[
                { value: 'RESTRICTED', label: 'Restricted' },
                { value: 'UNRESTRICTED', label: 'Unrestricted' },
              ]}
              value={newMode}
              onChange={(e) => setNewMode(e.target.value)}
            />
          </div> */}
        </form>
      </ModalBody>
      <ModalButtonGroup
        primaryButtons={[
          <ModalButton
            label="Save"
            type="submit"
            form="edit-channel"
            variant="primary"
            className="primary-btn ad-progress-btn primary-btn-outline"
            disabled={!newName}
          />,
          <ModalButton label="Cancel" variant="secondary" closesModal className="primary-btn primary-btn-outline outline" />,
        ]}
      />
    </Modal>
  );
};

export default EditChannelModal;
