import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ahaApi from "../../../app/services/aha-api";
import { phoneFormat } from "../../../app/utils/testUtils";

const initialState = {
  value: [],
  loading: false,
};

export const getHospitals = createAsyncThunk(
  "hospitals/getHospitals",
  async (data, { rejectWithValue }) => {
    const response = await ahaApi.Account.hospitals(data)
      .then((res) => {
        let result = res ? (res.content || []) : [];
        result = result.map(re=> {return {
          ...re,
          fullAddress: re.street_address1 + " "+ re.street_address2,
          city_string: re.city || "-",
          zip_string: re.zip || "-",
          state_name_string: re.state ? re.state.state_name || "-" : "-",
          zone_name_string: re.zone ? re.zone.zone_name || "-" : "-",
          time_zone_name_string: re.timezone ? re.timezone.timezone_name || "-" : "-",
          latitude_string: re.latitude ? re.latitude : "-",
          longitude_string: re.longitude ? re.longitude : "-",
          account_number_string: re.account_number || "-",
          hospital_contact_phone_number_ext_string: re.hospital_contact_phone_number_ext || "-",
          hospital_notes_string: re.hospital_notes || "-",
          hospital_contact_phone_number_string: re.hospital_contact_phone_number 
            ? `${phoneFormat(re.hospital_contact_phone_number.toString())} ${re.hospital_contact_phone_number_ext ? ` Ext. ${re.hospital_contact_phone_number_ext}` : ""}` : "-"
        }});
        res.content = result;
        return res;
      })
      .catch((err) => {
        return rejectWithValue(err.response.data);
      });

    return response;
  }
);

export const hospitalsSlice = createSlice({
  name: "hospitals",
  initialState,

  extraReducers: (builder) => {
    builder
      .addCase(getHospitals.pending, (state) => {
        state.loading = true;
      })
      .addCase(getHospitals.fulfilled, (state, action) => {
        state.loading = false;
        state.value = action.payload;
      })
      .addCase(getHospitals.rejected, (state, err) => {
        state.loading = false;
        state.value = err.payload;
      });
  },
});

export const hospitalsList = (state) => state.hospitals.value;
export const hospitalsLoader = (state) => state.hospitals.loading;

export default hospitalsSlice.reducer;
