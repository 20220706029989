import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ahaApi from "../../../app/services/aha-api";
const initialState = {
  value: {},
  loading: false,
  activeFacilityId: "",
  activeFacility: {},
  menuIsOpenValue: {
    checkIn: false,
    checkOut: false,
    stopDay: false
  },
  scrollPos: 0,
  auditKey: "",
  auditKeyDropOff: "",
  menuOpenStopDay: false,
  disableSaveButton: false
};

export const checkinCheckoutApiResponse = createAsyncThunk(
  "schedule/checkinCheckout",
  async (data, { rejectWithValue }) => {
    const response = await ahaApi.Account.routineCheckinCheckout(data)
      .then((res) => {
        return res
      })
      .catch((err) => {
        return rejectWithValue(err.response.data);
      });
    return response;
  }
);
export const checkinCheckoutSlice = createSlice({
  name: "checkinCheckout",
  initialState,
  reducers: {
    clearCheckinCheckout(state, action) {
      state.value = {};
    },
    setActiveFacilityId(state, action) {
      state.activeFacilityId = action.payload;
    },
    setActiveFacility(state, action) {
      state.activeFacility = action.payload;
    },
    setMenuIsOpen(state, action) {
      state.menuIsOpenValue = action.payload;
    },
    setMenuOpenStopDay(state, action) {
      state.menuOpenStopDay = action.payload;
    },
    setScrollPosition(state, action) {
      state.scrollPos = action.payload;
    },
    setAuditKey(state, action) {
      state.auditKey = action.payload;
    },
    setAuditKeyDropOff(state, action) {
      state.auditKeyDropOff = action.payload;
    },
    setDisableSaveButton(state, action) {
      state.disableSaveButton = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(checkinCheckoutApiResponse.pending, (state) => {
        state.loading = true;
      })
      .addCase(checkinCheckoutApiResponse.fulfilled, (state, action) => {
        state.loading = false;
        state.value = action.payload;
      })
      .addCase(checkinCheckoutApiResponse.rejected, (state, err) => {
        state.loading = false;
        state.value = err.payload;
      });
  },
});

export const checkinCheckoutResponse = (state) => state.checkinCheckout.value;
export const activeFacilityId = (state) => state.checkinCheckout.activeFacilityId;
export const activeFacility = (state) => state.checkinCheckout.activeFacility;
export const menuIsOpenValue = (state) => state.checkinCheckout.menuIsOpenValue;
export const menuOpenStopDayValue = (state) => state.checkinCheckout.menuOpenStopDay;
export const scrollPos = (state) => state.checkinCheckout ? state.checkinCheckout.scrollPos : 0;
export const auditKeyVal = (state) => state.checkinCheckout.auditKey;
export const auditKeyDropOffVal = (state) => state.checkinCheckout.auditKeyDropOff;
export const disableSaveButton = (state) => state.checkinCheckout.disableSaveButton;

export const {clearCheckinCheckout, setActiveFacilityId, setActiveFacility, setMenuIsOpen, setMenuOpenStopDay, setScrollPosition, setAuditKey, setAuditKeyDropOff, setDisableSaveButton} = checkinCheckoutSlice.actions;

export default checkinCheckoutSlice.reducer;
