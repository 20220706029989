import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import ahaApi from "../../app/services/aha-api";
import _ from "lodash";
import { ACKNOWLEDGED_STATUS, UNACKNOWLEDGED_STATUS, findLastIndex } from "../../app/utils/Constants";
const initialState = {
  value: [],
  loading: false,
};

export const getTimedDraws = createAsyncThunk(
  "timedDraws/getTimedDraws",
  async (data, { rejectWithValue }) => {
    const response = await ahaApi.Account.getOrders(data)
      .then((res) => {
        let { content = {} } = res;
        let { orders = [], count } = content;
        orders = orders.map(c => {
          const { patient_first_name = "", patient_last_name = "", notes = [], child_account_address1 = "", child_account_address2 = "", child_city = "",
            child_state = {}, child_zip, order_creation_datetime = "", user = {}, order_line_items = [], hl7 = false, collection_time = "", patient_dob,
            created_by = {}, patient_location, notified_person = "", phleb_activity_informations = [], order_status = {}, last_updated_datetime,
            order_creation_timezone = {}, last_updated_timezone = {}, collection_timezone = {} } = c;

          let tempNotes = notes ? notes.length ? notes.sort((a, b) => new Date(a.created_date_time) > new Date(b.created_date_time)
            ? -1
            : new Date(a.created_date_time) < new Date(b.created_date_time)
              ? 1
              : 0) : [] : []
          const cancellationNotes = tempNotes.filter(note => note.note_type && note.note_type.id == 3);
          const notesWithoutCancellation = tempNotes.filter(note => note.note_type && note.note_type.id != 3);

          let tempCheckIn = {};
          let tempCheckOut = {};
          let tempDropOff = {};

          let checkIns =
            phleb_activity_informations.filter((act) => act.action_type_id === 1) ||
            [];
          let checkOuts =
            phleb_activity_informations.filter((act) => act.action_type_id === 2) ||
            [];
          let dropOffs =
            phleb_activity_informations.filter((act) => act.action_type_id === 3) ||
            [];
          let checkInObj = {};
          let checkOutObj = {};
          let dropOffObj = {};

          if (dropOffs.length) {
            let checkinIndex = findLastIndex(
              phleb_activity_informations,
              (act) => act.action_type_id == 1
            );

            checkInObj = phleb_activity_informations[checkinIndex] || {};

            let checkoutIndex = findLastIndex(
              phleb_activity_informations,
              (act) => act.action_type_id == 2
            );

            checkOutObj = phleb_activity_informations[checkoutIndex] || {};
            dropOffObj = dropOffs.length
            ? dropOffs.reduce((a, b) => {
              return new Date(a.action_datetime) > new Date(b.action_datetime) ? a : b;
            })
            : undefined;
          } else {
            if (checkIns.length > checkOuts.length) {
              checkInObj = phleb_activity_informations.length
                ? phleb_activity_informations[phleb_activity_informations.length - 1]
                  ? phleb_activity_informations[
                    phleb_activity_informations.length - 1
                  ].action_type_id === 1
                    ? phleb_activity_informations[
                    phleb_activity_informations.length - 1
                    ]
                    : phleb_activity_informations[
                      phleb_activity_informations.length - 2
                    ]
                      ? phleb_activity_informations[
                        phleb_activity_informations.length - 2
                      ].action_type_id === 1
                        ? phleb_activity_informations[
                        phleb_activity_informations.length - 2
                        ]
                        : {}
                      : {}
                  : {}
                : {};

              checkOutObj = {};
            } else {
              let checkinIndex = findLastIndex(
                phleb_activity_informations,
                (act) => act.action_type_id == 1
              );

              checkInObj = phleb_activity_informations[checkinIndex] || {};

              let checkoutIndex = findLastIndex(
                phleb_activity_informations,
                (act) => act.action_type_id == 2
              );

              checkOutObj = phleb_activity_informations[checkoutIndex] || {};
            }
            dropOffObj = dropOffs.length
            ? dropOffs.reduce((a, b) => {
              return new Date(a.action_datetime) > new Date(b.action_datetime) ? a : b;
            })
            : undefined;
          }

          if (checkInObj && Object.keys(checkInObj).length) {
            tempCheckIn = {
              ...checkInObj,
              actionDateTime: checkInObj.action_datetime
                ? new Date(checkInObj.action_datetime)
                : ""
            };
          }

          if (checkOutObj && Object.keys(checkOutObj).length) {
            tempCheckOut = {
              ...checkOutObj,
              actionDateTime: checkOutObj.action_datetime
                ? new Date(checkOutObj.action_datetime)
                : ""
            };
          }

          if (dropOffObj) {
            tempDropOff = {
              ...dropOffObj,
              actionDateTime: dropOffObj.action_datetime ? new Date(dropOffObj.action_datetime) : "",
            };
          }

          const checkInDateTime = order_status && (order_status.id == UNACKNOWLEDGED_STATUS || order_status.id == ACKNOWLEDGED_STATUS) && phleb_activity_informations.length > 0
            ? {}
            : tempCheckIn;

          const checkOutDateTime = order_status && (order_status.id == UNACKNOWLEDGED_STATUS || order_status.id == ACKNOWLEDGED_STATUS) && phleb_activity_informations.length > 0
            ? {}
            : tempCheckOut;

            const dropOffDateTime = order_status && (order_status.id == UNACKNOWLEDGED_STATUS || order_status.id == ACKNOWLEDGED_STATUS) && phleb_activity_informations.length > 0
            ? {}
            : tempDropOff;

          let collectionMethods = order_line_items ? order_line_items.length ? order_line_items.filter(item => item.order_collection_method && item.order_collection_method.collection_type) : [] : [];
          collectionMethods = collectionMethods ? collectionMethods.length ? collectionMethods.map(item => item.order_collection_method && item.order_collection_method.collection_type).join("; ") : "-" : "-"

          let collectionDates = order_line_items ? order_line_items.length ? order_line_items.filter(item => item.collection_date) : [] : [];
          collectionDates = collectionDates ? collectionDates.length ? collectionDates.map(item => moment(new Date(`${item.collection_date} 00:00:00`)).format("MM/DD/yyyy")).join("; ") : "-" : "-"

          return {
            ...c,
            patientNameString: `${patient_first_name} ${patient_last_name}`,
            notes_without_cancellation_string: notesWithoutCancellation.length ? notesWithoutCancellation.map(note => note.notes).join(", ") : "-",
            cancellation_notes_string: cancellationNotes.length ? cancellationNotes.map(note => note.notes).join(", ") : "-",
            childAccountAddressString: `${child_account_address1} ${child_account_address2} ${child_city ? `, ${child_city}` : ""} ${child_state ? child_state.state_name ? `, ${child_state.state_name}` : "" : ""} ${child_zip ? `, ${child_zip}` : ""}`,
            order_creation_datetime_for_modal: order_creation_datetime,
            order_creation_datetime: `${moment(new Date(order_creation_datetime)).format("MM/DD/yyyy HH:mm:ss")} ${order_creation_timezone ? order_creation_timezone.abbreviation || "" : ""}`,
            phleb: user ? user.name ? user.name : "-" : "-",
            phlebEmployeeId: user ? user.employee_id ? user.employee_id : "-" : "-",
            parent_account_name_string: c.parent_account_name || "-",
            parent_account_number_string: c.parent_account_number || "-",
            child_account_number_string: c.child_account_number || "-",
            child_account_name_string: c.child_account_name || "-",
            ordering_physician_string: c.ordering_physician || "-",
            region_name_string: c.region ? c.region.region_name || "-" : "-",
            territory_name_string: c.territory ? c.territory.territory_name || "-" : "-",
            zone_name_string: c.zone ? c.zone.zone_name || "-" : "-",
            patient_dob_string: !_.isEmpty(patient_dob) ? moment(new Date(`${patient_dob} 00:00:00`)).format("MM/DD/yyyy") : "-",
            gender_string: c.gender ? c.gender.type || "-" : "-",
            patient_location_string: c.patient_location || "-",
            test_codes_string: order_line_items ? order_line_items.length ? order_line_items.map(item => hl7 ? item.test_code : item.test_code_id ? item.test_code_id.test_code : "").join("; ") : "-" : "-",
            drop_point: c.drop_point || "-",
            drop_point_location: c.drop_point_location || "-",
            collection_time_string: `${moment(collection_time).format("MM/DD/yyyy HH:mm:ss")} ${collection_timezone ? collection_timezone.abbreviation || "" : ""}`,
            "OrderCreationUser": created_by ? created_by.name || "-" : "-",
            "PatientLocation": patient_location || "-",
            "NotifiedPerson": !_.isEmpty(notified_person) ? notified_person : "-",
            "SupervisorName": !_.isEmpty(user) ? user.reporting_manager_name ? user.reporting_manager_name : "-" : "-",
            "OrderCollectionMethod": collectionMethods,
            "CollectionDate": collectionDates,
            "TestName": order_line_items ? order_line_items.length ? order_line_items.map(item => item.test_code_name || (item.test_code_id ? item.test_code_id.test_code_name : "")).join("; ") : "-" : "-",
            "CheckIn": checkInDateTime ? checkInDateTime.action_datetime ? `${moment(new Date(checkInDateTime.action_datetime)).format("MM/DD/yyyy HH:mm:ss")} ${checkInDateTime.timezone ? checkInDateTime.timezone.abbreviation || "" : ""}` : "-" : "-",
            "CheckOut": checkOutDateTime ? checkOutDateTime.action_datetime ? `${moment(new Date(checkOutDateTime.action_datetime)).format("MM/DD/yyyy HH:mm:ss")} ${checkOutDateTime.timezone ? checkOutDateTime.timezone.abbreviation || "" : ""}` : "-" : "-",
            "lastUpdated": last_updated_datetime ? `${moment(new Date(last_updated_datetime)).format("MM/DD/yyyy HH:mm:ss")} ${last_updated_timezone ? last_updated_timezone.abbreviation || "" : ""}` : "-",
            "DropOff": dropOffDateTime ? dropOffDateTime.action_datetime ? `${moment(new Date(dropOffDateTime.action_datetime)).format("MM/DD/yyyy HH:mm:ss")} ${dropOffDateTime.timezone ? dropOffDateTime.timezone.abbreviation || "" : ""}` : "-" : "-",
          }
        })
        res.content = { orders, count };
        return res;
      })
      .catch((err) => {
        return rejectWithValue(err.response.data);
      });

    return response;
  }
);

export const timedDrawSlice = createSlice({
  name: "timedDraws",
  initialState,
  reducers: {
    setTimedDrawsList(state, action) {
      state.value = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTimedDraws.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTimedDraws.fulfilled, (state, action) => {
        state.loading = false;
        state.value = action.payload;
      })
      .addCase(getTimedDraws.rejected, (state, err) => {
        state.loading = false;
        state.value = err.payload;
      });
  },
});

export const timedDraws = (state) => state.timedDraws.value;
export const timedDrawsLoader = (state) => state.timedDraws.loading;
export const { setTimedDrawsList } = timedDrawSlice.actions;

export default timedDrawSlice.reducer;
